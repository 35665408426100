import styles from './index.module.css';
import newThoughtworksLogo from '../../../../assets/Icons/newThoughtWorksLogo.svg';
import journeyBg from '../../../../assets/Icons/selfReflectIcon.png';
import { Link, useHistory } from 'react-router-dom';
import * as PATH from '../../../../constants/path';
import { DASHBOARD_WITH_TARGET } from '../../../../constants/path';
import LogoArrowDown from '../../../../assets/Icons/landingPageIcons/arrow.svg';
import React from 'react';
import { Button } from '@mui/material';
import globalStyles from '../../../../globalStyles';
import { ModuleLine } from '../../../../nonFeatureComponents/ModuleLine';
import { DASHBOARD_URL_TAB } from '../../../../constants/dashborad';
import * as DOMPurify from 'dompurify';
import { landingPageDes } from '../../language';
import { styled } from '@mui/material/styles';
export const JourneyModule = ({ videoRef }) => {
  const StartSelfReflectingButton = styled(Button)({
    color: globalStyles.white,
    backgroundColor: globalStyles.magenta,
    minWidth: '252px',
    height: '60px',
    fontWeight: 'bold',
    fontSize: 20,
    textTransform: 'none',
    boxShadow: '0 2px 4px 0 rgba(95, 95, 95, 0.5)',
    opacity: '0.9',
    '&:hover': {
      backgroundColor: globalStyles.magenta,
      opacity: '1'
    },
    '&:focus': {
      backgroundColor: globalStyles.magenta,
      opacity: '1',
      outline: 'none'
    }
  });
  const sanitizer = DOMPurify.sanitize;
  const {
    title,
    description,
    selfReflectBtn,
    tellMeMore
  } = landingPageDes.journeyModule;

  const history = useHistory();
  const goMeNowDashboard = () => {
    history.push(DASHBOARD_WITH_TARGET + DASHBOARD_URL_TAB.ME_NOW);
  };

  const handleTellMeMoreClick = e => {
    e.preventDefault();
    const pathwaysModule = videoRef.current;
    pathwaysModule &&
      pathwaysModule.scrollIntoView({ block: 'start', behavior: 'smooth' });
  };
  return (
    <section className={styles.journey}>
      <div className={styles.twLogoWrapper}>
        <div className={styles.wording}>At</div>
        <img
          src={newThoughtworksLogo}
          className={styles.newThoughtworksLogo}
          alt=""
        />
      </div>
      <h2 className={styles.journeyTitle}>{title}</h2>
      <ModuleLine />
      <p
        dangerouslySetInnerHTML={{
          __html: sanitizer(description)
        }}
      />
      <img src={journeyBg} alt="" className={styles.journeyBg} />
      <div className={styles.journeyBtns}>
        <Link
          to={PATH.DASHBOARD}
          className={styles.selfReflectBtn}
          tabIndex={0}
        >
          <StartSelfReflectingButton
            className={'reflectBtn'}
            onClick={() => {
              goMeNowDashboard();
            }}
          >
            {selfReflectBtn}
          </StartSelfReflectingButton>
        </Link>
        <div className={styles.tellMeMore} onClick={handleTellMeMoreClick}>
          <img src={LogoArrowDown} alt="tell me more" />
          {tellMeMore}
        </div>
      </div>
    </section>
  );
};
