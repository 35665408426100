import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
import styles from './index.module.css';
import { FLOW_PAGE_EVENT } from '../../../constants/matomo';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import {
  EDIT_REFLECT_SKILLS,
  REFLECT_COMPETENCIES,
  SELECT_ARCHETYPES,
  SELECT_COMPETENCIES,
  SELECT_SKILLS
} from '../../../constants/path';
import CompetencyAndArchetypeTipModal from '../../../feature/CompetencyAndArchetypeTipModal';
import { dashboardDes } from '../language';
import EntryPoint from '@nonFeatureComponents/EntryPoint';
import ArchetypesCapableOf from '../components/ArchetypesCapableOf';
import Competencies from '../components/Competencies';
import { TopCourseRecommendation } from '../components/TopCourseRecommendation';
import Skills from '../components/Skills';
import Communities from './Communities';
import ShareMeNextNotification from './ShareMeNextNotification';

const MeNextCard = props => {
  const history = useHistory();
  const { trackEvent } = useMatomo();

  const meNextCardDes = dashboardDes.meNextCard;
  const entryPointDes = dashboardDes.entryPoint;

  const [tipModalOpened, setTipModalOpened] = useState(false);
  const closeTipModal = () => setTipModalOpened(false);
  const navToArchetype = e => {
    e.preventDefault();
    trackEvent({
      category: FLOW_PAGE_EVENT.category,
      action: FLOW_PAGE_EVENT.action.ARCHETYPE
    });
    history.push(SELECT_ARCHETYPES);
  };
  const navToSelectCompetencies = e => {
    e.preventDefault();
    trackEvent({
      category: FLOW_PAGE_EVENT.category,
      action: FLOW_PAGE_EVENT.action.COMPETENCIES
    });
    history.push(SELECT_COMPETENCIES);
  };
  const navToSkills = e => {
    e.preventDefault();
    trackEvent({
      category: FLOW_PAGE_EVENT.category,
      action: FLOW_PAGE_EVENT.action.SKILL
    });
    history.push(SELECT_SKILLS);
  };

  return (
    <div className={styles.DashboardContainer}>
      <div className={styles.contentContainer}>
        <ShareMeNextNotification />
        <Grid spacing={2} className={styles.cards} container>
          <Grid item lg={6} md={6} xs={12} data-cy="meNowArchetypesCapableOf">
            <ArchetypesCapableOf
              title={meNextCardDes.archetypesTitle}
              setOpen={setTipModalOpened}
              archetypes={props.archetypes}
              emptyMessage={
                <div data-cy="archetype">
                  <EntryPoint
                    bg={2}
                    title={entryPointDes.archetype.title}
                    subTitle={entryPointDes.archetype.subtitle}
                    setOpen={setTipModalOpened}
                    onClick={navToArchetype}
                  />
                </div>
              }
            />
          </Grid>

          <Grid item lg={6} md={6} xs={12} data-cy="meNowCompetencies">
            <Competencies
              title={meNextCardDes.competenciesTitle}
              setOpen={setTipModalOpened}
              isLoading={props.isLoading}
              competencies={props.competencies}
              emptyMessage={
                <div data-cy="coreCompetencies">
                  <EntryPoint
                    bg={1}
                    title={entryPointDes.competency.title}
                    subTitle={entryPointDes.competency.subtitle}
                    setOpen={setTipModalOpened}
                    onClick={navToSelectCompetencies}
                  />
                </div>
              }
              editTargetPath={{
                path: REFLECT_COMPETENCIES
              }}
            />
          </Grid>

          <Grid item lg={6} md={6} xs={12} data-cy="meNextSkills">
            <Skills
              title={meNextCardDes.skillTitle}
              setOpen={setTipModalOpened}
              isLoading={props.isLoading}
              skills={props.skills}
              emptyMessage={
                <div data-cy="skills">
                  <EntryPoint
                    bg={3}
                    title={entryPointDes.skill.title}
                    subTitle={entryPointDes.skill.subtitle}
                    setOpen={setTipModalOpened}
                    onClick={navToSkills}
                  />
                </div>
              }
              editTargetPath={{
                path: EDIT_REFLECT_SKILLS
              }}
            />
          </Grid>

          {props.isGraduate && (
            <Grid item lg={6} md={6} xs={12} data-cy="topCourseRecommendation">
              <TopCourseRecommendation tabIndex={0} />
            </Grid>
          )}

          <Grid item lg={6} md={6} xs={12} data-cy="learningCommunities">
            <Communities />
          </Grid>
        </Grid>
      </div>
      <CompetencyAndArchetypeTipModal
        tipModalOpened={tipModalOpened}
        closeTipModal={closeTipModal}
      />
    </div>
  );
};

MeNextCard.propTypes = {
  archetypes: PropTypes.array.isRequired,
  competencies: PropTypes.array.isRequired,
  skills: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLoadErr: PropTypes.bool.isRequired
};
export default MeNextCard;
