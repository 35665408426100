import React from 'react';
import clsx from 'clsx';
import Modal from '@mui/material/Modal';
import PropTypes from 'prop-types';
import {
  ModalCancelButton,
  ModalSaveButton
} from '../../../../nonFeatureComponents/Buttons/Buttons';
import Close from '../../../../assets/Icons/Close';
import FocusIcon from '../../../../assets/Icons/focus-3-line.png';
import styles from './index.module.css';
import BadgeIcon from '../../../../components/Common/SvgIcons/BadgeIcon';
import { onKeyDown } from '../../../../utils/onKeyDown';
import { dashboardDes } from '../../language';
import { styled } from '@mui/material/styles';

const ModalStyles = styled(Modal)({
  overflowY: 'auto'
});

export default function GoalModal({
  open,
  close,
  title,
  loading,
  children,
  onConfirm,
  cancelText,
  confirmText,
  isAccomplish,
  isShowAccomplish,
  handleOpenAccomplishModal,
  disableBackdropClose
}) {
  return (
    <ModalStyles
      aria-modal={true}
      disableScrollLock
      disablebackdropclose={disableBackdropClose.toString()}
      open={open}
      onClose={close}
    >
      <div aria-labelledby="init" className={styles.modal}>
        <div className={styles.title}>
          <div id="init" className={styles.centerlize}>
            <span className={styles.icon}>
              <img src={FocusIcon} alt="" />
            </span>
            {title}
          </div>
          <div
            tabIndex={0}
            role="button"
            onClick={close}
            onKeyDown={e => onKeyDown(e, close)}
            className={styles.close}
          >
            <span className={styles.closeInner} tabIndex={-1}>
              <Close />
            </span>
          </div>
        </div>
        <div className={clsx(styles.content, styles.primaryScrollbar)}>
          {children}
        </div>
        <div className={styles.operations}>
          {isShowAccomplish && (
            <div className={styles.accomplishLinkInfo}>
              <BadgeIcon className={styles.badgeIcon}></BadgeIcon>
              {!isAccomplish ? (
                <div
                  onKeyDown={e => onKeyDown(e, handleOpenAccomplishModal)}
                  className={styles.makeAccomplish}
                  onClick={handleOpenAccomplishModal}
                  tabIndex={0}
                >
                  <span className={styles.makeAccomplishInner} tabIndex={-1}>
                    {dashboardDes.myPlanCardDes.markAsAccomplishedButton}
                  </span>
                </div>
              ) : (
                <div className={styles.hasAccomplished}>
                  {dashboardDes.myPlanCardDes.goalModal.markAsAccomplishedDes}
                </div>
              )}
            </div>
          )}

          {!isAccomplish && (
            <div className={styles.buttonContainer}>
              <ModalCancelButton
                variant="outlined"
                disabled={loading}
                onClick={close}
                data-cy="cancel"
              >
                {cancelText || 'Cancel'}
              </ModalCancelButton>
              <ModalSaveButton
                variant="contained"
                disabled={loading}
                className={styles.marginLeft}
                onClick={onConfirm}
                data-cy="confirm"
              >
                {confirmText || 'Confirm'}
              </ModalSaveButton>
            </div>
          )}
        </div>
      </div>
    </ModalStyles>
  );
}

GoalModal.defaultProps = {
  isShowAccomplish: false
};
GoalModal.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  children: PropTypes.object.isRequired,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  onConfirm: PropTypes.func,
  loading: PropTypes.bool,
  isShowAccomplish: PropTypes.bool,
  isAccomplish: PropTypes.bool,
  handleOpenAccomplishModal: PropTypes.func,
  disableBackdropClose: PropTypes.bool
};
