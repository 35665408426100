import React, { useState } from 'react';
import styles from './index.module.css';
import ConfirmationModal from '../../../../../feature/ConfirmationModal';
import {
  CANCEL_BUTTON_TEXT,
  CONFIRM_BUTTON_TEXT,
  REMOVE_ACHIEVEMENT_CONTENT,
  REMOVE_ACHIEVEMENT_TITLE
} from '../../../../../constants/modalConstants';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { List } from '@mui/material';
export const ListWithStyles = styled(List)({
  padding: '0px',
  width: '100%',
  height: 'auto'
});

export const CustomList = ({ list, deleteItem, renderItem }) => {
  const [dialogId, setDialogId] = useState('');

  return (
    <ListWithStyles className={styles.list} data-cy="interestList">
      {list.map(item => (
        <React.Fragment key={item.id}>
          {renderItem({ item, onClick: () => setDialogId(item.id) })}
          <ConfirmationModal
            content={REMOVE_ACHIEVEMENT_CONTENT}
            title={REMOVE_ACHIEVEMENT_TITLE}
            open={dialogId === item.id}
            setOpen={setDialogId}
            confirm={() => deleteItem(dialogId)}
            data={item.id}
            confirmButtonText={CONFIRM_BUTTON_TEXT}
            cancelButtonText={CANCEL_BUTTON_TEXT}
          />
        </React.Fragment>
      ))}
    </ListWithStyles>
  );
};

CustomList.propTypes = {
  list: PropTypes.array.isRequired,
  deleteItem: PropTypes.func.isRequired,
  renderItem: PropTypes.func.isRequired
};
