export const PathNameAndPageTitle = [
  { pageTitle: 'Pathways', pathName: '/' },
  { pageTitle: 'Dashboard | Pathways', pathName: '/dashboard' },
  {
    pageTitle: 'Select archetypes | Pathways',
    pathName: '/select-archetypes'
  },
  {
    pageTitle: 'Reflect archetypes | Pathways',
    pathName: '/reflect-archetypes'
  },
  {
    pageTitle: 'Archetypes intention | Pathways',
    pathName: '/archetypes-intention'
  },
  {
    pageTitle: 'Select competencies | Pathways',
    pathName: '/select-competencies'
  },
  {
    pageTitle: 'Reflect competencies | Pathways',
    pathName: '/reflect-competencies'
  },
  {
    pageTitle: 'Select skills | Pathways',
    pathName: '/select-skills'
  },
  {
    pageTitle: 'Reflect skills | Pathways',
    pathName: '/reflect-skills'
  },
  {
    pageTitle: 'Course recommendations | Pathways',
    pathName: '/course-recommendations'
  }
];
