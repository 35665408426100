import PropTypes from 'prop-types';
import React from 'react';
import styles from './PDFMyInterest.module.css';

const PDFMyInterest = ({ title, interests }) => {
  return (
    <section className={styles.myInterestGroup}>
      <p className={styles.pdfInterestTitle}>{title}</p>
      {interests.length > 0 ? (
        <div className={styles.interestList}>
          <ul>
            {interests.map(item => (
              <li key={item.id} className={styles.interestItem}>
                {item.content}
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </section>
  );
};

PDFMyInterest.propTypes = {
  title: PropTypes.string.isRequired,
  interests: PropTypes.array.isRequired,
  type: PropTypes.number.isRequired
};

export default PDFMyInterest;
