import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Route, Switch, useHistory } from 'react-router-dom';
import { LoginCallback, SecureRoute, Security } from '@okta/okta-react';
import React from 'react';
import { callbackPath, getEnvVars } from '../config/config';

const { oktaAuth: auth } = getEnvVars();

const oktaAuth = new OktaAuth({
  issuer: auth.issuer,
  clientId: auth.clientId,
  redirectUri: auth.redirectUri,
  scopes: ['openid', 'email', 'profile'],
  pcke: true
});

export const Authentication = ({ children }) => {
  const history = useHistory();

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Switch>
        <Route path={callbackPath} exact={true} component={LoginCallback} />
        <SecureRoute path={'/'}>{children}</SecureRoute>
      </Switch>
    </Security>
  );
};
