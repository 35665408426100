import Button from '@mui/material/Button';
import globalStyles from '../../globalStyles';
import { styled } from '@mui/material/styles';
import { onKeyDown } from '../../utils/onKeyDown';
import styles from './Button.module.css';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { withStyles } from '@mui/styles';

export const DefaultButton = styled(Button)({
  borderRadius: '7px',
  padding: '16px',
  fontSize: '14px',
  textTransform: 'none',
  lineHeight: '1.4em',
  background: globalStyles.white,
  border: '1px solid',
  borderColor: globalStyles.black3,
  '&:hover': {
    backgroundColor: globalStyles.white
  }
});

export const ModalCancelButton = styled(Button)({
  minWidth: '120px',
  padding: '10px 0',
  borderColor: globalStyles.black3,
  borderRadius: '7px',
  textTransform: 'capitalize',
  backgroundColor: globalStyles.white,
  color: globalStyles.black3,
  '&:hover': {
    backgroundColor: globalStyles.white
  },
  '&.MuiButton-root:hover': {
    border: '1px solid'
  }
});

export const ModalSaveButton = styled(Button)({
  minWidth: '120px',
  width: 'auto',
  padding: '10px',
  borderRadius: '7px',
  color: globalStyles.white,
  fontWeight: 'bold',
  textTransform: 'none',
  backgroundColor: globalStyles.black3,
  '&:hover': {
    backgroundColor: globalStyles.black3
  }
});

export const GotButton = styled(Button)({
  width: '180px',
  height: '44px',
  backgroundColor: globalStyles.pdfRead,
  color: globalStyles.white,
  fontWeight: 'bold',
  fontSize: '16px',
  borderRadius: '7px',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: globalStyles.hoverRed
  }
});

export const ConfirmButton = styled(DefaultButton)({
  minWidth: '12em',
  height: '48px',
  fontSize: '16px',
  margin: '0px 15px',
  lineHeight: '1.5',
  padding: '10px',
  backgroundColor: globalStyles.magenta,
  border: globalStyles.magenta,
  '&:hover': {
    backgroundColor: globalStyles.magenta
  },
  color: globalStyles.white
});
export const CancelButton = styled(DefaultButton)({
  minWidth: '12rem',
  height: '48px',
  fontSize: '16px',
  margin: '0px 15px',
  color: globalStyles.black3,
  lineHeight: '1.5',
  padding: '10px',
  '&:hover': {
    backgroundColor: globalStyles.white
  }
});

export const CloseIconButton = withStyles({
  root: {}, // a style rule
  label: {} // a nested style rule
})(props => {
  return (
    <div
      role="button"
      aria-label="close"
      tabIndex={0}
      onClick={() => props.handleClick(false)}
      onKeyDown={e => onKeyDown(e, () => props.handleClick(false))}
      className={styles.close}
    >
      <span className={styles.closeInner} tabIndex={-1}>
        <CloseIcon role="button" titleAccess="close" />
      </span>
    </div>
  );
});
