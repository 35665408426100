import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from 'react';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import CloseIcon from '@mui/icons-material/Close';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import globalStyles from '../../../../globalStyles';
import styles from './index.module.css';
import Checkbox from '@mui/material/Checkbox';
import InformationFillIcon from '../../../../components/Common/SvgIcons/InformationFillIcon';
import { inputBlackList } from '../../../../constants/inputBlackList';
import { onKeyDown } from '../../../../utils/onKeyDown';
import { dashboardDes } from '../../language';
import { styled } from '@mui/material/styles';

export const CustomCheckbox = styled(Checkbox)({
  '& svg': {
    width: '30px',
    height: '30px',
    color: globalStyles.grey23
  },
  '&.MuiCheckbox-root': {
    '&:hover': {
      backgroundColor: 'rgba(245, 0, 87, 0.04)'
    }
  },
  '&.Mui-checked svg': {
    color: globalStyles.primaryGreen
  },
  '&.Mui-disabled svg': {
    opacity: 0.4
  }
});
export const GoalAddButton = styled(Button)({
  width: '75px',
  padding: '4px 0',
  borderColor: globalStyles.black3,
  borderRadius: '29px',
  textTransform: 'none',
  zIndex: 2,
  backgroundColor: globalStyles.white,
  color: globalStyles.black3,
  '&:hover': {
    backgroundColor: globalStyles.white
  },
  '&.MuiButton-root:hover': {
    border: '1px solid'
  }
});
export const InputWithButton = styled(TextField)({
  width: '89%',

  '& .MuiOutlinedInput-root': {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    },
    '& .MuiOutlinedInput-input': {
      border: '1px solid #e1e2e3',
      borderRadius: '30.5px 0 0 30.5px',
      borderRight: 'none',
      padding: '10px',
      height: '19px',
      '&::-webkit-input-placeholder': {
        textIndent: '10px',
        color: globalStyles.goalCreatedPlaceholder,
        fontSize: '16px',
        fontStyle: 'italic'
      }
    }
  }
});

const GoalInputForm = forwardRef((props, ref) => {
  const [value, setValue] = useState(props?.value || []);
  const [isError, setError] = useState(false);
  const [valueInput, setValueInput] = useState('');
  const addGap = gap => {
    setValue([gap.name, ...value]);
  };
  const addAction = action => {
    setValue([{ name: action.name, isDone: false }, ...value]);
  };
  const addCategory = category => {
    setValue([category.name, ...value]);
  };
  const deleteGap = id => {
    setValue([...value.filter((item, index) => index !== id)]);
  };
  const changeActionIsDone = index => {
    const newValue = value.map((item, i) => {
      if (index === i) {
        item.isDone = !item.isDone;
      }
      return item;
    });
    setValue(newValue);
  };

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value;
      },
      setValue(value) {
        setValue(value);
      },
      isInValidate() {
        return isError;
      },
      setInputValue(value) {
        setValueInput(value);
      }
    };
  });

  return (
    <div>
      <div className={styles.inputName}>{props.title}</div>
      <div className={styles.inputDescription}>{props.des}</div>
      {!props.disableEdit && (
        <InputWithButtonWrapper
          placeholder={props.placeholder ? props.placeholder : ' '}
          addItem={
            props.isGap ? addGap : props.isCategory ? addCategory : addAction
          }
          disabled={false}
          setError={setError}
          isError={isError}
          item={valueInput}
          setItem={setValueInput}
        />
      )}
      {props.disableEdit && value.length === 0 && (
        <div className={styles.noItemInformation}>
          <InformationFillIcon className={styles.informationIcon} />
          <span>
            {props.isGap
              ? dashboardDes.myPlanCardDes.goalGapAndAction.noGaps
              : props.isCategory
              ? dashboardDes.myPlanCardDes.goalGapAndAction.noCategories
              : dashboardDes.myPlanCardDes.goalGapAndAction.noActions}
          </span>
        </div>
      )}
      <GoalListWrapper
        list={value}
        deleteItem={deleteGap}
        renderItem={({ item, index, onClick }) => (
          <div className={styles.item}>
            <div
              className={clsx(styles.itemLeft, item.isDone && styles.greenBgc)}
              data-cy="gapAndActionList"
            >
              <span>
                {props.isGap ? item : props.isCategory ? item : item.name}
              </span>
              {!props.disableEdit && !item.isDone && (
                <div
                  role="button"
                  title={
                    dashboardDes.myPlanCardDes.goalGapAndAction.removeButton
                  }
                  className={styles.deleteIcon}
                  onClick={onClick}
                  onKeyDown={e => onKeyDown(e, onClick)}
                  tabIndex={0}
                >
                  <CloseIcon className={styles.deleteIconInner} tabIndex={-1} />
                </div>
              )}
            </div>
            {!props.isGap && !props.isCategory && (
              <div
                className={clsx(
                  item.isDone ? styles.greenColor : styles.checkbox
                )}
                data-cy="actionCheckBox"
              >
                <CustomCheckbox
                  checked={item.isDone}
                  onChange={() => {
                    changeActionIsDone(index);
                  }}
                  disabled={props.disableEdit}
                />
                Done
              </div>
            )}
          </div>
        )}
      />
    </div>
  );
});

const InputWithButtonWrapper = ({
  placeholder,
  addItem,
  disabled,
  isError,
  setError,
  item,
  setItem
}) => {
  const [errorMessage, setErrorMessage] = useState('');

  const handleAddItem = () => {
    if (item.trim().length > 0 && !isError && !disabled) {
      const newItem = { name: item.trim() };
      addItem(newItem);
      setItem('');
    }
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      handleAddItem();
    }
  };

  useEffect(() => {
    if (item.match(new RegExp(`[${inputBlackList}]`, 'g'))) {
      setError(true);
      setErrorMessage(`*Input should not contain ${inputBlackList}`);
    } else if (item.length > 160) {
      setError(true);
      setErrorMessage(
        dashboardDes.myPlanCardDes.goalGapAndAction.lengthErrorMessage
      );
    } else if (isError) {
      setError(false);
    }
  }, [isError, item, setError]);

  return (
    <div className={styles.inputWithButton} data-cy="gapActionInput">
      <div className={styles.inputWrapper}>
        <InputWithButton
          placeholder={placeholder}
          variant="outlined"
          value={item}
          onChange={event => setItem(event.target.value)}
          onKeyPress={handleKeyPress}
          disabled={disabled}
        />
        <div className={styles.errorMessage}>{isError && errorMessage}</div>
      </div>
      <div className={styles.addButton}>
        <GoalAddButton variant="outlined" onClick={handleAddItem}>
          Add
        </GoalAddButton>
      </div>
    </div>
  );
};

const GoalListWrapper = ({ list, renderItem, deleteItem }) => {
  const [isHidden, setIsHidden] = useState(true);

  const handleExpandButtonClick = () => {
    setIsHidden(!isHidden);
  };

  const shouldHideItems = (index, isHidden) => {
    return index >= 4 && isHidden;
  };

  return (
    <List className={styles.inputList}>
      {(list || []).map((item, index) => (
        <div
          className={styles.listItem}
          key={index}
          style={{
            display: shouldHideItems(index, isHidden) ? 'none' : 'block'
          }}
        >
          <React.Fragment>
            {renderItem({ item, index, onClick: () => deleteItem(index) })}
          </React.Fragment>
        </div>
      ))}
      {list.length > 4 && (
        <button className={styles.hideButton} onClick={handleExpandButtonClick}>
          {isHidden ? `Show all ${list.length} items` : 'Show less items'}
        </button>
      )}
    </List>
  );
};

InputWithButtonWrapper.propTypes = {
  placeholder: PropTypes.string.isRequired,
  addItem: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isError: PropTypes.bool,
  setError: PropTypes.func,
  item: PropTypes.string.isRequired,
  setItem: PropTypes.func.isRequired
};

GoalListWrapper.propTypes = {
  list: PropTypes.array.isRequired,
  renderItem: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired
};

GoalInputForm.propTypes = {
  value: PropTypes.array,
  title: PropTypes.string.isRequired,
  des: PropTypes.string.isRequired,
  isGap: PropTypes.bool.isRequired,
  disableEdit: PropTypes.bool,
  placeholder: PropTypes.string,
  isCategory: PropTypes.bool
};

export { GoalInputForm, GoalListWrapper, InputWithButtonWrapper };
