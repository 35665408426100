import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import styles from './index.module.css';
import LampIcon from '../../assets/Icons/Lamp.png';
import { onKeyDown } from '../../utils/onKeyDown';

const TipTrigger = props => {
  return (
    <div
      onClick={() => props.onOpen(!props.isOpen)}
      onKeyDown={e => onKeyDown(e, () => props.onOpen(!props.isOpen))}
      tabIndex={0}
      role="button"
    >
      <div
        className={clsx(styles.ovalWrapperInner, props.wrapperClassName)}
        tabIndex={-1}
      >
        <div className={clsx(styles.oval)}>
          <img src={LampIcon} alt="" />
        </div>
        <div className={clsx(props.textClassName, styles.ovalText)}>
          {props.content}
        </div>
      </div>
    </div>
  );
};

TipTrigger.propTypes = {
  onOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  content: PropTypes.string,
  wrapperClassName: PropTypes.string,
  textClassName: PropTypes.string
};

export default TipTrigger;
