import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

import styles from './ReflectList.module.css';

import {
  reflectListTextInfo,
  skillLevelBehaviours
} from './ReflectListConstants';
import { EDIT_REFLECT_SKILLS } from '../../../../constants/path';
import {
  removeByID as removeCompetencyByID,
  removeNewSelectedCompetencyName
} from '../../../../components/Utils/competenciesStorage';
import {
  filter,
  get,
  isEmpty,
  isEqual,
  isNil,
  sortBy,
  uniqBy,
  uniqWith
} from 'lodash';
import { orderedCategory } from '../../../SkillsSelectPage/language';
import { featureComponentsDes } from '../../../../feature/language';
import { removeCompetencyById } from '../../../../api/domain/competency';
import {
  removeByID as removeSkillByID,
  removeNewSelectedSkillName
} from '../../../../components/Utils/skillsStorage';
import { COMPETENCY_DEFAULT_PRACTICE_LEVEL } from '../../../../constants/competency';
import { SKILL_DEFAULT_PRACTICE_LEVEL } from '../../../../constants/skill';
import { removeSkillById } from '../../../../api/domain/skill';
import { ReflectItem } from '../../../../feature/ReflectItem';
import { SearchCategoryEmpty } from '../../../../nonFeatureComponents/SearchCategoryEmpty';
import { reflectPageDes } from '../../language';
import { CATEGORY_EMPTY } from '../../../../constants/modalConstants';

const ReflectList = ({
  competencies,
  emptyMessage,
  handleNameClick,
  reflectType,
  categoryName,
  allCategoryList,
  searchValue,
  getCurrentCompetencies,
  filterCompetencies,
  highlightedCompetency
}) => {
  const { push } = useHistory();
  const pathName = window.location.pathname;

  const goMeNextVisPreferences = async () => {
    await push('/visibility-preference');
  };
  const currentReflectListTextInfo = reflectListTextInfo(
    goMeNextVisPreferences
  )[reflectType];

  const handleChange = async changedItem => {
    let removeIndex = null;
    let newCompetencies = competencies.map((item, index) => {
      let newItem = item;
      const id = get(item, 'competencyId') || get(item, 'skillId');
      const changedItemId =
        get(changedItem, 'competencyId') || get(changedItem, 'skillId');
      if (id === changedItemId) {
        newItem = changedItem;
      }
      if (newItem.isRemove) {
        removeIndex = index;
      }
      return newItem;
    });
    const edit = async () => {
      if (reflectType === 'Competencies') {
        removeNewSelectedCompetencyName(changedItem.name);
      }
    };
    if (removeIndex !== null) {
      newCompetencies.splice(removeIndex, 1);
    } else {
      await edit();
    }
    getCurrentCompetencies(uniqWith(newCompetencies, isEqual));
    removeIndex = null;
  };

  const selectedData = () => {
    if (isEmpty(competencies) || isEmpty(filterCompetencies)) {
      if (!isEmpty(searchValue)) {
        return SearchCategoryEmpty(
          reflectPageDes.searchBarConstants,
          CATEGORY_EMPTY
        );
      }
      return <div className={styles.noData}>{emptyMessage}</div>;
    }

    let newSelectedData = {};
    let dataForDropDown = {};
    newSelectedData[currentReflectListTextInfo.defaultFilterValue] = [];
    competencies.forEach(item => {
      newSelectedData[currentReflectListTextInfo.defaultFilterValue].push(item);
    });

    sortBy(competencies, ['coreCapabilities']).forEach(item => {
      const { coreCapabilities } = item;
      if (reflectType === 'Competencies' && coreCapabilities) {
        coreCapabilities.forEach(c => {
          const coreCategoryName =
            currentReflectListTextInfo.coreCategoryRevertMapping[c];
          if (!newSelectedData[coreCategoryName]) {
            newSelectedData[coreCategoryName] = [];
          }
          newSelectedData[coreCategoryName].push(item);
        });
      }
    });
    sortBy(competencies, ['category']).forEach(item => {
      const { category = '' } = item;
      if (
        Object.keys(newSelectedData) &&
        Object.keys(newSelectedData).indexOf('' + category) === -1
      ) {
        newSelectedData[category] = [];
      }
      newSelectedData[category].push(item);
      if (
        Object.keys(dataForDropDown) &&
        Object.keys(dataForDropDown).indexOf('' + category) === -1
      ) {
        dataForDropDown[category] = [];
      }
      dataForDropDown[category].push(item);
    });

    if (reflectType === 'Skills') {
      const orderedSelectedData = {};
      orderedCategory.forEach(item => {
        if (!isNil(newSelectedData[item])) {
          orderedSelectedData[item] = newSelectedData[item];
        }
      });
      newSelectedData = orderedSelectedData;
    }

    if (!isEmpty(searchValue)) {
      let newList = {};
      allCategoryList &&
        allCategoryList.forEach((key, index) => {
          if (isEmpty(key)) return;
          if (!isEmpty(newSelectedData[key])) {
            newList[key] = newSelectedData[key].filter(item =>
              filterCompetencies.includes(item)
            );
          }
        });
      newSelectedData = newList;
    } else newSelectedData = dataForDropDown;

    const selectedReflectListDom = Object.keys(newSelectedData).map(
      (key, index) => {
        let title = key.toUpperCase();
        let filterData = [];
        if (!isEmpty(searchValue)) {
          filterData = newSelectedData[key];
        }

        if (
          key === categoryName ||
          categoryName === currentReflectListTextInfo.defaultFilterValue
        ) {
          filterData = newSelectedData[key];
        }

        if (
          Object.keys(currentReflectListTextInfo.coreCategoryMapping).includes(
            categoryName
          )
        ) {
          const coreCompetencies = filter(newSelectedData[key], item =>
            item?.coreCapabilities.includes(
              currentReflectListTextInfo.coreCategoryMapping[categoryName]
            )
          );
          filterData = coreCompetencies;
        }

        const showTitle =
          categoryName === currentReflectListTextInfo.defaultFilterValue ||
          !isEmpty(filterData);
        const showEmptyDom = isEmpty(filterData) && !isEmpty(searchValue);

        return (
          <div key={key} className={styles.categoryWrap}>
            {showTitle && <div className={styles.categoryTitle}>{title}</div>}
            {showEmptyDom ? (
              <div className={styles.categoryEmpty}>
                {SearchCategoryEmpty(
                  reflectType,
                  reflectType + 'ListSearchEmpty'
                )}
              </div>
            ) : (
              listItemFun(filterData)
            )}
          </div>
        );
      }
    );
    return <>{selectedReflectListDom}</>;
  };

  const listItemFun = selectedData => {
    return uniqBy(selectedData, 'name').map((item, index) => {
      return reflectType === 'Skills' ? (
        <ReflectItem
          reflectItem={item}
          levelDescriptions={skillLevelBehaviours}
          showName
          newSelectedStorageName={
            currentReflectListTextInfo.newSelectedNameString
          }
          onChange={data => handleChange(data)}
          defaultPracticeLevel={SKILL_DEFAULT_PRACTICE_LEVEL}
          description={featureComponentsDes.reflectItem.skill}
          searchValue={searchValue}
          removeInfo={{
            itemId: item.skillId,
            removeRequest: removeSkillById,
            removeStorageName: removeNewSelectedSkillName,
            removeStorageId: removeSkillByID
          }}
        />
      ) : (
        <ReflectItem
          reflectItem={item}
          key={item.competencyId}
          newSelectedStorageName={
            currentReflectListTextInfo.newSelectedNameString
          }
          defaultPracticeLevel={COMPETENCY_DEFAULT_PRACTICE_LEVEL}
          description={featureComponentsDes.reflectItem.competency}
          levelDescriptions={item.levelDescriptions}
          onChange={data => handleChange(data)}
          searchValue={searchValue}
          showName
          showItemDescription
          handleNameClick={() => handleNameClick(item)}
          removeInfo={{
            itemId: item.competencyId,
            removeRequest: removeCompetencyById,
            removeStorageName: removeNewSelectedCompetencyName,
            removeStorageId: removeCompetencyByID
          }}
          highlightedCompetency={highlightedCompetency}
        />
      );
    });
  };

  const isShowNewLatest =
    pathName !== EDIT_REFLECT_SKILLS &&
    currentReflectListTextInfo.newSelectedName &&
    currentReflectListTextInfo.newSelectedName.length !== 0;

  return (
    <>
      <div className={styles.title}>
        <div className={clsx(styles.firstColumn, styles.Competencies)}>
          <div>{currentReflectListTextInfo.firstColumn}</div>
          {isShowNewLatest && (
            <div className={styles.latestCompetencies}>
              <div className={styles.circle} />
              <div className={styles.wording}>Latest Selected</div>
            </div>
          )}
        </div>
        <div className={clsx(styles.secondColumn, styles.levelTitle)}>
          {currentReflectListTextInfo.secondColumn}
        </div>
        <div className={clsx(styles.thirdColumn, styles.isLearningPriority)}>
          {currentReflectListTextInfo.thirdColumn}
        </div>
        <div className={clsx(styles.fourthColumn, styles.flexEnd)} />
      </div>
      {selectedData(competencies)}
    </>
  );
};
ReflectList.propTypes = {
  competencies: PropTypes.array.isRequired,
  emptyMessage: PropTypes.string.isRequired,
  handleNameClick: PropTypes.func,
  reflectType: PropTypes.string,
  categoryName: PropTypes.string,
  allCategoryList: PropTypes.array,
  searchValue: PropTypes.string,
  tipModalState: PropTypes.func,
  highlightedCompetency: PropTypes.string
};
export default ReflectList;
