export const ErrorMessage = 'Matomo is required.';

export const CustomScope = {
  VISIT: 'visit',
  PAGE: 'page'
};

export const CustomName = {
  WORKINGOFFICE: 'working-office',
  EMAIL: 'email'
};

// The "index" parameter is the custom variable slot index, an integer from 1 to 5.
export const CustomIndex = {
  [CustomName.WORKINGOFFICE]: 1,
  [CustomName.EMAIL]: 2
};

export const setCustomVariable = (name, value, scope = CustomScope.PAGE) => {
  try {
    window._paq.push([
      'setCustomVariable',
      CustomIndex[name],
      name,
      value,
      scope
    ]);
  } catch (error) {
    throw new Error(ErrorMessage);
  }
};

export const getCustomVariable = (name, scope = CustomScope.VISIT) => {
  return new Promise((resolve, reject) => {
    try {
      window._paq.push([
        function() {
          const customVariable = this.getCustomVariable(
            CustomIndex[name],
            scope
          );
          resolve(customVariable);
        }
      ]);
    } catch (e) {
      reject(new Error(ErrorMessage));
    }
  });
};

export const deleteCustomVariable = (name, scope = CustomScope.VISIT) => {
  try {
    window._paq.push(['deleteCustomVariable', CustomIndex[name], scope]);
  } catch (e) {
    throw new Error(ErrorMessage);
  }
};
