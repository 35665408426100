import {
  FETCH_USER,
  UPDATE_USER,
  CREATE_USER_GOAL,
  FETCH_USER_GOALS,
  UPDATE_USER_GOAL,
  FETCH_USER_ARCHETYPES,
  FETCH_USER_REFLECTED_COMPETENCIES,
  FETCH_INTERESTS,
  ADD_INTEREST,
  REMOVE_INTEREST,
  FETCH_USER_REFLECTED_SKILLS,
  UPDATE_USER_ARCHETYPES,
  UPDATE_PROFILE,
  UPDATE_ARCHETYPE_STAGE,
  REMOVE_SKILLS
} from '../actions/profile';
import { isEmpty, cloneDeep, set, remove } from 'lodash';

export const profileReducer = (state, action) => {
  const newState = cloneDeep(state);
  switch (action.type) {
    //user
    case FETCH_USER:
    case UPDATE_USER:
      const { user } = newState;
      const { user: newUser } = action.payload;

      return {
        ...state,
        ...action.payload,
        user: {
          ...user,
          ...newUser
        }
      };
    case UPDATE_PROFILE:
      newState.user = {
        ...newState.user,
        ...action.payload
      };
      return newState;

    //archetype
    case FETCH_USER_ARCHETYPES:
      newState.user.reflectedArchetypes = action.payload;
      return newState;
    case UPDATE_USER_ARCHETYPES:
      newState.user.reflectedArchetypes = action.payload;
      return newState;
    case UPDATE_ARCHETYPE_STAGE:
      newState.user.reflectedArchetypes = newState.user.reflectedArchetypes.map(
        arche => {
          if (arche.archetypeId === action.payload.archetypeId) {
            return {
              ...arche,
              ...action.payload
            };
          }
          return arche;
        }
      );
      return newState;

    //competency
    case FETCH_USER_REFLECTED_COMPETENCIES:
      newState.user.reflectedCompetencies = action.payload;
      return newState;

    //skill
    case FETCH_USER_REFLECTED_SKILLS:
      set(newState, 'user.reflectedSkills', action.payload);
      return newState;
    case REMOVE_SKILLS:
      newState.user.reflectedSkills = remove(
        newState.user.reflectedSkills,
        skill => skill.reflectedInformation.id !== action.payload
      );
      return newState;

    //goal
    case CREATE_USER_GOAL:
      if (newState.user.goals) {
        newState.user.goals.push(action.payload);
      } else {
        newState.user.goals = [action.payload];
      }
      return newState;
    case FETCH_USER_GOALS:
      newState.user.goals = action.payload;
      return newState;
    case UPDATE_USER_GOAL:
      const targetGoalIndex = newState.user.goals.findIndex(
        goal => goal.id === action.payload.id
      );
      newState.user.goals[targetGoalIndex] = action.payload;
      return newState;

    //interest
    case FETCH_INTERESTS:
      newState.user.interests = action.payload;
      return newState;
    case ADD_INTEREST:
      if (!isEmpty(action.payload)) {
        newState.user.interests.unshift(action.payload);
      }
      return newState;
    case REMOVE_INTEREST:
      newState.user.interests = remove(
        newState.user.interests,
        interest => interest.id !== action.payload
      );
      return newState;
    default:
      return state;
  }
};
