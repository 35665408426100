import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ArchetypeDetailBaseModal from './Components/ArchetypeDetailBaseModal/ArchetypeDetailBaseModal';
import styles from './ArchetypeDetailModal.module.css';
import TellMeMoreButton from './Components/Buttons/TellMeMoreButton';
import ArchetypeDetailTabs from './Components/ArchetypeDetailTabs/ArchetypeDetailTabs';
import HexagonView from './Components/HexagonView/HexagonView';
import * as DOMPurify from 'dompurify';
import { useUserContext } from '../../../../contexts/UserContext';
import { updateArchetypes } from '../../../../actions/profile';
import { DevWhiteListForRecommendedCourses } from '../../../../constants/devWhiteListForRecommendedCourses';
import {
  COURSE_REC_FROM_DASHBOARD,
  COURSE_REC_FROM_SELECT_ARCHETYPE_EVENT
} from '../../../../constants/matomo';
import { PathwaysTrackEvents } from '../../../../support/pathwaysTrack';
import { getEnvVars } from '../../../../config/config';
import {
  fetchCourseRecommendations,
  fetchPeopleArchetypeDetail
} from '../../../../api/domain/archetype';
import { DASHBOARD_URL_TAB } from '../../../../constants/dashborad';
import { DASHBOARD, SELECT_ARCHETYPES } from '../../../../constants/path';
import { USER_GRADE } from '../../../../constants/user';
import Switch from '@mui/material/Switch';
import { FormControlLabel } from '@mui/material';
import globalStyles from '../../../../globalStyles';
import { onKeyDown } from '../../../../utils/onKeyDown';
import { getUrlParam } from '../../../Utils/CommonUtil';
import Loader from '../../../../nonFeatureComponents/Loader';
import { EmptyRecommendedCourse } from '../../../../nonFeatureComponents/EmptyRecommendedCourse';
import { RecommendedCourse } from '../../../../feature/RecommendedCourse';
import { styled } from '@mui/material/styles';

const sanitizer = DOMPurify.sanitize;

const { capableLink } = getEnvVars();

export const getArchetypeInformationLink = id => {
  return `${capableLink}/archetypes/${id}`;
};
export const ArchetypeDetailModal = props => {
  const { archetypeId, close, isShowSelectButton } = props;

  const [loading, setLoading] = useState(false);
  const [loadErr, setLoadErr] = useState(false);
  const [archetype, setArchetype] = useState(null);
  const [open, setOpen] = useState(false);
  const [histories, setHistories] = useState([]);

  useEffect(() => {
    const show = archetypeId => {
      fetch(archetypeId);
      setOpen(true);
    };
    archetypeId && show(archetypeId);
  }, [archetypeId]);

  const handleTellMeMoreClick = identifier => {
    window.open(getArchetypeInformationLink(identifier));
  };
  const fetch = archetypeId => {
    setLoading(true);
    setLoadErr(false);
    fetchPeopleArchetypeDetail(archetypeId)
      .then(r => {
        if (!r) {
          throw new Error('no match found');
        }
        setArchetype(r);
      })
      .catch(() => {
        setLoadErr(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onForward = (currentId, nextId) => {
    // setPrevName(currentName);
    setHistories([...histories, currentId]);
    fetch(nextId);
  };
  const handleBack = () => {
    const _histories = [...histories];
    fetch(_histories.pop());
    setHistories(_histories);
    // setPrevName('');
  };
  const handleClose = () => {
    close();
    setOpen(false);
    setHistories([]);
    setArchetype(null);
  };
  const hasBaseArchetype = useMemo(() => {
    if (!archetype) {
      return false;
    }
    return !!archetype.baseArchetypes;
  }, [archetype]);
  let content = null;
  if (loading) {
    content = <Loader />;
  } else if (loadErr) {
    content = (
      <p className={styles.textCenter}>
        Something went wrong, Please try again later
      </p>
    );
  } else if (archetype) {
    const contentProps = {
      onTellMeMoreClick: handleTellMeMoreClick,
      archetype,
      onForward,
      hasBaseArchetype,
      isShowSelectButton: isShowSelectButton !== false,
      competenciesLists: hasBaseArchetype
        ? [archetype.competencies, archetype.baseArchetypes.competencies]
        : [archetype.competencies]
    };
    content = <GenericContent {...contentProps} />;
  }
  return (
    <ArchetypeDetailBaseModal
      showBackButton={histories.length !== 0}
      onBack={handleBack}
      backText={''}
      title={archetype?.name || ''}
      open={open}
      close={handleClose}
    >
      {content}
    </ArchetypeDetailBaseModal>
  );
};

export const GenericContent = ({
  hasBaseArchetype,
  onForward,
  competenciesLists,
  archetype,
  onTellMeMoreClick,
  isShowSelectButton
}) => {
  const { state: userState, dispatch } = useUserContext();
  const { user } = userState;
  const addedArchetypeIds = user.reflectedArchetypes.map(a => a.archetypeId);
  const [tabIndex, setTabIndex] = useState(0);
  const [recommendedCourses, setRecommendedCourses] = useState(undefined);
  const isSelected = addedArchetypeIds.includes(archetype.archetypeId);

  const courseRecommendationFeatureToggle =
    DevWhiteListForRecommendedCourses.includes(user.email) ||
    user.grade === USER_GRADE.GRADUATE;

  useEffect(() => {
    async function fetchData() {
      fetchCourseRecommendations(archetype.archetypeId).then(courses => {
        if (courses.length > 6) {
          courses = courses.slice(0, 6);
        }
        setRecommendedCourses(courses);
      });
    }

    if (courseRecommendationFeatureToggle) {
      fetchData().then();
    }
  }, [archetype.archetypeId, courseRecommendationFeatureToggle]);

  const handleSelect = () => {
    const addedArchetypes = user.reflectedArchetypes;
    const { archetypeId, identifier, name } = archetype;
    const updatedArchetypes = isSelected
      ? addedArchetypes.filter(a => a.archetypeId !== archetypeId)
      : Array.from(
          new Set(
            addedArchetypes.concat([
              { archetypeId, identifier, archetypeName: name }
            ])
          )
        );
    const updatedIds = updatedArchetypes.map(a => {
      return { archetypeId: a.archetypeId };
    });
    updateArchetypes(dispatch)(updatedIds, updatedArchetypes);
  };

  const changeTabIndex = current => {
    setTabIndex(current);
    if (current === 2) {
      clickTopCoursesTab();
    }
  };

  const clickTopCoursesTab = () => {
    const isFromDashboard = window.location.pathname === DASHBOARD;
    const dashboardTab = getUrlParam('target') || DASHBOARD_URL_TAB.ME_NOW;

    isFromDashboard &&
      PathwaysTrackEvents([
        {
          category: COURSE_REC_FROM_DASHBOARD.category,
          action:
            dashboardTab === DASHBOARD_URL_TAB.ME_NOW
              ? COURSE_REC_FROM_DASHBOARD.action.CLICK_COURSE_TAB_FROM_ME_NOW
              : COURSE_REC_FROM_DASHBOARD.action.CLICK_COURSE_TAB_FROM_ME_NEXT
        }
      ]);

    const isFromSelectPage = window.location.pathname === SELECT_ARCHETYPES;
    isFromSelectPage &&
      PathwaysTrackEvents([
        {
          category: COURSE_REC_FROM_SELECT_ARCHETYPE_EVENT.category,
          action:
            COURSE_REC_FROM_SELECT_ARCHETYPE_EVENT.action.CLICK_TOP_COURSE_TAB
        }
      ]);
  };

  const SwitchWithStyles = styled(Switch)({
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    width: 22,
    height: 10,
    overflow: 'initial',
    '& .MuiSwitch-switchBase': {
      padding: '2px 2px 2px 4px',
      color: globalStyles.black3,
      '&.Mui-checked': {
        margin: '0px -4px',
        transform: 'translateX(12px)',
        color: globalStyles.white,
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: globalStyles.primaryGreen,
          borderColor: globalStyles.primaryGreen
        }
      }
    },
    '& .MuiSwitch-thumb': {
      width: 6,
      height: 6,
      boxShadow: 'none'
    },
    '& .MuiSwitch-track': {
      border: `2px solid ${[globalStyles.black3]}`,
      opacity: 1,
      backgroundColor: globalStyles.white
    }
  });

  const FormControlLabelWithStyles = styled(FormControlLabel)(props => ({
    margin: 0,
    '& .MuiFormControlLabel-label': {
      marginLeft: 9,
      width: 64,
      color:
        props.isSelected === true
          ? globalStyles.primaryGreen
          : globalStyles.black3
    }
  }));
  return (
    <>
      <section className={styles.singleIntroduceWrapper}>
        <div className={styles.singleIntroduceArchetypeWrapper}>
          <div
            className={clsx(
              styles.singleIntroduceArchetype,
              isSelected ? styles.selectedIntroduceArchetype : ''
            )}
          >
            <span className={styles.singleArchetypeName}>{archetype.name}</span>
            {isShowSelectButton && (
              <div className={styles.switchMask}>
                <FormControlLabelWithStyles
                  isSelected={isSelected}
                  control={
                    <SwitchWithStyles
                      checked={isSelected}
                      onChange={handleSelect}
                    />
                  }
                  label={isSelected ? `Deselect` : 'Select'}
                />
              </div>
            )}
          </div>
        </div>
        <div className={styles.singleIntroduceArchetypeDescription}>
          <div
            dangerouslySetInnerHTML={{
              __html: sanitizer(archetype.description)
            }}
          />
          <TellMeMoreButton
            onClick={() => onTellMeMoreClick(archetype.identifier)}
            onKeyPress={() => onTellMeMoreClick(archetype.identifier)}
          />
        </div>
      </section>
      <ArchetypeDetailTabs
        currentIndex={tabIndex}
        onChange={current => changeTabIndex(current)}
        tabs={
          courseRecommendationFeatureToggle
            ? ['Competencies', 'Outcomes', 'Top Courses']
            : ['Competencies', 'Outcomes']
        }
      />

      <section className={styles.tabPanel}>
        {tabIndex === 0 && (
          <>
            {hasBaseArchetype && (
              <div className={styles.baseArchetypeEntry}>
                <div>
                  This Archetype has Competencies associated with the{' '}
                  <span
                    onClick={() =>
                      onForward(
                        archetype.archetypeId,
                        archetype.baseArchetypes.archetypeId
                      )
                    }
                    onKeyDown={e =>
                      onKeyDown(e, () =>
                        onForward(
                          archetype.archetypeId,
                          archetype.baseArchetypes.archetypeId
                        )
                      )
                    }
                    role="button"
                    tabIndex={0}
                    aria-label={`This Archetype has Competencies associated with
                    the ${archetype.baseArchetypes.name}
                    Archetype plus some additional Competencies.`}
                    className={styles.baseArchetypeLink}
                  >
                    <span
                      className={styles.baseArchetypeLinkInner}
                      tabIndex={-1}
                    >
                      {archetype.baseArchetypes.name}
                    </span>
                  </span>{' '}
                  Archetype plus some additional Competencies.
                </div>
                <div className={styles.indicatorWrapper}>
                  <div className={styles.indicator}>{archetype.name}</div>
                  <div className={clsx(styles.indicator, styles.based)}>
                    {archetype.baseArchetypes.name}
                  </div>
                </div>
              </div>
            )}
            <HexagonView competenciesLists={competenciesLists} />
          </>
        )}
        {tabIndex === 1 && (
          <div>
            <ul className={styles.outcomesWrapper}>
              {archetype.outcomes?.map((outcome, index) => {
                return <li key={index}>{outcome}</li>;
              })}
            </ul>
            <OutcomesNotes />
          </div>
        )}
        {tabIndex === 2 && (
          <div>
            {recommendedCourses && recommendedCourses.length > 0 && (
              <div className={styles.topCourses}>
                <div className={styles.topCoursesText}>
                  Discover the <b>most enrolled courses</b> in Campus among
                  Thoughtworkers interested in {archetype.name}.
                </div>
                <ul className={styles.topCoursesWrapper}>
                  {recommendedCourses.map((course, index) => {
                    return (
                      <li className={styles.topCoursesItem} key={index}>
                        <RecommendedCourse
                          course={course}
                          archetypeId={archetype.archetypeId}
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}

            {recommendedCourses && recommendedCourses.length === 0 && (
              <EmptyRecommendedCourse />
            )}
          </div>
        )}
      </section>
    </>
  );
};
GenericContent.propTypes = {
  archetype: PropTypes.object.isRequired,
  competenciesLists: PropTypes.array.isRequired,
  onTellMeMoreClick: PropTypes.func.isRequired,
  onForward: PropTypes.func.isRequired,
  hasBaseArchetype: PropTypes.bool.isRequired
};

const OutcomesNotes = () => {
  return (
    <div className={styles.outcomeBoard}>
      <p className={styles.outcomesNotes}>
        If you are already fulfilling these outcomes today and/or in the past,
        then you can play this Archetype{' '}
        <span className={styles.bold}>now</span>. If not, you can still select
        it as an Archetype you would like to grow{' '}
        <span className={styles.bold}>next</span>.
      </p>
    </div>
  );
};
