import React from 'react';
import PropTypes from 'prop-types';
import Notification from '../../../../components/Common/Modal/Notification';
import styles from './index.module.css';
import { useUserContext } from '../../../../contexts/UserContext';
import { updateProfile, updateUser } from '../../../../actions/profile';
import { PathwaysTrackEvents } from '../../../../support/pathwaysTrack';
import { TAKE_THE_TOUR } from '../../../../constants/matomo';
import knowPathwaysModalTip from '../../../../assets/Icons/knowPathwaysModalTip.png';
import { dashboardDes } from '../../language';

const KnowPathwaysModal = ({ tipModalOpened, closeTipModal }) => {
  const {
    state: { user },
    dispatch
  } = useUserContext();
  const knowPathwaysModalDes = dashboardDes.knowPathwaysModal;
  const updateGuideStepNumber = number => {
    user.guideStepNumber = number;
    dispatch(updateUser(user));
  };

  const upsertOnBoardingTypeFun = () => {
    updateProfile(dispatch)({
      showOnboardingTour: false,
      showTopCourseGuide: false
    });
  };
  const takeQuickTour = () => {
    updateGuideStepNumber(1);
    closeTipModal();
    upsertOnBoardingTypeFun();
    PathwaysTrackEvents([
      {
        category: TAKE_THE_TOUR.category,
        action: TAKE_THE_TOUR.action.TAKE_QUICK_TOUR_ON_WELCOME_MODAL
      }
    ]);
  };
  return (
    <Notification
      open={tipModalOpened}
      close={() => {
        closeTipModal();
        updateGuideStepNumber(-1);
        upsertOnBoardingTypeFun();
      }}
      title={knowPathwaysModalDes.title}
      desc={
        <div className={styles.knowPathwaysMain}>
          <div className={styles.knowPathwaysImage}>
            <img src={knowPathwaysModalTip} alt="" />
          </div>
          <div className={styles.describeTitle}>
            {knowPathwaysModalDes.describeTitle}
          </div>
          <div className={styles.describeInfo}>
            {knowPathwaysModalDes.describeInfoPart1}
          </div>
          <div className={styles.describeInfo}>
            {knowPathwaysModalDes.describeInfoPart2}
          </div>
        </div>
      }
      btns={
        <div className={styles.knowPathwaysBtns}>
          <div
            className={styles.skipTourBtn}
            onClick={() => {
              closeTipModal();
              upsertOnBoardingTypeFun();
            }}
          >
            {knowPathwaysModalDes.skipTourBtn}
          </div>
          <div className={styles.takeQuickTourBtn} onClick={takeQuickTour}>
            {knowPathwaysModalDes.takeQuickTourBtn}
          </div>
        </div>
      }
    />
  );
};

KnowPathwaysModal.propTypes = {
  tipModalOpened: PropTypes.bool.isRequired,
  closeTipModal: PropTypes.func.isRequired
};

export default KnowPathwaysModal;
