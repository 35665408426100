import React from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import styles from './index.module.css';
import Logo from '../Common/SvgIcons/Logo';
import PropTypes from 'prop-types';

export const PathwaysLogo = props => {
  const {
    pathname,
    targetPath,
    modalClassName,
    smallScreenHideLogo = false,
    ...rest
  } = props;
  const history = useHistory();

  const handleLogoClick = e => {
    if (!targetPath) e.preventDefault();
    targetPath && history.push(targetPath);
  };
  return (
    <div {...rest}>
      <div
        className={clsx(
          styles.headerLeft,
          {
            [styles.headerLeftWithAfter]: props.pathname === '/'
          },
          {
            [styles.pointer]: props.targetPath
          }
        )}
        tabIndex="-1"
        onClick={handleLogoClick}
      >
        <Logo
          className={clsx(styles.pathwaysLogo, {
            [styles[modalClassName]]: modalClassName
          })}
        />
        <span
          className={clsx(
            styles.headerTitle,
            smallScreenHideLogo && styles.hideLogo
          )}
        >
          Pathways
        </span>
      </div>
    </div>
  );
};
PathwaysLogo.propTypes = {
  targetPath: PropTypes.string,
  smallScreenHideLogo: PropTypes.bool,
  pathname: PropTypes.string,
  modalClassName: PropTypes.string
};
