import React from 'react';
import PropTypes from 'prop-types';

const fixedStyle = {
  position: 'sticky',
  left: 0,
  top: 0,
  width: '100%',
  // do not cover the error dialog component
  zIndex: 1300
};

const FixedBar = props => {
  return <div style={fixedStyle}>{props.children}</div>;
};

FixedBar.propTypes = {
  children: PropTypes.object
};

export default FixedBar;
