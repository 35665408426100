import React from 'react';
import { DASHBOARD, REFLECT_ARCHETYPES } from '../../../../../constants/path';
import { getUrlParam } from '../../../../../components/Utils/CommonUtil';
import { DASHBOARD_URL_TAB } from '../../../../../constants/dashborad';
import { PathwaysTrackEvents } from '../../../../../support/pathwaysTrack';
import { COURSE_REC_FROM_DASHBOARD } from '../../../../../constants/matomo';
import { Link } from 'react-router-dom';
import { onKeyDown } from '../../../../../utils/onKeyDown';
import PropTypes from 'prop-types';
import { ReactComponent as EditCircleLine } from '../../../../../assets/Icons/edit-circle-line.svg';
import { ReactComponent as ToggleFill } from '../../../../../assets/Icons/toggle-fill.svg';
import { ReactComponent as InformationLine } from '../../../../../assets/Icons/information-line.svg';
import styles from './index.module.css';
export const ArchetypeItem = ({
  id,
  name,
  onRemove,
  onDetail,
  shouldTrackPopUpForCourseRecV2UserInMatomo
}) => {
  const handleRemove = (e, id) => {
    e.preventDefault();
    onRemove(id);
  };

  const handleInformationClick = (e, id) => {
    e.preventDefault();
    onDetail(id);
    OpenDetailFromDashboard();
  };

  const OpenDetailFromDashboard = () => {
    const isFromDashboard = window.location.pathname === DASHBOARD;
    const dashboardTab = getUrlParam('target') || DASHBOARD_URL_TAB.ME_NOW;
    isFromDashboard &&
      shouldTrackPopUpForCourseRecV2UserInMatomo &&
      PathwaysTrackEvents([
        {
          category: COURSE_REC_FROM_DASHBOARD.category,
          action:
            dashboardTab === DASHBOARD_URL_TAB.ME_NOW
              ? COURSE_REC_FROM_DASHBOARD.action.OPEN_DETAIL_FROM_ME_NOW
              : COURSE_REC_FROM_DASHBOARD.action.OPEN_DETAIL_FROM_ME_NEXT
        }
      ]);
  };

  return (
    <div tabIndex={0}>
      <div tabIndex={-1} className={styles.archetypeItem}>
        <span>{name}</span>
        <div className={styles.archetypeItemMask}>
          <div className={styles.inners}>
            <Link
              to={{
                pathname: REFLECT_ARCHETYPES,
                state: {
                  value: id
                }
              }}
              aria-label="Edit this reflection"
              className={styles.inner}
            >
              <EditCircleLine className={styles.icon} />
              <div className={styles.text}>Edit reflection</div>
            </Link>
            <Link
              to=""
              role="button"
              aria-label="Remove this archetype from my list"
              onClick={e => handleRemove(e, id)}
              onKeyDown={e => onKeyDown(e, id => handleRemove(e, id))}
              className={styles.inner}
            >
              <ToggleFill className={styles.icon} />
              <div className={styles.text}>Deselect</div>
            </Link>
            <Link
              to=""
              role="button"
              aria-label="Open this archetype detail"
              className={styles.inner}
              onKeyDown={e => onKeyDown(e, id => handleInformationClick(e, id))}
              onClick={e => handleInformationClick(e, id)}
            >
              <InformationLine className={styles.icon} />
              <div className={styles.text}>Details</div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
ArchetypeItem.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired
};
