import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { chunk } from 'lodash';
import styles from './index.module.css';
import Loader from '../../../../nonFeatureComponents/Loader';
import { archetypesReflectPageDes } from '../../language';
import CompetencyItem from '../CompetencyItem';
const CompetenciesList = ({
  competencies,
  extendCompetencies,
  archetypeName,
  extendArchetypeName,
  loading,
  loadErr
}) => {
  const pageSize = 6;
  const competenciesListDes = archetypesReflectPageDes.competenciesList;
  const [combineCompetencies, setCombineCompetencies] = useState([]);
  const handleCompetencyChange = newCompetency => {
    const competencies = combineCompetencies.map(item => {
      if (item.competencyId === newCompetency.competencyId) {
        return {
          ...item,
          competencyReflectedInformation: {
            ...item.competencyReflectedInformation,
            practiceLevel:
              newCompetency.competencyReflectedInformation.practiceLevel,
            isLearningPriority:
              newCompetency.competencyReflectedInformation.isLearningPriority
          }
        };
      }
      return item;
    });
    setCombineCompetencies(competencies);
  };

  useEffect(() => {
    let extendCompetenciesWithTitle = extendCompetencies.map((el, i) => {
      if (i === 0) el.title = extendArchetypeName;
      return el;
    });
    let competenciesWithTitle = competencies.map((el, i) => {
      if (i === 0 && extendCompetencies.length) el.title = archetypeName;
      return el;
    });

    setCombineCompetencies([
      ...competenciesWithTitle,
      ...extendCompetenciesWithTitle
    ]);
  }, [extendCompetencies, competencies, archetypeName, extendArchetypeName]);

  return (
    <div className={styles.competenciesList} data-cy="competenciesList">
      <div className={styles.header}>
        <div className={styles.firstColumn}>
          {competenciesListDes.firstColumn}
        </div>
        <div className={clsx(styles.secondColumn)}>
          <div>{competenciesListDes.secondColumn}</div>
          <div className={styles.suggestedLevel}>
            <div />
            {competenciesListDes.suggestedLevel}
          </div>
        </div>
        <div className={styles.thirdColumn}>
          {competenciesListDes.thirdColumn}
        </div>
      </div>
      <div className={styles.body} data-cy="competenciesListBody">
        {loadErr && (
          <p className={styles.errorMsg}>{competenciesListDes.errorMsg}</p>
        )}
        {loading && <Loader />}
        {!loading &&
          !loadErr &&
          chunk(combineCompetencies, pageSize).map((competenciesChunk, i) => (
            <Fragment key={i}>
              {competenciesChunk.map(competency => (
                <Fragment key={competency.competencyId}>
                  {competency.title && (
                    <div className={styles.archetypeName}>
                      <p
                        className={clsx(styles.firstColumn, styles.groupTitle)}
                      >
                        {competency.title}
                      </p>
                      <p className={styles.secondColumn} />
                      <p className={styles.thirdColumn} />
                    </div>
                  )}
                  <CompetencyItem
                    onChange={handleCompetencyChange}
                    competency={competency}
                  />
                </Fragment>
              ))}
            </Fragment>
          ))}
      </div>
    </div>
  );
};

CompetenciesList.propTypes = {
  competencies: PropTypes.array.isRequired,
  extendCompetencies: PropTypes.array.isRequired,
  archetypeName: PropTypes.string,
  extendArchetypeName: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  loadErr: PropTypes.bool.isRequired
};

export default CompetenciesList;
