import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useUserContext } from '../../contexts/UserContext';
import { get, isNil, throttle } from 'lodash';

import styles from './index.module.css';
import { DASHBOARD, REFLECT_ARCHETYPES } from '../../constants/path';
import {
  fetchReflectedArchetypes,
  updateArchetypeStage
} from '../../actions/profile';
import { REMOVE_ACHIEVEMENT_TITLE } from '../../constants/modalConstants';
import { trigSurvey } from '../../support/qualarooTrigger';
import { REFLECT_ARCHETYPE } from '../../constants/qualaroo';
import { archetypesIntentionDes } from './language';
import FixedBar from '../../nonFeatureComponents/FixedBar';
import NavigationHeader from '../../nonFeatureComponents/NavigationHeader';
import ArchetypesIntentionItem from './components/ArchetypesIntentionItem';
import StepperLine from '../../nonFeatureComponents/StepperLine';
import TipTrigger from '../../nonFeatureComponents/TipTrigger';
import Popup from '../../nonFeatureComponents/Popup';
import ConfirmationModal from '../../feature/ConfirmationModal';
import modalTipIcon from '../../assets/Icons/whatDoTheseMeanModal.png';
import { ARCHETYPE_STEPS } from '../../constants/archetype';

const archetypeCardWidth = 300;
const {
  backToTitle,
  goToTitle,
  tipTrigger,
  axeCheck,
  tipContent,
  tipTitle,
  noData,
  confirmModal,
  popup
} = archetypesIntentionDes;

const ArchetypesIntention = () => {
  const history = useHistory();
  const { state, dispatch } = useUserContext();
  const reflectedArchetypes = state.user?.reflectedArchetypes;
  const [rowCount, setRowCount] = useState(3);
  const pageRef = useRef(null);
  const [tipModalOpened, setTipModalOpened] = useState(false);
  const [archetypeGroups, setArchetypeGroups] = useState([]);

  const [leaveConfirmationModalOpen, setLevelConfirmationModalOpen] = useState(
    false
  );
  const checkExistsUnSelected = useCallback(() => {
    for (const archetype of reflectedArchetypes) {
      if (isNil(archetype.reflectedInformation.stage)) {
        return true;
      }
    }
    return false;
  }, [reflectedArchetypes]);

  useEffect(() => {
    fetchReflectedArchetypes(dispatch).then();
  }, [dispatch]);

  useEffect(() => {
    if (!reflectedArchetypes) {
      return;
    }

    const count = rowCount > 1 ? rowCount : 1;
    const result = Array.from(
      { length: Math.ceil(reflectedArchetypes.length / count) },
      (_, index) =>
        reflectedArchetypes.slice(index * count, index * count + count)
    );

    setArchetypeGroups(result);
  }, [rowCount, reflectedArchetypes]);

  useEffect(() => {
    const handleResize = throttle(() => {
      let rowCount = 0;
      if (pageRef.current) {
        const { width } = pageRef.current.getBoundingClientRect();
        rowCount = width / archetypeCardWidth;
      }
      setRowCount(rowCount);
    }, 300);
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleChange = ({ archetypeId, data }) => {
    updateArchetypeStage(dispatch)(archetypeId, data);
  };

  const goDashboard = () => {
    history.push(DASHBOARD);
  };

  const onGoto = () => {
    if (checkExistsUnSelected()) {
      setLevelConfirmationModalOpen(true);
      return;
    }
    trigSurvey(REFLECT_ARCHETYPE);
    goDashboard();
  };

  return (
    <>
      <FixedBar>
        <NavigationHeader
          onBackTo={() => history.replace(REFLECT_ARCHETYPES)}
          onGoTo={onGoto}
          backToTitle={backToTitle}
          goToTitle={goToTitle}
        >
          <StepperLine activeStep={2} steps={ARCHETYPE_STEPS} />
        </NavigationHeader>
      </FixedBar>
      <div className={styles.tips}>
        <h2 className={styles.forAxeCheck}>{axeCheck}</h2>
        <h3 className={styles.tipTitle}>{tipTitle}</h3>
        <div className={styles.tipContent}>
          {tipContent}
          <div className={styles.tipWrapper}>
            <TipTrigger
              content={tipTrigger}
              onOpen={setTipModalOpened}
              isOpen={tipModalOpened}
            />
          </div>
        </div>
      </div>
      <div className={styles.page} ref={pageRef}>
        <div className={styles.cardWrapper}>
          {archetypeGroups &&
            archetypeGroups.map((ra, index) => (
              <div className={styles.carouselPage} key={index}>
                <div className={styles.archetypeGroups}>
                  {ra.map(archetype => (
                    <ArchetypesIntentionItem
                      archetype={archetype}
                      key={archetype.archetypeId}
                      onChange={handleChange}
                    />
                  ))}
                </div>
              </div>
            ))}
        </div>
        {get(reflectedArchetypes, 'length') === 0 && (
          <div className={styles.nodata}>{noData}</div>
        )}
      </div>
      <ConfirmationModal
        open={leaveConfirmationModalOpen}
        setOpen={() => setLevelConfirmationModalOpen(false)}
        cancelButtonText={confirmModal.answerLater}
        cancel={() => {
          setLevelConfirmationModalOpen(false);
          goDashboard();
        }}
        confirm={() => {
          setLevelConfirmationModalOpen(false);
        }}
        confirmButtonText={confirmModal.answerNow}
        title={REMOVE_ACHIEVEMENT_TITLE}
        content={
          <>
            <div>{confirmModal.p1}</div>
            <div>{confirmModal.p2}</div>
          </>
        }
      />
      <Popup
        modalClassName={'tipsToHelpYouDecideModal'}
        open={tipModalOpened}
        close={() => setTipModalOpened(false)}
        title={popup.title}
        desc={popup.tipModalInfo}
        image={modalTipIcon}
      />
    </>
  );
};
export default ArchetypesIntention;
