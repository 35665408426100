import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import styles from './index.module.css';
import cardStyles from '../index.module.css';
import HistoryIcon from '../../../../assets/Icons/history-fill.png';
import FlagIcon from '../../../../components/Common/SvgIcons/FlagIcon';
import { formatToEN } from '../../../../utils/datetimeFormat';
import MedalIcon from '../../../../components/Common/SvgIcons/MedalIcon';
import ArrowForward from '@mui/icons-material/ArrowForward';
import {
  accomplishGoal,
  unaccomplishGoal,
  removeGoal
} from '../../../../api/domain/goal';
import { useUserContext } from '../../../../contexts/UserContext';
import { fetchUserGoals } from '../../../../actions/profile';
import {
  CANCEL_BUTTON_TEXT,
  REMOVE_ACHIEVEMENT_TITLE
} from '../../../../constants/modalConstants';
import { GOAL_TYPE } from '../../../../constants/goal';
import { onKeyDown } from '../../../../utils/onKeyDown';
import { dashboardDes } from '../../language';
import ConfirmationModal from '../../../../feature/ConfirmationModal';
import { HexagonCompetencyView } from './HexagonCompetencyView';
import AccomplishedModal from '../AccomplishedModal';
import DetailsModal from '../DetailsModal';
import { styled } from '@mui/material/styles';
export const MenuPop = styled(Menu)({
  '& .MuiList-padding': {
    padding: '0'
  }
});

const GoalCard = ({ goal }) => {
  const {
    name,
    actions,
    practiceLevel,
    period,
    description,
    updatedAt,
    createdAt,
    isAccomplished,
    accomplishedAt,
    practiceLevelWhenGoalCreated,
    practiceLevelAfterGoalAccomplished,
    id,
    goalType
  } = goal;
  const { dispatch } = useUserContext();
  const [isShowNotification, setShowNotification] = useState(false);
  const [
    isShowMarkOwnGoalAccomplishedNotification,
    setShowMarkOwnGoalAccomplishedNotification
  ] = useState(false);
  const [justDisplay, setJustDisplay] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isUnaccomplishingGoal, setIsUnaccomplishingGoal] = useState(false);
  const accomplishedRef = useRef(null);
  const detailsRef = useRef(null);
  const totalLength = actions.length;
  const hasDoneLength = actions.filter(action => action.isDone).length;
  const actionProgressPercentage =
    totalLength === 0 ? 0 : (hasDoneLength / totalLength) * 100;
  const isMyOwnGoal = goalType === GOAL_TYPE.CUSTOM;

  const showActionPop = event => {
    setAnchorEl(event.currentTarget);
  };

  const disableActionPop = () => {
    setAnchorEl(null);
  };

  const handleOpenAccomplishModal = () => {
    if (isMyOwnGoal) {
      setShowMarkOwnGoalAccomplishedNotification(true);
      return;
    }
    const ref = accomplishedRef.current;
    if (!ref) return;
    ref.open(goal);
  };

  const handleOpenGoalDetails = (justDisplay = false) => {
    if (detailsRef.current) {
      setJustDisplay(justDisplay);
      detailsRef.current.open(true);
    }
  };

  const handleRemoveGoal = async () => {
    try {
      await removeGoal(id);
      setShowNotification(false);
      fetchUserGoals(dispatch);
    } catch (e) {}
  };

  const handleMarkGoalUnaccomplished = async () => {
    if (isUnaccomplishingGoal) {
      return;
    }
    setIsUnaccomplishingGoal(true);
    disableActionPop();
    try {
      await unaccomplishGoal(id);
      fetchUserGoals(dispatch);
    } catch (e) {}
    setIsUnaccomplishingGoal(false);
  };

  const handleMarkOwnGoalAccomplished = async () => {
    try {
      await accomplishGoal(id, { goalType: GOAL_TYPE.CUSTOM });
      fetchUserGoals(dispatch);
    } catch (err) {}
  };

  return (
    <div className={clsx(cardStyles.card)} data-cy="createdGoalCard">
      <div className={cardStyles.cardHeader}>
        <div
          tabIndex={0}
          className={styles.time}
          data-cy="goalCardHeaderHistory"
        >
          <span className={clsx(styles.circle, styles.history)}>
            <img src={HistoryIcon} alt="" />
          </span>
          <div className={styles.tooltipContainer}>
            <p>Last updated at {formatToEN(updatedAt)}</p>
            <p>Created at {formatToEN(createdAt)}</p>
          </div>
        </div>
        <div className={styles.cardTitle}>{name}</div>
        <span
          aria-label="menu button"
          tabIndex={0}
          onClick={showActionPop}
          onKeyDown={e => onKeyDown(e, showActionPop)}
          className={clsx(styles.circle, styles.more)}
          data-cy="goalCardHeaderButton"
        >
          <span className={styles.dotContainer} tabIndex={-1}>
            <i className={styles.dot} />
            <i className={styles.dot} />
            <i className={styles.dot} />
          </span>
        </span>
        <MenuPop
          className={styles.actionWrapper}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={disableActionPop}
        >
          {isAccomplished && (
            <MenuItem
              tabIndex={0}
              onKeyDown={e => onKeyDown(e, handleMarkGoalUnaccomplished)}
              className={styles.unaccomplishedBtn}
              onClick={handleMarkGoalUnaccomplished}
              data-cy="goalCardUnaccomplishedBtn"
            >
              {dashboardDes.myPlanCardDes.goalCard.markAsUnaccomplishedButton}
            </MenuItem>
          )}
          <MenuItem
            tabIndex={0}
            className={styles.deleteBtn}
            onClick={() => setShowNotification(true)}
            onKeyDown={e => onKeyDown(e, () => setShowNotification(true))}
            data-cy="goalCardDeleteBtn"
          >
            {dashboardDes.myPlanCardDes.goalCard.removeGoal}
          </MenuItem>
        </MenuPop>
      </div>
      <div>
        <div className={styles.progressBarWrap}>
          <div
            className={clsx(
              isAccomplished && styles.grayBgColor,
              styles.progressBar
            )}
            style={{ width: `${actionProgressPercentage}%` }}
            data-cy="actionProgressBar"
          />
        </div>
        {isAccomplished ? (
          <div className={styles.progressDes}>
            <MedalIcon className={styles.medalIcon} />
            <span> Marked as accomplished at {formatToEN(accomplishedAt)}</span>
          </div>
        ) : (
          <div className={styles.progressDes} data-cy="actionProgressPer">
            <FlagIcon
              className={clsx(
                styles.progressFlag,
                !hasDoneLength && styles.grayColor
              )}
            />
            {totalLength ? (
              <span>
                {`${hasDoneLength} / ${totalLength}`} Actions completed
              </span>
            ) : (
              <span>{dashboardDes.myPlanCardDes.goalCard.noLoggedActions}</span>
            )}
          </div>
        )}
      </div>
      <div className={styles.mainContainer}>
        <div className={styles.hexagonViewWrapper}>
          {isAccomplished ? (
            <div className={styles.accomplishView}>
              {!isMyOwnGoal &&
                practiceLevelWhenGoalCreated !==
                  practiceLevelAfterGoalAccomplished && (
                  <>
                    <HexagonCompetencyView
                      name={name}
                      score={practiceLevelWhenGoalCreated}
                      grayLevel={true}
                      isMyOwnGoal={isMyOwnGoal}
                    />
                    <ArrowForward className={styles.arrow} />
                  </>
                )}
              <HexagonCompetencyView
                name={name}
                score={practiceLevelAfterGoalAccomplished}
                isMyOwnGoal={isMyOwnGoal}
              />
            </div>
          ) : (
            <HexagonCompetencyView
              name={name}
              score={practiceLevel}
              isMyOwnGoal={isMyOwnGoal}
            />
          )}
        </div>
        <div className={styles.periodWrapper}>
          <span>
            In {period} month{period > 1 && 's'}, I would like to:
          </span>
        </div>
        <div className={styles.descriptionWrapper}>
          <span className={styles.description}>{description}</span>
        </div>

        {!isAccomplished ? (
          <div className={styles.toolbar}>
            <span
              tabIndex={0}
              onKeyDown={handleOpenAccomplishModal}
              onClick={handleOpenAccomplishModal}
              className={styles.linkButton}
              data-cy="markAccomplishedLink"
            >
              <span tabIndex={-1} className={styles.linkButtonInner}>
                {dashboardDes.myPlanCardDes.markAsAccomplishedButton}
              </span>
            </span>
            <span
              tabIndex={0}
              onKeyDown={() => handleOpenGoalDetails()}
              className={styles.linkButton}
              onClick={() => handleOpenGoalDetails()}
              data-cy="editGoalLink"
            >
              <span tabIndex={-1} className={styles.linkButtonInner}>
                {dashboardDes.myPlanCardDes.goalCard.editButton}
              </span>
            </span>
          </div>
        ) : (
          <div className={styles.toolbar}>
            <span
              tabIndex={0}
              className={styles.linkButton}
              onKeyDown={() => handleOpenGoalDetails(true)}
              onClick={() => handleOpenGoalDetails(true)}
            >
              <span className={styles.linkButtonInner} tabIndex={-1}>
                {dashboardDes.myPlanCardDes.goalDetailsButton}
              </span>
            </span>
          </div>
        )}
      </div>
      <AccomplishedModal ref={accomplishedRef} />

      <DetailsModal
        ref={detailsRef}
        goal={goal}
        handleOpenAccomplishModal={handleOpenAccomplishModal}
        isAccomplish={isAccomplished}
        justDisplay={justDisplay}
        disableBackdropClose={!justDisplay}
      />

      {isShowNotification && (
        <ConfirmationModal
          open={true}
          setOpen={() => {
            setShowNotification(false);
            disableActionPop();
          }}
          cancelButtonText={CANCEL_BUTTON_TEXT}
          confirmButtonText="Confirm"
          confirm={handleRemoveGoal}
          cancel={() => {
            setShowNotification(false);
            disableActionPop();
          }}
          title={REMOVE_ACHIEVEMENT_TITLE}
          content={dashboardDes.myPlanCardDes.goalCard.removeGoalTip}
        />
      )}

      {isShowMarkOwnGoalAccomplishedNotification && (
        <ConfirmationModal
          open={true}
          setOpen={() => {
            setShowMarkOwnGoalAccomplishedNotification(false);
            disableActionPop();
          }}
          confirm={handleMarkOwnGoalAccomplished}
          title={REMOVE_ACHIEVEMENT_TITLE}
          confirmButtonText={
            dashboardDes.myPlanCardDes.markAsAccomplishedButton
          }
          cancelButtonText={CANCEL_BUTTON_TEXT}
          content={
            dashboardDes.myPlanCardDes.goalCard.markOwnGoalAccomplishedTip
          }
        />
      )}
    </div>
  );
};

GoalCard.propTypes = {
  goal: PropTypes.shape({
    name: PropTypes.string.isRequired,
    actions: PropTypes.array.isRequired,
    practiceLevel: PropTypes.number,
    period: PropTypes.number,
    description: PropTypes.string,
    isAccomplished: PropTypes.bool,
    updatedAt: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    accomplishedAt: PropTypes.string,
    practiceLevelAfterGoalAccomplished: PropTypes.number,
    practiceLevelWhenGoalCreated: PropTypes.number,
    id: PropTypes.string,
    isMyOwnGoal: PropTypes.bool,
    contributions: PropTypes.any.isRequired
  })
};

export default GoalCard;
