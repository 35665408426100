import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { get, isEmpty, isEqual, uniq, remove } from 'lodash';
import * as CompetenciesStorage from '../../components/Utils/competenciesStorage';
import { getNewSelectedCompetencyName } from '../../components/Utils/competenciesStorage';
import * as SkillsStorage from '../../components/Utils/skillsStorage';
import { sortBy } from '../../components/Utils/CommonUtil';
import loaderStyles from '../../nonFeatureComponents/Loader/index.module.css';
import { CORE_CAPABILITY_EVENT } from '@constants/matomo';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import {
  DASHBOARD,
  EDIT_REFLECT_SKILLS,
  REFLECT_COMPETENCIES
} from '@constants/path';
import { fetchPeopleSkills } from '@api/domain/skill';
import { fetchPeopleCompetencies } from '@api/domain/competency';
import { reflectedSkill } from '../../domain/skill';
import { reflectedCompetencies } from '../../domain/competency';
import ConfirmationModal from '@feature/ConfirmationModal';
import { ReflectContent } from './components/ReflectContent';
import Loader from '@nonFeatureComponents/Loader';
import { reflectPageDes } from './language';

export const ReflectPage = () => {
  const history = useHistory();
  const location = useLocation();
  const { state } = location;
  const { trackEvent } = useMatomo();

  const [isLoading, setIsLoading] = useState(true);
  const [allSelectedCompetencies, setAllSelectedCompetencies] = useState([]);
  const [currentCompetencies, setCurrentCompetencies] = useState(
    allSelectedCompetencies
  );

  const { confirmModal } = reflectPageDes.reflectPage;

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  let reflectType = 'Skills';
  let isCompetency = false;
  const pathName = window.location.pathname;
  if (pathName === REFLECT_COMPETENCIES) {
    reflectType = 'Competencies';
    isCompetency = true;
  }

  useEffect(() => {
    if (isCompetency) {
      let storedCompetencies = uniq(CompetenciesStorage.get());
      const isEditCompetencies = state && state.haveEditFlag;
      const newSelectedItems = isEditCompetencies
        ? []
        : getNewSelectedCompetencyName();
      fetchPeopleCompetencies()
        .then(allItems => {
          const reflectedItems = reflectedCompetencies(allItems);
          getAllSelectedItems({
            allItems,
            storedItems: storedCompetencies,
            newSelectedItems,
            reflectedItems
          });
        })
        .catch(() => setIsLoading(false));
    } else {
      let storedSkills = uniq(SkillsStorage.get());
      const isEditReflectSkill = pathName === EDIT_REFLECT_SKILLS;
      const newSelectedItems = isEditReflectSkill
        ? []
        : JSON.parse(localStorage.getItem('newSelectedSkillName'));
      fetchPeopleSkills()
        .then(allItems => {
          const reflectedItems = reflectedSkill(allItems);
          getAllSelectedItems({
            allItems,
            storedItems: storedSkills,
            newSelectedItems,
            reflectedItems
          });
        })
        .catch(() => setIsLoading(false));
    }
  }, [pathName, state, isCompetency]);

  const haveUpdateItem = useMemo(() => {
    return !isEqual(allSelectedCompetencies, currentCompetencies);
  }, [allSelectedCompetencies, currentCompetencies]);

  const getAllSelectedItems = ({
    allItems,
    storedItems,
    newSelectedItems,
    reflectedItems
  }) => {
    let preReflectedItems = [];

    !isEmpty(newSelectedItems) &&
      allItems.forEach(com => {
        const id = get(com, 'skillId') || get(com, 'competencyId');
        if (newSelectedItems.includes(com.name)) {
          remove(storedItems, storedCom => storedCom === id);
          preReflectedItems.push({
            ...com
          });
        }
      });
    const allCompetencies = [
      ...sortBy(preReflectedItems, 'name'),
      ...sortBy(reflectedItems, 'name')
    ];
    setAllSelectedCompetencies(allCompetencies);
    setCurrentCompetencies(allCompetencies);
    setIsLoading(false);
  };

  const handleBackTo = () => {
    if (haveUpdateItem) {
      return setIsConfirmModalOpen(true);
    }
    history.push({
      pathname: DASHBOARD,
      state: { fromReflectPage: true }
    });
  };
  const handleGoTo = () => {
    CompetenciesStorage.clear();
    SkillsStorage.clear();
    history.replace(DASHBOARD);
    trackEvent({
      category: CORE_CAPABILITY_EVENT.category,
      action: CORE_CAPABILITY_EVENT.action.DONE_CLICKS
    });
  };

  const confirmModalInfo = useMemo(() => {
    return (
      <>
        <div>{confirmModal.content}</div>
        <div>{confirmModal.cancel}</div>
      </>
    );
  }, [confirmModal]);

  return isLoading ? (
    <div className={loaderStyles.loaderWrapper}>
      <Loader />
    </div>
  ) : (
    <div>
      <ReflectContent
        onBackTo={handleBackTo}
        onGoTo={handleGoTo}
        reflectType={reflectType}
        pathName={pathName}
        competencies={allSelectedCompetencies}
        currentCompetencies={currentCompetencies}
        getCurrentCompetencies={competencies =>
          setCurrentCompetencies(competencies)
        }
      />
      <ConfirmationModal
        open={isConfirmModalOpen}
        setOpen={() => {
          setIsConfirmModalOpen(false);
        }}
        confirmButtonText={confirmModal.confirmButtonText}
        cancelButtonText={confirmModal.cancelButtonText}
        confirm={() => {
          history.replace(DASHBOARD);
        }}
        cancel={() => {
          setIsConfirmModalOpen(false);
        }}
        title={confirmModal.title}
        content={confirmModalInfo}
      />
    </div>
  );
};
