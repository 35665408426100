import { TextField } from '@mui/material';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { isNumber } from 'lodash';
import styles from './index.module.css';
import { proficiencyLevelsDescription } from '../../../../constants/proficiencyLevels';
import { checkIsPositiveInteger } from '../../../../utils/competency';
import { inputBlackList } from '../../../../constants/inputBlackList';
import globalStyles from '../../../../globalStyles';
import clsx from 'clsx';
import { dashboardDes } from '../../language';
import { styled } from '@mui/material/styles';

export const InputWithStyle = styled(TextField)(props => ({
  '& .MuiInputBase-root': {
    width: props.width,
    lineHeight: '1.1876em'
  },
  '& .MuiOutlinedInput-root': {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    },
    '& .MuiOutlinedInput-input': {
      border: `1px solid ${globalStyles.grey21}`,
      borderRadius: '6px',
      padding: '10px',
      height: props.height,
      '&::-webkit-input-placeholder': {
        textIndent: '2px',
        color: globalStyles.goalCreatedPlaceholder,
        fontSize: '16px',
        fontStyle: 'italic'
      }
    }
  },
  '& .MuiInputBase-multiline': {
    padding: '10px 0 0 10px'
  },
  '& .Mui-disabled': {
    color: globalStyles.black3
  }
}));

const GoalInformationForm = forwardRef(
  (
    {
      selectedCompetency,
      disabled,
      goal,
      justDisplay,
      setIsDisplayCompetencyErrorMsg,
      isMyOwnGoal
    },
    ref
  ) => {
    const [period, setPeriod] = useState(
      isNumber(goal?.period) ? goal.period : ''
    );
    const [description, setDescription] = useState(goal?.description || '');
    const [isPeriodError, setPeriodIsError] = useState(false);
    const [periodErrorMessage, setPeriodErrorMessage] = useState('');
    const [isDescError, setDescIsError] = useState(false);
    const [descErrorMessage, setDescErrorMessage] = useState('');
    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return {
            period,
            description
          };
        },
        setValue({ period, description }) {
          setPeriod(isNumber(period) ? period : '');
          setDescription(description || '');
        },
        isInValidate() {
          const isPeriodHasError = !checkIsPositiveInteger(period);
          const isDescriptionHasError = !description.trim();
          let errorMessages = [];
          if (isPeriodHasError) {
            setPeriodIsError(true);
            errorMessages.push('duration');
            setPeriodErrorMessage(
              dashboardDes.myPlanCardDes.goalInformation.noPeriodErrorMessage
            );
          }
          if (isDescriptionHasError) {
            setDescIsError(true);
            errorMessages.push('description');
            setDescErrorMessage(
              dashboardDes.myPlanCardDes.goalInformation.noDescErrorMessage
            );
          }
          return errorMessages.length > 0 || isPeriodError || isDescError;
        }
      };
    });
    const validatePeriod = month => {
      if (month > 99) {
        setPeriodIsError(true);
        setPeriodErrorMessage(
          dashboardDes.myPlanCardDes.goalInformation.outPeriodErrorMessage
        );
      } else if (month < 0) {
        setPeriodIsError(true);
        setPeriod('');
      } else {
        setPeriodIsError(false);
      }
    };

    const validateDesc = desc => {
      if (desc === undefined || desc.length > 1000) {
        setDescIsError(true);
        setDescErrorMessage(
          dashboardDes.myPlanCardDes.goalInformation.outDescErrorMessage
        );
      } else if (desc.match(new RegExp(`[${inputBlackList}]`, 'g'))) {
        setDescIsError(true);
        setDescErrorMessage(`*Input should not contain ${inputBlackList}`);
      } else {
        setDescIsError(false);
      }
    };

    useEffect(() => {
      validatePeriod(period);
    }, [period]);

    useEffect(() => {
      validateDesc(description);
    }, [description]);

    useEffect(
      () =>
        setIsDisplayCompetencyErrorMsg && setIsDisplayCompetencyErrorMsg(false),
      [setIsDisplayCompetencyErrorMsg]
    );

    return (
      <div className={styles.informationContainer}>
        {selectedCompetency ? (
          <div className={styles.hexagonView}>
            <div className={styles.hexagonViewAfter} />
            <div className={styles.hexagonContent}>
              <div className={styles.competencyName}>
                {selectedCompetency.name}
              </div>
              {selectedCompetency.practiceLevel > 0 && (
                <div className={styles.competencyLevel}>
                  <span>
                    {
                      proficiencyLevelsDescription[
                        selectedCompetency.practiceLevel - 1
                      ]
                    }
                  </span>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div>
            {!isMyOwnGoal && (
              <div className={styles.unselectedHexagonView}>
                <div className={styles.unselectedHexagonViewAfter} />
                <div className={styles.unselectedMessage}>
                  {dashboardDes.myPlanCardDes.goalInformation.unselectedMessage}
                </div>
              </div>
            )}
            {isMyOwnGoal && (
              <div className={styles.ownGoalView}>
                <div className={styles.ownGoalViewContent}>
                  {
                    dashboardDes.myPlanCardDes.goalInformation
                      .ownGoalViewContent
                  }
                </div>
              </div>
            )}
          </div>
        )}

        <div>
          <div className={styles.period}>
            <span>In&nbsp;</span>
            {justDisplay ? (
              <span>{period}</span>
            ) : (
              <InputWithStyle
                error={isPeriodError}
                width="80px"
                height="19px"
                type="number"
                placeholder={
                  dashboardDes.myPlanCardDes.goalInformation.titlePlaceholder
                }
                variant="outlined"
                value={period}
                onChange={event => setPeriod(event.target.value)}
                disabled={disabled}
                data-cy="durationInput"
              />
            )}
            <span>
              {!justDisplay && <i>&nbsp;*</i>}&nbsp;months, I would like to:
            </span>
          </div>
          <div className={clsx(styles.errorMessage, styles.periodError)}>
            {isPeriodError && periodErrorMessage}
          </div>
          {justDisplay ? (
            <div className={styles.displayDescription}>{description}</div>
          ) : (
            <InputWithStyle
              width="520px"
              height="57px"
              placeholder={
                dashboardDes.myPlanCardDes.goalInformation.textPlaceholder
              }
              disabled={disabled}
              variant="outlined"
              multiline
              minRows={3}
              value={description}
              onChange={event => setDescription(event.target.value)}
              error={isDescError}
              data-cy="descriptionInput"
            />
          )}
          {!justDisplay && <span className={styles.notificationDot}>*</span>}
          <div
            className={styles.errorMessage}
            data-cy="durationDescriptionErrorMsg"
          >
            {isDescError && descErrorMessage}
          </div>
        </div>
      </div>
    );
  }
);

GoalInformationForm.defaultProps = {
  justDisplay: false,
  disabled: false
};
GoalInformationForm.propTypes = {
  selectedCompetency: PropTypes.shape({
    name: PropTypes.string,
    practiceLevel: PropTypes.number
  }),
  goal: PropTypes.shape({
    description: PropTypes.string,
    period: PropTypes.number
  }),
  justDisplay: PropTypes.bool,
  disabled: PropTypes.bool,
  setIsDisplayCompetencyErrorMsg: PropTypes.func,
  isMyOwnGoal: PropTypes.bool
};
export default GoalInformationForm;
