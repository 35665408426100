import React, { useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import _ from 'lodash';
import { useUserContext } from '../../../contexts/UserContext';
import {
  createUserGoal,
  fetchUser,
  fetchUserGoals
} from '../../../actions/profile';

import styles from './index.module.css';
import { RelatedContributionForGoal } from './RelatedContributionForGoal';
import GoalInformationForm from './GoalInformation';

import { GoalInputForm } from './GoalGapAndAction';
import MedalIcon from '../../../components/Common/SvgIcons/MedalIcon';
import CurrentGoalIcon from '../../../components/Common/SvgIcons/CurrentGoalIcon';
import GroupIcon from '../../../components/Common/SvgIcons/GroupIcon';
import cloneDeep from 'lodash/cloneDeep';
import { getCompetencyInformationLink } from '../../../utils/competency';
import LinkIcon from '../../../components/Common/SvgIcons/LinkIcon';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { CREATE_GOAL_FLOW } from '../../../constants/matomo';
import { trigSurvey } from '../../../support/qualarooTrigger';
import { CREATE_GOAL } from '../../../constants/qualaroo';
import { GOAL_TYPE } from '../../../constants/goal';
import { onKeyDown } from '../../../utils/onKeyDown';
import { dashboardDes } from '../language';
import ArchetypesSelector from '../../../feature/ArchetypesSelector';
import Loader from '../../../nonFeatureComponents/Loader';
import GoalCard from './GoalCard';
import GoalModal from './GoalModal';

export default function MyPlanCard() {
  const { state, dispatch } = useUserContext();
  const goals = useMemo(() => {
    return state.user.goals || [];
  }, [state]);
  const [isCreationModalOpen, setCreationModalOpen] = useState(false);
  const [isDisplayAccomplishGoals, setIsDisplayAccomplishGoals] = useState(
    false
  );

  const [selectedCompetencyIndex, setSelectedCompetencyIndex] = useState(-1);

  const [
    isDisplayCompetencyErrorMsg,
    setIsDisplayCompetencyErrorMsg
  ] = useState(false);
  const [loading, setLoading] = useState(true);
  const [createLoading, setCreateLoading] = useState(false);
  const [selectedCompetency, setSelectedCompetency] = useState(null);

  const informationRef = useRef(null);
  const gapRef = useRef(null);
  const actionRef = useRef(null);
  const categoryRef = useRef(null);
  const { trackEvent } = useMatomo();

  const clearGoal = () => {
    if (informationRef.current) {
      informationRef.current.setValue({
        period: '',
        description: ''
      });
    }
    if (gapRef.current) {
      gapRef.current.setValue([]);
      gapRef.current.setInputValue('');
    }
    if (actionRef.current) {
      actionRef.current.setValue([]);
      actionRef.current.setInputValue('');
    }
    if (isMyOwnGoal && categoryRef.current) {
      categoryRef.current.setValue([]);
      categoryRef.current.setInputValue('');
    }
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([fetchUserGoals(dispatch), fetchUser(dispatch)]);
    setLoading(false);
  }, [dispatch]);

  const currentGoals = useMemo(() => {
    if (isDisplayAccomplishGoals) {
      return _.orderBy(
        goals.filter(goal => goal.isAccomplished),
        ['accomplishedAt'],
        ['desc']
      );
    }
    return _.orderBy(
      goals.filter(goal => !goal.isAccomplished),
      ['createdAt'],
      ['desc']
    );
  }, [goals, isDisplayAccomplishGoals]);

  const allLearningGoalCompetencies = useMemo(
    () =>
      (state.user.reflectedCompetencies &&
        _.orderBy(
          _.flatten(
            state.user.reflectedCompetencies.filter(
              com =>
                com.reflectedInformation.stage === 'MeNext' ||
                com.reflectedInformation.stage === 'BothMeNowAndMeNext'
            )
          ),
          ['name'],
          ['asc']
        )) ||
      [],
    [state.user.reflectedCompetencies]
  );

  useEffect(() => {
    if (allLearningGoalCompetencies) {
      setSelectedCompetency(
        allLearningGoalCompetencies[selectedCompetencyIndex]
      );
    }
  }, [allLearningGoalCompetencies, selectedCompetencyIndex]);

  const onSelectedCompetencyChange = index => {
    if (selectedCompetencyIndex === index) {
      return;
    }
    setSelectedCompetencyIndex(index);
    setIsDisplayCompetencyErrorMsg(false);
  };

  const onOpenGoalModal = () => {
    trackEvent({
      category: CREATE_GOAL_FLOW.category,
      action: CREATE_GOAL_FLOW.action.START_GOAL
    });
    setCreationModalOpen(true);
  };

  const onCreate = () => {
    const isMyOwnGoal =
      selectedCompetencyIndex === allLearningGoalCompetencies.length;
    let selectedCom = allLearningGoalCompetencies[selectedCompetencyIndex];
    let categories = [];
    if (isMyOwnGoal) {
      categories = categoryRef.current.getValue();
    }
    if (isMyOwnGoal) {
      if (categories.length === 0) {
        selectedCom = { name: 'My own learning priority' };
      } else {
        // const newName = cloneDeep(categories);
        selectedCom = {
          name: cloneDeep(categories)
            .reverse()
            .join(',')
        };
      }
    }
    let shouldReturn = false;
    if (!selectedCom) {
      setIsDisplayCompetencyErrorMsg(true);
      shouldReturn = true;
    }
    if (
      gapRef.current.isInValidate() ||
      actionRef.current.isInValidate() ||
      informationRef.current.isInValidate() ||
      (isMyOwnGoal && categoryRef.current.isInValidate())
    ) {
      shouldReturn = true;
    }
    if (shouldReturn) {
      return;
    }
    setCreateLoading(true);
    let createGoalBody = {
      goalType: isMyOwnGoal
        ? GOAL_TYPE.CUSTOM
        : GOAL_TYPE.UNDER_ME_NEXT_COMPETENCY,
      name: selectedCom.name,
      period: informationRef.current.getValue().period,
      description: informationRef.current.getValue().description,
      gaps: gapRef.current.getValue(),
      actions: actionRef.current.getValue()
    };
    if (isMyOwnGoal) {
      createGoalBody = {
        ...createGoalBody,
        contributionsCategories: categories
      };
    } else {
      createGoalBody = {
        ...createGoalBody,
        competencyId: selectedCom.competencyId
      };
    }
    createUserGoal(dispatch)(createGoalBody)
      .then(() => {
        trackEvent({
          category: CREATE_GOAL_FLOW.category,
          action: CREATE_GOAL_FLOW.action.CREATE_GOAL
        });
        trigSurvey(CREATE_GOAL);
        if (actionRef.current.getValue().length > 0) {
          trackEvent({
            category: CREATE_GOAL_FLOW.category,
            action: CREATE_GOAL_FLOW.action.ADDED_ACTIONS
          });
        }
        setCreationModalOpen(false);
        setSelectedCompetencyIndex(-1);
      })
      .finally(() => {
        setCreateLoading(false);
      });
  };

  if (loading) {
    return <Loader />;
  }

  const closeGoalModal = () => {
    setCreationModalOpen(false);
    setSelectedCompetencyIndex(-1);
    clearGoal();
  };
  const isMyOwnGoal =
    selectedCompetencyIndex === allLearningGoalCompetencies.length;

  return (
    <div className={styles.contentContainer}>
      {!isDisplayAccomplishGoals ? (
        <div className={styles.titleWrapper} data-cy="myPlanCardTitle">
          <p className={styles.title}>
            {currentGoals.length > 0
              ? `You have ${currentGoals.length} ${
                  currentGoals.length > 1 ? 'goals' : 'goal'
                } in progress at the moment.`
              : dashboardDes.myPlanCardDes.currentGoals.title}
          </p>
          <span
            tabIndex={0}
            className={styles.medalContent}
            onKeyDown={e =>
              onKeyDown(e, () => setIsDisplayAccomplishGoals(true))
            }
            onClick={() => setIsDisplayAccomplishGoals(true)}
            data-cy="switchGoalStatusButton"
          >
            <span tabIndex={-1} className={styles.medalContentInner}>
              <MedalIcon />
              <span>{dashboardDes.myPlanCardDes.accomplishGoals.button}</span>
              <div className={styles.tooltipContainer}>
                <p>{dashboardDes.myPlanCardDes.accomplishGoals.title}</p>
              </div>
            </span>
          </span>
        </div>
      ) : (
        <div className={styles.titleWrapper} data-cy="myPlanCardTitle">
          <p className={styles.title}>
            {currentGoals.length > 0
              ? `You have accomplished ${currentGoals.length} ${
                  currentGoals.length > 1 ? 'goals' : 'goal'
                }. Way to go!`
              : dashboardDes.myPlanCardDes.accomplishGoals.title}
          </p>
          <span
            tabIndex={0}
            onKeyDown={() => setIsDisplayAccomplishGoals(false)}
            className={styles.medalContent}
            onClick={() => setIsDisplayAccomplishGoals(false)}
            data-cy="switchGoalStatusButton"
          >
            <span tabIndex={-1} className={styles.medalContentInner}>
              <CurrentGoalIcon />
              <span>{dashboardDes.myPlanCardDes.currentGoals.button}</span>
            </span>
          </span>
        </div>
      )}

      {isDisplayAccomplishGoals && currentGoals.length <= 0 && (
        <div className={styles.emptyGoalsContainer}>
          <GroupIcon className={styles.group} />
          <span data-cy="noAccomplishedGoalSmg">
            {dashboardDes.myPlanCardDes.accomplishGoals.noAccomplishGoals}
          </span>
        </div>
      )}

      <div className={styles.goalList}>
        {!isDisplayAccomplishGoals && (
          <div
            tabIndex={0}
            className={clsx(styles.card, styles.createGoalCard)}
            onClick={onOpenGoalModal}
            onKeyDown={onOpenGoalModal}
            data-cy="newGoalPanel"
          >
            <p>{dashboardDes.myPlanCardDes.currentGoals.createGoal}</p>
          </div>
        )}
        {currentGoals.map(g => (
          <GoalCard key={g.id} goal={g} />
        ))}
      </div>
      <GoalModal
        open={isCreationModalOpen}
        close={closeGoalModal}
        title={dashboardDes.myPlanCardDes.currentGoals.createGoal}
        confirmText="Create"
        onConfirm={onCreate}
        loading={createLoading}
        disableBackdropClose
      >
        <div>
          <div className={styles.selectWrapper}>
            <ArchetypesSelector
              defaultOption={
                dashboardDes.myPlanCardDes.currentGoals.selectLearningPriority
                  .defaultOption
              }
              myOwnGoalOption={
                dashboardDes.myPlanCardDes.currentGoals.selectLearningPriority
                  .myOwnGoalOption
              }
              onChange={onSelectedCompetencyChange}
              value={selectedCompetencyIndex}
              options={allLearningGoalCompetencies.map(com => (
                <p key={com.id} className={styles.option}>
                  {com.name}
                </p>
              ))}
              isDisplayErrorMsg={isDisplayCompetencyErrorMsg}
            />
            <span className={styles.errorMsg} data-cy="learningGoalRequired">
              {
                dashboardDes.myPlanCardDes.currentGoals.selectLearningPriority
                  .learningGoalRequired
              }
            </span>
            {selectedCompetency && (
              <a
                className={styles.competencyGroupLink}
                href={getCompetencyInformationLink(
                  selectedCompetency.identifier
                )}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>
                  {
                    dashboardDes.myPlanCardDes.currentGoals
                      .selectLearningPriority.seeCompetencyDetails
                  }
                </span>
                <div className={styles.iconWrapper}>
                  <LinkIcon className={styles.competencyGroupLinkIcon} />
                </div>
              </a>
            )}
            <div
              className={clsx(styles.errorMsg, styles.learningGoalErrorMsg)}
              data-cy="learningGoalErrorMsg"
            >
              {isDisplayCompetencyErrorMsg &&
                dashboardDes.myPlanCardDes.selectLearningPriority
                  ?.learningGoalErrorMsg}
            </div>
          </div>
          <div className={styles.container}>
            <GoalInformationForm
              ref={informationRef}
              selectedCompetency={
                selectedCompetency
                  ? {
                      name: selectedCompetency.name,
                      practiceLevel:
                        selectedCompetency.reflectedInformation.practiceLevel
                    }
                  : null
              }
              setIsDisplayCompetencyErrorMsg={setIsDisplayCompetencyErrorMsg}
              isMyOwnGoal={isMyOwnGoal}
            />
            <div
              className={clsx(styles.line, {
                [styles.unselectedLine]: !selectedCompetency && !isMyOwnGoal
              })}
            />
          </div>

          <div className={styles.container}>
            <GoalInputForm
              title={dashboardDes.myPlanCardDes.myGaps.title}
              placeholder={dashboardDes.myPlanCardDes.myGaps.placeholder}
              des={dashboardDes.myPlanCardDes.myGaps.des}
              isGap={true}
              ref={gapRef}
            />
          </div>
          <div className={styles.container}>
            <div
              className={clsx(styles.connectedLine, {
                [styles.unselectedLine]: !selectedCompetency && !isMyOwnGoal
              })}
            />
            <GoalInputForm
              title={dashboardDes.myPlanCardDes.actions.title}
              isGap={false}
              placeholder={dashboardDes.myPlanCardDes.actions.placeholder}
              des={dashboardDes.myPlanCardDes.actions.des}
              ref={actionRef}
            />
          </div>
          {!isMyOwnGoal && (
            <div className={styles.container}>
              <div
                className={clsx(styles.connectedLine, {
                  [styles.unselectedLine]: !selectedCompetency
                })}
              />
              {selectedCompetency ? (
                <RelatedContributionForGoal
                  archetypes={
                    selectedCompetency.reflectedInformation.reflectedArchetypes
                  }
                  coreCapabilities={selectedCompetency.coreCapabilities}
                />
              ) : (
                <p>
                  {dashboardDes.myPlanCardDes.noSelectedLearningPriorityDes}
                </p>
              )}
            </div>
          )}
          {isMyOwnGoal && (
            <div className={styles.container}>
              <div className={clsx(styles.connectedLine)} />
              <GoalInputForm
                title={dashboardDes.myPlanCardDes.myOwnGoal.title}
                isGap={false}
                des={dashboardDes.myPlanCardDes.myOwnGoal.des}
                isCategory={true}
                placeholder={dashboardDes.myPlanCardDes.myOwnGoal.placeholder}
                ref={categoryRef}
              />
            </div>
          )}
        </div>
      </GoalModal>
    </div>
  );
}
