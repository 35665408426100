export const safeJSONParseArrayString = str => {
  try {
    const parsed = JSON.parse(str);
    if (Array.isArray(parsed)) {
      return parsed;
    }
    return [];
  } catch (error) {
    return [];
  }
};

export const COMPETENCIES_STORAGE_KEY = 'competencies_storage_key';
export const newSelectedCompetencyName = 'newSelectedCompetencyName';

export const set = ID => {
  const stored = safeJSONParseArrayString(
    window?.localStorage.getItem(COMPETENCIES_STORAGE_KEY)
  );
  stored.push(ID);
  return window?.localStorage.setItem(
    COMPETENCIES_STORAGE_KEY,
    JSON.stringify(stored)
  );
};

export const removeByID = ID => {
  const stored = safeJSONParseArrayString(
    window?.localStorage.getItem(COMPETENCIES_STORAGE_KEY)
  );
  return window?.localStorage.setItem(
    COMPETENCIES_STORAGE_KEY,
    JSON.stringify(stored.filter(id => id !== ID))
  );
};

export const removeNewSelectedCompetencyName = ID => {
  const stored = safeJSONParseArrayString(
    window?.localStorage.getItem(newSelectedCompetencyName)
  );
  return window?.localStorage.setItem(
    newSelectedCompetencyName,
    JSON.stringify(stored.filter(id => id !== ID))
  );
};

export const get = () =>
  safeJSONParseArrayString(
    window?.localStorage.getItem(COMPETENCIES_STORAGE_KEY)
  );

export const clear = () =>
  window?.localStorage.removeItem(COMPETENCIES_STORAGE_KEY);

export const clearNewSelectedCompetencyName = () =>
  window?.localStorage.removeItem(newSelectedCompetencyName);

export const getNewSelectedCompetencyName = () =>
  safeJSONParseArrayString(
    window?.localStorage.getItem(newSelectedCompetencyName)
  );
