import { useUserContext } from '../../../../contexts/UserContext';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { capitalize, get, includes, isEmpty, isNil, uniq } from 'lodash';
import { upsertOrRemoveCompetencies } from '../../../../api/domain/competency';
import { trigSurvey } from '../../../../support/qualarooTrigger';
import {
  REFLECT_COMPETENCY,
  REFLECT_SKILL
} from '../../../../constants/qualaroo';
import { updatePeopleSkills } from '../../../../api/domain/skill';
import { orderedCategory } from '../../../SkillsSelectPage/language';
import { updateProfile } from '../../../../actions/profile';
import FixedBar from '../../../../nonFeatureComponents/FixedBar';
import NavigationHeader from '../../../../nonFeatureComponents/NavigationHeader';
import StepperLine from '../../../../nonFeatureComponents/StepperLine';
import styles from './index.module.css';
import ArchetypesSelector from '../../../../feature/ArchetypesSelector';
import SearchBar from '../../../../feature/SearchBar';
import CompetencyWarning from '../CompetencyWarning';
import ReflectList from '../ReflectList/ReflectList';
import CompetencyModal from '../../../../feature/CompetencyModal';
import PropTypes from 'prop-types';
import { reflectPageDes } from '../../language';
import ConfirmationModal from '../../../../feature/ConfirmationModal';

const NotSelectedCategory = -1;
const AllSelectedComIndex = 0;
export const ReflectContent = ({
  onGoTo,
  reflectType,
  updateItemFun,
  competencies,
  currentCompetencies,
  getCurrentCompetencies,
  ...props
}) => {
  const {
    state: { user },
    dispatch
  } = useUserContext();

  const currentReflectInfo = reflectPageDes.reflectContent[reflectType];
  const {
    defaultFilterValue,
    categoryKey,
    emptyMessage,
    isShowSearchBar,
    steps,
    reflectPage: {
      reflectContentTitle,
      reflectContentSubTitle,
      warningDescription,
      warningTitle
    }
  } = currentReflectInfo;

  const [isFocus, setFocus] = useState(false);
  const [infoOpen, setInfoOpen] = useState(false);
  const [competency, setCompetency] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isShowWarning, setIsShowWarning] = useState(
    user?.showReflectCompetencyWarning
  );
  const [filterCompetencies, setFilterCompetencies] = useState(
    currentCompetencies
  );
  const [categoryList, setCategoryList] = useState([defaultFilterValue]);
  const [categoryName, setCategoryName] = useState(defaultFilterValue);
  const [affectedCompetencies, setAffectedCompetencies] = useState([]);
  const [toBeRemovedCompetencies, setToBeRemovedCompetencies] = useState([]);
  const isCompetency = reflectType === 'Competencies';
  const [
    isConfirmModalOpenAfterDone,
    setIsConfirmModalOpenAfterDone
  ] = useState(false);

  // Todo will be removed after removing <CompetencyWarning/>
  const [highlightedCompetency, setHighlightedCompetency] = useState('');

  const { confirmModalAfterDone } = reflectPageDes.reflectPage;

  useEffect(() => {
    const filteredCompetencies = competencies.filter(item => item.toBeRemoved);
    setToBeRemovedCompetencies(filteredCompetencies);
    setAffectedCompetencies(filteredCompetencies);
  }, [competencies]);

  useEffect(() => {
    if (!isEmpty(filterCompetencies)) {
      let categoryList = uniq(
        filterCompetencies.map(item => item[categoryKey])
      ).sort();

      if (isCompetency) {
        const coreCompetencyType = [];
        filterCompetencies.forEach(item => {
          const coreCapabilities = item.coreCapabilities;
          coreCapabilities.forEach(item =>
            coreCompetencyType.push('Core competencies - ' + capitalize(item))
          );
        });
        categoryList = [defaultFilterValue]
          .concat(uniq(coreCompetencyType))
          .concat(categoryList);
      }

      if (!isCompetency) {
        const categories = [];
        orderedCategory.forEach(item => {
          if (includes(categoryList, item)) {
            categories.push(item);
          }
        });
        categoryList = [defaultFilterValue].concat(categories);
      }

      setCategoryList(categoryList);
      if (categoryList.indexOf(categoryName) === NotSelectedCategory) {
        setCategoryName(defaultFilterValue);
      }
    } else {
      setCategoryName(defaultFilterValue);
      setCategoryList([defaultFilterValue]);
    }
  }, [
    filterCompetencies,
    categoryName,
    categoryKey,
    defaultFilterValue,
    isCompetency
  ]);

  useEffect(() => {
    if (isFocus || !isEmpty(searchValue)) {
      setCategoryName(defaultFilterValue);
    }
  }, [isFocus, searchValue, defaultFilterValue]);

  const handleGoTo = useCallback(async () => {
    if (isUpdating) {
      return;
    }
    setIsUpdating(true);
    if (isCompetency) {
      if (!isEmpty(currentCompetencies)) {
        await upsertOrRemoveCompetencies(
          currentCompetencies.map(com => ({
            id: get(com, 'reflectedInformation.id'),
            competencyId: com.competencyId,
            practiceLevel: get(com, 'reflectedInformation.practiceLevel', 0),
            isLearningPriority: get(
              com,
              'reflectedInformation.isLearningPriority',
              false
            )
          }))
        )
          .then(() => {
            onGoTo && onGoTo();
          })
          .finally(() => {
            setIsUpdating(false);
          });
      }
      localStorage.removeItem('newSelectedCompetencyName');
      trigSurvey(REFLECT_COMPETENCY);
    }
    if (!isCompetency) {
      if (!isEmpty(currentCompetencies)) {
        await updatePeopleSkills(
          currentCompetencies.map(skill => {
            return {
              id: get(skill, 'reflectedInformation.id'),
              skillId: skill.skillId,
              practiceLevel: get(
                skill,
                'reflectedInformation.practiceLevel',
                1
              ),
              isLearningPriority: get(
                skill,
                'reflectedInformation.isLearningPriority',
                false
              )
            };
          })
        )
          .then(() => {
            onGoTo && onGoTo();
          })
          .finally(() => {
            setIsUpdating(false);
          });
      }
      localStorage.removeItem('newSelectedSkillName');
      trigSurvey(REFLECT_SKILL);
    }
  }, [isUpdating, isCompetency, currentCompetencies, onGoTo]);

  const handleInfoClick = competency => {
    setCompetency(competency);
    setInfoOpen(true);
  };

  const onSearchBarChange = searchResult => {
    const { filterCoreDataList, curSearchVal } = searchResult;
    setSearchValue(curSearchVal);
    if (isEmpty(curSearchVal)) {
      setFilterCompetencies(currentCompetencies);
    } else {
      setFilterCompetencies(filterCoreDataList);
    }
  };

  const onSearchBarFocus = isFocus => {
    setFocus(isFocus);
  };

  const filterAffectedCompetencies = categoryName => {
    if (categoryName !== defaultFilterValue) {
      let filteredToBeRemovedCompetencies;
      if (categoryName.startsWith('Core competencies')) {
        const coreCapabilityName = categoryName.split(' - ')[1];
        filteredToBeRemovedCompetencies = toBeRemovedCompetencies.filter(item =>
          item.coreCapabilities.includes(coreCapabilityName)
        );
      } else {
        filteredToBeRemovedCompetencies = toBeRemovedCompetencies.filter(
          item => item.category === categoryName
        );
      }
      setAffectedCompetencies(filteredToBeRemovedCompetencies);
    }
  };

  const scrollView = competency => {
    const targetElement = document.querySelector(
      `[data-cy="reflectItem:${competency}"]`
    );
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });

      setHighlightedCompetency(competency);

      setTimeout(() => {
        setHighlightedCompetency('');
      }, 1500);
    }
  };

  const affectedCompetenciesNames = affectedCompetencies => {
    affectedCompetencies = affectedCompetencies.map(item => item.name);

    return (
      <div>
        {affectedCompetencies.map((competency, index) => (
          <span key={index}>
            <span
              style={{
                textDecoration: 'underline',
                cursor: 'pointer'
              }}
              onClick={() => scrollView(competency)}
            >
              {competency}
            </span>
            <span>{index < affectedCompetencies.length - 1 && ', '}</span>
          </span>
        ))}
      </div>
    );
  };

  const closeWarning = () => {
    setIsShowWarning(false);
    updateProfile(dispatch)({ showReflectCompetencyWarning: false });
  };

  const haveEmptyCompetency = useMemo(() => {
    return (
      currentCompetencies.length > 0 &&
      currentCompetencies.some(
        com =>
          isNil(com.reflectedInformation) ||
          (com.reflectedInformation.practiceLevel === 0 &&
            com.reflectedInformation.isLearningPriority === false)
      )
    );
  }, [currentCompetencies]);

  const confirmModalInfoAfterDone = useMemo(() => {
    return (
      <>
        <div>{confirmModalAfterDone.content1}</div>
        <div>{confirmModalAfterDone.content2}</div>
      </>
    );
  }, [confirmModalAfterDone]);

  const toShowWarning = () => {
    const currentDate = new Date();
    const startDate = new Date('2023-06-01');
    const endDate = new Date('2023-12-31');
    return (
      isShowWarning &&
      currentDate >= startDate &&
      currentDate <= endDate &&
      affectedCompetencies.length > 0
    );
  };

  return (
    <>
      <FixedBar>
        <NavigationHeader
          onGoTo={
            haveEmptyCompetency
              ? () => setIsConfirmModalOpenAfterDone(true)
              : handleGoTo
          }
          backToTitle="Cancel"
          goToTitle={
            <span>
              I&#39;m do<u>n</u>e!
            </span>
          }
          disabled={isUpdating}
          {...props}
        >
          <StepperLine
            activeStep={1}
            steps={steps}
            isShowStepTooltip={isCompetency}
          />
        </NavigationHeader>
      </FixedBar>
      <div className={styles.page}>
        <div className={styles.titleWrapper}>
          <div>
            <h3 className={styles.title}>{reflectContentTitle}</h3>
            {reflectContentSubTitle && (
              <p className={styles.description}>{reflectContentSubTitle}</p>
            )}
            <section className={styles.reflectSearch}>
              <ArchetypesSelector
                defaultOption={null}
                disabled={isFocus || !isEmpty(searchValue)}
                onChange={index => {
                  if (
                    index === AllSelectedComIndex ||
                    index === NotSelectedCategory
                  ) {
                    setCategoryName(defaultFilterValue);
                    setAffectedCompetencies(toBeRemovedCompetencies);
                  }
                  const categoryName = categoryList[index];
                  setCategoryName(categoryName);
                  filterAffectedCompetencies(categoryName);
                }}
                options={categoryList}
                value={categoryList.indexOf(categoryName)}
              />

              {isShowSearchBar && (
                <SearchBar
                  singleLayerArrayData={currentCompetencies}
                  searchConstants={reflectPageDes.searchBarConstants}
                  onSearchBarChange={onSearchBarChange}
                  onSearchBarFocus={onSearchBarFocus}
                />
              )}
            </section>
          </div>
        </div>
        {toShowWarning() && (
          <CompetencyWarning
            warningTitle={warningTitle}
            warningDescription={warningDescription}
            nameList={affectedCompetenciesNames(affectedCompetencies)}
            closeWarning={closeWarning}
          />
        )}
        <ReflectList
          handleNameClick={handleInfoClick}
          emptyMessage={emptyMessage}
          reflectType={reflectType}
          categoryName={categoryName}
          allCategoryList={categoryList}
          searchValue={searchValue}
          updateItemFun={updateItemFun}
          filterCompetencies={filterCompetencies}
          competencies={currentCompetencies}
          getCurrentCompetencies={getCurrentCompetencies}
          highlightedCompetency={highlightedCompetency}
        />
      </div>
      <CompetencyModal
        notShowSelectButton={true}
        competency={competency}
        open={infoOpen}
        close={() => setInfoOpen(false)}
      />
      <ConfirmationModal
        open={isConfirmModalOpenAfterDone}
        setOpen={open => {
          setIsConfirmModalOpenAfterDone(open);
        }}
        confirmButtonText={confirmModalAfterDone.confirmButtonText}
        cancelButtonText={confirmModalAfterDone.cancelButtonText}
        confirm={handleGoTo}
        cancel={() => {
          setIsConfirmModalOpenAfterDone(false);
        }}
        title={confirmModalAfterDone.title}
        content={confirmModalInfoAfterDone}
      />
    </>
  );
};

ReflectContent.propTypes = {
  onGoTo: PropTypes.func.isRequired,
  reflectType: PropTypes.string
};
