import React, { useEffect } from 'react';
import styles from './SkillReleaseNotes.module.css';
import LogoSkills from '../../../../../assets/Icons/skills.svg';
import LogoSkillEntrance from '../../../../../assets/Icons/skillEntrance.svg';
import LogoPathwaysToJigsaw from '../../../../../assets/Icons/pathwaysToJigsaw.svg';
import globalStyles from '../../../../../globalStyles';
import { useHistory } from 'react-router-dom';
import * as SkillsStorage from '../../../../Utils/skillsStorage';
import {
  TAKE_ME_TO_ADD_SKILLS,
  UPDATE_MY_SKILLS_NOW
} from '../../../../../constants/matomo';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { updateProfile } from '../../../../../actions/profile';
import { useUserContext } from '../../../../../contexts/UserContext';
import { JigsawLink } from '../../../../../constants/links';
import { getEnvVars } from '../../../../../config/config';
import { styled } from '@mui/material/styles';
import {
  ModalCancelButton,
  ModalSaveButton
} from '../../../../../nonFeatureComponents/Buttons/Buttons';

const ButtonNow = styled(ModalSaveButton)({
  color: globalStyles.white,
  backgroundColor: globalStyles.magenta,
  height: '50px',
  fontSize: '16px',
  '&:hover': {
    backgroundColor: globalStyles.magenta
  },
  '&:focus': {
    backgroundColor: globalStyles.magenta,
    outline: 'none'
  }
});

const ButtonLater = styled(ModalCancelButton)({
  color: globalStyles.black,
  border: '1px solid',
  height: '50px',
  fontSize: '16px',
  padding: '6px 12px',
  marginRight: '16px',
  '&:hover': {
    backgroundColor: globalStyles.grey81
  }
});

const SkillReleaseNotes = ({ name, strength, onClose }) => {
  const { dispatch } = useUserContext();

  const { push } = useHistory();

  const { trackEvent } = useMatomo();

  const {
    state: { user }
  } = useUserContext();
  const { env } = getEnvVars();

  useEffect(() => {
    if (strength?.length > 0) {
      SkillsStorage.clear();
      strength.forEach(group => {
        group.children.forEach(skill => {
          SkillsStorage.set(skill.id);
        });
      });
    }
  }, [strength]);

  const hideSkillTip = async (showNext = true) => {
    updateProfile(dispatch)({ showSkillModal: false });
    onClose(showNext);
  };

  const goTo = async page => {
    await hideSkillTip(false);
    push(page);
  };

  const goSkills = async () => {
    if (strength?.length > 0) {
      trackEvent({
        category: UPDATE_MY_SKILLS_NOW.category,
        action: UPDATE_MY_SKILLS_NOW.action.JUMP_TO_REFLECT_SKILLS
      });
      await goTo('/reflect-skills');
    } else {
      trackEvent({
        category: TAKE_ME_TO_ADD_SKILLS.category,
        action: TAKE_ME_TO_ADD_SKILLS.action.JUMP_TO_SELECT_SKILLS
      });
      await goTo('/select-skills');
    }
  };

  const goCompetencyList = async () => {
    await goTo('/select-competencies');
  };

  const goMeNextVisPreferences = async () => {
    await goTo('/visibility-preference');
  };

  return (
    <>
      <div className={styles.twoColumn}>
        <div className={styles.imgContainer}>
          <img src={LogoSkills} alt="skills" />
        </div>
        {strength?.length > 0 ? (
          <div className={styles.descContainer}>
            <h3 className={styles.name}>{`Hi ${name}!`}</h3>
            <p>
              We’ve moved your Skills into Pathways. You can now continuing
              self-reflecting about your growth using Competencies,
              Archetypes... and now Skills.
            </p>
            <p>According to Jigsaw, these are your skills*:</p>
            <div className={styles.skillContainer}>
              {strength.map(group =>
                group.children.map(skill => (
                  <span key={skill.id} className={styles.skillPod}>
                    {skill.name}
                  </span>
                ))
              )}
            </div>
            <div className={styles.missTips}>
              *Missing a certain skill? Have a look at your updated&nbsp;
              <span onClick={goCompetencyList} className={styles.listLink}>
                competency list
              </span>
              . Some skills have been merged with competenices.
            </div>
            <h3 className={styles.paragraphTitle}>Update your skills now!</h3>
            <p className={styles.paragraphMessage}>
              Once updated, your skills will be synced with your{' '}
              <a
                className={styles.highlighted}
                rel="noopener noreferrer"
                target="_blank"
                href={JigsawLink(user?.employeeId, env)}
              >
                Jigsaw Profile
              </a>{' '}
              and visible to Staffing specialists, Account CLTs and fellow
              Thoughtworkers. This will let Staffing Specialists know which
              skills you would like to get more opportunities to work on.
            </p>
            <h3 className={styles.paragraphTitle}>
              <span
                onClick={goMeNextVisPreferences}
                className={styles.listLink}
              >
                Manage ‘Me Next’ visibility preferences
              </span>
            </h3>
            <p className={styles.paragraphMessage}>
              Making all your ‘Me Next’ data visible in your{' '}
              <a
                className={styles.highlighted}
                rel="noopener noreferrer"
                target="_blank"
                href={JigsawLink(user?.employeeId)}
              >
                Jigsaw Profile
              </a>{' '}
              helps you and staffing teams align project opportunities according
              to your growth aspirations.
            </p>
          </div>
        ) : (
          <div className={styles.descContainer}>
            <h3>{`Hi ${name}!`}</h3>
            <p>
              Besides Archetypes and Competencies, you can now use Pathways to
              self-reflect on your skills too.{' '}
            </p>
            <div className={styles.twoSubColumn}>
              <div className={styles.imgContainer}>
                <img src={LogoSkillEntrance} alt="skill entrance" />
              </div>
              <div className={styles.descContainer}>
                <div>
                  <span className={styles.newLabel}>New</span>
                </div>
                <div>
                  Once updated, your skills and all your Me Now information will
                  be synced with your{' '}
                  <span className={styles.highlighted}>Jigsaw</span> profile and
                  visible to Staffing specialists, Account CLTs and fellow
                  Thoughtworkers.{' '}
                  <b>
                    This will increase your chances to match your current
                    capabilities and growth aspirations with project
                    opportunities.
                  </b>
                </div>
              </div>
            </div>
            <div className={styles.twoSubColumn}>
              <div className={styles.imgContainer}>
                <img src={LogoPathwaysToJigsaw} alt="pathways to jigsaw" />
              </div>
              <div className={styles.descContainer}>
                <div>
                  <span className={styles.newLabel}>New</span>
                </div>
                <div>
                  You can also <b>opt whether or not you want to</b>{' '}
                  <span
                    className={styles.listLink}
                    onClick={goMeNextVisPreferences}
                  >
                    make your Me Next self-reflection visible
                  </span>
                  . Your Growth Plan, though, remains always private to you.
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={styles.buttonContainer}>
        <ButtonLater onClick={hideSkillTip}>
          {strength?.length > 0 ? 'I’ll update them later' : 'Ok, Thank you!'}
        </ButtonLater>
        <ButtonNow onClick={goSkills}>
          {strength?.length > 0
            ? 'Yes! Let’s update my skills now'
            : 'Great! Take me to add skills'}
        </ButtonNow>
      </div>
    </>
  );
};

export default SkillReleaseNotes;
