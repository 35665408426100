import React from 'react';
import { SvgIcon } from '@mui/material';

const CurrentGoalIcon = props => {
  return (
    <SvgIcon {...props}>
      <path
        fill="#C43067"
        fillRule="evenodd"
        d="M12 0l.001 3.062c3.625.458 6.48 3.313 6.937 6.938H22v2l-3.062.001c-.458 3.625-3.312 6.479-6.937 6.937L12 22h-2v-3.062c-3.625-.458-6.48-3.312-6.938-6.937L0 12v-2h3.062C3.52 6.375 6.375 3.52 10 3.062V0h2zm-1 5c-3.314 0-6 2.686-6 6s2.686 6 6 6 6-2.686 6-6-2.686-6-6-6zm0 4c1.105 0 2 .895 2 2s-.895 2-2 2-2-.895-2-2 .895-2 2-2z"
      />
    </SvgIcon>
  );
};

export default CurrentGoalIcon;
