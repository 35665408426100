import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { CustomInput } from './CustomInput';
import { ARCHETYPE_DASHBOARD_EVENT } from '../../../../constants/matomo';
import { useUserContext } from '../../../../contexts/UserContext';
import {
  fetchInterests,
  addInterest,
  removeInterest
} from '../../../../actions/profile';

import styles from './MyInterests.module.css';
import clsx from 'clsx';
import CloseIcon from '@mui/icons-material/Close';
import { isEmpty } from 'lodash';
import { onKeyDown } from '../../../../utils/onKeyDown';
import { CustomList } from './CustomList';
import { dashboardDes } from '../../language';

const MyInterests = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { trackEvent } = useMatomo();
  const { state, dispatch } = useUserContext();
  const user = state?.user || { interests: [] };
  const inputRef = useRef(null);

  useEffect(() => {
    fetchInterests(dispatch).then();
  }, [dispatch]);

  const handleAddInterest = async (content, callback) => {
    setIsLoading(true);
    const response = await addInterest(dispatch)(content);
    setIsLoading(false);
    if (!isEmpty(response)) {
      callback && callback();
      trackEvent({
        category: ARCHETYPE_DASHBOARD_EVENT.category,
        action: ARCHETYPE_DASHBOARD_EVENT.action.INTERESTS
      });
      inputRef.current.focus();
    }
  };

  const handleDeleteInterest = useCallback(
    id => {
      removeInterest(dispatch)(id);
    },
    [dispatch]
  );

  return (
    <div className={styles.myInterests}>
      <h3 className={styles.title} id="interests">
        {dashboardDes.myInterests.title}
      </h3>
      <CustomInput
        placeholder={dashboardDes.myInterests.defaultInput}
        addItem={handleAddInterest}
        disabled={isLoading}
        ref={inputRef}
        inputProps={{ 'aria-labelledby': 'interests' }}
      />
      <div
        className={clsx(styles.myInterestsContainer, styles.primaryScrollbar)}
      >
        <CustomList
          list={user.interests || []}
          deleteItem={handleDeleteInterest}
          renderItem={({ item, onClick }) => (
            <li className={styles.interestItem} data-cy="myInterestsItem">
              <span>{item.content}</span>
              <div
                className={styles.deleteIcon}
                onClick={onClick}
                onKeyDown={e => onKeyDown(e, onClick)}
                tabIndex="0"
              >
                <CloseIcon className={styles.deleteIconInner} tabIndex="-1" />
              </div>
            </li>
          )}
        />
      </div>
    </div>
  );
};

export default MyInterests;
