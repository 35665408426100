import styles from './globalStyles.module.css';

const globalStyles = {
  defaultFontStyles: {
    fontFamily: "'Open Sans', 'sans-serif'",
    letterSpacing: 'normal'
  },
  white: styles.white,
  pageBackgroundColor: styles.pageBackgroundColor,
  secondaryColor: styles.secondaryColor,
  primaryColor: styles.primaryColor,
  magenta: styles.magenta,
  avocadoGreen: styles.avocadoGreen,
  primaryGreen: styles.primaryGreen,
  lightGreen: styles.lightGreen,
  darkGrey: styles.darkGrey,
  primaryGrey: styles.primaryGrey,
  black: styles.black,
  black3: styles.black3,
  aquaBlue: styles.aquaBlue,
  lightBlue: styles.lightBlue,
  aquaBlueLight: styles.aquaBluelight,
  blue1: styles.blue1,
  blue2: styles.blue2,
  blue3: styles.blue3,
  grey2: styles.grey2,
  grey4: styles.grey4,
  grey10: styles.grey10,
  grey11: styles.grey11,
  grey20: styles.grey20,
  grey21: styles.grey21,
  grey23: styles.grey23,
  grey24: styles.grey24,
  grey40: styles.grey40,
  grey41: styles.grey41,
  grey80: styles.grey80,
  grey83: styles.grey83,
  grey86: styles.grey86,
  grey70: styles.grey70,
  orange: styles.orange,
  orange2: styles.orange2,
  pink: styles.pink,
  errorRed: styles.errorRed,
  pdfRead: styles.pdfRead,
  hoverRed: styles.hoverRed,
  jigsawPurple: styles.jigsaw,
  goalCreatedPlaceholder: styles.goalCreatedPlaceholder,

  /* core competencies flow variable */
  btnSecondary: styles.btnSecondary,
  basicGreen: styles.basicGreen,
  archetypeActive: styles.archetypeActive,

  red1: styles.red1,
  grey81: styles.grey81,

  space1: styles.space1,
  space2: styles.space2,
  space3: styles.space3,
  space4: styles.space4,
  space5: styles.space5,
  space6: styles.space6,
  space7: styles.space7,
  space8: styles.space8,
  space9: styles.space9,
  space10: styles.space10,
  space11: styles.space11
};

export default globalStyles;
