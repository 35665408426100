import React from 'react';
import { SvgIcon } from '@mui/material';

const information = props => {
  const {
    color = '#525A70',
    opacity = '0.562058222',
    isShowInfo = true,
    fontSize = '16px'
  } = props;

  let filterProps = {};
  Object.keys(props).forEach(key => {
    if (key !== 'color' && key !== 'opacity') {
      filterProps[key] = props[key];
    }
  });

  return (
    <SvgIcon {...filterProps}>
      <svg viewBox="0 0 15 15" version="1.1">
        {isShowInfo && <title>icon/information</title>}
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-317.000000, -248.000000)">
            <g transform="translate(315.000000, 246.000000)">
              <polygon points="0 0 18 0 18 18 0 18"></polygon>
              <path
                d="M9.5,17 C5.35775,17 2,13.64225 2,9.5 C2,5.35775 5.35775,2 9.5,2 C13.64225,2 17,5.35775 17,9.5 C17,13.64225 13.64225,17 9.5,17 Z M8.75,8.75 L8.75,13.25 L10.25,13.25 L10.25,8.75 L8.75,8.75 Z M8.75,5.75 L8.75,7.25 L10.25,7.25 L10.25,5.75 L8.75,5.75 Z"
                fill={color}
                fillRule="nonzero"
                opacity={opacity}
                fontSize={fontSize}
              ></path>
            </g>
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
};

export default information;
