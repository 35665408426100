export const ME_NOW = 'MeNow';
export const ME_NEXT = 'MeNext';
export const BOTH_ME_NOW_AND_ME_NEXT = 'BothMeNowAndMeNext';
export const DEFAULT_TAB = -1;
export const ME_NOW_TAB = 0;
export const ME_NEXT_TAB = 1;
export const MY_PLAN_TAB = 2;
export const DASHBOARD_TAB_ITEM = 'tabIndex';

export const DASHBOARD_URL_TAB = {
  ME_NOW: 'me-now',
  ME_NEXT: 'me-next',
  MY_PLAN: 'my-plan'
};
export const DASHBOARD_TAB_URL_ENUM = {
  [DASHBOARD_URL_TAB.ME_NOW]: 0,
  [DASHBOARD_URL_TAB.ME_NEXT]: 1,
  [DASHBOARD_URL_TAB.MY_PLAN]: 2
};
