import React from 'react';
import styles from './index.module.css';
import clsx from 'clsx';
import { landingPageDes } from '../../language';
import { useHistory } from 'react-router-dom';
import { DASHBOARD_WITH_TARGET } from '../../../../constants/path';

export const PlanModule = () => {
  const history = useHistory();
  const { planModule } = landingPageDes;

  const handleGoDashboard = tab => {
    history.push(DASHBOARD_WITH_TARGET + tab);
  };
  return (
    <section id="pathways">
      <div className={styles.pathwaysIntroduceTitle}>{planModule.title}</div>
      <p className={styles.pathwaysIntroduce}>{planModule.pathwaysIntro}</p>

      <div className={styles.cards}>
        {planModule.cardList.map(card => (
          <div className={styles.card} key={card.title}>
            {card.decorationImgs}
            <div className={styles.cardTitle}>{card.title}</div>
            <p
              className={clsx(
                styles.description,
                styles[card.descriptionClassName]
              )}
            >
              {card.description(handleGoDashboard)}
            </p>
          </div>
        ))}
      </div>
    </section>
  );
};
