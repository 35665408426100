import React from 'react';
import PropTypes from 'prop-types';
import DialogContent from '@mui/material/DialogContent';
import {
  DialogActionsWithStyle,
  DialogBox,
  DialogContentTextWithStyle,
  DialogTitleWithStyle
} from '../../components/ErrorBox/ErrorBoxComponents';
import {
  CloseIconButton,
  CancelButton,
  ConfirmButton
} from '../../nonFeatureComponents/Buttons/Buttons';
import { styled } from '@mui/material/styles';
import ModalHeader from '../../components/Common/Modal/ModalHeader';

const ConfirmationModal = props => {
  const handleClick = confirm => {
    if (confirm) {
      props.confirm(props.data);
    }
    props.setOpen(false);
  };

  const handleCancel = () => {
    if (props.cancel) {
      props.cancel();
    } else {
      props.setOpen(false);
    }
  };

  return (
    <div data-cy="notification">
      <DialogBox role="dialog" open={props.open} aria-labelledby="init">
        <ModalHeader>
          <DialogTitleWithStyle id="init">{props.title}</DialogTitleWithStyle>
          <CloseIconButton handleClick={handleClick} />
        </ModalHeader>
        <DialogContentWithStyle>
          <DialogContentTextWithStyle>
            {props.content}
          </DialogContentTextWithStyle>
        </DialogContentWithStyle>
        <DialogActionsWithStyle>
          <CancelButton onClick={handleCancel} data-cy="cancel">
            {props.cancelButtonText}
          </CancelButton>
          <ConfirmButton onClick={() => handleClick(true)} data-cy="confirm">
            {props.confirmButtonText}
          </ConfirmButton>
        </DialogActionsWithStyle>
      </DialogBox>
    </div>
  );
};

ConfirmationModal.propTypes = {
  cancelButtonText: PropTypes.string.isRequired,
  confirmButtonText: PropTypes.string.isRequired,
  confirm: PropTypes.func.isRequired,
  cancel: PropTypes.func,
  data: PropTypes.any,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

export const DialogContentWithStyle = styled(DialogContent)({
  padding: '8px 24px',
  overflowY: 'hidden'
});

export default ConfirmationModal;
