import { groupBy, isEmpty, filter } from 'lodash';

export const allSkillsGroupByCategory = res => {
  return groupBy(res, 'category');
};

export const reflectedSkill = res =>
  filter(res, com => !isEmpty(com?.reflectedInformation));

export const reflectedSkillsGroupByPriority = res => {
  let prioritized = [];
  let strength = [];

  const prioritizedSkillListInThisGroup = 'prioritizedSkillListInThisGroup';
  const strengthSkillListInThisGroup = 'strengthSkillListInThisGroup';
  const skillsGroupByCategory = allSkillsGroupByCategory(res);

  Object.keys(skillsGroupByCategory).forEach(skillGroupName => {
    const skillsGroupByIsPrioritized = groupBy(
      skillsGroupByCategory[skillGroupName],
      item => {
        return item.reflectedInformation.isLearningPriority
          ? prioritizedSkillListInThisGroup
          : strengthSkillListInThisGroup;
      }
    );
    !isEmpty(skillsGroupByIsPrioritized[prioritizedSkillListInThisGroup]) &&
      prioritized.push({
        name: skillGroupName,
        children: skillsGroupByIsPrioritized[prioritizedSkillListInThisGroup]
      });
    !isEmpty(skillsGroupByIsPrioritized[strengthSkillListInThisGroup]) &&
      strength.push({
        name: skillGroupName,
        children: skillsGroupByIsPrioritized[strengthSkillListInThisGroup]
      });
  });

  return {
    prioritized: prioritized,
    strength: strength
  };
};
