import React from 'react';

export const archetypesIntentionDes = {
  archetypeIntentionItem: {
    meNowLabel: `I'm playing this Archetype <b>Now</b>.`,
    meNextLabel: ` I would like to grow <b>Next</b> into this Archetype.`,
    meNowAndMeNextLabel: `It's both. I can play it  <b>Now</b> and I want to continue growing <b>Next</b> on it.`
  },
  backToTitle: 'Cancel',
  goToTitle: (
    <span>
      I&#39;m do<u>n</u>e reflecting!
    </span>
  ),
  axeCheck: 'forAxeCheck',
  tipTitle: 'Based on your reflection,',
  tipContent: (
    <>
      is this an Archetype you are playing <b>now</b>, one you would like to
      grow <b>next, or both</b>?
    </>
  ),
  tipTrigger: 'Tips to help you decide',
  noData: 'Please select the archetypes to get started.',
  confirmModal: {
    answerLater: "I'll answer later",
    answerNow: 'Answer now',
    p1: 'You haven’t answered this last question on all your Archetypes.',
    p2: 'Answering this now will place the Archetype into your Growth Canvas.'
  },
  popup: {
    title: 'Tips to help you decide',
    tipModalInfo: [
      {
        h: 'Do I play this archetype now?',
        p:
          'Archetypes you can play now are archetypes that you have experience and capability in. This could be the archetype you are currently playing on your project/team or an archetype you have played in the past.'
      },
      {
        h: 'Do I play this archetype now and want to continue growing it?',
        p:
          'Archetypes you have some experience in and would like to continue growing/learning/working on fall into this category.'
      },
      {
        h: 'Do I want to grow into this archetype next?',
        p:
          'The archetypes that you are interested in learning and growing into. Think about what archetypes interest you and you would like to discover in the future.'
      }
    ]
  },
  gotButton: 'Got it!'
};
