import axios from 'axios';
import { getEnvVars } from '../../config/config';
import { redirectToSignIn } from '../../authentication/redirectToSignIn';
import { HTTP_STATUS } from '../../constants/httpStatus';
import logger from '../../config/logsConfig';
import stringify from '../../utils/queryString';

const { bffHostUrl } = getEnvVars();

export const ajax = axios.create({
  baseURL: bffHostUrl,
  paramsSerializer(params) {
    return stringify(params);
  },
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json'
  }
});

const addAuthorizationHeader = async (config, oktaAuth) => {
  const { accessToken } = await oktaAuth.tokenManager.getTokens();
  if (!accessToken) {
    redirectToSignIn(oktaAuth);
    return { ...config };
  }
  config.headers.Authorization = `Bearer ${accessToken.accessToken}`;
  config.headers.emp = accessToken?.claims?.emp;
  config.headers.email = accessToken?.claims?.sub;
  return config;
};

export const handleErrorResp = (err, oktaAuth) => {
  function handleUnauthorizedError() {
    return oktaAuth.tokenManager
      .renew('accessToken')
      .then(({ accessToken }) => {
        err.config.headers.Authorization = `Bearer ${accessToken}`;
        //redirectToSignIn temporary, need to distinguish token expire or invalid token cased by okta !
        redirectToSignIn(oktaAuth);
        return Promise.reject(err);
      })
      .catch(() => {
        redirectToSignIn(oktaAuth);
        return Promise.reject(err);
      });
  }

  if (!axios.isCancel(err)) {
    const statusCode = err.response.status;
    switch (statusCode) {
      case HTTP_STATUS.UNAUTHORIZED:
        handleUnauthorizedError();
        break;
      default:
        logger.error(`Request failed - ${err.message}`);
        return Promise.reject(err);
    }
  }
};

export const addInterceptors = oktaAuth => {
  ajax.interceptors.request.use(
    async config => addAuthorizationHeader(config, oktaAuth),
    error => Promise.reject(error)
  );
  ajax.interceptors.response.use(
    response => response.data,
    error => handleErrorResp(error, oktaAuth)
  );
};

export const get = (path, params) => {
  return ajax
    .get(`${bffHostUrl}/${path}`, params)
    .then(response => response)
    .catch(error => {
      logger.error(`GET request ${path} failed - ${error.message}`);
      throw error;
    });
};
export const post = (path, body) => {
  return ajax.post(`${bffHostUrl}/${path}`, body).catch(error => {
    logger.error(`POST request ${path} failed - ${error.message}`);
    throw error;
  });
};

export const put = (path, body) => {
  return ajax.put(`${bffHostUrl}/${path}`, body).catch(error => {
    logger.error(`PUT request ${path} failed - ${error.message}`);
    throw error;
  });
};

export const deleteREST = path =>
  ajax.delete(`${bffHostUrl}/${path}`).catch(error => {
    logger.error(`DELETE request ${path} failed - ${error.message}`);
    throw error;
  });

export const patch = (path, body) => {
  return ajax.patch(`${bffHostUrl}/${path}`, body).catch(error => {
    logger.error(`PATCH request ${path} failed - ${error.message}`);
    throw error;
  });
};

export const api = {
  get: get,
  post: post,
  put: put,
  patch: patch,
  delete: deleteREST
};
