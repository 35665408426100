import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';

import Slider from '@mui/material/Slider';
import * as DOMPurify from 'dompurify';
import ArrowTooltip from '../../components/Tooltip/ArrowTooltip';
import { proficiencyLevelsDescription } from '../../constants/proficiencyLevels';
import globalStyles from '../../globalStyles';
import styles from './index.module.css';
import { onKeyDown } from '../../utils/onKeyDown';

const sanitizer = DOMPurify.sanitize;

const useStylesTooltip = {
  '& .MuiTooltip-tooltip': {
    minWidth: '200px',
    maxWidth: '480px'
  }
};

const CustomSlider = styled(Slider)(({ theme }) => ({
  color: globalStyles.primaryColor,
  height: 4,
  padding: '15px 0',
  '.MuiSlider-thumb': {
    position: 'absolute',
    width: 14,
    height: 14,
    top: 17,
    marginLeft: 5,
    backgroundColor: globalStyles.primaryColor
  },
  '.MuiSlider-track': {
    height: 2
  },
  '.MuiSlider-rail': {
    height: 4,
    backgroundColor: globalStyles.grey41,
    opacity: '1'
  },
  '.MuiSlider-mark': {
    height: 14,
    width: 14,
    borderRadius: '50%',
    backgroundColor: globalStyles.grey41
  },
  '.MuiSlider-markActive': {
    opacity: 1,
    backgroundColor: 'currentColor'
  }
}));

const LevelBehaviourTooltip = ({
  name,
  description,
  levelDescription,
  children
}) => {
  return (
    <ArrowTooltip
      arrow
      aria-label={levelDescription}
      role="tooltip"
      title={
        <div className={styles.behaviour}>
          <div className={styles.title}>
            <span className={styles.proficiencyLevel}>{levelDescription}</span>
          </div>
          <div
            className={styles.levelBehaviour}
            dangerouslySetInnerHTML={{ __html: sanitizer(description) }}
          />
        </div>
      }
      interactive={'true'}
      classes={useStylesTooltip}
      placement="bottom"
    >
      {children}
    </ArrowTooltip>
  );
};

LevelBehaviourTooltip.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  levelDescription: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

const AssessSlider = ({
  suggestedLevel,
  actualPracticeLevel,
  levelDescriptions,
  name,
  step,
  min,
  max,
  marks,
  onChange
}) => {
  const [practiceLevel, setPracticeLevel] = useState(0);

  useEffect(() => {
    setPracticeLevel(actualPracticeLevel);
  }, [actualPracticeLevel]);

  return (
    <div className={styles.assessSlider} data-cy="selfAssessSlider">
      <CustomSlider
        aria-label={name}
        aria-valuetext={
          proficiencyLevelsDescription[practiceLevel - 1] || 'no selection'
        }
        defaultValue={practiceLevel}
        value={practiceLevel}
        step={step}
        min={min}
        max={max}
        marks={marks}
      />
      <div className={styles.slider}>
        {levelDescriptions?.map((level, i) => (
          <LevelBehaviourTooltip
            key={level.level}
            name={name}
            description={level.description}
            levelDescription={proficiencyLevelsDescription[i]}
          >
            <div
              className={clsx(
                styles.mark,
                !practiceLevel && styles.markDefault,
                suggestedLevel === i + 1 && styles.markExpected
              )}
              style={{ left: `${i * 25}%` }}
              onClick={() => onChange(i + 1)}
              onKeyDown={e => {
                onKeyDown(e, () => onChange(i + 1));
              }}
              tabIndex={0}
              data-cy="sliderMark"
            />
          </LevelBehaviourTooltip>
        ))}
      </div>
    </div>
  );
};

AssessSlider.defaultProps = {
  step: 1,
  min: 1,
  max: 5,
  marks: true
};

AssessSlider.propTypes = {
  step: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  marks: PropTypes.bool,
  expectedScore: PropTypes.number,
  actualScore: PropTypes.number,
  levelBehaviours: PropTypes.array,
  name: PropTypes.string,
  onChange: PropTypes.func
};

export default AssessSlider;
