import styles from './index.module.css';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { onKeyDown } from '../../../../utils/onKeyDown';

const TopCourseTabs = ({ onChange, currentIndex, tabs }) => {
  return (
    <section className={styles.tabs}>
      {tabs.map((tab, index) => {
        return (
          <nav
            onClick={() => onChange(index)}
            onKeyDown={e => onKeyDown(e, () => onChange(index))}
            key={tab}
            className={clsx(styles.tabItem, {
              [styles.active]: index === currentIndex
            })}
          >
            <div className={styles.tabItemInner}>{tab}</div>
          </nav>
        );
      })}
    </section>
  );
};
TopCourseTabs.propTypes = {
  currentIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  tabs: PropTypes.array.isRequired
};
export default TopCourseTabs;
