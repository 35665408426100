import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from 'react';
import { get } from 'lodash';
import * as DOMPurify from 'dompurify';
import clsx from 'clsx';
import styles from './index.module.css';
import Notification from '../../components/Common/Modal/Notification';
import PropTypes from 'prop-types';
import { onKeyDown } from '../../utils/onKeyDown';
import { CheckBox } from '../../nonFeatureComponents/CheckBox';
import { LEARNING_PRIORITY } from '../../constants/competency';
import deleteBin from '../../assets/Icons/deleteBin.svg';
import AssessSlider from '../../nonFeatureComponents/AssessSlider';
import ArrowTooltip from '../../components/Tooltip/ArrowTooltip';
import globalStyles from '../../globalStyles';

const sanitizer = DOMPurify.sanitize;

export const ReflectItem = forwardRef((props, ref) => {
  const RemoveArrowTooltip = {
    '& .MuiTooltip-tooltip': {
      color: globalStyles.white,
      borderRadius: '7px',
      backgroundColor: globalStyles.blue3,
      padding: '15px',
      maxWidth: '480px',
      height: '28px',
      marginTop: '-1em',
      marginBottom: '-1em',
      lineHeight: 1.6,
      fontSize: '16px'
    }
  };
  const {
    reflectItem: { reflectedInformation, name, nameFilter = '' },
    newSelectedStorageName,
    reflectItem,
    defaultPracticeLevel,
    description,
    levelDescriptions,
    onChange,
    searchValue,
    showName = false,
    showItemDescription = false,
    handleNameClick,
    customSliderStyle = {},
    customCheckBoxStyle = {},
    removeInfo,
    highlightedCompetency = ''
  } = props;

  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [isLatestName, setLatestName] = useState(false);

  const [practiceLevel, setPracticeLevel] = useState(
    get(reflectedInformation, 'practiceLevel') || defaultPracticeLevel
  );
  const [isLearningPriority, setIsLearningPriority] = useState(
    get(reflectedInformation, 'isLearningPriority', false)
  );
  useEffect(() => {
    setPracticeLevel(get(reflectedInformation, 'practiceLevel'));
    setIsLearningPriority(get(reflectedInformation, 'isLearningPriority'));
  }, [reflectedInformation]);

  useEffect(() => {
    let newSelectedNameArray =
      (newSelectedStorageName && JSON.parse(newSelectedStorageName)) || [];
    let isLatestNameState = newSelectedNameArray.some(latestName => {
      return name === latestName;
    });
    setLatestName(isLatestNameState);
  }, [newSelectedStorageName, name]);

  useImperativeHandle(ref, () => {
    return {
      setValues({ practiceLevel, isLearningPriority }) {
        setIsLearningPriority(isLearningPriority);
        setPracticeLevel(practiceLevel);
        const newCompetency = {
          ...reflectItem,
          reflectedInformation: {
            ...reflectedInformation,
            practiceLevel: practiceLevel,
            isLearningPriority: isLearningPriority
          }
        };
        onChange(newCompetency);
      }
    };
  });

  const onPracticeLevelChange = sliderScore => {
    let tmpPracticeLevel = sliderScore;
    if (sliderScore === practiceLevel) {
      tmpPracticeLevel = defaultPracticeLevel;
    }
    setPracticeLevel(tmpPracticeLevel);
    const newCompetency = {
      ...reflectItem,
      reflectedInformation: {
        ...reflectedInformation,
        practiceLevel: tmpPracticeLevel
      }
    };
    onChange(newCompetency);
  };

  const onLearningPriorityChange = event => {
    setIsLearningPriority(event.target.checked);
    const newCompetency = {
      ...reflectItem,
      reflectedInformation: {
        ...reflectedInformation,
        isLearningPriority: event.target.checked
      }
    };
    onChange(newCompetency);
  };
  const onRemoveConfirm = async () => {
    const {
      removeRequest,
      removeStorageName,
      removeStorageId,
      itemId
    } = removeInfo;
    setShowRemoveModal(false);
    if (reflectedInformation?.id) {
      await removeRequest(reflectedInformation.id);
    }
    removeStorageName(name);
    const newCompetency = { ...reflectItem, isRemove: true };
    onChange(newCompetency);
    removeStorageId(itemId);
  };

  return (
    <div
      className={clsx(
        styles.reflectItem,
        name === highlightedCompetency && styles.highlight
      )}
      data-cy={`reflectItem:${name}`}
    >
      {showName && (
        <div
          role="button"
          tabIndex={0}
          className={clsx(
            styles.name,
            styles.firstColumn,
            showItemDescription && styles.itemDescription
          )}
          data-cy="reflectItem"
          onClick={() => {
            showItemDescription && handleNameClick();
          }}
          onKeyDown={e =>
            onKeyDown(e, () => showItemDescription && handleNameClick())
          }
        >
          {isLatestName && <div className={styles.circle} />}
          <span
            dangerouslySetInnerHTML={{
              __html: sanitizer(searchValue ? nameFilter : name)
            }}
          />
        </div>
      )}
      <div
        className={clsx(styles.slider, styles.secondColumn)}
        style={customSliderStyle}
      >
        <AssessSlider
          actualPracticeLevel={practiceLevel}
          levelDescriptions={levelDescriptions?.sort((a, b) =>
            a.level > b.level ? 1 : -1
          )}
          name={name}
          onChange={onPracticeLevelChange}
        />
      </div>
      <div
        className={clsx(styles.thirdColumn, styles.learningPriority)}
        style={customCheckBoxStyle}
      >
        <CheckBox
          label={LEARNING_PRIORITY}
          onChange={onLearningPriorityChange}
          checked={isLearningPriority}
        />
      </div>
      {showName && (
        <div className={clsx(styles.fourthColumn, styles.remove)}>
          {removeInfo && (
            <ArrowTooltip
              title={
                <p className={styles.removeTitle}>
                  {description?.removeArrowTooltip}
                </p>
              }
              interactive={'true'}
              placement="top"
              classes={RemoveArrowTooltip}
            >
              <div
                className={styles.removeIconWrapper}
                onClick={() => setShowRemoveModal(true)}
                onKeyDown={e => onKeyDown(e, () => setShowRemoveModal(true))}
              >
                <img src={deleteBin} alt="delete the competency" />
                Remove
              </div>
            </ArrowTooltip>
          )}
        </div>
      )}
      <Notification
        open={showRemoveModal}
        close={() => {
          setShowRemoveModal(false);
        }}
        title={description?.notification.title}
        desc={
          <p
            className={styles.removeModalContent}
            dangerouslySetInnerHTML={{
              __html: sanitizer(description?.notification.content)
            }}
          />
        }
        confirmation={description?.notification.confirmation}
        onConfirm={onRemoveConfirm}
      />
    </div>
  );
});

ReflectItem.propTypes = {
  reflectItem: PropTypes.object.isRequired,
  defaultPracticeLevel: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  newSelectedStorageName: PropTypes.string,
  itemId: PropTypes.string,
  description: PropTypes.object,
  levelDescriptions: PropTypes.array,
  searchValue: PropTypes.string,
  handleNameClick: PropTypes.func,
  showName: PropTypes.bool,
  showItemDescription: PropTypes.bool,
  customSliderStyle: PropTypes.object,
  removeInfo: PropTypes.object,
  highlightedCompetency: PropTypes.string
};
