import React, { useState } from 'react';
import globalStyles from '../../../../globalStyles';
import eyeOffFill from '../../../../assets/Icons/eye-off-fill.svg';
import eyeFill from '../../../../assets/Icons/eye-fill.svg';
import styles from './index.module.css';
import { styled } from '@mui/material/styles';
import { visibilityPreferenceDes } from '../../language';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import { makeStyles } from '@mui/styles';

const visibleImg = <img alt={'eyeFill'} src={eyeFill} />;
const hiddenImg = <img alt={'eyeOffFill'} src={eyeOffFill} />;

const ToggleButtonGroupWithStyle = styled(ToggleButtonGroup)({
  width: '240px',
  height: '40px',
  backgroundColor: globalStyles.grey21,
  borderRadius: '20px'
});

const useStyles = makeStyles(theme => ({
  modal: {
    '&.MuiToggleButton-root': {
      width: '120px',
      border: 0,
      borderRadius: '20px',
      textTransform: 'none'
    },
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&.Mui-selected': {
      '&&': {
        backgroundColor: props =>
          props.value ? globalStyles.avocadoGreen : globalStyles.orange,
        borderRadius: '20px',
        '&:hover': {
          background: props =>
            props.value ? globalStyles.avocadoGreen : globalStyles.orange
        }
      }
    }
  }
}));

export const VisibilityButtonGroup = ({ isVisible, setIsVisible }) => {
  const [isShared, setIsShared] = useState(isVisible);
  const handleEdit = (event, isVisibleNow) => {
    setIsVisible(isVisibleNow);
    setIsShared(isVisibleNow);
  };
  const classes = useStyles();

  const visibilityButton = isVisibleStyle => (
    <ToggleButton
      value={isVisibleStyle}
      selected={isVisibleStyle ? isShared : !isShared}
      className={classes.modal}
    >
      <div className={styles.buttonContent}>
        {isVisibleStyle ? visibleImg : hiddenImg}
        <div>
          {isVisibleStyle
            ? visibilityPreferenceDes.visibilityButton.visible
            : visibilityPreferenceDes.visibilityButton.hidden}
        </div>
      </div>
    </ToggleButton>
  );

  return (
    <ToggleButtonGroupWithStyle exclusive onChange={handleEdit}>
      {visibilityButton(false)}
      {visibilityButton(true)}
    </ToggleButtonGroupWithStyle>
  );
};
