import { COURSE_REC_PAGE } from '../../constants/modalConstants';
import courseRecPageStyles from './index_style2.module.css';
import styles from './index_style1.module.css';
import GREY_EMPTY_COURSE from '../../assets/Pictures/EmptyRecCourseGrey.svg';
import EMPTY_COURSE from '../../assets/Pictures/EmptyRecommendedCourse.svg';
import React from 'react';

export const EmptyRecommendedCourse = ({ type }) => {
  const actualStyles = type === COURSE_REC_PAGE ? courseRecPageStyles : styles;
  const imgSrc = type === COURSE_REC_PAGE ? GREY_EMPTY_COURSE : EMPTY_COURSE;

  return (
    <div className={actualStyles.emptyCourseRec}>
      <div className={actualStyles.emptyCourseImgBoard}>
        <img
          className={actualStyles.emptyCourseImg}
          src={imgSrc}
          alt={'empty recommended course'}
        />
      </div>
      <div>
        <p className={actualStyles.emptyCoursePrimaryDescription}>
          We don’t have any recommendations yet.
        </p>
        <p className={actualStyles.emptyCourseSecondaryDescription}>
          Come back some other time!
        </p>
      </div>
    </div>
  );
};
