import React from 'react';
import DialogContent from '@mui/material/DialogContent';
import PropTypes from 'prop-types';

import { CloseButton, GoHomepageButton } from './ErrorBoxButtons';
import {
  DialogActionsWithStyle,
  DialogBox,
  DialogContentTextWithStyle,
  DialogTitleWithStyle
} from './ErrorBoxComponents';
import withErrorChecking from './withErrorChecking';
import ModalHeader from '../Common/Modal/ModalHeader';
import styles from './ErrorBox.module.css';
import ErrorPageIcon from '../../components/Common/SvgIcons/ErrorPageIcon';
import { CloseIconButton } from '../../nonFeatureComponents/Buttons/Buttons';

const errorBoxWithCloseButton = props => {
  if (Object.keys(props.error).length === 0) {
    return <></>;
  } else {
    const errorResponse =
      props.error?.response?.data?.message || props.error?.message;
    return (
      <div>
        <DialogBox open={true} keepMounted>
          <ModalHeader>
            <DialogTitleWithStyle>Something went wrong!</DialogTitleWithStyle>
            <CloseIconButton handleClick={props.setError} />
          </ModalHeader>
          <DialogContent>
            <DialogContentTextWithStyle>
              {errorResponse || 'Please try again later'}
            </DialogContentTextWithStyle>
          </DialogContent>
          <DialogActionsWithStyle>
            <CloseButton closeErrorBox={() => props.setError({})} />
          </DialogActionsWithStyle>
        </DialogBox>
      </div>
    );
  }
};

export const ErrorBoxWithCloseButton = withErrorChecking(
  errorBoxWithCloseButton
);

export const ErrorBoxGoHomepage = props => {
  return (
    <div className={styles.errorPage}>
      <div className={styles.errorIcon}>
        <ErrorPageIcon />
      </div>
      <div className={styles.errorTitle}>{props.title}.</div>
      <div className={styles.homePageButton}>
        <GoHomepageButton />
      </div>
    </div>
  );
};

ErrorBoxGoHomepage.propTypes = {
  title: PropTypes.string.isRequired
};
