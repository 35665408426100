import React from 'react';
import { useEffect } from 'react';
import { post } from '../../api/util/ajax';
import ErrorImage from '../../assets/Pictures/error.svg';
import styles from './index.module.css';

const FallbackComponent = ({ error }) => {
  const backToHome = () => {
    window.open('/', '_self');
  };

  useEffect(() => {
    post('/front-end-log', { message: error.stack });
  }, [error]);

  return (
    <div className={styles.fallbackComponent}>
      <div className={styles.hintArea}>
        <div className="bug-message">
          <h1 className={styles.bugH1}>Ooops!</h1>
          <span className={styles.bugSpan}>
            Sorry we couldn’t serve your case due to some errors.
          </span>
        </div>
        <div className={styles.links}>
          <span className={styles.linkTitle}>
            Here are some helpful links instead:
          </span>
          <a className={styles.linkButton} onClick={backToHome}>
            HomePage
          </a>
        </div>
      </div>
      <img src={ErrorImage} className={styles.errorImg} alt="" />
    </div>
  );
};

export default FallbackComponent;
