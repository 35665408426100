import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  getWidthBySideWidth,
  getHeightBySideWidth
} from '../../../../utils/hexagon';
import useRefWidth from '../../../../hooks/useRefWidth';
import styles from './index.module.css';
import CompetencyHexagon, {
  COM_ITEM_GAP,
  ORIGIN_HEX_SIZE_HEIGHT,
  ORIGIN_HEX_SIZE_WIDTH
} from '../CompetencyHexagonV2';

const CompetencyHexagonList = ({
  wrapper,
  curWrapperWidth,
  competencies,
  hexagonSideWidth,
  onCompetencySelected,
  onSingleClickEdit,
  onSingleClickInfo,
  showInPDF
}) => {
  const [lineNumber, setLineNumber] = useState(0);
  const [lineItemNumber, setLineItemNumber] = useState(0);
  const [isFullLine, setIsFullLine] = useState(false);

  const hexagonWidth = hexagonSideWidth
    ? getWidthBySideWidth(hexagonSideWidth)
    : ORIGIN_HEX_SIZE_WIDTH;
  const hexagonHeight = hexagonSideWidth
    ? getHeightBySideWidth(hexagonSideWidth)
    : ORIGIN_HEX_SIZE_HEIGHT;

  let defaultWrapperWidth = hexagonWidth + COM_ITEM_GAP;
  if (curWrapperWidth) {
    defaultWrapperWidth = curWrapperWidth;
  }

  const wrapperWidth = useRefWidth(wrapper, defaultWrapperWidth);

  const intendWidth = hexagonWidth / 2 + 2;
  const groupListHeight =
    hexagonHeight - hexagonWidth / 2 / Math.sqrt(3) + COM_ITEM_GAP;

  useEffect(() => {
    const itemNumber = Math.floor(wrapperWidth / (hexagonWidth + COM_ITEM_GAP));
    setLineItemNumber(itemNumber);
    const isOnlyOneLine = competencies.length <= itemNumber;
    // can each line can be filled with ${itemNumber} items
    const isFullLine =
      Math.floor(
        (wrapperWidth - intendWidth) / (hexagonWidth + +COM_ITEM_GAP)
      ) === itemNumber || isOnlyOneLine;
    if (isFullLine) {
      setLineNumber(Math.ceil(competencies.length / itemNumber));
    } else {
      setIsFullLine(isFullLine);
      setLineNumber(
        Math.ceil((competencies.length / (2 * itemNumber - 1)) * 2)
      );
    }
  }, [competencies.length, hexagonWidth, intendWidth, wrapperWidth]);

  return new Array(lineNumber).fill(' ').map((_, i) => {
    const startPoint = !isFullLine
      ? i * lineItemNumber - Math.floor(i / 2)
      : i * lineItemNumber;

    if (i % 2 === 1) {
      const _competencies = competencies.slice(
        startPoint,
        startPoint + lineItemNumber - 1 * Number(!isFullLine)
      );
      if (_competencies.length === 0) {
        return null;
      }
      return (
        <div
          key={i}
          style={{
            marginLeft: `${intendWidth}px`,
            height: groupListHeight
          }}
          className={styles.listGroupIndent}
        >
          {_competencies.map((com, index) => {
            return (
              <CompetencyHexagon
                sideWidth={hexagonSideWidth}
                selected={com.selected}
                onSingleClickEdit={onSingleClickEdit}
                onSingleClickInfo={onSingleClickInfo}
                onSelect={() => onCompetencySelected(com.competencyId)}
                {...com}
                key={index}
                showInPDF={showInPDF}
              />
            );
          })}
        </div>
      );
    }

    const _competencies = competencies.slice(
      startPoint,
      startPoint + lineItemNumber
    );
    if (_competencies.length === 0) {
      return null;
    }
    return (
      <div
        key={i}
        style={{
          height: groupListHeight
        }}
        className={styles.listGroup}
      >
        {_competencies.map((com, index) => (
          <CompetencyHexagon
            sideWidth={hexagonSideWidth}
            selected={com.selected}
            onSingleClickEdit={onSingleClickEdit}
            onSingleClickInfo={onSingleClickInfo}
            onSelect={() => onCompetencySelected(com.competencyId)}
            {...com}
            key={index}
            showInPDF={showInPDF}
          />
        ))}
      </div>
    );
  });
};

CompetencyHexagonList.propTypes = {
  hexagonSideWidth: PropTypes.number,
  curWrapperWidth: PropTypes.number,
  wrapper: PropTypes.object,
  competencies: PropTypes.array.isRequired,
  onCompetencySelected: PropTypes.func,
  onSingleClickEdit: PropTypes.func,
  onSingleClickInfo: PropTypes.func,
  showInPDF: PropTypes.bool
};

export default CompetencyHexagonList;
