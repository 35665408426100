import React, { useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import { sortBy } from 'lodash';
import styles from './index.module.css';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDown from '../../../../components/Common/SvgIcons/ArrowDown';
import { ReactComponent as ArchetypeIcon } from '../../../../assets/Icons/archetype-icon.svg';
import PropTypes from 'prop-types';
import { RecommendedCourse } from '../../../../feature/RecommendedCourse';
import { DevWhiteListForRecommendedCourses } from '../../../../constants/devWhiteListForRecommendedCourses';
import { USER_GRADE } from '../../../../constants/user';
import { fetchCourseRecommendations } from '../../../../api/domain/archetype';
import { useUserContext } from '../../../../contexts/UserContext';
import { PathwaysTrackEvents } from '../../../../support/pathwaysTrack';
import { COURSE_REC_STANDALONE_PAGE_EVENT } from '../../../../constants/matomo';
import { courseRecommendationsPageDes } from '../../language';
import { COURSE_REC_PAGE } from '../../../../constants/modalConstants';
import { MAX_REC_COURSES } from '../../../../constants/archetype';
import { EmptyRecommendedCourse } from '../../../../nonFeatureComponents/EmptyRecommendedCourse';
import { styled } from '@mui/material/styles';
import { Skeleton } from '@mui/material';

export const ArchetypeList = ({ archetypes, tab }) => {
  const AccordionStyles = styled(Accordion)({
    borderRadius: '7px',
    textAlign: 'center',
    backgroundColor: '#FFFFFF',
    '&::before': {
      height: 0
    },
    '&.Mui-expanded': {
      margin: 0,
      borderRadius: '7px 7px 0 0'
    },
    '&.MuiPaper-elevation1': {
      boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)'
    }
  });
  const AccordionSummaryStyles = styled(AccordionSummary)({
    backgroundColor: '#0D8F82',
    color: '#FFFFFF',
    height: '40px',
    padding: '0 10px',
    borderRadius: '7px',
    minHeight: '40px',
    '&.Mui-expanded': {
      margin: '0',
      padding: '0 10px',
      minHeight: '40px',
      borderRadius: '7px 7px 0 0'
    },
    '.MuiAccordionSummary-content': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '14px',
      lineHeight: '40px',
      fontWeight: 700,
      height: '40px',
      marginLeft: '36px',
      '&.Mui-expanded': {
        marginLeft: '36px'
      }
    }
  });
  const AccordionDetailsStyles = styled(AccordionDetails)({
    padding: '0',
    justifyContent: 'center'
  });
  const SkeletonWithStyles = styled(Skeleton)({
    '&.MuiSkeleton-rectangular': {
      width: '190px',
      height: '100px',
      borderRadius: '7px 0 0 7px'
    },
    '&.MuiSkeleton-text': {
      width: '300px',
      height: '14px',
      borderRadius: '4px',
      transform: 'none'
    }
  });

  const {
    state: { user }
  } = useUserContext();
  const courseRecommendationFeatureToggle =
    DevWhiteListForRecommendedCourses.includes(user.email) ||
    user.grade === USER_GRADE.GRADUATE;

  const sortedArchetypes = sortBy(archetypes, ['archetypeName']);
  const [recommendedCourses, setRecommendedCourses] = useState(new Map());

  const trackMatomoEvent = tab => {
    tab === courseRecommendationsPageDes.meNowTab &&
      PathwaysTrackEvents([
        {
          category: COURSE_REC_STANDALONE_PAGE_EVENT.category,
          action:
            COURSE_REC_STANDALONE_PAGE_EVENT.action
              .CLICK_INTO_COURSE_FROM_TOP_COURSE_VIA_STANDALONE_ME_NOW
        }
      ]);
    tab === courseRecommendationsPageDes.meNextTab &&
      PathwaysTrackEvents([
        {
          category: COURSE_REC_STANDALONE_PAGE_EVENT.category,
          action:
            COURSE_REC_STANDALONE_PAGE_EVENT.action
              .CLICK_INTO_COURSE_FROM_TOP_COURSE_VIA_STANDALONE_ME_NEXT
        }
      ]);
  };

  const getRecommendedCourses = archetype => {
    async function fetchData() {
      fetchCourseRecommendations(archetype.archetypeId).then(courses => {
        if (courses.length > MAX_REC_COURSES) {
          courses = courses.slice(0, MAX_REC_COURSES);
        }
        setRecommendedCourses(
          new Map(recommendedCourses.set(archetype.archetypeId, courses))
        );
      });
    }
    if (
      courseRecommendationFeatureToggle &&
      !recommendedCourses.get(archetype.archetypeId)
    ) {
      fetchData().then();
    }
  };

  const displayCourses = archetype => {
    const courses = recommendedCourses.get(archetype.archetypeId);
    if (courses?.length > 0) {
      return courses.map((course, index) => {
        return (
          <li className={styles.topCoursesItem} key={index}>
            <RecommendedCourse
              course={course}
              isRelevant={course.isRelevant}
              archetypeId={archetype.archetypeId}
              type={COURSE_REC_PAGE}
              courseTrackEvent={() => trackMatomoEvent(tab)}
            />
          </li>
        );
      });
    }
    return <EmptyRecommendedCourse type={COURSE_REC_PAGE} />;
  };

  const recCoursesSkeletons = [...Array(6)].map((_, i) => {
    return (
      <div key={i} className={styles.skeletons}>
        <SkeletonWithStyles variant="rectangular" />
        <div className={styles.skeletonTexts}>
          <SkeletonWithStyles variant="text" />
          <SkeletonWithStyles variant="text" />
          <SkeletonWithStyles variant="text" width={150} />
        </div>
      </div>
    );
  });

  useEffect(() => {
    !recommendedCourses.get(sortedArchetypes[0].archetypeId) &&
      getRecommendedCourses(sortedArchetypes[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedArchetypes]);

  return (
    <div className={styles.archetypeStyle}>
      {sortedArchetypes.map((archetype, index) => {
        return (
          <AccordionStyles
            key={index}
            defaultExpanded={index === 0}
            onClick={() => getRecommendedCourses(archetype)}
          >
            <AccordionSummaryStyles
              id={'panel1a-header-' + index}
              expandIcon={
                <div className={styles.circleArrow}>
                  <ArrowDown />
                </div>
              }
              aria-controls={'panel1a-content-' + index}
            >
              {<ArchetypeIcon className={styles.archetypeIcon} />}
              {archetype.archetypeName}
            </AccordionSummaryStyles>
            <AccordionDetailsStyles>
              <ul className={styles.topCoursesWrapper}>
                {recommendedCourses.get(archetype.archetypeId)
                  ? displayCourses(archetype)
                  : recCoursesSkeletons}
              </ul>
            </AccordionDetailsStyles>
          </AccordionStyles>
        );
      })}
    </div>
  );
};

ArchetypeList.propTypes = {
  archetypes: PropTypes.array.isRequired
};
