import { reject, reduce, concat } from 'lodash';

export const filterAndFormatReflectedArchetypeData = res => {
  return reduce(
    res,
    (reflectedArchetypes, item) => {
      const archetypes = reduce(
        item.roles,
        (as, role) => concat(as, role.archetypes),
        []
      );
      return concat(
        reflectedArchetypes,
        reject(archetypes, ['reflectedInformation', null])
      );
    },
    []
  );
};
