import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as DOMPurify from 'dompurify';
import FixedBar from '../../nonFeatureComponents/FixedBar';
import * as SkillsStorage from '../../components/Utils/skillsStorage';
import styles from './index.module.css';
import loaderStyles from '../../nonFeatureComponents/Loader/index.module.css';
import { DASHBOARD, REFLECT_SKILLS } from '../../constants/path';
import { cloneDeep, isEmpty, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  description,
  goToTitle,
  localStorageKey,
  orderedCategory,
  SearchBarConstants,
  title
} from './language';
import { fetchPeopleSkills } from '../../api/domain/skill';
import { allSkillsGroupByCategory } from '../../domain/skill';
import NavigationHeader from '../../nonFeatureComponents/NavigationHeader';
import StepperLine from '../../nonFeatureComponents/StepperLine';
import tipIcon from '../../assets/Icons/tipIcon.png';
import { SearchCategoryEmpty } from '../../nonFeatureComponents/SearchCategoryEmpty';
import { specialCharactersPattern } from '../../feature/language';
import Loader from '../../nonFeatureComponents/Loader';
import { skillSteps } from '../../constants/skill';
import SearchBar from '../../feature/SearchBar';
import {
  CATEGORY_EMPTY,
  MISSING_CATEGORY,
  SKILL_LIST_EMPTY
} from '../../constants/modalConstants';

const sanitizer = DOMPurify.sanitize;

const SkillsSelectPage = props => {
  const history = useHistory();
  const listWrapper = useRef(null);
  const [isLoading, setLoading] = useState(true);
  const [allSkills, setAllSkills] = useState([]);
  const [filterSkills, setFilterSkills] = useState([]);
  const wrapper = useRef(null);
  const [searchValue, setSearchValue] = useState('');
  const [searchEmptyIndexs, setSearchEmptyIndexs] = useState([]);

  const hasSelected = allSkills.some(category =>
    category.some(skill => skill.selected === true)
  );
  const isFromReflectPage = props.location.state || false;

  useEffect(() => {
    const storedSkills = SkillsStorage.get();
    fetchPeopleSkills()
      .then(res => {
        const skillsByCategory = allSkillsGroupByCategory(res);
        const allSkills =
          orderedCategory.map(category =>
            sortBy(skillsByCategory[category], skill =>
              skill.name.toLowerCase()
            ).map(skill => {
              const isReflectSkill = !isEmpty(skill.reflectedInformation);
              if (!isFromReflectPage && isReflectSkill) {
                SkillsStorage.set(skill.skillId);
                return {
                  ...skill,
                  selected: true
                };
              }
              if (storedSkills.includes(skill.skillId)) {
                return {
                  ...skill,
                  selected: true
                };
              }
              return {
                ...skill,
                selected: false
              };
            })
          ) || [];
        setAllSkills(allSkills);
        setFilterSkills(allSkills);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [isFromReflectPage]);

  const onSkillSelected = chosenSkill => {
    const newAllSkills = cloneDeep(allSkills);
    const newSelectedSkills = JSON.parse(
      localStorage.getItem(localStorageKey) || '[]'
    );
    const selected = !chosenSkill.selected;
    const skill = newAllSkills
      .find(group => group[0].category === chosenSkill.category)
      .find(skill => skill.skillId === chosenSkill.skillId);
    skill.selected = selected;
    if (selected) {
      SkillsStorage.set(chosenSkill.skillId);
      newSelectedSkills.push(chosenSkill.name);
    } else {
      SkillsStorage.removeByID(chosenSkill.skillId);
      const existedIndex = newSelectedSkills.indexOf(chosenSkill.name);
      if (existedIndex !== -1) {
        newSelectedSkills.splice(existedIndex, 1);
      }
    }
    localStorage.setItem(localStorageKey, JSON.stringify(newSelectedSkills));
    setAllSkills(newAllSkills);
  };

  const searchChange = searchResult => {
    let { curSearchVal } = searchResult;
    if (isEmpty(curSearchVal)) {
      setFilterSkills(allSkills);
    }
    setSearchValue(curSearchVal);
  };

  useEffect(() => {
    let searchEmptyIndexArray = [];
    let newSearchValue = searchValue.trim();
    let newFilterSkills =
      allSkills &&
      allSkills.map((categoryList, categoryListIndex) => {
        let searchCategoryList = [];
        categoryList &&
          categoryList.forEach(categoryListItem => {
            const { name = '' } = categoryListItem;
            if (isEmpty(name)) return null;
            const formatName = name.toLowerCase();
            const formatSearchVal = newSearchValue.trim().toLowerCase();
            if (formatName.indexOf(formatSearchVal) !== -1) {
              if (!isEmpty(formatSearchVal)) {
                let isSpecialCharacters = specialCharactersPattern.test(
                  newSearchValue
                );
                let regValue = newSearchValue;
                if (isSpecialCharacters) {
                  regValue = newSearchValue.replace(
                    specialCharactersPattern,
                    function(txt) {
                      return '\\' + txt;
                    }
                  );
                }
                let reg = new RegExp(regValue, 'gi');
                categoryListItem.nameFilter = name.replace(reg, function(txt) {
                  return '<strong>' + txt + '</strong>';
                });
              }

              searchCategoryList.push(categoryListItem);
            }
          });
        if (isEmpty(searchCategoryList)) {
          searchEmptyIndexArray.push(categoryListIndex);
        }
        return searchCategoryList;
      });
    setSearchEmptyIndexs(searchEmptyIndexArray);
    setFilterSkills(newFilterSkills);
  }, [allSkills, searchValue]);

  const renderSkillListDom = () => {
    if (searchEmptyIndexs.length === orderedCategory.length) {
      return SearchCategoryEmpty(SearchBarConstants, CATEGORY_EMPTY);
    }
    return filterSkills.map((skills, index) => {
      if (isEmpty(skills)) {
        return (
          <section
            key={orderedCategory[index]}
            className={styles.skillGroup}
            data-cy="groupName"
          >
            <div
              className={styles.skillGroupTitle}
            >{`${orderedCategory[index]} Skills`}</div>
            <div ref={listWrapper} className={styles.skillList}>
              {SearchCategoryEmpty(SearchBarConstants, SKILL_LIST_EMPTY)}
            </div>
          </section>
        );
      }
      const { category = '' } = skills[0];
      return (
        <section
          key={category}
          className={styles.skillGroup}
          data-cy="groupName"
        >
          <div className={styles.skillGroupTitle}>{`${category} Skills`}</div>
          <div ref={listWrapper} className={styles.skillList}>
            {skills.map(skill => (
              <button
                key={skill.skillId}
                onClick={() => onSkillSelected(skill)}
                className={clsx(styles.skillPod, {
                  [styles.selected]: skill.selected
                })}
                dangerouslySetInnerHTML={{
                  __html: sanitizer(
                    !isEmpty(searchValue.trim()) &&
                      !isEmpty(skills[0].nameFilter)
                      ? skill.nameFilter
                      : skill.name
                  )
                }}
              />
            ))}
          </div>
        </section>
      );
    });
  };

  return isLoading ? (
    <div className={loaderStyles.loaderWrapper}>
      <Loader />
    </div>
  ) : (
    <>
      <FixedBar>
        <NavigationHeader
          onBackTo={() => history.replace(DASHBOARD)}
          disabled={!hasSelected}
          onGoTo={() => history.push(REFLECT_SKILLS)}
          backToTitle="Cancel"
          goToTitle={goToTitle}
          showGoToIcon
        >
          <StepperLine
            activeStep={0}
            steps={skillSteps}
            validated={hasSelected}
            isShowStepTooltip={false}
          />
        </NavigationHeader>
      </FixedBar>
      <div className={styles.page}>
        <div ref={wrapper} className={styles.skillsExpl}>
          <p className={styles.skillsExplTitle}>{title}</p>
          <p className={styles.description}>{description}</p>
          <div className={styles.search}>
            <SearchBar
              searchConstants={SearchBarConstants}
              onSearchBarChange={searchChange}
              doubleLayerArrayData={allSkills}
              singleLayerArrayData={filterSkills}
            />
          </div>
          {renderSkillListDom()}
          {isEmpty(searchValue) &&
            SearchCategoryEmpty(SearchBarConstants, MISSING_CATEGORY, tipIcon)}
        </div>
      </div>
    </>
  );
};

SkillsSelectPage.propTypes = {
  location: PropTypes.object.isRequired
};

export default SkillsSelectPage;
