import React from 'react';
import { useUserContext } from '../../contexts/UserContext';
import styles from './HeaderBar.module.css';
import ArrowDown from '../Common/SvgIcons/ArrowDown';
import VectorIcon from '../Common/SvgIcons/Vector';
import JigsawIcon from '../Common/SvgIcons/JigsawIcon';
import ArrowTooltip from '../Tooltip/ArrowTooltip';
import globalStyles from '../../globalStyles';
import { updateUser } from '../../actions/profile';
import { PathwaysTrackEvents } from '../../support/pathwaysTrack';
import {
  JUMP_TO_JIGSAW_FROM_PATHWAY,
  TAKE_THE_TOUR
} from '../../constants/matomo';
import { DASHBOARD } from '../../constants/path';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { JigsawLink } from '../../constants/links';
import { getEnvVars } from '../../config/config';

const ShareArrowTooltip = {
  '.MuiTooltip-arrow': {
    marginLeft: '20px'
  },
  '& .MuiTooltip-tooltip': {
    color: globalStyles.white,
    left: '60px',
    top: '-16px',
    width: '200px',
    padding: '30px 36px 15px 40px',
    backgroundColor: globalStyles.grey83
  }
};

const hideToolTips = {
  '& .MuiTooltip-tooltip': {
    display: 'none'
  }
};

const TooltipContent = () => {
  const history = useHistory();
  const {
    state: {
      user,
      user: { grade = '', role = '', employeeId = '' }
    },
    dispatch
  } = useUserContext();
  const { env } = getEnvVars();

  const userEmployeeId = employeeId;

  const showGuideMap = () => {
    const pathname = window.location.pathname;
    if (pathname !== DASHBOARD) {
      history.push(DASHBOARD);
    }

    user.guideStepNumber = 1;
    dispatch(updateUser(user));
    PathwaysTrackEvents([
      {
        category: TAKE_THE_TOUR.category,
        action: TAKE_THE_TOUR.action.TAKE_PATHWAYS_TOUR_ON_USER_BOX
      }
    ]);
  };

  const goToJigsaw = () => {
    PathwaysTrackEvents([
      {
        category: JUMP_TO_JIGSAW_FROM_PATHWAY.category,
        action: JUMP_TO_JIGSAW_FROM_PATHWAY.action.GO_TO_JIGSAW_ON_USER_BOX
      }
    ]);
  };
  return (
    <>
      {role && <div className={styles.userNameRole}>{role}</div>}
      {grade && <div className={styles.userNameGrade}>{grade}</div>}
      <div className={styles.tourButton} onClick={showGuideMap}>
        <VectorIcon
          style={{ width: '17px', height: '17px', margin: '0 5px 0 8px' }}
          viewBox="0 0 17 17"
        />
        <span>Take Pathways tour</span>
      </div>
      <a
        className={styles.goJigsawButton}
        href={JigsawLink(userEmployeeId, env)}
        target="_blank"
        rel="noopener noreferrer"
        onClick={goToJigsaw}
      >
        <JigsawIcon width={23} height={18} />
        <span>Go to Jigsaw</span>
      </a>
    </>
  );
};

TooltipContent.propTypes = {};

const UserName = props => {
  const {
    state: {
      user: { firstName = '', lastName = '' }
    }
  } = useUserContext();

  const classes = props.isGuideShowState ? hideToolTips : ShareArrowTooltip;

  return (
    <>
      <ArrowTooltip
        arrow
        offset={'90px'}
        classes={classes}
        placement="bottom"
        title={<TooltipContent {...props} />}
        interactive={'true'}
      >
        <div className={styles.growthPath}>
          <span>{`Welcome, ${firstName} ${lastName}`}</span>
          <div className={styles.circleArrow}>
            <ArrowDown className={styles.arrowDown} />
          </div>
        </div>
      </ArrowTooltip>
    </>
  );
};

UserName.propTypes = {
  isGuideShowState: PropTypes.bool
};

export default UserName;
export { TooltipContent };
