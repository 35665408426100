import { get, post, put, deleteREST } from '../util/ajax';
export const fetchGoals = () => {
  return get('goals');
};

export const createGoal = body => {
  return post('goals', body);
};

export const updateGoal = (goalId, body) => {
  return put(`goals/${goalId}`, body);
};

export const accomplishGoal = (goalId, body) => {
  return put(`goals/${goalId}/accomplish`, body);
};

export const unaccomplishGoal = goalId => {
  return put(`goals/${goalId}/unaccomplish`);
};

export const removeGoal = goalId => {
  return deleteREST(`goals/${goalId}`);
};
