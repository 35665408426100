import React, { useEffect, useState } from 'react';
import styles from './index.module.css';
import {
  courseRecommendationsPageDes,
  ME_NEXT_ARCHETYPE_TAB,
  ME_NOW_ARCHETYPE_TAB
} from './language';
import { useHistory } from 'react-router-dom';
import { useUserContext } from '../../contexts/UserContext';
import {
  BOTH_ME_NOW_AND_ME_NEXT,
  ME_NEXT,
  ME_NOW
} from '../../constants/dashborad';
import { ArchetypeList } from './components/ArchetypeList';
import { fetchReflectedArchetypes } from '../../actions/profile';
import { SELECT_ARCHETYPES } from '../../constants/path';
import { Button } from '@mui/material';
import globalStyles from '../../globalStyles';
import { PathwaysTrackEvents } from '../../support/pathwaysTrack';
import { COURSE_REC_STANDALONE_PAGE_EVENT } from '../../constants/matomo';
import Loader from '../../nonFeatureComponents/Loader';
import * as DOMPurify from 'dompurify';
import TopCourseTabs from './components/TopCourseTabs';
import EntryPoint from '@nonFeatureComponents/EntryPoint';
import { styled } from '@mui/material/styles';

const OtherArchetypesButton = styled(Button)({
  width: '400px',
  height: '40px',
  borderRadius: '7px',
  background: globalStyles.black3,
  color: globalStyles.white,
  fontSize: '16px',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: globalStyles.black3,
    color: globalStyles.white
  }
});
export const CourseRecommendations = ({ location }) => {
  const sanitizer = DOMPurify.sanitize;

  const {
    state: { user },
    dispatch
  } = useUserContext();
  const history = useHistory();

  const [tabIndex, setTabIndex] = useState(0);
  const [meNowSelectedArchetype, setMeNowSelectedArchetype] = useState([]);
  const [meNextSelectedArchetype, setMeNextSelectedArchetype] = useState([]);
  const [loading, setLoading] = useState(false);

  const changeTabIndex = current => {
    setTabIndex(current);
  };
  const navToArchetype = e => {
    e.preventDefault();
    PathwaysTrackEvents([
      {
        category: COURSE_REC_STANDALONE_PAGE_EVENT.category,
        action:
          COURSE_REC_STANDALONE_PAGE_EVENT.action
            .CLICK_COURSE_FOR_OTHER_ARCHETYPES_BUTTON
      }
    ]);
    history.push(SELECT_ARCHETYPES);
  };

  const otherArchetypes = (
    <div className={styles.otherArchetypes}>
      <OtherArchetypesButton
        disableRipple
        variant="contained"
        onClick={navToArchetype}
      >
        {courseRecommendationsPageDes.otherArchetypes.title}
      </OtherArchetypesButton>
      <div
        className={styles.otherArchetypesGuide}
        dangerouslySetInnerHTML={{
          __html: sanitizer(courseRecommendationsPageDes.otherArchetypes.guide)
        }}
      />
    </div>
  );

  const emptyEntryPoint = stage => {
    return (
      <div data-cy="archetype">
        <EntryPoint
          bg={2}
          page={'courseRecommendations'}
          title={
            <div>
              <span className={styles.emptyMsgTitle}>
                {courseRecommendationsPageDes.EntryPoint[stage + 'EmptyMsg']}
              </span>
            </div>
          }
          subTitle={courseRecommendationsPageDes.EntryPoint.subTitle}
          onClick={navToArchetype}
        />
      </div>
    );
  };

  const getCourseRecommendationsContent = (archetype, stage) => {
    return (
      <>
        {archetype.length > 0 ? (
          <>
            <ArchetypeList
              archetypes={archetype}
              tab={courseRecommendationsPageDes[stage + 'Tab']}
            />
            {otherArchetypes}
          </>
        ) : (
          emptyEntryPoint(stage)
        )}
      </>
    );
  };

  useEffect(() => {
    if (user.reflectedArchetypes && user.reflectedArchetypes.length > 0) {
      const meNowArchetypes = user.reflectedArchetypes.filter(
        archetype =>
          archetype.reflectedInformation?.stage === ME_NOW ||
          archetype.reflectedInformation?.stage === BOTH_ME_NOW_AND_ME_NEXT
      );
      setMeNowSelectedArchetype(meNowArchetypes);
      const meNextArchetypes = user.reflectedArchetypes.filter(
        archetype =>
          archetype.reflectedInformation?.stage === ME_NEXT ||
          archetype.reflectedInformation?.stage === BOTH_ME_NOW_AND_ME_NEXT
      );
      setMeNextSelectedArchetype(meNextArchetypes);
    }
  }, [user.reflectedArchetypes]);

  useEffect(() => {
    setLoading(true);
    fetchReflectedArchetypes(dispatch)
      .then()
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch]);

  useEffect(() => {
    location?.state?.tab === ME_NOW_ARCHETYPE_TAB
      ? setTabIndex(ME_NOW_ARCHETYPE_TAB)
      : setTabIndex(ME_NEXT_ARCHETYPE_TAB) || setTabIndex(0);
  }, [location]);

  return (
    <div className={styles.page}>
      <div className={styles.title}>
        <b>Top Courses</b>
        <div className={styles.dividingLine}></div>
        <p className={styles.description}>
          {courseRecommendationsPageDes.description}
        </p>
        <TopCourseTabs
          currentIndex={tabIndex}
          onChange={current => changeTabIndex(current)}
          tabs={courseRecommendationsPageDes.topCourseTabs}
        />
      </div>
      {loading ? (
        <Loader />
      ) : (
        <section>
          {tabIndex === ME_NEXT_ARCHETYPE_TAB
            ? getCourseRecommendationsContent(meNextSelectedArchetype, 'meNext')
            : getCourseRecommendationsContent(meNowSelectedArchetype, 'meNow')}
        </section>
      )}
    </div>
  );
};
