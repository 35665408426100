import searchCompetenciesEmpty from '../../assets/Icons/searchCompetenciesEmpty.png';

export const reflectPageDes = {
  reflectContent: {
    Competencies: {
      tooltipContent:
        'No worries if you haven’t finished your reflection. You can update your Competencies reflection anytime.',
      steps: ['Select Competencies', 'Reflect on them'],
      reflectPage: {
        warningTitle:
          'Some of your reflected Competencies require your attention',
        warningDescription: `All Competencies <b>without a Practice Level</b> and <b>marked as “Strength”</b> were <b>removed
      from your Growth Canvas</b> to improve the quality of data and better-fitting learning and staffing opportunities.<br/>`,
        reflectContentTitle: 'Reflect on your competency experience',
        reflectContentSubTitle:
          'Think about the level of practice on your competencies today and decide whether they are a strength, learning priority or both.'
      },
      emptyMessage: 'no competency has been assessed yet',
      defaultFilterValue: 'All selected competencies',
      categoryKey: 'category',
      isShowSearchBar: true
    },
    Skills: {
      tooltipContent:
        'No worries if you haven’t finished your reflection. You can update your Skills reflection anytime.',
      steps: ['Select skills', 'Reflect on skills'],
      reflectPage: {
        reflectContentTitle: 'Reflect on your skill experience',
        reflectContentSubTitle:
          'How would you rate your skill level today? This information will help you to plan your growth. In addition, our staffing teams will explore opportunities according to your developed skills and learning priorities. '
      },
      emptyMessage: 'no skill has been assessed yet',
      defaultFilterValue: 'All selected skills',
      categoryKey: 'category',
      isShowSearchBar: false
    }
  },
  searchBarConstants: {
    name: 'competency',
    placeholder: 'Search by competency name',
    ClassName: 'CompetenciesSearchBar',
    searchEmptyIcon: searchCompetenciesEmpty
  },
  reflectPage: {
    confirmModal: {
      content: 'Any changes you made will be lost.',
      cancel: 'Are you sure you want to cancel?',
      confirmButtonText: 'Yes, cancel changes',
      cancelButtonText: 'No, back to self-reflection',
      title: 'Cancelling changes'
    },
    confirmModalAfterDone: {
      content1:
        'Some competencies stand without practice level and learning priority,',
      content2: 'which will be removed once you finish reflection.',
      confirmButtonText: "Yes, I'm done reflection",
      cancelButtonText: 'No, go back and reflect on them',
      title: 'Are you sure to finish reflection?'
    }
  }
};
