import React, { useEffect, useRef, useState } from 'react';
import { isEqual } from 'lodash';

import Slide from '@mui/material/Slide';
import Modal from '@mui/material/Modal';
import PropTypes from 'prop-types';
import styles from './index.module.css';
import CompetencyTooltip from '../../components/Tooltip/CompetencyTooltip';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import { styled } from '@mui/material/styles';
import { proficiencyLevelsDescription } from '../../constants/proficiencyLevels';
import {
  removeCompetencyById,
  updateCompetency
} from '../../api/domain/competency';
import { updateSkill } from '../../api/domain/skill';
import { useUserContext } from '../../contexts/UserContext';
import {
  fetchReflectedCompetencies,
  fetchReflectedSkills,
  removeSkills
} from '../../actions/profile';
import {
  CloseIconButton,
  ModalCancelButton,
  ModalSaveButton
} from '../../nonFeatureComponents/Buttons/Buttons';
import InformationIcon from '../../components/Common/SvgIcons/Information';
import PencilIcon from '../../components/Common/SvgIcons/PencilIcon';
import * as CompetenciesStorage from '../../components/Utils/competenciesStorage';
import clsx from 'clsx';
import { removeByID } from '../../components/Utils/skillsStorage';
import logger from '../../config/logsConfig';
import { skillLevelBehaviours } from '../../pages/ReflectPage/components/ReflectList/ReflectListConstants';
import { SKILL_DEFAULT_PRACTICE_LEVEL } from '../../constants/skill';
import { COMPETENCY_DEFAULT_PRACTICE_LEVEL } from '../../constants/competency';
import { ReflectItem } from '../ReflectItem';
import removeIcon from '../../assets/Icons/delete-bin-2-line.svg';
import MLinearProgress from '../LinearProgress';

export const ModalCancelButtonStyle = styled(ModalCancelButton)({
  width: '136px',
  height: '56px'
});

export const ModalSaveButtonStyle = styled(ModalSaveButton)({
  width: '136px',
  height: '56px'
});
const ReflectSingleItem = ({
  open,
  close,
  ability,
  modalTitle,
  reflectType
}) => {
  const customSliderStyle = { padding: '4px 0px 0px 26px', flex: '1 1 51%' };
  const customCheckBoxStyle = {
    flex: '1 1 18%',
    justifyContent: 'flex-end',
    paddingRight: '0'
  };

  const { dispatch } = useUserContext();
  const itemRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const [disableSaveButton, setDisableSaveButton] = useState(false);

  const isCompetency = reflectType === 'Competencies';

  useEffect(() => {
    const { reflectedInformation } = data;

    const shouldDisableSaveButton =
      reflectedInformation &&
      reflectedInformation.practiceLevel === 0 &&
      reflectedInformation.isLearningPriority === false;

    setDisableSaveButton(shouldDisableSaveButton);
  }, [data]);

  const handleSubmit = async () => {
    const id = data.reflectedInformation.id;
    if (!id) {
      return;
    }

    const originData = {
      practiceLevel: ability.reflectedInformation.practiceLevel,
      isLearningPriority: ability.reflectedInformation.isLearningPriority
    };
    const changedData = {
      practiceLevel: data.reflectedInformation.practiceLevel,
      isLearningPriority: data.reflectedInformation.isLearningPriority
    };

    if (isEqual(originData, changedData)) {
      handleClose();
      return;
    }

    const isRemove =
      !data.reflectedInformation.isLearningPriority &&
      data.reflectedInformation.practiceLevel === 0;
    const updateOrRemoveFn = !isCompetency
      ? async () => {
          await updateSkill(id, changedData);
        }
      : isRemove
      ? async () => {
          await removeCompetencyById(id);
          CompetenciesStorage.removeByID(id);
        }
      : () => updateCompetency(id, changedData);
    const fetchAbility = isCompetency
      ? async () => {
          await fetchReflectedCompetencies(dispatch);
        }
      : async () => {
          await fetchReflectedSkills(dispatch);
        };

    setLoading(true);
    try {
      await updateOrRemoveFn().then(async () => {
        await fetchAbility();
      });
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  const handleClose = () => {
    close();
    setData({});
    setIsLoading(false);
  };

  const handleRemove = async () => {
    const id = data.reflectedInformation.id;
    try {
      setIsLoading(true);

      if (isCompetency) {
        await removeCompetencyById(id);
        await fetchReflectedCompetencies(dispatch);
        CompetenciesStorage.removeByID(id);
      } else {
        await removeSkills(dispatch)(id);
        await fetchReflectedSkills(dispatch);
        removeByID(id);
      }
    } catch (e) {
      logger.error(e);
    } finally {
      handleClose();
    }
  };

  useEffect(() => {
    if (open && ability) {
      setData(ability);
    }
  }, [open, ability]);

  return (
    <Modal
      role="dialog"
      aria-modal={true}
      disablebackdropclose={loading.toString()}
      disableEscapeKeyDown={loading}
      open={open}
      onClose={handleClose}
      Backdrop={{
        timeout: 500
      }}
    >
      <Slide aria-labelledby="init" direction="up" in={open}>
        <div className={styles.model}>
          {isLoading && <div className={styles.loadingMask} />}
          <ModalHeader>
            <div className={styles.modalHeader} data-cy="title">
              <span>
                <PencilIcon className={styles.editIcon} />
                {modalTitle}
              </span>
            </div>
            <CloseIconButton handleClick={handleClose} />
          </ModalHeader>
          <div className={styles.modalBody}>
            <MLinearProgress loading={loading} />
            {(ability.competencyId || ability.skillId) && (
              <div className={styles.titleWrapper}>
                <h2 id="init" className={styles.title}>
                  {ability.name}
                </h2>
                {isCompetency && (
                  <CompetencyTooltip
                    id={ability.competencyId}
                    identifier={ability.identifier}
                    description={ability.description}
                    coreCapabilities={ability.coreCapabilities}
                  >
                    <span
                      className={clsx(
                        styles.informationIconWrapper,
                        styles.title
                      )}
                      tabIndex={0}
                    >
                      <InformationIcon
                        titleAccess={`${ability.name} details`}
                        className={styles.informationIcon}
                      />
                    </span>
                  </CompetencyTooltip>
                )}
              </div>
            )}
            <div className={styles.proficiencyLevel}>
              {proficiencyLevelsDescription.map(proficiencyLevel => {
                return (
                  <div
                    key={proficiencyLevel}
                    className={styles.proficiencyLevelItem}
                  >
                    {proficiencyLevel}
                  </div>
                );
              })}
            </div>
            <div className={styles.lineWrapper}>
              <ReflectItem
                ref={itemRef}
                reflectItem={data}
                onChange={data => setData(data)}
                customSliderStyle={customSliderStyle}
                customCheckBoxStyle={customCheckBoxStyle}
                defaultPracticeLevel={
                  isCompetency
                    ? COMPETENCY_DEFAULT_PRACTICE_LEVEL
                    : SKILL_DEFAULT_PRACTICE_LEVEL
                }
                levelDescriptions={
                  isCompetency ? data.levelDescriptions : skillLevelBehaviours
                }
              />
            </div>
            <div className={styles.actionBar}>
              <button
                onClick={handleRemove}
                disabled={loading}
                className={clsx(styles.removeButton)}
                tabIndex={0}
              >
                <img
                  src={removeIcon}
                  alt={isCompetency ? 'remove competency' : 'remove skill'}
                  tabIndex={-1}
                  className={styles.removeIcon}
                />
                <span className={styles.removeText} tabIndex={-1}>
                  {isCompetency ? 'Remove Competency' : 'Remove Skill'}
                </span>
              </button>
              <ModalCancelButtonStyle
                variant="outlined"
                disabled={loading}
                onClick={handleClose}
              >
                cancel
              </ModalCancelButtonStyle>
              <ModalSaveButtonStyle
                variant="contained"
                className={styles.marginLeft}
                disabled={loading || disableSaveButton}
                onClick={handleSubmit}
              >
                Save
              </ModalSaveButtonStyle>
            </div>
          </div>
        </div>
      </Slide>
    </Modal>
  );
};
ReflectSingleItem.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  ability: PropTypes.object.isRequired,
  modalTitle: PropTypes.string.isRequired,
  reflectType: PropTypes.string.isRequired
};
export default ReflectSingleItem;
