import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from './HexagonView.module.css';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { orderBy, cloneDeep, throttle, find } from 'lodash';

const HexagonItem = ({ name, competencyLevel, className }) => {
  return (
    <div
      data-cy="hexagon-item"
      className={clsx(styles.hexagonItem, className)}
      tabIndex={0}
    >
      <div className={styles.hexagonItemBody}>
        <div className={styles.competencyName}>{name}</div>
        <div className={styles.proficiencyLevel}>
          <span>{competencyLevel}</span>
        </div>
      </div>
    </div>
  );
};
HexagonItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  competencyLevel: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  className: PropTypes.string
};
const rowCountMap = {
  mini: 1,
  extraSmall: 2,
  small: 3,
  extraMedium: 4,
  medium: 5,
  large: 6,
  extraLarge: 7
};
const calcClassName = width => {
  let className = 'mini';
  if (width >= 380) {
    className = 'extraSmall';
  }
  if (width >= 540) {
    className = 'small';
  }
  if (width >= 684) {
    className = 'extraMedium';
  }
  if (width >= 836) {
    className = 'medium';
  }
  if (width >= 1000) {
    className = 'large';
  }
  if (width >= 1280) {
    className = 'extraLarge';
  }
  return className;
};
const findSuggestedLevelName = (suggestedLevel, levelDescriptions) => {
  const level = find(levelDescriptions, l => {
    return suggestedLevel === parseInt(l.level);
  });
  return level.name;
};

const HexagonView = ({ competenciesLists }) => {
  const ref = useRef(null);
  const [className, setClassName] = useState('medium');
  const firstCompetencies = useMemo(
    () => orderBy(competenciesLists[0], ['competency.name']),
    [competenciesLists]
  );
  const secondCompetencies = useMemo(
    () => orderBy(competenciesLists[1], ['competency.name']),
    [competenciesLists]
  );
  const competencies = useMemo(() => {
    if (secondCompetencies.length === 0) {
      return firstCompetencies;
    }
    const rowCount = rowCountMap[className];
    const _firstCompetencies = cloneDeep(
      firstCompetencies.map(item => ({ ...item, className: styles.inherit }))
    );
    const _secondCompetencies = cloneDeep(
      secondCompetencies.map(item => ({ ...item, className: styles.based }))
    );
    if (rowCount <= 3) {
      return _firstCompetencies.concat(_secondCompetencies);
    }

    const firstCompetenciesLength = _firstCompetencies.length;
    const secondCompetenciesLength = _secondCompetencies.length;
    const count =
      (firstCompetenciesLength /
        (firstCompetenciesLength + secondCompetenciesLength)) *
      rowCount;
    let x = Math.round(count);
    let y = rowCount - x;
    if (x > rowCount) {
      x = rowCount - 1;
      y = rowCount - x;
    }
    const result = [];
    while (result.length < firstCompetenciesLength + secondCompetenciesLength) {
      result.push(
        ..._firstCompetencies.splice(0, x),
        ..._secondCompetencies.splice(0, y)
      );
    }
    return result;
  }, [firstCompetencies, secondCompetencies, className]);
  const competenciesListsIsEmpty = useMemo(() => competencies.length === 0, [
    competencies
  ]);
  useEffect(() => {
    const handleResize = throttle(() => {
      if (ref.current) {
        const wrapperElement = ref.current.parentElement;
        if (wrapperElement) {
          const { width } = wrapperElement.getBoundingClientRect();
          setClassName(calcClassName(width));
        }
      }
    }, 300);
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div
      data-cy="hexagon-container"
      ref={ref}
      className={clsx(styles.hexagonItemContainer, styles[className])}
    >
      {competenciesListsIsEmpty && (
        <p className={styles.emptyMessage}>There were no competency.</p>
      )}
      {competencies.map(
        (
          {
            className,
            competencyId,
            name,
            description,
            suggestedLevel,
            levelDescriptions
          },
          index
        ) => {
          return (
            <HexagonItem
              key={index}
              id={competencyId}
              name={name}
              description={description}
              competencyLevel={findSuggestedLevelName(
                suggestedLevel,
                levelDescriptions
              )}
              className={className}
            />
          );
        }
      )}
    </div>
  );
};
HexagonView.propTypes = {
  competenciesLists: PropTypes.array.isRequired
};

export default HexagonView;
