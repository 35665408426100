import DudeLook from '../../assets/Icons/Dude/DudeLook.png';
import DudeWait from '../../assets/Icons/Dude/DudeWait.png';
import DudeHold from '../../assets/Icons/Dude/DudeHold.png';

export const dashboardDes = {
  index: {
    toolBarOptions: [
      {
        title: 'ME NOW',
        dude: DudeLook,
        style: { left: '-70px', top: '-53px' }
      },
      {
        title: 'ME NEXT',
        dude: DudeWait,
        style: { right: '-38px', top: '-53px' }
      },
      {
        title: 'MY PLAN',
        dude: DudeHold,
        style: {
          right: '-55px',
          top: '-32px',
          width: '80px',
          height: '130px'
        }
      }
    ]
  },
  shareMeNextNotification: {
    currentPreferenceTitle: {
      AllDataVisible: 'All of your ‘Me Next’ data is visible to staffing teams',
      SomeDataVisible:
        'Only some of your ‘Me Next’ data is visible to staffing teams',
      NoneDataVisible:
        'None of your ‘Me Next’ data is visible to staffing teams'
    },
    currentPreferenceDescribePart1: `Making all your ‘Me Next’ data visible in your `,
    currentPreferenceDescribePart2: ` helps you and staffing teams align `,
    currentPreferenceDescribePart3: ` according to your growth aspirations.`,
    editVisibility: 'Edit visibility preferences'
  },
  myPlanCardDes: {
    accomplishedContent: {
      title: 'Do you want to reassess and adjust your competency level?'
    },
    contribution: {
      bothListEmptyTitle:
        "This competency doesn't belong to your selected Archetypes or Core Competencies",
      notEmptyTitle:
        'Working on this learning priority will support my Growth with:'
    },
    goalCard: {
      removeGoal: 'Remove goal',
      removeGoalTip: 'Are you sure you want to remove this goal?',
      editButton: 'Edit Goal',
      markOwnGoalAccomplishedTip:
        'This learning priority will be moved to your "Accomplished Goals" section.',
      markAsUnaccomplishedButton: 'Mark as Unaccomplished',
      noLoggedActions: 'You haven’t logged any action.'
    },
    goalGapAndAction: {
      noGaps: 'No Gaps.',
      noActions: 'No Actions.',
      noCategories: 'No Categories',
      removeButton: 'remove the item',
      lengthErrorMessage: '*Input should be at most 160 characters in length'
    },
    goalModal: {
      markAsAccomplishedDes: 'This goal is marked as accomplished.'
    },
    currentGoals: {
      button: 'Current Goals',
      title: 'Start making your plan based on your learning priorities.',
      createGoal: 'Create a Goal',
      selectLearningPriority: {
        defaultOption: 'Select a learning priority',
        myOwnGoalOption: 'Create my own learning goal',
        learningGoalRequired: '* Required',
        seeCompetencyDetails: 'See Competency details',
        learningGoalErrorMsg: 'Please select a learning priority.'
      },
      noSelectedLearningPriorityDes:
        'Select a learning priority to see related Archetypes or Core Competencies.'
    },
    accomplishGoals: {
      button: 'Accomplished Goals',
      title: 'Accomplished goals will be recorded here.',
      noAccomplishGoals: 'No accomplished goals.'
    },
    markAsAccomplishedButton: 'Mark as Accomplished',
    goalDetailsButton: 'Goal Details',
    goalInformation: {
      noPeriodErrorMessage: 'Please select a duration.',
      outPeriodErrorMessage: 'The max input is 99',
      noDescErrorMessage: 'Please fill in the goal description.',
      outDescErrorMessage: '*Input should be at most 1000 characters in length',
      unselectedMessage: 'Please select a goal first',
      ownGoalViewContent: 'My own learning priority',
      titlePlaceholder: 'e.g. 6',
      textPlaceholder:
        'e.g. practise it more with my client and with my teammates to become a practitioner.'
    },
    myGaps: {
      title: 'My Gaps',
      placeholder: 'e.g. I need to organize my thoughts first',
      des: 'I think (or I’ve been told) my gaps on this learning priority are:'
    },
    actions: {
      title: 'Actions',
      placeholder: 'e.g. Join the code refactoring community in my region',
      des: 'I’m planning to take these actions to get me there:'
    },
    myOwnGoal: {
      title: '',
      placeholder: 'e.g. social change, life aspirations…',
      des: 'Working on this learning priority will support my growth in:'
    }
  },
  connectSummit: {
    fileInfo: {
      title: 'Connect with Summit',
      button: 'Open Summit'
    },
    pathwaysToSummit: {
      dev: {
        pathways: 'dev.pathways.thoughtworks.net',
        summit: 'dev.performance.thoughtworks.net'
      },
      qa: {
        pathways: 'qa.pathways.thoughtworks.net',
        summit: 'qa.performance.thoughtworks.net'
      },
      prod: {
        pathways: 'pathways.thoughtworks.net',
        summit: 'performance.thoughtworks.net'
      },
      staging: {
        pathways: 'staging.pathways.thoughtworks.net',
        summit: 'staging.performance.thoughtworks.net'
      }
    }
  },
  toolsBar: {
    lastUpdate: 'Last update,',
    downloadPDF: 'Download pdf'
  },
  entryPoint: {
    archetype: {
      title: 'Archetypes',
      subtitle: 'Use Competencies to self-reflect on'
    },
    competency: {
      title: 'Competencies',
      subtitle: 'Start your self-reflection selecting'
    },
    skill: {
      title: 'Skills',
      subtitle: 'Start your self-reflection selecting'
    }
  },
  meNowCard: {
    archetypesTitle: 'My Archetypes Today',
    competenciesTitle: 'My Competencies Today',
    skillTitle: 'My Skills Today'
  },
  meNextCard: {
    archetypesTitle: 'Archetypes to develop',
    competenciesTitle: 'Competencies to develop',
    skillTitle: 'Skills to develop'
  },
  archetypesCapableOf: {
    confirmButtonText: 'Remove Archetype',
    confirmContent: 'This Archetype will be removed from your Growth Canvas.'
  },
  topCourseRecommendation: {
    title: 'Top Course Recommendations',
    description: `Discover the most enrolled courses in Campus <br /> among Thoughtworkers interested in the same Archetypes as you.`
  },
  myInterests: {
    title: 'My Interests are',
    defaultInput: 'e.g. Analytics, Psychology of design…'
  },
  guideModal: {
    topCourseGuideTitle: `New ‘Top Courses’ page`
  },
  knowPathwaysModal: {
    title: 'Get to know Pathways!',
    describeTitle: 'Welcome to your Pathways Growth Canvas!',
    describeInfoPart1:
      'Here you get to explore your different archetypes, competencies and\n' +
      '            skills and create a priority list...',
    describeInfoPart2:
      'Take a quick tour to get to know the interface and how it can help\n' +
      '            you grow at TW!',
    skipTourBtn: 'Skip tour',
    takeQuickTourBtn: 'Take quick tour!'
  },
  communities: {
    title: 'Learning Communities',
    subTitle: 'Here are some learning groups you might want to join today!'
  }
};

export const connectSummitContent = name => {
  return {
    noCyclesRecord: {
      fold:
        'Summit will help you align project, team and role expectations with your goals defined here in Pathways!',
      unFold: [
        `Summit, a Performance tool, will help you align project, team and role expectations with your growth goals, defined here in Pathways. `,
        `It’s easy! Go to Summit, choose the Performance Partner/s you want to support you and capture their feedback at the end of the performance cycle.`
      ]
    },
    ongoingCycleWithoutNotes: {
      fold:
        'Hi, ' + name + '! Check out the expectations you set up in Summit!',
      unFold: [
        'Hi, ' + name + '! Check out the expectations you set up in Summit!',
        'Have a look at the notes and expectations you set up in Summit. They can help you expand your growth plan and inspire the next step in your career!'
      ]
    },
    ongoingCycleWithNotes: {
      fold: 'Hi, ' + name + '! You have new check-in notes in Summit!',
      unFold: [
        'Hi, ' + name + '! You have new check-in notes in Summit!',
        'Read your check-in notes to see how you are doing and get inspired about what direction to go in your growth path.'
      ]
    },
    doneCycle: {
      fold:
        'Hi, ' +
        name +
        '! Your performance partner has filled in your assessment!',
      unFold: [
        'Hi, ' +
          name +
          '! Your performance partner has filled in your assessment!',
        'Check in out to see how you are doing this cycle and get fresh ideas on what paths to go on next!'
      ]
    }
  };
};
