import React from 'react';
import { SvgIcon } from '@mui/material';

const Medal = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 18 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0L26 0 26 26 0 26z" transform="translate(-4 -1)" />
        <path
          fill="currentColor"
          fillRule="nonzero"
          d="M13 6.715c4.97 0 9 4.093 9 9.143C22 20.907 17.97 25 13 25s-9-4.093-9-9.142c0-5.05 4.03-9.143 9-9.143zm0 2.286c-3.728 0-6.75 3.07-6.75 6.857 0 3.786 3.022 6.856 6.75 6.856s6.75-3.07 6.75-6.856C19.75 12.07 16.728 9 13 9zm0 1.714l1.488 3.063 3.327.491-2.408 2.383.569 3.367L13 18.429l-2.976 1.588.569-3.365-2.408-2.384 3.327-.491L13 10.715zm6.75-9.714V4.43l-1.533 1.3c-1.273-.678-2.662-1.1-4.092-1.244V1h5.625zM11.875 1v3.486c-1.43.143-2.818.565-4.09 1.243l-1.535-1.3V1.002L11.875 1z"
          transform="translate(-4 -1)"
        />
      </g>
    </SvgIcon>
  );
};

export default Medal;
