import React from 'react';
import { SvgIcon } from '@mui/material';

const Group = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 54 47">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0L64 0 64 64 0 64z" transform="translate(-5 -9)" />
        <path
          fill="#E1E2E3"
          fillRule="nonzero"
          d="M7.7 27.278H5V11.619C5 10.172 6.228 9 7.678 9h48.643c.716-.001 1.403.275 1.906.767.504.493.782 1.16.773 1.852v15.659h-2.7V53.39c.002.69-.28 1.353-.782 1.842-.503.489-1.186.765-1.9.767H10.381c-.713-.002-1.396-.278-1.9-.767-.502-.49-.783-1.152-.781-1.842V27.278zm43.2 0H13.1v23.5h37.8v-23.5zM10.4 14.222v7.834h43.2v-7.834H10.4zM23.9 32.5h16.2v5.222H23.9V32.5z"
          transform="translate(-5 -9)"
        />
      </g>
    </SvgIcon>
  );
};

export default Group;
