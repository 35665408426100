import styles from './index.module.css';
import React from 'react';
import { CATEGORY_EMPTY } from '../../constants/modalConstants';

export const SearchCategoryEmpty = (searchBarConstants, moduleName, img) => {
  const emailAddress = 'capable-admin-support@thoughtworks.com';
  const formUrl =
    'https://mail.google.com/mail/?ui=2&view=cm&to=' +
    emailAddress +
    '&fs=1&tf=1';

  const ListSearchEmptyModule = moduleName.includes('ListSearchEmpty');
  return (
    <div className={styles[moduleName]}>
      {!ListSearchEmptyModule && (
        <img
          src={img || searchBarConstants.searchEmptyIcon}
          className={styles[moduleName + 'Icon']}
          alt=""
        />
      )}
      {moduleName === CATEGORY_EMPTY && (
        <p>Sorry, we couldn’t find any matching results.</p>
      )}
      {ListSearchEmptyModule && (
        <p>Sorry, we couldn’t find any matching results under this category.</p>
      )}
      <div className={styles[moduleName + 'Des']}>
        If your {searchBarConstants.name} is not yet in our list, please send us
        an email to
        <a
          className={styles.formUrl}
          href={formUrl}
          rel="noopener noreferrer"
          target="_blank"
        >
          {emailAddress}
        </a>
        and submit a request to add it to Pathways. Thank you!
      </div>
    </div>
  );
};
