import React from 'react';
import { SvgIcon } from '@mui/material';

const HistoryIcon = props => {
  return (
    <SvgIcon {...props}>
      <path fill="none" d="M0 0H24V24H0z" />
      <path d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12h2c0 4.418 3.582 8 8 8s8-3.582 8-8-3.582-8-8-8C9.25 4 6.824 5.387 5.385 7.5H8v2H2v-6h2V6c1.824-2.43 4.729-4 8-4zm1 5v4.585l3.243 3.243-1.415 1.415L11 12.413V7h2z" />
    </SvgIcon>
  );
};

export default HistoryIcon;
