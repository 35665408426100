import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import GoalInformationForm from '../../GoalInformation';

import { proficiencyLevelsDescription } from '../../../../../constants/proficiencyLevels';
import { getCompetencyLevelDescription } from '../../../../../api/domain/competency';
import { useUserContext } from '../../../../../contexts/UserContext';

import myPlanCardStyles from '../../index.module.css';
import styles from './index.module.css';
import { dashboardDes } from '../../../language';
import { CheckBox } from '../../../../../nonFeatureComponents/CheckBox';
import { LEARNING_PRIORITY } from '../../../../../constants/competency';
import AssessSlider from '../../../../../nonFeatureComponents/AssessSlider';
import MLinearProgress from '../../../../../feature/LinearProgress';

const AccomplishedContent = ({ goal, onChange, originPracticeLevel }) => {
  const goalInformationRef = useRef(null);
  const {
    state: {
      user: { reflectedCompetencies }
    }
  } = useUserContext();
  const [competencyLevelDescription, setCompetencyLevelDescription] = useState(
    []
  );
  const findCompetencyLevelDescription = useCallback(
    async id => {
      let competencyLevelDescription = [];
      const item = reflectedCompetencies.find(item => item.id === id);
      if (item) {
        competencyLevelDescription = item.levelDescriptions;
      }
      if (competencyLevelDescription.length === 0) {
        competencyLevelDescription = await getCompetencyLevelDescription(id);
      }
      setCompetencyLevelDescription(competencyLevelDescription);
    },
    [reflectedCompetencies]
  );

  const handleOnPracticeLevelChange = practiceLevel => {
    let pl = practiceLevel;

    if (pl < originPracticeLevel) {
      pl = originPracticeLevel;
    }

    onChange({ ...goal, practiceLevel: pl });
  };

  const selectedCompetency = {
    name: goal.name,
    practiceLevel: originPracticeLevel
  };
  const handleIsLearningPriorityChange = event => {
    onChange({ ...goal, isLearningPriority: event.target.checked });
  };
  useEffect(() => {
    const ref = goalInformationRef.current;
    if (!ref || !goal) return;
    ref.setValue({
      period: goal.period,
      description: goal.description
    });
  }, [goal]);
  useEffect(() => {
    findCompetencyLevelDescription(goal.competencyId).then();
  }, [findCompetencyLevelDescription, goal.competencyId]);

  return (
    <div>
      <div className={myPlanCardStyles.container}>
        <GoalInformationForm
          justDisplay
          selectedCompetency={selectedCompetency}
          ref={goalInformationRef}
          disabled={true}
        />
        <div className={styles.line} />
      </div>

      <div className={myPlanCardStyles.container}>
        <div className={styles.title}>
          {dashboardDes.myPlanCardDes.accomplishedContent.title}
        </div>
        <div className={styles.adjustWrapper}>
          <MLinearProgress loading={competencyLevelDescription.length === 0} />
          {competencyLevelDescription.length !== 0 && (
            <>
              <div className={styles.assessWrapper}>
                <ul className={styles.proficiencyLevelWrapper}>
                  {proficiencyLevelsDescription.map(level => {
                    return (
                      <li className={styles.proficiencyLevelItem} key={level}>
                        {level}
                      </li>
                    );
                  })}
                </ul>
                <AssessSlider
                  onChange={handleOnPracticeLevelChange}
                  levelDescriptions={competencyLevelDescription}
                  name={goal.name}
                  actualPracticeLevel={goal.practiceLevel}
                />
              </div>
              <div className={clsx(styles.checkboxWrapper)}>
                <CheckBox
                  label={LEARNING_PRIORITY}
                  checked={goal.isLearningPriority}
                  onChange={handleIsLearningPriorityChange}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

AccomplishedContent.propTypes = {
  goal: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  originPracticeLevel: PropTypes.number
};

export default AccomplishedContent;
