export let DevWhiteListForRecommendedCourses = [
  'lu.aifei@thoughtworks.com',
  'jing.liang@thoughtworks.com',
  'qihang.yuan@thoughtworks.com',
  'qingqing.luo@thoughtworks.com',
  'aristori@thoughtworks.com',
  'anuja.karnik@thoughtworks.com',
  'archana.raman@thoughtworks.com',
  'yilun.liu@thoughtworks.com',
  'yixuan.li1@thoughtworks.com',
  'wenshuang.huang@thoughtworks.com',
  'samantha.rosa@thoughtworks.com',
  'yzli@thoughtworks.com',
  'rmirand@thoughtworks.com',
  'keqian.cao@thoughtworks.com',
  'lmunno@thoughtworks.com',
  'nmenhem@thoughtworks.com',
  'peter.gillard-moss@thoughtworks.com',
  'alexandru.mandiuc@thoughtworks.com',
  'zekai.liu@thoughtworks.com',
  'tshen@thoughtworks.com',
  'yuxuan.li1@thoughtworks.com'
];
