import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

import globalStyles from '../../../globalStyles';

const useStyles = makeStyles(() => ({
  header: {
    height: '57px',
    width: '100%',
    lineHeight: '57px',
    backgroundColor: globalStyles.primaryColor,
    fontSize: '20px',
    fontWeight: 'bold',
    color: globalStyles.grey80,
    display: 'flex'
  }
}));

const ModalHeader = ({ children }) => {
  const classes = useStyles();
  return <header className={classes.header}>{children}</header>;
};

ModalHeader.propTypes = {
  children: PropTypes.node.isRequired
};

export default ModalHeader;
