import React from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';

import FeedbackIcon from '../Common/SvgIcons/FeedbackIcon';

import { ARCHETYPE_DASHBOARD_EVENT } from '../../constants/matomo';

import styles from './Footer.module.css';
import CapableLogo from '../../assets/Pictures/CapableLogo.png';
import newThoughtworksLogo from '../../assets/Icons/newThoughtworksWhiteLogo.svg';

import { getEnvVars } from '../../config/config';

import {
  DisclaimerLink,
  coreCapabilityFeedbackLink,
  FAQsLink
} from '../../constants/links';

const { capableLink } = getEnvVars();
const Footer = () => {
  const { trackEvent } = useMatomo();

  return (
    <footer>
      <div className={styles.footerContainer}>
        {/*feedback icon*/}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={coreCapabilityFeedbackLink}
          className={styles.footerFeedbackIconWrapper}
          onClick={() => {
            trackEvent({
              category: ARCHETYPE_DASHBOARD_EVENT.category,
              action: ARCHETYPE_DASHBOARD_EVENT.action.FEEDBACK
            });
          }}
          aria-label="Please give us feedback on Pathways!"
        >
          <div className={styles.footerFeedbackIconInner} tabIndex="-1">
            <FeedbackIcon
              className={styles.footerFeedbackIcon}
              titleAccess="feedback"
            />
          </div>
        </a>
        {/*title*/}
        <div className={styles.footerTitle}>
          <b>Talk to us!</b> Your feedback counts!
        </div>
        <div className={styles.footerOtherApps}>
          <div className={styles.appFAQ}>
            <div>
              <b className={styles.title}>I need further help.</b>
              <div className={styles.subTitle}>
                Find answers in our Pathways FAQs.
              </div>
              <a
                className={styles.link}
                rel="noopener noreferrer"
                target="_blank"
                href={FAQsLink}
              >
                <div className={styles.linkContent}>
                  <span className={styles.linkLeftText}>Pathways</span>
                  <span className={styles.linkDividerLine} />
                  <span className={styles.linkRightText}>FAQs</span>
                </div>
              </a>
            </div>
          </div>
          <div className={styles.appCapable}>
            <div>
              <b className={styles.title}>Where does content come from?</b>
              <div className={styles.appCapableLogoTitleWrapper}>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={capableLink}
                  className={styles.appCapableLogoWrapper}
                >
                  <img src={CapableLogo} alt="capable" />
                </a>
              </div>
            </div>
          </div>
          <div className={styles.dataPrivacy}>
            <b className={styles.title}>How will Pathways use my data?</b>
            <div className={styles.subTitle}>
              We are currently exploring this.
            </div>
            <div className={styles.dataPrivacyBtn} role="button" tabIndex="0">
              <a
                className={styles.dataPrivacyBtnInner}
                target="_blank"
                rel="noopener noreferrer"
                href={DisclaimerLink}
              >
                Read our Data Disclaimer
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.contactContent}>
        <span>
          <b>Contact: </b>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://mail.google.com/mail/?ui=2&view=cm&to=pathways-support@thoughtworks.com&fs=1&tf=1"
          >
            pathways-support@thoughtworks.com
          </a>
        </span>
        <span className={styles.dividerLine} />
        <span>
          <span role="img" aria-label="copy">
            ©️️
          </span>
          &nbsp;
          <img
            src={newThoughtworksLogo}
            className={styles.newThoughtworksLogo}
            alt=""
          />
          <span className={styles.copyright}>, Inc. All Rights Reserved.</span>
        </span>
      </div>
    </footer>
  );
};

export default Footer;
