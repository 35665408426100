import React from 'react';
import { Grid } from '@mui/material';
import clsx from 'clsx';
import styles from './index.module.css';
import { onKeyDown } from '../../../../utils/onKeyDown';
import PropTypes from 'prop-types';
import { ReactComponent as ToggleFill } from '../../../../assets/Icons/toggle-fill.svg';
import { ReactComponent as ToggleLine } from '../../../../assets/Icons/toggle-line.svg';
import { ReactComponent as InformationLine } from '../../../../assets/Icons/information-line.svg';
export const ArchetypeItem = ({
  archetype,
  single,
  largeItem,
  isSelected,
  onCreate,
  onRemove,
  onDetail
}) => {
  const handleItemClick = (archetype, isSelected) => {
    const fn = !isSelected
      ? () => onCreate(archetype)
      : () => onRemove(archetype.archetypeId);
    fn();
  };
  const handleInformationClick = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    onDetail(id);
  };
  const props = { xs: 12, sm: 6, md: 4, lg: single ? 3 : 6 };

  return (
    <Grid
      key={archetype.archetypeId}
      item
      {...props}
      data-cy={`archetype:${archetype.archetypeName}`}
    >
      <div
        tabIndex={0}
        className={clsx(styles.archetypeItem, {
          [styles.selected]: isSelected,
          [styles.largeItem]: largeItem
        })}
        aria-label={archetype.archetypeName}
        data-cy="archetype"
      >
        <div
          className={clsx({
            [styles.boldText]: largeItem
          })}
        >
          <span className={styles.archetypeItemName}>
            {archetype.archetypeName}
          </span>
        </div>

        <div
          className={clsx(styles.archetypeItemMask, {
            [styles.largeItem]: largeItem
          })}
        >
          <div className={styles.inners}>
            <div
              role="button"
              aria-label="Deselect this archetype"
              onClick={() => handleItemClick(archetype, isSelected)}
              onKeyDown={e =>
                onKeyDown(e, () => handleItemClick(archetype, isSelected))
              }
              className={styles.inner}
            >
              {isSelected ? (
                <>
                  <ToggleFill className={styles.icon} />
                  <div className={styles.text}>Deselect</div>
                </>
              ) : (
                <>
                  <ToggleLine className={styles.icon} />
                  <div className={styles.text}>Select</div>
                </>
              )}
            </div>
            <div
              role="button"
              aria-label="Open this archetype detail"
              className={styles.inner}
              onClick={e => handleInformationClick(e, archetype.archetypeId)}
              onKeyDown={e =>
                onKeyDown(e, () =>
                  handleInformationClick(e, archetype.archetypeId)
                )
              }
            >
              <InformationLine className={styles.icon} />
              <div className={styles.text}>Details</div>
            </div>
          </div>
        </div>
      </div>
    </Grid>
  );
};
ArchetypeItem.propTypes = {
  archetype: PropTypes.object.isRequired,
  single: PropTypes.bool.isRequired,
  largeItem: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onCreate: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onDetail: PropTypes.func.isRequired
};
