import searchCompetenciesEmpty from '../../assets/Icons/searchCompetenciesEmpty.png';
import React from 'react';

export const localStorageKey = 'newSelectedSkillName';
export const title = 'First, select skills.';
export const description =
  'Think about your work experience and also the types of skills you would like to develop next. You can choose as many skills as you like.';

export const orderedCategory = ['Technical', 'Testing', 'Language'];
export const SearchBarConstants = {
  name: 'skill',
  placeholder: 'Search by skill name',
  ClassName: 'SkillSearchBar',
  searchEmptyIcon: searchCompetenciesEmpty
};

export const goToTitle = (
  <span>
    <u>N</u>ow, add details
  </span>
);
