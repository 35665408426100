export const visibilityPreferenceDes = {
  title: '‘Me Next’ data visibility preferences',
  confirmModal: {
    confirmButtonText: 'Go to ‘Me Next’ Dashboard',
    cancelButtonText: 'Go to Jigsaw Profile',
    title: 'Changes successfully saved',
    content:
      'Your ‘Me Next’ data visibility preferences have been updated and will be automatically synced with your Jigsaw Profile .'
  },
  operationButtons: {
    save: 'Save preferences',
    cancel: 'Cancel'
  },
  visibilityButton: {
    hidden: 'Hidden',
    visible: 'Visible'
  }
};
