import React from 'react';
import styles from './PDFFooter.module.css';
import newThoughtworksLogo from '../../assets/Icons/newThoughtworksWhiteLogo.svg';

const PDFFooter = () => {
  return (
    <div className={styles.contactContent}>
      <span>
        <b>Contact: </b>
        pathways-support@thoughtworks.com
      </span>
      <span className={styles.dividerLine} />
      <span>
        <span role="img" aria-label="copy" />
        ©️️ &nbsp;
        <img
          src={newThoughtworksLogo}
          className={styles.newThoughtworksLogo}
          alt=""
        />
        <span>, Inc. All Rights Reserved.</span>
      </span>
    </div>
  );
};

export default PDFFooter;
