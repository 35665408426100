import * as React from 'react';
import PropTypes from 'prop-types';
import Modal from '@mui/material/Modal';

import styles from './Notification.module.css';

import clsx from 'clsx';
import {
  CloseIconButton,
  CancelButton,
  ConfirmButton
} from '../../../nonFeatureComponents/Buttons/Buttons';

export default function Notification({
  title,
  desc,
  open,
  close,
  confirmation,
  onConfirm,
  cancelText,
  onCancel,
  btns,
  isShareModal
}) {
  return (
    <Modal
      role="dialog"
      aria-modal={true}
      open={open}
      onClose={close}
      Backdrop={{
        timeout: 500
      }}
    >
      <div
        aria-labelledby="init"
        className={clsx(styles.modal, isShareModal && styles.shareModal)}
        data-cy="notification"
      >
        <div className={styles.title}>
          <h2 className={styles.title} id="init">
            {title || 'Are you sure?'}
          </h2>
          <CloseIconButton handleClick={close} />
        </div>
        <section className={styles.content}>
          <div className={styles.desc}>{desc}</div>
          <div className={styles.operations}>
            {btns || (
              <>
                <CancelButton onClick={onCancel || close} data-cy="cancel">
                  {cancelText || 'Cancel'}
                </CancelButton>
                <ConfirmButton onClick={onConfirm} data-cy="confirm">
                  {confirmation || 'Confirm'}
                </ConfirmButton>
              </>
            )}
          </div>
        </section>
      </div>
    </Modal>
  );
}

Notification.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  confirmation: PropTypes.string,
  cancelText: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  btns: PropTypes.any
};
