import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import styles from './index.module.css';
import { useUserContext } from '../../../../contexts/UserContext';
import { updateUser } from '../../../../actions/profile';
import CloseIcon from '@mui/icons-material/Close';
import { getEnvVars } from '../../../../config/config';
import { USER_GRADE } from '../../../../constants/user';
import {
  GUIDE_STEPS_WITH_TOP_COURSES,
  GUIDE_STEPS_WITHOUT_TOP_COURSES
} from '../../../../constants/modalConstants';
import { TOP_COURSE_GUIDE_STEP_NUMBER } from '../../../../constants/number';
import { dashboardDes } from '../../language';

const { capableLink } = getEnvVars();

const guideDescribes = stepNumber => {
  const steps = [
    {
      info: (
        <>
          <p>
            In Pathways you can explore and define your current archetypes,
            competencies and skills.
          </p>
          <p>
            <b>Me now</b> is a snapshot of where you are at the moment. You can
            self-reflect on your strengths today and keep track of them.
          </p>
        </>
      )
    },
    {
      info: (
        <>
          <p>
            As well as defining your current state, with Pathways you can
            explore growth opportunities for the future too.
          </p>
          <p>
            <b>Me next</b> offers a space to define which areas you would like
            to prioritise to learn and grow in.
          </p>
        </>
      )
    },
    {
      info: (
        <>
          <p>
            Once you’ve defined your current strengths and learning priorities,
            you can create a plan! A plan is simply a set of time-bound goals
            and actions to help you achieve them.
          </p>
          <p>
            Your plan remains <b>private to you</b>.
          </p>
        </>
      )
    },
    {
      info: (
        <>
          <p>
            Competencies and Archetypes are all elements of our Global
            Capability Model. To understand more please visit the Reference
            Guide here and Capable &nbsp;
            <a
              className={styles.capableAddress}
              target="_blank"
              rel="noopener noreferrer"
              href={capableLink}
            >
              here
            </a>
            .
          </p>
          <p>
            <b>Archetypes</b> focus on the craft of what someone does, they
            highlight the most focused-on <b>competencies</b> and the level of
            depth that we most frequently find.
          </p>
        </>
      )
    },
    {
      info: (
        <>
          <p>
            <b>Skills</b> are knowledge you put to use at work in order to
            achieve your team’s goals (for example languages you can speak or
            programming languages you can code in).
          </p>
          <p>
            Applying skills in your day to day work helps to achieve the goals
            of your team. They can also be used as part of your Plan to help
            work towards certain goals.
          </p>
        </>
      )
    },
    {
      info: (
        <p>
          Discover the most enrolled courses in Campus among Thoughtworkers
          interested in the same Archetypes as you.
        </p>
      )
    }
  ];
  const topCourseGuide = {
    info: (
      <>
        <p>
          Discover the most enrolled courses in Campus among Thoughtworkers
          interested in the same Archetypes as you.
        </p>
      </>
    )
  };
  if (stepNumber === TOP_COURSE_GUIDE_STEP_NUMBER) {
    return topCourseGuide;
  }
  return steps[stepNumber - 1];
};

const GuideModal = ({ guideModalShow, closeGuideModal, updateGuideModal }) => {
  const {
    state: {
      user,
      user: { grade, guideStepNumber = 0 }
    },
    dispatch
  } = useUserContext();
  const stepNumberStyle = 'guideModal-' + guideStepNumber;
  const isShowTopCourseFeature =
    guideStepNumber === TOP_COURSE_GUIDE_STEP_NUMBER;

  const maxGuideStep =
    grade === USER_GRADE.GRADUATE
      ? GUIDE_STEPS_WITH_TOP_COURSES
      : GUIDE_STEPS_WITHOUT_TOP_COURSES;

  const updateGuideStepNumber = number => {
    user.guideStepNumber = number;
    if (+number === maxGuideStep + 1) {
      user.guideStepNumber = -1;
    }
    dispatch(updateUser(user));
  };

  const getModalButton = () => (
    <div
      className={styles.gotBtn}
      onClick={() => {
        closeGuideModal();
        updateGuideStepNumber(-1);
      }}
    >
      Got it!
    </div>
  );
  const getTipsButton = guideStepNumber => (
    <div className={styles.buttons}>
      {+guideStepNumber > 1 && (
        <div
          className={styles.prevBtn}
          onClick={event => {
            event.stopPropagation();
            let curStepNumber = +guideStepNumber - 1;
            updateGuideStepNumber(curStepNumber);
            updateGuideModal(curStepNumber);
          }}
        >
          Back
        </div>
      )}

      <div
        className={styles.nextBtn}
        onClick={event => {
          event.stopPropagation();
          let curStepNumber = +guideStepNumber + 1;
          updateGuideStepNumber(curStepNumber);
          updateGuideModal(curStepNumber);
        }}
      >
        {+guideStepNumber === maxGuideStep ? 'Done' : 'Next'}
      </div>
    </div>
  );

  return (
    <div
      className={clsx(
        styles.guideModal,
        styles[stepNumberStyle],
        guideModalShow && styles.guideModalShow
      )}
    >
      <div className={styles.guideMask} />
      <div className={styles.guideInfo}>
        <div className={styles.arrow} />
        <div className={styles.guideHeader}>
          {isShowTopCourseFeature ? (
            <div className={styles.topCourseGuideTitle}>
              {dashboardDes.guideModal.topCourseGuideTitle}
            </div>
          ) : (
            <div className={styles.steps}>
              Tip {guideStepNumber}/{maxGuideStep}
            </div>
          )}
          <div
            className={styles.close}
            tabIndex={-1}
            onClick={() => {
              closeGuideModal();
              updateGuideStepNumber(-1);
            }}
          >
            <CloseIcon
              className={styles.closeIcon}
              role="button"
              titleAccess="close"
            />
          </div>
        </div>
        <div className={styles.guideDescribeInfo}>
          {guideDescribes(guideStepNumber) &&
            guideDescribes(guideStepNumber).info}
        </div>
        <div className={styles.guideBottom}>
          {isShowTopCourseFeature
            ? getModalButton()
            : getTipsButton(guideStepNumber)}
        </div>
      </div>
    </div>
  );
};

GuideModal.propTypes = {
  guideModalShow: PropTypes.bool.isRequired,
  closeGuideModal: PropTypes.func.isRequired,
  updateGuideModal: PropTypes.func.isRequired
};

export default GuideModal;
