import React, { useRef } from 'react';
import styles from './index.module.css';
import { CampusModule } from './components/CampusModule';
import { CapabilityModule } from './components/CapabilityModule';
import { JourneyModule } from './components/JourneyModule';
import { PlanModule } from './components/PlanModule';
import { ModuleLine } from '../../nonFeatureComponents/ModuleLine';

const LandingPage = () => {
  const videoRef = useRef(null);

  return (
    <div className={styles.main}>
      <JourneyModule videoRef={videoRef} />
      <ModuleLine className={'moduleClassName'} />
      <CampusModule videoRef={videoRef} />
      <ModuleLine className={'moduleClassName'} />
      <PlanModule />
      <ModuleLine className={'moduleClassName'} />
      <CapabilityModule />
    </div>
  );
};

export default LandingPage;
