import styles from './index.module.css';
import { dashboardDes } from '../../../language';
import clsx from 'clsx';
import { proficiencyLevelsDescription } from '../../../../../constants/proficiencyLevels';
import PropTypes from 'prop-types';
import React from 'react';

export const HexagonCompetencyView = ({
  name,
  score,
  grayLevel,
  isMyOwnGoal
}) => {
  return (
    <div>
      {isMyOwnGoal ? (
        <div className={styles.ownGoalView}>
          <div className={styles.ownGoalName}>
            {dashboardDes.myPlanCardDes.goalInformation.ownGoalViewContent}
          </div>
        </div>
      ) : (
        <div className={styles.hexagonView}>
          <div className={styles.hexagonViewAfter} />
          <div className={styles.hexagonContent}>
            <div className={styles.competencyName}>{name}</div>
            {score > 0 && (
              <span
                className={clsx(
                  styles.competencyLevel,
                  grayLevel && styles.grayBgColor
                )}
              >
                {proficiencyLevelsDescription[score - 1]}
              </span>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

HexagonCompetencyView.propTypes = {
  name: PropTypes.string.isRequired,
  score: PropTypes.number,
  grayLevel: PropTypes.bool,
  isMyOwnGoal: PropTypes.bool
};
