import React from 'react';
import boat from '../../assets/Icons/landingPageIcons/boat.svg';

import module1Img from '../../assets/Icons/landingPageIcons/landing-module1.svg';
import module2Img from '../../assets/Icons/landingPageIcons/landing-module2.svg';
import module3Img from '../../assets/Icons/landingPageIcons/landing-module3.svg';

import { Link } from 'react-router-dom';
import { DASHBOARD_WITH_TARGET } from '../../constants/path';
import styles from './index.module.css';
import { DASHBOARD_URL_TAB } from '../../constants/dashborad';

export const landingPageDes = {
  planModule: {
    title: 'A new tool to plan your growth at Thoughtworks.',
    pathwaysIntro:
      'Pathways is your ally to discover growth paths in Thoughtworks, self-reflect about your career and make a plan to get you there!',
    cardList: [
      {
        decorationImgs: (
          <>
            <img src={boat} className={styles.boatIcon} alt="" />
            <img src={module1Img} className={styles.module1Icon} alt="" />
          </>
        ),

        title: 'Discover growth paths.',
        description: () => (
          <>
            There are many different ways to grow at Thoughtworks & every
            Thoughtworker can choose their own adventure.{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://docs.google.com/presentation/d/1ia5GdebBrFZoSvXqM5hk9kUXYGaSYFUq46m8Uz8I7E4/edit#slide=id.g75358c0721_0_16615 "
              className={styles.link}
            >
              This deck
            </a>{' '}
            is a great place to get inspired.
          </>
        ),
        descriptionClassName: 'marginRight73'
      },
      {
        decorationImgs: (
          <img src={module2Img} className={styles.module2Icon} alt="" />
        ),
        title: 'Self-reflect on your strengths.',
        description: handleGoDashboard => (
          <>
            Reflecting on your strengths can help you better understand yourself
            and your growth journey. Through it, you can set a course of action
            and discover new learning opportunities. Start{' '}
            <Link
              onClick={() => handleGoDashboard(DASHBOARD_URL_TAB.ME_NOW)}
              className={styles.link}
              to={DASHBOARD_WITH_TARGET + DASHBOARD_URL_TAB.ME_NOW}
            >
              self-reflecting
            </Link>{' '}
            now.
          </>
        )
      },
      {
        decorationImgs: (
          <img src={module3Img} className={styles.module3Icon} alt="" />
        ),
        title: 'Plan and reach your goals.',
        description: handleGoDashboard => (
          <>
            Setting goals increases your chance of realising your growth. It’s
            time to set up goals and{' '}
            <Link
              onClick={() => handleGoDashboard(DASHBOARD_URL_TAB.MY_PLAN)}
              className={styles.link}
              id="make-plan"
              to={DASHBOARD_WITH_TARGET + DASHBOARD_URL_TAB.MY_PLAN}
            >
              make a plan
            </Link>
            . Think about your gaps and plan actions to help you get there at
            your pace!
          </>
        )
      }
    ]
  },
  campusModule: {
    courseTitle: 'Learn how to apply Pathways to your needs.',
    wondering:
      'Wondering how to make best use of Pathways for your growth journey at Thoughtworks?',
    watching: 'Watch this Campus course for a short introduction to Pathways!',
    takeQuickCourseBtn: 'Take quick course',
    courseLength: 'Approximate length: 22min'
  },
  capabilityModule: {
    descriptionTitle:
      'Pathways is designed around the language of Thoughtworks’ Capability Model:',
    descriptionPart1: `<b>The Capability Model</b> is a common language to align our
          business. This globally common language identifies and defines the
          capabilities, competencies and archetypes that Thoughtworks needs to
          be able to do the work we want to do.`,
    descriptionPart2: `<b>Archetypes</b> will give you craft-specific ideas based on the
          current and future archetypes you want to play, and the
          <b>core capabilities</b> cover the <b>competencies</b> that everyone
          should be continuously developing regardless of role, grade or
          archetype. They are the special sauce of Thoughtworks and Core to all
          the work we do.`,
    descriptionPart3: `Competencies, Capabilities and Archetypes are all elements of our
          Global Capability Model. To understand more please visit the `
  },
  journeyModule: {
    title: 'Your growth is our journey.',
    description: `<b>Discover</b> growth paths, <b>self-reflect</b> about your strengths &
        <b>set a plan</b> to accomplish your learning priorities.`,
    selfReflectBtn: 'Start self-reflecting',
    tellMeMore: 'Tell me more!'
  }
};
