import React from 'react';
import styles from './index.module.css';
import clsx from 'clsx';
import pencil from '../../assets/Icons/pencil.svg';
import { onKeyDown } from '../../utils/onKeyDown';

export const CapabilityItem = ({ name, value, label, onClick }) => {
  const progress = () => {
    const circleAndLine = checkedScore => {
      return (
        <div className={styles.progressItem} key={checkedScore}>
          <div
            className={clsx(styles.line, {
              [styles.checked]: value >= checkedScore
            })}
          />
          <div
            className={clsx(styles.circle, {
              [styles.checked]: value >= checkedScore
            })}
            aria-label="value"
          />
        </div>
      );
    };

    return (
      <div className={styles.progress}>
        <div className={styles.slider}>
          <div
            className={clsx(styles.circle, {
              [styles.checked]: value >= 1
            })}
            aria-label="value"
          />
          {[2, 3, 4, 5].map(item => circleAndLine(item))}
        </div>
        <div className={styles.label} aria-label="label">
          {label}
        </div>
      </div>
    );
  };
  return (
    <div className={styles.capabilityItem}>
      <div className={styles.itemInfo}>
        <div className={styles.name}>
          <div className={styles.ellipsis} aria-label="name" tabIndex={0}>
            {name}
          </div>
        </div>
        {progress()}
      </div>
      <div
        className={styles.edit}
        onClick={onClick}
        aria-label="edit"
        onKeyDown={e => onKeyDown(e, onClick)}
      >
        <img className={styles.pencil} src={pencil} alt="pencil icon" />
        Edit
      </div>
    </div>
  );
};
