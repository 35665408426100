import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { InputBase, InputLabel } from '@mui/material';
import globalStyles from '../../globalStyles';
import styles from './index.module.css';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import PencilWithLineIcon from '../../components/Common/SvgIcons/PencilWithLineIcon';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';

export const CustomInputBase = styled(InputBase)({
  boxShadow: 'none'
});
export const CustomDivider = styled(Divider)({
  border: 'none'
});
export const Selector = styled(Select)({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  width: 'auto',
  minWidth: '300px',
  height: '50px',
  padding: '0',
  boxSizing: 'border-box',
  borderRadius: 6,
  color: globalStyles.grey80,
  backgroundColor: globalStyles.white,
  textAlign: 'left',
  lineHeight: '50px',
  boxShadow: 'none',
  '&:focus': {
    border: '1px solid #C2C5D0'
  },
  '& .MuiSelect-select': {
    fontWeight: '700',
    padding: 0,
    '&:focus': {
      borderRadius: 6,
      outline: 'none',
      outlineOffset: '1px'
    },
    '&.MuiInputBase-input': {
      boxSizing: 'border-box',
      border: '1px solid #E1E2E3',
      width: '100%',
      height: '100%',
      paddingLeft: '20px',
      paddingRight: '70px !important'
    }
  }
});

export const CustomMenuItem = styled(MenuItem)({
  height: '46px',
  '&.Mui-selected': {
    fontWeight: '700',
    lineHeight: '34px',
    backgroundColor: `#93E7F2 !important`
  }
});

export const CustomListItemIcon = styled(ListItemIcon)({
  minWidth: '26px'
});
const MenuProps = props => ({
  PaperProps: {
    style: {
      marginLeft: props.menuStyleName && props.value === -1 ? 44 : 0,
      maxHeight: 496
    }
  }
});
export const CustomInputLabel = styled(InputLabel)({
  position: 'absolute',
  top: '-12px',
  left: '10px',
  zIndex: '2',
  padding: '0 4px',
  fontSize: '14px',
  color: globalStyles.black,
  fontWeight: '400',
  lineHeight: 1.5
});
const IconComponent = () => {
  return (
    <div className={styles.iconElement}>
      <div className={styles.iconWrapper}>
        <ArrowDropDownIcon className={styles.icon} />
      </div>
    </div>
  );
};

const ArchetypesSelector = ({
  value,
  disabled,
  options,
  onChange,
  defaultOption,
  menuStyleName,
  myOwnGoalOption,
  isDisplayErrorMsg
}) => {
  const [boxShadow, setBoxShadow] = useState(false);
  const onMouseDown = () => {
    setBoxShadow(true);
  };

  useEffect(() => {
    const listener = keyEvent => {
      if (keyEvent.key === 'Tab') {
        setBoxShadow(false);
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  });

  return (
    <div className={styles.selectorWrapper}>
      {value !== -1 && (
        <CustomInputLabel id="selector-label">
          <div className={styles.selectorLabel}>
            <span>{defaultOption}</span>
          </div>
        </CustomInputLabel>
      )}
      <Selector
        labelId="selector-label"
        className={clsx({
          [styles.noBoxShadow]: boxShadow,
          [styles.disabledSelect]: disabled
        })}
        value={value}
        onChange={e => {
          onChange(e.target.value);
        }}
        disableunderline={'true'}
        native={false}
        input={<CustomInputBase />}
        IconComponent={IconComponent}
        data-cy="dropDownListButton"
        onMouseDown={onMouseDown}
        disabled={disabled}
        MenuProps={MenuProps({ menuStyleName, value })}
      >
        {defaultOption && (
          <CustomMenuItem value={-1} data-cy="menuItem">
            <span
              style={{
                fontWeight: '400',
                color: isDisplayErrorMsg && globalStyles.errorRed
              }}
            >
              {defaultOption}
            </span>
          </CustomMenuItem>
        )}
        {options.map((option, index) => {
          return (
            <CustomMenuItem key={index} value={index} data-cy="menuItem">
              {option}
            </CustomMenuItem>
          );
        })}
        {myOwnGoalOption && <CustomDivider className={styles.optionDivider} />}
        {myOwnGoalOption && (
          <CustomMenuItem value={options.length}>
            <span className={styles.myOwnGoalOptionText}>
              {myOwnGoalOption}
            </span>
            <CustomListItemIcon>
              <div className={styles.pencilContent}>
                <PencilWithLineIcon className={styles.pencilIconSize} />
              </div>
            </CustomListItemIcon>
          </CustomMenuItem>
        )}
      </Selector>
    </div>
  );
};
ArchetypesSelector.propTypes = {
  defaultOption: PropTypes.any,
  value: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  menuStyle: PropTypes.any,
  myOwnGoalOption: PropTypes.any,
  isDisplayErrorMsg: PropTypes.bool
};

export default ArchetypesSelector;
