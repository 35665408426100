import React from 'react';
import styles from './index.module.css';
import { Link } from 'react-router-dom';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PencilIcon from '../../components/Common/SvgIcons/PencilIcon';
import InformationIcon from '../../components/Common/SvgIcons/Information';
export const DashboardSectionHeader = ({
  title,
  setOpen,
  ariaDes,
  selectTargetPath,
  editTargetPath
}) => {
  return (
    <div className={styles.header}>
      <div className={styles.title}>
        <div className={styles.contentTitle}>
          <span>{title}</span>
          <span className={styles.iconSpanInner} tabIndex="-1">
            <InformationIcon
              className={styles.informationIcon}
              onClick={setOpen}
            />
          </span>
        </div>
      </div>
      <div className={styles.iconWrapper}>
        <Link
          className={styles.add}
          to={selectTargetPath}
          aria-label={'Explore new' + ariaDes}
        >
          <AddCircleOutlineIcon />
          Add new
        </Link>
        <Link
          to={{
            pathname: editTargetPath && editTargetPath.path,
            state: {
              value: editTargetPath && editTargetPath.value,
              haveEditFlag: true
            }
          }}
          className={styles.edit}
          aria-label={'Edit my reflections on ' + ariaDes}
        >
          <PencilIcon />
          Edit all
        </Link>
      </div>
    </div>
  );
};
