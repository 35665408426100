export const featureComponentsDes = {
  competencyAndArchetypeTipModal: {
    title: 'Archetypes, Competencies and Skills',
    tipModalInfo: [
      {
        h: 'What are Competencies, Archetypes and Skills?',
        p: `<ul>
              <li><b>Competencies</b> are a combination of the knowledge,
                  behavior and abilities that enable Thoughtworkers to deliver a
                  particular archetype or set of capabilities. They bring
                  evidence to what Thoughtworkers have experience in and/or want
                  to grow more in the future.
              </li>
              <li>
                  <b>Archetypes</b> focus on the craft of what someone
                  does, they highlight the most focused-on competencies and the
                  level of depth that we most frequently find.
              </li>
              <li>
                  <b>Skills</b> are knowledge you put to use at work in
                  order to achieve your project’s goals (for example languages
                  you can speak or programming languages you can code in).
              </li>
            </ul>`
      },
      {
        h: 'Should I explore Archetypes, Competencies and Skills?',
        p:
          'Yes! Together these elements give a rounded picture of the archetypes, competencies and skills you have now and' +
          ' want to develop next and can give you plenty of ideas for what to focus on for your growth.'
      },
      {
        p: ` <b>Remember</b>&nbsp; that all these are a guide and should be
                seen as an inspiration. Everyone comes with their own set of
                experiences, competencies and different paths, which is totally
                OK. We do not expect people to fit neatly into Archetype shaped
                boxes. Start small, and find some areas to focus on, let it
                inspire you, not define you.`
      }
    ],
    takeQuickTour: {
      link: 'Take a quick tour',
      des:
        ' of the Pathways interface to learn how to make best use of this app!'
    },
    headerInfo: {
      title: `<b>Archetypes and Competencies</b> are elements of our Global
            Capability Model.`,
      description: 'To understand more please visit the Reference Guide'
    }
  },
  reflectItem: {
    competency: {
      removeArrowTooltip: 'Remove competency from reflection list',
      notification: {
        title: 'Are you sure you want to remove this competency?',
        content:
          'The competency will be removed from your Growth canvas. You can add it again later.',
        confirmation: 'Remove competency'
      }
    },
    skill: {
      removeArrowTooltip: 'Remove skill from reflection list',
      notification: {
        title: 'Are you sure you want to remove this skill?',
        content:
          'Your practice level and learning priority information will be lost.<br/>The skill will be also removed from your profile.',
        confirmation: 'Remove skill'
      }
    }
  }
};

export const specialCharactersPattern = /[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]/im;
