import React, { useEffect, useReducer, useState } from 'react';
import PathwaysRouter from './pages/Router';
import { profileReducer } from './reducer/profile';
import UserContext from './contexts/UserContext';
import { fetchUser } from './actions/profile';
import { get, isNil } from 'lodash';
import 'setimmediate';

import styles from './App.module.css';
import PathwayUpdates, {
  SKILL_RELEASED
} from './components/Common/Modal/PathwayUpdates/PathwayUpdates';
import { useOktaAuth } from '@okta/okta-react';
import { addInterceptors } from './api/util/ajax';
import { fetchPeopleSkills } from './api/domain/skill';
import { reflectedSkillsGroupByPriority } from './domain/skill';
import Loader from './nonFeatureComponents/Loader';

export const popupOrder = [SKILL_RELEASED];

const App = () => {
  const { oktaAuth } = useOktaAuth();
  const [state, dispatch] = useReducer(profileReducer, {});
  const [shouldShowUpdateModal, setShouldShowUpdateModal] = useState(false);
  const [type, setType] = useState(null);
  const [skillsNow, setSkillsNow] = useState(null);
  const [popups, setPopups] = useState({});

  useEffect(() => {
    const authCheck = async () => await addInterceptors(oktaAuth);
    authCheck();
  }, [oktaAuth]);

  useEffect(() => {
    fetchUser(dispatch).then();
  }, []);

  useEffect(() => {
    if (!isNil(state.user) && !isNil(state.user.showSkillModal)) {
      fetchPeopleSkills({ reflected: true }).then(result => {
        const skills = reflectedSkillsGroupByPriority(result);
        const { strength } = skills;
        const showSkillModal =
          get(state, 'user.showSkillModal') &&
          !get(state, 'user.showOnboardingTour');
        setPopups(currentPopups => ({
          ...currentPopups,
          [SKILL_RELEASED]: Boolean(showSkillModal)
        }));
        if (showSkillModal) {
          setSkillsNow(strength);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.user?.showSkillModal]);

  useEffect(() => {
    if (Object.keys(popups).length < popupOrder.length) {
      return;
    }
    const currentShown = popupOrder.find(popup => popups[popup]);
    if (currentShown) {
      setType(currentShown);
      setShouldShowUpdateModal(true);
    }
  }, [popups]);

  const onClose = (showNext = true) => {
    setShouldShowUpdateModal(false);
    if (showNext) {
      setPopups(currentPopups => ({ ...currentPopups, [type]: false }));
    } else {
      setPopups(
        popupOrder.reduce(
          (allPopups, type) => ({ ...allPopups, [type]: false }),
          {}
        )
      );
    }
  };

  return state?.isAuthenticated ? (
    <UserContext.Provider value={{ state, dispatch }}>
      <div className={styles.body}>
        <PathwaysRouter />
        <PathwayUpdates
          key={type}
          type={type}
          skills={skillsNow}
          open={shouldShowUpdateModal}
          onClose={onClose}
          user={state.user}
        />
      </div>
    </UserContext.Provider>
  ) : (
    <Loader />
  );
};

export default App;
