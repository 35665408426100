import * as React from 'react';

export default function Close() {
  return (
    <svg
      width="64px"
      height="64px"
      viewBox="0 0 64 64"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="MVP-Modal-Core-Capabilities-Copy-2"
          transform="translate(-1067.000000, -300.000000)"
        >
          <rect
            id="Rectangle"
            fill="none"
            opacity="0.433849516"
            x="0"
            y="0"
            width="1440"
            height="1537"
          ></rect>
          <rect
            id="Rectangle"
            fill="none"
            x="309"
            y="300"
            width="822"
            height="298"
            rx="7"
          ></rect>
          <g id="Group" transform="translate(1067.000000, 300.000000)">
            <circle
              id="Oval"
              fill="#e1e2e3"
              transform="translate(32.000000, 32.000000) rotate(-315.000000) translate(-32.000000, -32.000000) "
              cx="32"
              cy="32"
              r="22"
            ></circle>
            <g
              id="32339"
              transform="translate(32.000000, 32.000000) rotate(-315.000000) translate(-32.000000, -32.000000) translate(22.000000, 22.000000)"
              fill="#000000"
              fillRule="nonzero"
            >
              <path
                d="M20,10.0002394 C20,10.9669188 19.2165691,11.7503292 18.249342,11.7503292 L11.7498961,11.7503292 L11.7498961,18.2499101 C11.7498961,19.2168508 10.9662039,20.0002612 9.99962991,20 C9.51625583,20 9.07873284,19.8044086 8.76230374,19.4878136 C8.44548275,19.1707832 8.24962502,18.7334893 8.24984274,18.2503891 L8.24958148,11.750068 L1.74935181,11.750068 C1.26628253,11.750068 0.829107882,11.5544766 0.512025632,11.2374027 C0.195727161,10.9211125 0,10.4836009 0,10.0002394 C-0.000261194425,9.03373425 0.78316971,8.25014965 1.75026622,8.25014965 L8.24984274,8.25014965 L8.24984274,1.75048168 C8.24984274,0.78367169 9.03370908,0 10.0003702,0 C10.9666829,0.000261252925 11.7498525,0.783149184 11.750375,1.74982855 L11.750375,8.25014965 L18.2506047,8.25014965 C19.2170916,8.25067216 19.999521,9.03373425 20,10.0002394 Z"
                id="Path"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
