import React, {
  useRef,
  useState,
  forwardRef,
  useImperativeHandle
} from 'react';
import PropTypes from 'prop-types';
import orderBy from 'lodash/orderBy';
import cloneDeep from 'lodash/cloneDeep';

import GoalInformationForm from '../GoalInformation';
import { GoalInputForm } from '../GoalGapAndAction';

import { updateUserGoal } from '../../../../actions/profile';
import { useUserContext } from '../../../../contexts/UserContext';
import { formatToEN } from '../../../../utils/datetimeFormat';

import FocusIcon from '../../../../assets/Icons/focus-3-line.png';
import HistoryIcon from '../../../../assets/Icons/history-fill.png';

import styles from './index.module.css';
import myPlanCardStyles from '../index.module.css';
import { RelatedContributionForGoal } from '../RelatedContributionForGoal';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { EDIT_GOAL_FLOW } from '../../../../constants/matomo';
import { trigSurvey } from '../../../../support/qualarooTrigger';
import { CREATE_GOAL } from '../../../../constants/qualaroo';
import { GOAL_TYPE } from '../../../../constants/goal';
import { dashboardDes } from '../../language';
import GoalModal from '../GoalModal';

const DetailsModal = forwardRef(
  (
    {
      handleOpenAccomplishModal,
      isAccomplish,
      goal,
      justDisplay,
      disableBackdropClose
    },
    ref
  ) => {
    const { dispatch } = useUserContext();
    const [isModalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const { trackEvent } = useMatomo();

    const informationRef = useRef(null);
    const gapRef = useRef(null);
    const actionRef = useRef(null);
    const categoryRef = useRef(null);
    let selectedCompetency;
    if (goal.goalType !== GOAL_TYPE.CUSTOM) {
      selectedCompetency = {
        name: goal.name,
        practiceLevel: goal.practiceLevel
      };
    }

    useImperativeHandle(ref, () => ({
      open: setModalOpen
    }));

    const handleMarkAccomplishedClick = () => {
      setModalOpen(false);
      handleOpenAccomplishModal();
    };

    const onSave = () => {
      if (
        gapRef.current.isInValidate() ||
        actionRef.current.isInValidate() ||
        informationRef.current.isInValidate() ||
        (goal.goalType === GOAL_TYPE.CUSTOM &&
          categoryRef.current.isInValidate())
      ) {
        return;
      }
      setLoading(true);
      let updateGoalBody = {
        goalType: GOAL_TYPE.UNDER_ME_NEXT_COMPETENCY,
        name: goal.name,
        period: informationRef.current.getValue().period,
        description: informationRef.current.getValue().description,
        gaps: gapRef.current.getValue(),
        actions: actionRef.current.getValue()
      };
      if (goal.goalType === GOAL_TYPE.CUSTOM) {
        updateGoalBody['name'] =
          categoryRef.current.getValue().length > 0
            ? cloneDeep(categoryRef.current.getValue())
                .reverse()
                .join(',')
            : dashboardDes.myPlanCardDes.goalInformation.ownGoalViewContent;
        updateGoalBody['goalType'] = GOAL_TYPE.CUSTOM;
        updateGoalBody[
          'contributionsCategories'
        ] = categoryRef.current.getValue();
      }
      updateUserGoal(dispatch)(goal.id, updateGoalBody).then(() => {
        trigSurvey(CREATE_GOAL);
        if (
          goal.actions.length === 0 &&
          actionRef.current.getValue().length > 0
        ) {
          trackEvent({
            category: EDIT_GOAL_FLOW.category,
            action: EDIT_GOAL_FLOW.action.ADDED_ACTIONS
          });
        }
        setLoading(false);
        setModalOpen(false);
      });
    };

    return (
      <GoalModal
        open={isModalOpen}
        close={() => setModalOpen(false)}
        title={dashboardDes.myPlanCardDes.goalDetailsButton}
        confirmText="Save"
        onConfirm={onSave}
        loading={loading}
        isShowAccomplish={true}
        isAccomplish={isAccomplish}
        handleOpenAccomplishModal={handleMarkAccomplishedClick}
        disableBackdropClose={disableBackdropClose}
      >
        <div>
          <div className={styles.timeInfo} data-cy="goalCreateUpdateTime">
            <div className={styles.createTimeInfo}>
              <img src={FocusIcon} alt="" />
              Created at {formatToEN(goal.createdAt)}
            </div>
            <div className={styles.updateTimeInfo}>
              <img src={HistoryIcon} alt="" />
              Last updated at {formatToEN(goal.updatedAt)}
            </div>
          </div>
          <div className={myPlanCardStyles.container}>
            <GoalInformationForm
              selectedCompetency={selectedCompetency}
              goal={goal}
              disabled={goal.isAccomplished}
              ref={informationRef}
              justDisplay={justDisplay}
              isMyOwnGoal={goal.goalType === GOAL_TYPE.CUSTOM}
            />
            <div className={myPlanCardStyles.line} />
          </div>
          <div className={myPlanCardStyles.container}>
            <GoalInputForm
              title={dashboardDes.myPlanCardDes.myGaps.title}
              des={dashboardDes.myPlanCardDes.myGaps.des}
              isGap={true}
              value={goal.gaps}
              disableEdit={goal.isAccomplished}
              ref={gapRef}
            />
          </div>
          <div className={myPlanCardStyles.container}>
            <div className={myPlanCardStyles.connectedLine} />
            <GoalInputForm
              title={dashboardDes.myPlanCardDes.actions.title}
              isGap={false}
              des={dashboardDes.myPlanCardDes.actions.des}
              disableEdit={goal.isAccomplished}
              value={cloneDeep(orderBy(goal.actions, ['isDone'], ['asc']))}
              ref={actionRef}
            />
          </div>

          {goal.goalType === GOAL_TYPE.CUSTOM ? (
            <div className={myPlanCardStyles.container}>
              <div className={myPlanCardStyles.connectedLine} />
              <GoalInputForm
                title={dashboardDes.myPlanCardDes.myOwnGoal.title}
                isGap={false}
                isCategory={true}
                des={dashboardDes.myPlanCardDes.myOwnGoal.des}
                disableEdit={goal.isAccomplished}
                value={goal.contributions.categories}
                ref={categoryRef}
              />
            </div>
          ) : (
            <div className={myPlanCardStyles.container}>
              <div className={myPlanCardStyles.connectedLine} />
              <RelatedContributionForGoal
                archetypes={goal.contributions.archetypes}
                coreCapabilities={goal.contributions.coreCapabilities}
              />
            </div>
          )}
        </div>
      </GoalModal>
    );
  }
);

DetailsModal.propTypes = {
  handleOpenAccomplishModal: PropTypes.func,
  isAccomplish: PropTypes.bool,
  goal: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    actions: PropTypes.array.isRequired,
    practiceLevel: PropTypes.number,
    period: PropTypes.number,
    gaps: PropTypes.array,
    isAccomplished: PropTypes.bool,
    createdAt: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
    contributions: PropTypes.shape({
      archetypes: PropTypes.array,
      coreCapabilities: PropTypes.array,
      categories: PropTypes.array
    }),
    goalType: PropTypes.string
  }),
  justDisplay: PropTypes.bool,
  disableBackdropClose: PropTypes.bool
};

export default DetailsModal;
