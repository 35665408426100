import React, { useMemo } from 'react';
import styles from './PDFHeader.module.css';
import Logo from '../../components/Common/SvgIcons/Logo';
import { useUserContext } from '../../contexts/UserContext';
import { formatToEN } from '../../utils/datetimeFormat';
import clsx from 'clsx';
import HistoryIcon from '../../components/Common/SvgIcons/HistoryIcon';
import ConversationsIcon from '../../assets/Icons/group-3.png';
import UserIcon from '../../assets/Icons/group-5.png';

const PDFHeader = () => {
  const { state } = useUserContext();
  const updateTime = useMemo(() => {
    if (!state?.user?.overallUpdatedAt) {
      return;
    }
    return formatToEN(state?.user?.overallUpdatedAt);
  }, [state]);
  const userFirstname = state?.user?.firstName ?? '';
  const userLastname = state?.user?.lastName ?? '';
  const role = state?.user?.role ?? '';
  const grade = state?.user?.grade ?? '';
  const email = state?.user?.email ?? '';
  return (
    <div className={styles.pdfHeader}>
      <div className={styles.left}>
        <div className={styles.headerLeft}>
          <Logo className={styles.pathwaysLogo} />
          <span className={styles.logoTitle}>Pathways</span>
        </div>
        <div className={styles.title}>My Growth canvas</div>
        <div
          className={clsx(styles.lastUpdateTime, !updateTime && styles.hidden)}
        >
          <HistoryIcon className={styles.historyIcon} />
          <div>Last update,</div>
          <span className={styles.lastUpdateTimeText}>{updateTime}</span>
        </div>
      </div>
      <div className={styles.middle}>
        <img
          className={styles.conversationsIcon}
          src={ConversationsIcon}
          alt=""
        />
        <div className={styles.middleDescription}>
          Use this canvas to drive growth conversations with your team lead,
          staffing person, mentor or anyone in from your support network.
        </div>
      </div>
      <div className={styles.right}>
        <img className={styles.conversationsIcon} src={UserIcon} alt="" />
        <div className={styles.user}>
          <div
            className={styles.userName}
          >{`${userFirstname} ${userLastname}`}</div>
          <div>{role}</div>
          <div>{grade}</div>
          <div>{email}</div>
        </div>
      </div>
    </div>
  );
};

export default PDFHeader;
