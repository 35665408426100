import React from 'react';
import clsx from 'clsx';
import { CommunityChatRooms } from '../../../../constants/community';

import styles from './index.module.css';
import googleChatIcon from '../../../../assets/Icons/Google_Hangouts_Chat.png';
import LinkIcon from '../../../../components/Common/SvgIcons/LinkIcon';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { LEARNING_COMMUNITY_CLICKS } from '../../../../constants/matomo';
import { Link } from 'react-router-dom';
import { dashboardDes } from '../../language';
import { GoogleChatLink } from '../../../../constants/links';

const Communities = () => {
  const { trackEvent } = useMatomo();
  const communitiesDes = dashboardDes.communities;
  const handleClickCommunity = (e, name, room) => {
    e.preventDefault();
    trackEvent({
      category: LEARNING_COMMUNITY_CLICKS.category,
      action: name
    });
    room && window.open(GoogleChatLink + `${room}`);
  };
  return (
    <div className={styles.communities}>
      <div className={styles.header}>
        <div className={styles.title}>
          <img src={googleChatIcon} alt="google chat" />
          {communitiesDes.title}
        </div>
        <div className={styles.subTitle}>{communitiesDes.subTitle}</div>
      </div>
      <div className={styles.chats}>
        {CommunityChatRooms.map(({ name, room }) => (
          <Link
            to=""
            role={room && 'link'}
            className={clsx(styles.chat, !room && styles.disabled)}
            key={name}
            onClick={e => handleClickCommunity(e, name, room)}
          >
            <p>
              <span>{name}</span>
              <span>{room ? '' : ' (Coming soon)'}</span>
            </p>
            {room && <LinkIcon className={styles.roomIcon} />}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Communities;
