import React from 'react';
import { SvgIcon } from '@mui/material';

const BadgeIcon = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 65 86">
      <path d="M32.5 20.48c17.95 0 32.5 14.667 32.5 32.76C65 71.333 50.45 86 32.5 86S0 71.333 0 53.24c0-18.093 14.55-32.76 32.5-32.76zm0 8.19c-13.462 0-24.375 11-24.375 24.57S19.038 77.81 32.5 77.81s24.375-11 24.375-24.57S45.962 28.67 32.5 28.67zm0 6.142l5.375 10.975 12.013 1.76-8.694 8.539 2.051 12.064L32.5 62.454l-10.745 5.692 2.051-12.06-8.694-8.543 12.013-1.76L32.5 34.812zM56.875.004V12.29l-5.537 4.66c-4.595-2.43-9.612-3.944-14.776-4.46V.005h20.313zM28.437 0v12.49c-5.162.514-10.177 2.027-14.77 4.455L8.124 12.29V.004L28.438 0z" />
    </SvgIcon>
  );
};

export default BadgeIcon;
