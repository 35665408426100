import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import styles from './index.module.css';
import * as React from 'react';
import PropTypes from 'prop-types';
import FormLabel from '@mui/material/FormLabel';
import globalStyles from '../../../../globalStyles';
import {
  BOTH_ME_NOW_AND_ME_NEXT,
  ME_NEXT,
  ME_NOW
} from '../../../../constants/archetype';
import { archetypesIntentionDes } from '../../language';
import * as DOMPurify from 'dompurify';
import { styled } from '@mui/material/styles';

const Form = styled(FormControl)({
  width: '100%',
  '&:focus': {
    outline: 'none'
  }
});

const Radios = styled(RadioGroup)({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: globalStyles.grey10,
  padding: '0 20px',
  boxSizing: 'border-box'
});

const RadiosLabel = styled(FormControlLabel)({
  display: 'flex',
  alignItems: 'flex-start'
});

const CustomerRadio = styled(Radio)({
  marginTop: '7px',
  '&.Mui-checked': {
    color: globalStyles.primaryGreen
  }
});

export const ArchetypesIntentionItem = ({ archetype, onChange }) => {
  const sanitizer = DOMPurify.sanitize;
  const stage = archetype.reflectedInformation?.stage;
  const {
    meNowLabel,
    meNextLabel,
    meNowAndMeNextLabel
  } = archetypesIntentionDes.archetypeIntentionItem;

  const handleChange = event => {
    const value = event.target.value === stage ? null : event.target.value;
    onChange({
      archetypeId: archetype.archetypeId,
      data: {
        reflectedInformation: {
          id: archetype.reflectedInformation.id,
          stage: value
        }
      }
    });
  };

  return (
    <section
      className={styles.archetypeCard}
      data-cy={`archetype:${archetype.identifier}`}
    >
      <Form component="fieldset">
        <FormLabel component="legend">
          <div className={styles.archetypeCardHeader}>
            <span>{archetype.archetypeName}</span>
          </div>
        </FormLabel>
        <Radios name={archetype.archetypeName} value={stage}>
          <RadiosLabel
            value={ME_NOW}
            control={
              <CustomerRadio onClick={handleChange} onKeyPress={handleChange} />
            }
            label={
              <div className={styles.radioLabel}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitizer(meNowLabel)
                  }}
                />
              </div>
            }
          />
          <RadiosLabel
            value={ME_NEXT}
            control={
              <CustomerRadio onClick={handleChange} onKeyPress={handleChange} />
            }
            label={
              <div className={styles.radioLabel}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitizer(meNextLabel)
                  }}
                />
              </div>
            }
          />
          <RadiosLabel
            value={BOTH_ME_NOW_AND_ME_NEXT}
            control={
              <CustomerRadio onClick={handleChange} onKeyPress={handleChange} />
            }
            label={
              <div className={styles.radioLabel}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitizer(meNowAndMeNextLabel)
                  }}
                />
              </div>
            }
          />
        </Radios>
      </Form>
    </section>
  );
};
ArchetypesIntentionItem.propTypes = {
  archetype: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};
export default ArchetypesIntentionItem;
