import { orderBy } from 'lodash';
import { Grid } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import styles from './index.module.css';
import { useHistory } from 'react-router-dom';
import { DASHBOARD, REFLECT_ARCHETYPES } from '../../constants/path';
import { useUserContext } from '../../contexts/UserContext';
import Logo from '../../components/Common/SvgIcons/Logo';
import { fetchReflectedArchetypes } from '../../actions/profile';
import {
  ALL_ARCHETYPES,
  archetypeSelectPageDes,
  DEFAULT_CATEGORY_INDEX
} from './language';
import FixedBar from '../../nonFeatureComponents/FixedBar';
import NavigationHeader from '../../nonFeatureComponents/NavigationHeader';
import StepperLine from '../../nonFeatureComponents/StepperLine';
import TipTrigger from '../../nonFeatureComponents/TipTrigger';
import Popup from '../../nonFeatureComponents/Popup';
import ArchetypesSelector from '../../feature/ArchetypesSelector';
import { ARCHETYPE_STEPS } from '../../constants/archetype';
import ArchetypeRole from './components/ArchetypeRole';
import { styled } from '@mui/material/styles';

const ArchetypesSelectPage = () => {
  const { dispatch } = useUserContext();
  const history = useHistory();
  const [tipModalOpened, setTipModalOpened] = useState(false);
  const [archetypeFamilies, setArchetypeFamilies] = useState([]);
  const [filterIndex, setFilterIndex] = useState(DEFAULT_CATEGORY_INDEX);

  const StyleGrid = styled(Grid)({
    '&.MuiGrid-item': {
      paddingTop: '8px'
    }
  });

  const availableArchetypeFamilies = useMemo(() => {
    let newArchetypeFamilies = archetypeFamilies.filter(af =>
      af.roles?.some(r => r.archetypes?.length > 0)
    );
    newArchetypeFamilies.unshift(ALL_ARCHETYPES);
    return newArchetypeFamilies;
  }, [archetypeFamilies]);

  const isDefaultCategory = i => i === DEFAULT_CATEGORY_INDEX;

  const currentList = !isDefaultCategory(filterIndex)
    ? [availableArchetypeFamilies[filterIndex]]
    : availableArchetypeFamilies;

  const backToDashboard = () => {
    history.push(DASHBOARD);
  };

  useEffect(() => {
    fetchReflectedArchetypes(dispatch).then(res => {
      setArchetypeFamilies(res);
    });
  }, [dispatch]);

  const getRoles = () => {
    return (
      <>
        {currentList.map(archetypeFamily => {
          if (archetypeFamily === ALL_ARCHETYPES) {
            return null;
          }
          const roles = orderBy(
            archetypeFamily.roles.filter(role => role.archetypes.length > 0),
            ['category']
          );
          return (
            <StyleGrid
              key={archetypeFamily.category}
              item
              xs={12}
              sm={isDefaultCategory(filterIndex) ? 6 : 12}
              data-cy="familyItem"
            >
              <div className={styles.gridItem}>
                {isDefaultCategory(filterIndex) && (
                  <header className={styles.gridItemTitle}>
                    {archetypeFamily.category}
                  </header>
                )}
                <div className={styles.rolesWrapper}>
                  <Grid spacing={2} container>
                    {orderBy(roles, 'roleName').map(role => {
                      return (
                        <ArchetypeRole
                          key={role.roleName}
                          single={roles.length === 1}
                          role={role}
                          largeItem={!isDefaultCategory(filterIndex)}
                        />
                      );
                    })}
                  </Grid>
                </div>
              </div>
            </StyleGrid>
          );
        })}
      </>
    );
  };

  return (
    <>
      <FixedBar>
        <NavigationHeader
          onBackTo={backToDashboard}
          disabled={false}
          onGoTo={() => history.push(REFLECT_ARCHETYPES)}
          backToTitle={archetypeSelectPageDes.backToTitle}
          goToTitle={archetypeSelectPageDes.goToTitle}
          showGoToIcon
        >
          <StepperLine activeStep={0} steps={ARCHETYPE_STEPS} />
        </NavigationHeader>
      </FixedBar>
      <div className={styles.page}>
        <div className={styles.titleWrapper}>
          <div className={styles.title}>{archetypeSelectPageDes.title}</div>
          <div>{archetypeSelectPageDes.selectArchetype}</div>
        </div>
        <div className={styles.filterBar}>
          <div className={styles.filterWrapper} data-cy="archetypeFilter">
            <ArchetypesSelector
              options={availableArchetypeFamilies.map(
                archetypeFamily => archetypeFamily.category
              )}
              value={filterIndex}
              onChange={e => setFilterIndex(e)}
            />
          </div>
          <div className={styles.tip}>
            <TipTrigger
              content={archetypeSelectPageDes.noSuitableArchetype}
              onOpen={setTipModalOpened}
              isOpen={tipModalOpened}
            />
          </div>
        </div>
        <Grid spacing={2} container>
          {getRoles()}
        </Grid>
      </div>
      <Popup
        open={tipModalOpened}
        close={() => setTipModalOpened(false)}
        title={archetypeSelectPageDes.noSuitableArchetype}
        image={Logo}
        desc={archetypeSelectPageDes.popup.tipModalInfo}
        modalClassName={archetypeSelectPageDes.popup.modalClassName}
      />
    </>
  );
};
ArchetypesSelectPage.propTypes = {};
export default ArchetypesSelectPage;
