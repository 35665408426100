import React from 'react';
import { coreCapabilityColorsMap } from '../../components/Utils/competencyUtils';
import styles from './index.module.css';
import searchCompetenciesEmpty from '../../assets/Icons/searchCompetenciesEmpty.png';
export const DEFAULT_GROUP_NAME = 'All Competencies';
export const INITIAL_FILTER_NAME = 'See all';
export const CORE_COMPETENCIES = 'Core Competencies';
export const SearchBarConstants = {
  name: 'competency',
  placeholder: 'Search by competency name',
  ClassName: 'CompetenciesSearchBar',
  searchEmptyIcon: searchCompetenciesEmpty
};
export const specialCharactersPattern = /[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]/im;

export const competenciesSelectPageDes = {
  navigationHeader: {
    backToTitle: 'Cancel',
    goToTitle: (
      <span>
        <u>N</u>ow, let&#39;s reflect
      </span>
    )
  },
  popup: {
    title: 'Core Competencies',
    tipModalInfo: [
      {
        p: `Core Competencies are important to everyone&apos;s role at
          Thoughtworks, irrelevant of department or function. There are&nbsp;
          <b>4 different categories</b> of&nbsp;
          <b>Core Competency: </b>&nbsp;
          <b>Consulting, Cultivation, Impact </b>
          and&nbsp;<b>Leadership</b>. Each category relates
          directly to the same Core Capability e.g. the Consulting competencies
          enable us to provide Consulting as a Business Capability. Some
          competencies appear in multiple categories and support multiple
          Capabilities.`
      },
      {
        p: `
              <i class="${styles.pointer}" style= "background-color: ${coreCapabilityColorsMap['consulting']}" ></i>
              <b>Consulting:</b> Cooperate with clients and guiding them through
              the process of delivery.
          `
      },
      {
        p: `<i class="${styles.pointer}" style= "background-color: ${coreCapabilityColorsMap['cultivation']}" ></i>
             <b>Cultivation:</b> Act in our belief that “we succeed by growing people who fulfill our vision".&nbsp;
             <p>Support, influence and encourage the growth and development of the individual and collective.&nbsp;</p>
             <p>Foster a working environment that supports a culture of cultivation.<br></p>
             <a href="https://sites.google.com/thoughtworks.com/empowering-twer-growth/cultivation-culture?authuser=1">For more information see this deck</a>
`
      },
      {
        p: `<i class="${styles.pointer}" style="background-color: ${coreCapabilityColorsMap['leadership']}"></i>
              <b>Leadership:</b> Leaders are role models of our cultural values. our purpose and lenses are at the heart of everything they do. Leaders protect our uniqueness whilst helping TW evolve.&nbsp;
              View the <u>Leadership Expectations deck <a href="https://docs.google.com/presentation/d/1vOF77JVF6VEl50MRrgIUFLOQUvksjkBXIwkLTh0QT_4/edit#slide=id.g78f9d6b579_2_36">here</a></u> for more information.<br>
        `
      },
      {
        p: `<i class="${styles.pointer}" style= "background-color: ${coreCapabilityColorsMap['impact']}" ></i>
              <b>Impact:</b> Amplifiers/differentiators critical to Thoughtworks' competitive edge.`
      }
    ]
  },
  coreCompetenciesExplanation: {
    title: 'First, Select Competencies',
    description:
      'Think about your work experience and also the types of competencies you would like to develop next.' +
      ' You can choose as many competencies as you like.'
  },
  confirmationModal: {
    confirmButtonText: 'Yes, cancel changes',
    cancelButtonText: 'No, back to self-reflection',
    title: 'Cancelling changes',
    content: (
      <>
        <div>Any changes you made on your competency</div>
        <div>assessment will be lost.</div>
        <div>Are you sure you want to cancel?</div>
      </>
    )
  }
};
