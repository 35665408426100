import React, { useEffect, useState, useMemo } from 'react';
import { useUserContext } from '../../contexts/UserContext';
import {
  fetchUser,
  fetchUserGoals,
  fetchReflectedArchetypes,
  fetchReflectedCompetencies
} from '../../actions/profile';
import { fetchReflectedSkills } from '../../actions/profile';
import styles from './index.module.css';
import PDFModal from '../../createPDF/PDFModal';
import { cloneDeep, filter, orderBy, reject } from 'lodash';
import clsx from 'clsx';
import { getUrlParam } from '../../components/Utils/CommonUtil';
import PropTypes from 'prop-types';
import {
  ME_NEXT,
  ME_NOW,
  BOTH_ME_NOW_AND_ME_NEXT,
  ME_NOW_TAB,
  ME_NEXT_TAB,
  MY_PLAN_TAB,
  DEFAULT_TAB,
  DASHBOARD_TAB_URL_ENUM
} from '../../constants/dashborad';
import { USER_GRADE } from '../../constants/user';
import { groupCompetenciesByStage } from '../../domain/competency';
import { DASHBOARD_WITH_TARGET } from '../../constants/path';
import { dashboardDes } from './language';
import ConnectSummit from './ConnectSummit';
import ToolsBar from './ToolsBar';
import MyPlanCard from './MyPlanCard';
import KnowPathwaysModal from './components/KnowPathwaysModal';
import MeNextCard from './MeNextCard';
import MeNowCard from './MeNowCard';

const DEFAULT_ABILITIES = {
  strength: [],
  prioritized: []
};

// request profile when rerender dashboard
let isNeedUpdateProfile = false;

const groupSkillsByStage = skills => {
  const prioritized = filter(skills, [
    'reflectedInformation.stage',
    BOTH_ME_NOW_AND_ME_NEXT
  ]);
  return {
    strength: skills,
    prioritized: prioritized
  };
};

const DashboardPage = props => {
  const {
    state: { user },
    dispatch
  } = useUserContext();
  const isGraduate = user.grade === USER_GRADE.GRADUATE;

  const [tabIndex, setTabIndex] = useState(DEFAULT_TAB);
  const [interests, setInterests] = useState([]);
  const [capableArchetypes, setCapableArchetypes] = useState([]);
  const [incapableArchetypes, setIncapableArchetypes] = useState([]);

  const [isLoadErr, setIsLoadErr] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [modalDisplay, setModalDisplay] = useState(false);
  const [knowPathwaysModalOpened, setKnowPathwaysModalOpened] = useState(false);
  const handleSetPDFDisplay = () => setModalDisplay(true);
  const closeKnowPathwaysModalOpened = () => setKnowPathwaysModalOpened(false);

  const userSkills = user.reflectedSkills
    ? groupSkillsByStage(user.reflectedSkills)
    : DEFAULT_ABILITIES;
  const userCompetencies = user.reflectedCompetencies
    ? groupCompetenciesByStage(user.reflectedCompetencies)
    : DEFAULT_ABILITIES;

  const levelOnlyCompetencies = user.reflectedCompetencies
    ? orderBy(
        filter(
          reject(user.reflectedCompetencies, [
            'reflectedInformation.practiceLevel',
            0
          ]),
          ['reflectedInformation.isPracticeLevel', false]
        ),
        ['name'],
        ['asc']
      )
    : [];

  const goals = useMemo(() => {
    return user.goals || [];
  }, [user]);

  const formatGoals = useMemo(() => {
    return orderBy(
      goals?.filter(goal => !goal.isAccomplished),
      ['createdAt'],
      ['desc']
    );
  }, [goals]);

  const handleTabIndexChange = index => {
    window.history.replaceState(
      {},
      '',
      DASHBOARD_WITH_TARGET + Object.keys(DASHBOARD_TAB_URL_ENUM)[index]
    );
    setTabIndex(index);
  };

  const formatData = (userCompetencies, scoreOnlyCompetencies) => {
    const competencies = cloneDeep(
      userCompetencies.concat(scoreOnlyCompetencies)
    );
    return competencies
      .map(item => {
        item.coreCapabilities = ['pdf'];
        return item;
      })
      .sort();
  };

  useEffect(() => {
    if (user.reflectedArchetypes && user.reflectedArchetypes.length > 0) {
      const curCapableArchetypes = user.reflectedArchetypes.filter(
        archetype =>
          archetype.reflectedInformation?.stage === ME_NOW ||
          archetype.reflectedInformation?.stage === BOTH_ME_NOW_AND_ME_NEXT
      );
      setCapableArchetypes(curCapableArchetypes);
      const curIncapableArchetypes = user.reflectedArchetypes.filter(
        archetype =>
          archetype.reflectedInformation?.stage === ME_NEXT ||
          archetype.reflectedInformation?.stage === BOTH_ME_NOW_AND_ME_NEXT
      );
      setIncapableArchetypes(curIncapableArchetypes);
    } else {
      setCapableArchetypes([]);
      setIncapableArchetypes([]);
    }
  }, [user.reflectedArchetypes]);

  useEffect(() => {
    setInterests(user.interests);
  }, [user.interests]);

  useEffect(() => {
    fetchUserGoals(dispatch);
  }, [dispatch]);

  useEffect(() => {
    setIsLoading(true);
    setIsLoadErr(false);
    Promise.all([
      fetchReflectedCompetencies(dispatch),
      fetchReflectedSkills(dispatch),
      fetchReflectedArchetypes(dispatch)
    ])
      .catch(() => {
        setIsLoadErr(true);
      })
      .finally(() => {
        setKnowPathwaysModalOpened(user.showOnboardingTour);
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (!isNeedUpdateProfile) {
      isNeedUpdateProfile = true;
      return;
    }
    fetchUser(dispatch);
    window.scrollTo(0, 0);
  }, [dispatch]);

  useEffect(() => {
    const target = getUrlParam('target');
    handleTabIndexChange(DASHBOARD_TAB_URL_ENUM[target] || 0);
  }, []);

  return (
    <div
      className={clsx(
        styles.dashboard,
        props.isGuideShowState && styles.UnClickDashboard
      )}
    >
      <ConnectSummit />
      <ToolsBar
        value={tabIndex}
        onChange={handleTabIndexChange}
        options={dashboardDes.index.toolBarOptions}
        handleSetPDFDisplay={handleSetPDFDisplay}
      />
      {tabIndex === ME_NOW_TAB && (
        <MeNowCard
          archetypes={capableArchetypes}
          isLoadErr={isLoadErr}
          isLoading={isLoading}
          competencies={userCompetencies.strength}
          skills={userSkills.strength}
          isGraduate={isGraduate}
        />
      )}
      {tabIndex === ME_NEXT_TAB && (
        <MeNextCard
          archetypes={incapableArchetypes}
          isLoadErr={isLoadErr}
          isLoading={isLoading}
          competencies={userCompetencies.prioritized}
          skills={userSkills.prioritized}
          isGraduate={isGraduate}
        />
      )}
      {tabIndex === MY_PLAN_TAB && <MyPlanCard />}
      <div className={styles.pdfModal}>
        <PDFModal
          open={modalDisplay}
          onClose={() => setModalDisplay(false)}
          meNowData={{
            competencies: formatData(
              userCompetencies.strength,
              levelOnlyCompetencies
            ),
            archetypes: capableArchetypes,
            interests
          }}
          meNextData={{
            competencies: formatData(userCompetencies.prioritized, []),
            archetypes: incapableArchetypes,
            interests: null
          }}
          myPlanData={formatGoals}
        />
      </div>
      <KnowPathwaysModal
        tipModalOpened={knowPathwaysModalOpened}
        closeTipModal={closeKnowPathwaysModalOpened}
      />
    </div>
  );
};

DashboardPage.propTypes = {
  isGuideShowState: PropTypes.bool
};

export default DashboardPage;
