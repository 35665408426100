import React from 'react';
import styles from './index.module.css';
import clsx from 'clsx';
import { withStyles } from '@mui/styles';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import * as DOMPurify from 'dompurify';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';

const CompetencyWarning = ({
  warningTitle,
  warningDescription,
  className,
  nameList,
  closeWarning
}) => {
  const sanitizer = DOMPurify.sanitize;
  const WarningIcon = withStyles({
    root: {
      fontSize: '32px'
    }
  })(ReportProblemIcon);

  return (
    <div className={styles.warningWrapper}>
      <div
        className={clsx(styles.WrapperIcon, styles[className + 'WrapperIcon'])}
      >
        <WarningIcon />
      </div>
      <div
        className={clsx(styles.warningText, styles[className + 'WrapperText'])}
      >
        <div className={styles.warningTitle}>{warningTitle}</div>
        <div
          dangerouslySetInnerHTML={{
            __html: sanitizer(warningDescription)
          }}
        />
        <div
          className={clsx(
            styles.affectedCompetencies,
            styles[className + 'Display']
          )}
        >
          <b>Affected Competencies (click to jump): </b>
          {nameList}
        </div>
      </div>
      <div className={styles.wrapperBtn}>
        <button onClick={closeWarning} className={styles.button}>
          <CloseIcon />
        </button>
      </div>
    </div>
  );
};

CompetencyWarning.propTypes = {
  warningTitle: PropTypes.string.isRequired,
  warningDescription: PropTypes.string.isRequired,
  className: PropTypes.string,
  nameList: PropTypes.element,
  closeWarning: PropTypes.func
};
export default CompetencyWarning;
