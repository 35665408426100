import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useUserContext } from '../../../../contexts/UserContext';
import { accomplishGoal } from '../../../../api/domain/goal';
import {
  fetchReflectedCompetencies,
  fetchUserGoals
} from '../../../../actions/profile';
import { GOAL_TYPE } from '../../../../constants/goal';
import { dashboardDes } from '../../language';
import AccomplishedContent from './AccomplishedContent';
import GoalModal from '../GoalModal';

const AccomplishedModal = forwardRef((props, ref) => {
  const [isAccomplishModalOpen, setAccomplishModalOpen] = useState(false);
  const [goal, setGoal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [originPracticeLevel, setOriginPracticeLevel] = useState(null);
  const { dispatch } = useUserContext();

  useImperativeHandle(ref, () => ({
    open: selectedGoal => {
      setGoal(selectedGoal);
      setOriginPracticeLevel(selectedGoal.practiceLevel);
      setAccomplishModalOpen(true);
    }
  }));
  const handleGoalChange = goal => {
    setGoal(goal);
  };
  const markAccomplished = async () => {
    setLoading(true);
    const bodyParameters = {
      goalType: GOAL_TYPE.UNDER_ME_NEXT_COMPETENCY,
      competencyId: goal.id,
      practiceLevel: goal.practiceLevel,
      isLearningPriority:
        goal.isLearningPriority === null ? false : goal.isLearningPriority,
      contributions: {
        archetypes: goal.contributions.archetypes,
        coreCapabilities: goal.contributions.coreCapabilities,
        categories: null
      },
      identifier: false
    };

    accomplishGoal(goal.id, bodyParameters)
      .then(() => {
        fetchUserGoals(dispatch);
        fetchReflectedCompetencies(dispatch);
      })
      .finally(() => {
        setLoading(false);
        setAccomplishModalOpen(false);
      });
  };

  return (
    <GoalModal
      open={isAccomplishModalOpen}
      close={() => setAccomplishModalOpen(false)}
      title={dashboardDes.myPlanCardDes.goalDetailsButton}
      confirmText={dashboardDes.myPlanCardDes.markAsAccomplishedButton}
      onConfirm={markAccomplished}
      loading={loading}
      disableBackdropClose
    >
      <AccomplishedContent
        goal={goal}
        originPracticeLevel={originPracticeLevel}
        onChange={handleGoalChange}
      />
    </GoalModal>
  );
});

export default AccomplishedModal;
