import React, { useState } from 'react';
import { get, groupBy, orderBy, upperCase } from 'lodash';
import ReflectSingleItem from '../../../../../../feature/ReflectSingleItem';
import styles from './index.module.css';
import { getLearningUrl } from '../../../../../../components/Utils/competencyUtils';
import LinkIcon from '../../../../../../components/Common/SvgIcons/LinkIcon';
import { CapabilityItem } from '../../../../../../components/CapabilityItem';
import { proficiencyLevelsDescription } from '../../../../../../constants/proficiencyLevels';
import PropTypes from 'prop-types';

export const ListView = ({ competencies }) => {
  const [editOpen, setEditOpen] = useState(false);
  const [competency, setCompetency] = useState({});
  const handleEditClick = competency => {
    setCompetency(competency);
    setEditOpen(true);
  };
  const groupCompetencies = competencies => {
    if (competencies.length === 0) {
      return [];
    }
    let res = [];
    const competenciesGroupByCategory = groupBy(competencies, 'category');
    Object.keys(competenciesGroupByCategory)
      .sort()
      .forEach(key => {
        res.push({
          name: competenciesGroupByCategory[key][0]['category'],
          children: orderBy(competenciesGroupByCategory[key], 'name')
        });
      });

    return res;
  };
  const currentCompetencies = groupCompetencies(competencies);

  return (
    <>
      <ReflectSingleItem
        ability={competency}
        open={editOpen}
        close={() => setEditOpen(false)}
        modalTitle="Edit Competency"
        reflectType="Competencies"
      />
      {currentCompetencies.map((group, groupIndex) => (
        <div className={styles.group} key={group.name}>
          <a
            className={styles.groupName}
            href={getLearningUrl(group.name)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkIcon className={styles.groupIcon} />
            {upperCase(group.name)}
          </a>
          {group.children.map(item => (
            <div key={item.name} className={styles.item}>
              <CapabilityItem
                key={item.name}
                name={item.name}
                value={get(item, 'reflectedInformation.practiceLevel', 0)}
                label={
                  proficiencyLevelsDescription[
                    item.reflectedInformation?.practiceLevel - 1
                  ] || 'No Level'
                }
                onClick={() => handleEditClick(item)}
              />
            </div>
          ))}
        </div>
      ))}
    </>
  );
};

ListView.propTypes = {
  competencies: PropTypes.array.isRequired,
  showMore: PropTypes.bool
};
