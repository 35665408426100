import React from 'react';

export const archetypesReflectPageDes = {
  navigationHeader: {
    backToTitle: 'Cancel',
    goToTitle: (
      <span>
        One last questio<u>n</u>
      </span>
    ),
    tooltipContent:
      'No worries if you haven’t finished your reflection. You can update your Archetype reflection anytime.'
  },
  noSelectMsg:
    'Competencies from your chosen Archetype will appear here to guide your reflection.',
  nodata: 'Please select the archetypes to get started.',
  title: `Let's reflect`,
  description: ` on your selected archetypes. All reflections should be based on where you are at today.`,
  selectDefaultOption: 'Choose an Archetype to reflect on',
  competenciesList: {
    firstColumn: 'Archetype’s Competencies',
    secondColumn: 'My Current Practice level',
    suggestedLevel: '= Suggested level',
    thirdColumn: 'This Competency is',
    errorMsg: 'Something went wrong, Please try again later'
  }
};
