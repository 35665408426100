import React from 'react';
import { SvgIcon } from '@mui/material';

const Flag = props => {
  return (
    <SvgIcon {...props} viewBox="0 0 15 16">
      <path
        fill="currentColor"
        d="M.941 1.882h12.84c.12 0 .231.061.291.16.06.1.06.222.001.322L11.676 6.39l2.397 4.026c.06.1.06.222 0 .322-.061.099-.172.16-.291.16H2.283v3.22H.941V1.882z"
        transform="translate(0 -1)"
      />
    </SvgIcon>
  );
};

export default Flag;
