import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';

import styles from './index.module.css';
import { inputBlackList } from '../../../../../constants/inputBlackList';
import { GoalAddButton } from '../../../MyPlanCard/GoalGapAndAction';
import globalStyles from '../../../../../globalStyles';
import { styled } from '@mui/material/styles';

export const InputWithStyles = styled(TextField)({
  width: '90%',
  '& .MuiOutlinedInput-root': {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    },
    '& .MuiOutlinedInput-input': {
      padding: '10px',
      height: '19px',
      '&::-webkit-input-placeholder': {
        textIndent: '10px',
        color: globalStyles.grey40,
        fontSize: '16px'
      }
    }
  }
});

export const CustomInput = forwardRef(
  ({ placeholder, addItem, disabled, inputProps }, ref) => {
    const [item, setItem] = useState('');
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleChange = event => {
      const itemName = event.target.value;
      setItem(itemName);
      if (itemName.match(new RegExp(`[${inputBlackList}]`, 'g'))) {
        setIsError(true);
        setErrorMessage(`*Input should not contain ${inputBlackList}`);
      } else if (itemName.length > 160) {
        setIsError(true);
        setErrorMessage('*Input should be at most 160 characters in length');
      } else if (isError) {
        setIsError(false);
      }
    };

    const handleKeyPress = event => {
      if (event.key === 'Enter') {
        if (item.trim().length > 0 && !isError && !disabled) {
          const newItem = { content: item.trim() };
          addItem(newItem, () => setItem(''));
        }
      }
    };

    const handleAddItem = () => {
      if (item.trim().length > 0 && !isError && !disabled) {
        const newItem = { content: item.trim() };
        addItem(newItem);
        setItem('');
      }
    };

    return (
      <>
        <div className={styles.inputContainer}>
          <InputWithStyles
            placeholder={placeholder}
            variant="outlined"
            value={item}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            disabled={disabled}
            data-cy="input"
            inputRef={ref}
            inputProps={inputProps}
          />
          <GoalAddButton variant="outlined" onClick={handleAddItem}>
            Add
          </GoalAddButton>
        </div>
        <div className={styles.errorMessage} data-cy="errorMessage">
          {isError && errorMessage}
        </div>
      </>
    );
  }
);

CustomInput.propTypes = {
  placeholder: PropTypes.string.isRequired,
  addItem: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  inputProps: PropTypes.object
};
