import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Button } from '@mui/material';

import globalStyles from '../../globalStyles.js';
import styles from './index.module.css';
import ArrowForward from '@mui/icons-material/ArrowForward';
import { styled } from '@mui/material/styles';

export const NavigationButton = styled(Button)({
  color: globalStyles.white,
  backgroundColor: globalStyles.magenta,
  minWidth: '215px',
  height: '40px',
  fontWeight: 'bold',
  fontSize: '16px',
  position: 'absolute',
  right: '0',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: globalStyles.hoverRed
  },
  '&:focus': {
    backgroundColor: globalStyles.hoverRed
  }
});

const NavigationHeader = ({
  onBackTo,
  backToTitle,
  onGoTo,
  goToTitle,
  showGoToIcon,
  disabled,
  children
}) => {
  return (
    <div className={styles.headerWrapper}>
      <div
        className={clsx(
          styles.navigationHeader,
          !onBackTo && styles.disableBack
        )}
      >
        {onBackTo && (
          <div
            className={styles.cancelButton}
            onClick={onBackTo}
            tabIndex="0"
            role="button"
            aria-label="cancelButton"
          >
            {backToTitle}
          </div>
        )}
        {children}
        {onGoTo && (
          <NavigationButton
            variant="contained"
            disabled={disabled}
            onClick={onGoTo}
            data-cy="gotoButton"
          >
            {goToTitle}
            {showGoToIcon && (
              <>
                &nbsp;
                <ArrowForward />
              </>
            )}
          </NavigationButton>
        )}
      </div>
    </div>
  );
};

NavigationHeader.defaultProps = {
  disabled: false,
  showGoToIcon: false
};

NavigationHeader.propTypes = {
  onBackTo: PropTypes.func,
  backToTitle: PropTypes.string,
  onGoTo: PropTypes.func,
  goToTitle: PropTypes.node,
  showGoToIcon: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
  children: PropTypes.node
};

export default NavigationHeader;
