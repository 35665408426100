const months = [
  '',
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

const ordinal = n => {
  var s = ['th', 'st', 'nd', 'rd'];
  var v = n % 100;
  return s[(v - 20) % 10] || s[v] || s[0];
};

export const formatToEN = datetime => {
  if (!datetime) {
    return '';
  }
  const date = new Date(datetime);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const suffix = ordinal(day);
  return `${day}${suffix} ${months[month]} ${year}`;
};
