import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import styles from './index.module.css';
import MyInterests from './MyInterests/MyInterests';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { FLOW_PAGE_EVENT } from '../../../constants/matomo';
import {
  EDIT_REFLECT_SKILLS,
  REFLECT_COMPETENCIES,
  SELECT_ARCHETYPES,
  SELECT_COMPETENCIES,
  SELECT_SKILLS
} from '../../../constants/path';
import { useUserContext } from '../../../contexts/UserContext';
import CompetencyAndArchetypeTipModal from '../../../feature/CompetencyAndArchetypeTipModal';
import { dashboardDes } from '../language';
import EntryPoint from '@nonFeatureComponents/EntryPoint';
import ArchetypesCapableOf from '../components/ArchetypesCapableOf';
import Competencies from '../components/Competencies';
import GuideModal from '../components/GuideModal';
import { TopCourseRecommendation } from '../components/TopCourseRecommendation';
import Skills from '../components/Skills';

const MeNowCard = props => {
  const {
    state: {
      user: { guideStepNumber = 1 }
    }
  } = useUserContext();
  const meNowCardDes = dashboardDes.meNowCard;
  const entryPointDes = dashboardDes.entryPoint;

  const [guideModalShow, setGuideModalShow] = useState(false);
  const [tipModalOpened, setTipModalOpened] = useState(false);
  const closeTipModal = () => setTipModalOpened(false);
  const history = useHistory();
  const { trackEvent } = useMatomo();
  const navToArchetype = e => {
    e.preventDefault();
    trackEvent({
      category: FLOW_PAGE_EVENT.category,
      action: FLOW_PAGE_EVENT.action.ARCHETYPE
    });
    history.push(SELECT_ARCHETYPES);
  };
  const navToSelectCompetencies = e => {
    e.preventDefault();
    trackEvent({
      category: FLOW_PAGE_EVENT.category,
      action: FLOW_PAGE_EVENT.action.COMPETENCIES
    });
    history.push(SELECT_COMPETENCIES);
  };
  const closeGuideModal = () => {
    setGuideModalShow(false);
  };
  const updateGuideModal = curStepNumber => {
    if (+curStepNumber === 6) {
      setGuideModalShow(false);
    }
  };
  const navToSkills = e => {
    e.preventDefault();
    trackEvent({
      category: FLOW_PAGE_EVENT.category,
      action: FLOW_PAGE_EVENT.action.SKILL
    });
    history.push(SELECT_SKILLS);
  };

  useEffect(() => {
    if (+guideStepNumber === 4 || +guideStepNumber === 5) {
      setGuideModalShow(true);
    } else {
      setGuideModalShow(false);
    }
  }, [guideStepNumber]);

  return (
    <div className={styles.DashboardContainer}>
      <div className={styles.contentContainer}>
        <Grid spacing={2} container>
          <Grid item lg={6} md={6} xs={12} data-cy="meNowArchetypesCapableOf">
            <ArchetypesCapableOf
              data-cy="archetype"
              title={meNowCardDes.archetypesTitle}
              setOpen={setTipModalOpened}
              archetypes={props.archetypes}
              emptyMessage={
                <div data-cy="archetype">
                  <EntryPoint
                    bg={2}
                    title={entryPointDes.archetype.title}
                    subTitle={entryPointDes.archetype.subtitle}
                    setOpen={setTipModalOpened}
                    onClick={navToArchetype}
                  />
                </div>
              }
            />
          </Grid>

          <Grid item lg={6} md={6} xs={12} data-cy="meNowCompetencies">
            <Competencies
              title={meNowCardDes.competenciesTitle}
              setOpen={setTipModalOpened}
              isLoading={props.isLoading}
              competencies={props.competencies}
              emptyMessage={
                <div data-cy="coreCompetencies">
                  <EntryPoint
                    bg={1}
                    title={entryPointDes.competency.title}
                    subTitle={entryPointDes.competency.subtitle}
                    setOpen={setTipModalOpened}
                    onClick={navToSelectCompetencies}
                  />
                </div>
              }
              editTargetPath={{
                path: REFLECT_COMPETENCIES
              }}
            />
          </Grid>

          <Grid item lg={6} md={6} xs={12} data-cy="meNowSkills">
            <Skills
              title={meNowCardDes.skillTitle}
              setOpen={setTipModalOpened}
              isLoading={props.isLoading}
              skills={props.skills}
              emptyMessage={
                <div data-cy="skills">
                  <EntryPoint
                    bg={3}
                    title={entryPointDes.skill.title}
                    subTitle={entryPointDes.skill.subtitle}
                    setOpen={setTipModalOpened}
                    onClick={navToSkills}
                  />
                </div>
              }
              editTargetPath={{
                path: EDIT_REFLECT_SKILLS
              }}
            />
          </Grid>

          {props.isGraduate && (
            <Grid item lg={6} md={6} xs={12} data-cy="TopCourseRecommendation">
              <TopCourseRecommendation tabIndex={1} />
            </Grid>
          )}

          <Grid item lg={6} md={6} xs={12} data-cy="MyInterests">
            <MyInterests />
          </Grid>

          <GuideModal
            guideModalShow={guideModalShow}
            closeGuideModal={closeGuideModal}
            updateGuideModal={updateGuideModal}
          />
        </Grid>
        <CompetencyAndArchetypeTipModal
          tipModalOpened={tipModalOpened}
          closeTipModal={closeTipModal}
        />
      </div>
    </div>
  );
};

MeNowCard.propTypes = {
  archetypes: PropTypes.array.isRequired,
  competencies: PropTypes.array.isRequired,
  skills: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLoadErr: PropTypes.bool.isRequired
};

export default MeNowCard;
