import React, { useMemo } from 'react';
import clsx from 'clsx';
import styles from './index.module.css';
import editIconWhite from '../../../../assets/Icons/visibilityEditIconWhite.svg';
import editIconBlack from '../../../../assets/Icons/visibilityEditIconBlack.svg';
import { dashboardDes } from '../../language';
import { useUserContext } from '../../../../contexts/UserContext';
import { VISIBILITY_PREFERENCE } from '../../../../constants/path';
import { useHistory } from 'react-router-dom';
import {
  Visibility,
  VisualizationIcon
} from '../../../../constants/visibilityPreference';
import { JigsawLink } from '../../../../constants/links';
import { onKeyDown } from '../../../../utils/onKeyDown';
import { getEnvVars } from '../../../../config/config';

const ShareMeNextNotification = () => {
  const history = useHistory();
  const {
    state: { user }
  } = useUserContext();
  const { env } = getEnvVars();

  const userPreference = useMemo(() => {
    if (
      user.meNextArchetypeVisibility &&
      user.meNextCompetencyVisibility &&
      user.meNextSkillVisibility
    ) {
      return Visibility.AllDataVisible;
    } else if (
      user.meNextArchetypeVisibility ||
      user.meNextCompetencyVisibility ||
      user.meNextSkillVisibility
    ) {
      return Visibility.SomeDataVisible;
    }
    return Visibility.NoneDataVisible;
  }, [user]);

  return (
    <div className={styles.meNextNotification}>
      <div className={styles.currentVisibilityCondition}>
        <img
          src={VisualizationIcon[userPreference]}
          alt="currentVisibilityConditionIcon"
        />
        <div className={styles.condition}>
          <div className={styles.title}>
            {
              dashboardDes.shareMeNextNotification.currentPreferenceTitle[
                userPreference
              ]
            }
          </div>
          <div className={styles.describe}>
            {
              dashboardDes.shareMeNextNotification
                .currentPreferenceDescribePart1
            }
            <a
              className={styles.link}
              rel="noopener noreferrer"
              target="_blank"
              href={JigsawLink(user?.employeeId, env)}
            >
              Jigsaw Profile
            </a>
            {
              dashboardDes.shareMeNextNotification
                .currentPreferenceDescribePart2
            }
            <b>project opportunities</b> <br />
            {
              dashboardDes.shareMeNextNotification
                .currentPreferenceDescribePart3
            }
          </div>
        </div>
      </div>
      <div
        className={clsx(styles.preferenceEdit, {
          [styles.allDataVisible]: userPreference === Visibility.AllDataVisible
        })}
        onClick={() => history.push(VISIBILITY_PREFERENCE)}
        onKeyDown={e => onKeyDown(e, () => history.push(VISIBILITY_PREFERENCE))}
        tabIndex="0"
        aria-label="preferenceEditButton"
      >
        <img
          src={
            userPreference === Visibility.AllDataVisible
              ? editIconBlack
              : editIconWhite
          }
          className={styles.editIcon}
          alt="visibleIcon"
        />
        <div
          className={clsx(styles.editWording, {
            [styles.allDataVisible]:
              userPreference === Visibility.AllDataVisible
          })}
        >
          {dashboardDes.shareMeNextNotification.editVisibility}
        </div>
      </div>
    </div>
  );
};

export default ShareMeNextNotification;
