import * as React from 'react';
import { useState } from 'react';
import styles from './index.module.css';
import { useUserContext } from '../../../../contexts/UserContext';
import {
  ModalCancelButton,
  ModalSaveButton
} from '../../../../nonFeatureComponents/Buttons/Buttons';
import { useHistory } from 'react-router-dom';
import { DASHBOARD_WITH_TARGET } from '../../../../constants/path';
import globalStyles from '../../../../globalStyles';
import { isEqual } from 'lodash';
import { updateProfile } from '../../../../actions/profile';
import { JigsawLink } from '../../../../constants/links';
import { visibilityPreferenceDes } from '../../language';
import { VISIBILITY_PREFERENCE_EVENT } from '../../../../constants/matomo';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { isUserFromEmail } from '../../../Router/PathwaysRouter';
import { DASHBOARD_URL_TAB } from '../../../../constants/dashborad';
import ConfirmationModal from '../../../../feature/ConfirmationModal';
import { getEnvVars } from '../../../../config/config';
import { VisibilityButtonGroup } from '../VisibilityButton';
import { styled } from '@mui/material/styles';

export const ModalSaveButtonStyle = styled(ModalSaveButton)({
  width: '177px',
  height: '40px',
  padding: '8px 20px',
  background: globalStyles.pdfRead,
  '&:hover': {
    backgroundColor: globalStyles.pdfRead
  }
});

export const ModalCancelButtonStyle = styled(ModalCancelButton)({
  width: '96px',
  height: '40px',
  border: '1px solid var(--competencyModalMoreLinkBorder)'
});

const Preference = () => {
  const {
    state: { user },
    dispatch
  } = useUserContext();
  const { env } = getEnvVars();

  const { trackEvent } = useMatomo();
  const [isShareArchetype, setIsShareArchetype] = useState(
    user.meNextArchetypeVisibility
  );
  const [isShareCompetency, setIsShareCompetency] = useState(
    user.meNextCompetencyVisibility
  );
  const [isShareSkill, setIsShareSkill] = useState(user.meNextSkillVisibility);
  const [isOpenPopUp, setIsOpenPopUp] = useState(false);
  const [isUpdateDataLoading, setIsUpdateDataLoading] = useState(false);
  const history = useHistory();
  const previousVisibility = {
    isShareArchetype: user.meNextArchetypeVisibility,
    isShareCompetency: user.meNextCompetencyVisibility,
    isShareSkill: user.meNextSkillVisibility
  };
  const currentVisibility = {
    isShareArchetype,
    isShareCompetency,
    isShareSkill
  };
  const employeeId = user.employeeId;
  const pageUrl = window.location.href;

  const itemList = [
    {
      title: 'Archetypes',
      isVisible: isShareArchetype,
      setIsVisible: setIsShareArchetype
    },
    {
      title: 'Competencies',
      isVisible: isShareCompetency,
      setIsVisible: setIsShareCompetency
    },
    {
      title: 'Skills',
      isVisible: isShareSkill,
      setIsVisible: setIsShareSkill
    }
  ];
  const items = () => {
    return itemList.map(item => (
      <div className={styles.item} key={item.title}>
        <div className={styles.content}>
          <b className={styles.contentTitle}>{item.title}</b> to develop
        </div>
        <VisibilityButtonGroup
          isVisible={item.isVisible}
          setIsVisible={item.setIsVisible}
        />
      </div>
    ));
  };

  const handleGoToMeNext = () => {
    history.push(DASHBOARD_WITH_TARGET + DASHBOARD_URL_TAB.ME_NEXT);
  };
  const handleSave = async () => {
    setIsUpdateDataLoading(true);
    const isUpdateProfileSuccessfully = await updateProfile(dispatch)({
      meNextArchetypeVisibility: isShareArchetype,
      meNextCompetencyVisibility: isShareCompetency,
      meNextSkillVisibility: isShareSkill
    });
    if (isUpdateProfileSuccessfully) {
      setIsOpenPopUp(true);
    }
    trackSaveEvent();
    setIsUpdateDataLoading(false);
  };
  const trackSaveEvent = () => {
    if (isUserFromEmail(pageUrl)) {
      const date = pageUrl.split('date=')[1];
      trackEvent({
        category: VISIBILITY_PREFERENCE_EVENT(date).category,
        action: VISIBILITY_PREFERENCE_EVENT(date).action
          .CLICK_SAVE_PREFERENCE_FROM_EMAIL
      });
    } else {
      trackEvent({
        category: VISIBILITY_PREFERENCE_EVENT().category,
        action: VISIBILITY_PREFERENCE_EVENT().action
          .CLICK_SAVE_PREFERENCE_FROM_SYSTEM
      });
    }
  };

  return (
    <div className={styles.preference}>
      <div className={styles.title}>{visibilityPreferenceDes.title}</div>
      <div className={styles.items}>{items()}</div>
      <div className={styles.operations}>
        <ModalCancelButtonStyle variant="outlined" onClick={handleGoToMeNext}>
          {visibilityPreferenceDes.operationButtons.cancel}
        </ModalCancelButtonStyle>
        <ModalSaveButtonStyle
          variant="contained"
          disabled={
            isEqual(previousVisibility, currentVisibility) ||
            isUpdateDataLoading
          }
          onClick={handleSave}
        >
          {visibilityPreferenceDes.operationButtons.save}
        </ModalSaveButtonStyle>
      </div>
      <ConfirmationModal
        open={isOpenPopUp}
        setOpen={setIsOpenPopUp}
        confirmButtonText={
          visibilityPreferenceDes.confirmModal.confirmButtonText
        }
        cancelButtonText={visibilityPreferenceDes.confirmModal.cancelButtonText}
        confirm={() => handleGoToMeNext()}
        cancel={() => window.open(JigsawLink(employeeId, env), '_blank')}
        title={visibilityPreferenceDes.confirmModal.title}
        content={visibilityPreferenceDes.confirmModal.content}
      />
    </div>
  );
};
export default Preference;
