import { cloneDeep } from 'lodash';
export const getCompetenciesRow = num => {
  const TWO_ROWS = 15;
  const twoRowsNum = Math.floor(num / TWO_ROWS);
  const restNum = num % TWO_ROWS;
  if (restNum === 0) {
    return twoRowsNum * 2;
  } else if (restNum <= 8) {
    return twoRowsNum * 2 + 1;
  } else {
    return twoRowsNum * 2 + 2;
  }
};

export const getArchetypesRow = (num, type) => {
  let ONE_ROWS = type === 0 ? 3 : 6;
  if (num % ONE_ROWS === 0) {
    return Math.floor(num / ONE_ROWS);
  } else {
    return Math.floor(num / ONE_ROWS) + 1;
  }
};

// Height of Menow, MeNext
export const MENOW_MENEXT_LOGO_HEIGHT = 127;
export const FULL_RENDER_HEIGHT = 1477 - MENOW_MENEXT_LOGO_HEIGHT;
// 一行competency高度
const ONE_ROW_COMPETENCY = 128;
// The height of each additional row of competency increases
const COMPETENCY_ROW_HEIGHT = 128;
// The number of competency that fills a page
export const MAX_COMPETENCIES_IN_PAGE = 68;
// 一行archetype高度
const ONE_ROW_ARCHETYPE = 251;
// The height of each additional row of archetype increases
const ARCHETYPE_INCREMENT = 165;
// 空com剩余高度
export const EMPTY_COM_HEIGHT = FULL_RENDER_HEIGHT - 462 - 20;

export const getCompetenciesHeight = (comCounts, heightList) => {
  let restHeight = FULL_RENDER_HEIGHT;

  const competenciesRows = getCompetenciesRow(comCounts);

  const competenciesHeight =
    ONE_ROW_COMPETENCY + (competenciesRows - 1) * COMPETENCY_ROW_HEIGHT;

  // 没有占满所有空间
  if (competenciesHeight <= restHeight) {
    heightList.push(comCounts);
  } else {
    heightList.push(MAX_COMPETENCIES_IN_PAGE);
    return getCompetenciesHeight(
      comCounts - MAX_COMPETENCIES_IN_PAGE,
      heightList
    );
  }

  return heightList;
};

export const getArchetypesHeight = (
  archCounts,
  type,
  restHeight,
  heightList
) => {
  const archRows = getArchetypesRow(archCounts, type);

  const archHeight = ONE_ROW_ARCHETYPE + (archRows - 1) * ARCHETYPE_INCREMENT;

  if (archHeight <= restHeight) {
    heightList.push(archCounts);
  } else {
    // Not enough space left for next line
    if (restHeight < ONE_ROW_ARCHETYPE) {
      heightList.push(0);
      return getArchetypesHeight(
        archCounts,
        type,
        FULL_RENDER_HEIGHT,
        heightList
      );
    } else {
      let numberOfArchetypesEachRow = type === 0 ? 3 : 6;
      // eslint-disable-next-line no-param-reassign
      restHeight -= ONE_ROW_ARCHETYPE;

      const numberOfRows = Math.floor(restHeight / ARCHETYPE_INCREMENT);

      let renderArchetypesCounts = Math.min(
        numberOfArchetypesEachRow + numberOfRows * numberOfArchetypesEachRow,
        archCounts
      );

      heightList.push(renderArchetypesCounts);

      return getArchetypesHeight(
        archCounts - renderArchetypesCounts,
        type,
        FULL_RENDER_HEIGHT,
        heightList
      );
    }
  }

  return heightList;
};

export const MY_PLAN_HEIGHT = 1296 + 30 + 30 - (48 + 172 + 54) - 50; //reduce 50px to fix deviation, when actions is too long and pagination happen
export const COMPETENCIES_TITLE_HEIGHT = 88;
export const COMPETENCIES_PADDING_BOTTOM = 60;
export const COMPETENCIES_MARGIN_BOTTOM = 20;
export const getRestHeightAfterRenderCompetencies = comNum => {
  const comRows = getCompetenciesRow(comNum);
  const totalHeight =
    COMPETENCIES_TITLE_HEIGHT +
    COMPETENCIES_PADDING_BOTTOM +
    COMPETENCIES_MARGIN_BOTTOM +
    comRows * COMPETENCY_ROW_HEIGHT;

  const restHeight = FULL_RENDER_HEIGHT - totalHeight;
  return restHeight;
};

// height of gaps/actions when it is empty: marginTop + height
export const EMPTY_LIST_HEIGHT = 32 + 58;
// 一行gaps或actions高度
const GAP_ACTION_LINE_HEIGHT = 20;
// list margin top
const MARGIN_TOP = 10;
// Page height
export const PAGINATION_HEIGHT = 1300;
//text number for each row of actions/gaps/description
export const TEXT_NUMBER_EACH_ROW = 36;
export const GROWTH_TEXT_NUMBER_EACH_ROW = 52;

export const getRowsHeight = (
  textLength,
  textNumberOfEachRow,
  lineHeight = GAP_ACTION_LINE_HEIGHT
) => Math.ceil(textLength / textNumberOfEachRow) * lineHeight;

// actions or gaps title height: marginTop + height + margin bottom
export const ACTIONS_GAPS_TITLE_HEIGHT = 32 + 22 + 16;
// growth title height: marginTop + height + marginBottom
export const GROWTH_TITLE_HEIGHT = 32 + 46 + 16;
// growth message display when archetypes and core capabilities are empty:  marginTop + height + marginBottom;
export const EMPTY_ARCHETYPES_CORE_CAPABILITIES_GROWTH_HEIGHT = 99 + 32 + 14;
export const LIST_TYPE = { ACTIONS: 'actions', GAPS: 'gaps', GROWTH: 'growth' };
export const getListHeight = (list, listType, pagination) => {
  // topMargin + titleHeight + marginBottom
  const titleHeight =
    listType === LIST_TYPE.GROWTH
      ? GROWTH_TITLE_HEIGHT
      : ACTIONS_GAPS_TITLE_HEIGHT;
  const textNumberForEachRow =
    listType === LIST_TYPE.GROWTH
      ? GROWTH_TEXT_NUMBER_EACH_ROW
      : TEXT_NUMBER_EACH_ROW;
  let height = pagination ? 0 : titleHeight;

  let rowHeight = 0;

  list.forEach(item => {
    rowHeight += getRowsHeight(item.length, textNumberForEachRow);
  });

  return height + rowHeight + (list.length - 1) * MARGIN_TOP;
};

export const getRestListNum = (list, restHeight, pagination, arr) => {
  let height = restHeight;
  let l = cloneDeep(list);

  if (!pagination) height -= 33 + 22;

  if (getListHeight(list) <= height) {
    arr.push(list.length);
    return arr;
  }

  const len = list.length;
  let splitNum = len;
  let rowHeight = 0;

  for (let i = 0; i < len; i++) {
    rowHeight += getRowsHeight(list[i].length, TEXT_NUMBER_EACH_ROW);

    if (i !== 0) rowHeight += MARGIN_TOP;
    if (rowHeight >= height) {
      splitNum = i;
      break;
    }
  }

  l.splice(0, splitNum);
  arr.push(splitNum);
  if (l.length !== 0) {
    return getRestListNum(l, PAGINATION_HEIGHT, true, arr);
  } else {
    return arr;
  }
};

const NUMBER_INTEREST_CHARACTERS_IN_ONE_LINE = 57;
const INTEREST_LINE_HEIGHT = 22;
const INTEREST_ITEM_MARGIN_TOP = 19;
const INTEREST_UL_MARGIN = 19;
const INTEREST_TITLE_HEIGHT = 47;
const INTEREST_TEXT_PADDING = 6;

export const getInterestItemHeight = interestItem => {
  return (
    getRowsHeight(
      interestItem.content.length,
      NUMBER_INTEREST_CHARACTERS_IN_ONE_LINE,
      INTEREST_LINE_HEIGHT
    ) +
    INTEREST_TEXT_PADDING * 2 +
    INTEREST_ITEM_MARGIN_TOP
  );
};

export const getTotalInterestHeight = interestList => {
  if (!interestList) {
    return 0;
  }

  let totalInterestHeight = INTEREST_TITLE_HEIGHT + INTEREST_UL_MARGIN * 2;

  interestList.forEach(interestItem => {
    const interestItemHeight = getInterestItemHeight(interestItem);
    totalInterestHeight += interestItemHeight;
  });

  return totalInterestHeight;
};

/**
 * Split interests list to many pages
 * @param {Array} interestListData
 * @param {Number} restHeight
 * @param {Array} paginationList
 * @returns an array of split index, for example: [2,3] means: page 1 includes item1, item2; page2 includes item3, item4, item5
 */
export const getInterestPagination = (
  interestListData,
  restHeight,
  paginationList
) => {
  if (!interestListData) {
    return [];
  }
  const interestList = cloneDeep(interestListData);
  const totalInterestHeight = getTotalInterestHeight(interestList);

  if (totalInterestHeight <= restHeight) {
    paginationList.push(interestList.length);
    return paginationList;
  }

  let sumInterestHeight = INTEREST_TITLE_HEIGHT + INTEREST_UL_MARGIN * 2;
  let splitIndex = 0;
  for (let i = 0; i < interestList.length; i++) {
    const interestItemHeight = getInterestItemHeight(interestList[i]);
    sumInterestHeight += interestItemHeight;
    if (sumInterestHeight > restHeight) {
      splitIndex = i;
      break;
    }
  }

  paginationList.push(splitIndex);
  interestList.splice(0, splitIndex);
  if (interestList.length !== 0) {
    return getInterestPagination(
      interestList,
      PAGINATION_HEIGHT,
      paginationList
    );
  }

  return paginationList;
};
