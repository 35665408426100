import React from 'react';
import { SELECT_COMPETENCIES } from '../../constants/path';

export const DEFAULT_CATEGORY_INDEX = 0;
export const ALL_ARCHETYPES = {
  category: 'All Archetypes',
  roles: []
};
export const archetypeSelectPageDes = {
  backToTitle: 'Cancel',
  goToTitle: (
    <span>
      <u>N</u>ow, let&#39;s reflect
    </span>
  ),
  noSuitableArchetype: 'I don’t see an Archetype that suits me',
  gotButtonText: 'Got it!',
  title: 'First, select Archetypes to reflect on.',
  selectArchetype:
    'You can either select Archetypes you are playing now or Archetypes you would like to grow next.',
  popup: {
    modalClassName: 'archetypesSelectPageModal',
    tipModalInfo: [
      {
        h:
          'That’s ok! You can still self-reflect and plan your growth using Competencies.',
        p: `
          <p>
            Go to the <a href=${SELECT_COMPETENCIES}>Competencies flow</a>
            and start selecting those that can help you achieve your learning
            priorities.
          </p>
        `
      },
      {
        p:
          'Competencies don’t necessarily belong to any Archetype and many of them are core to all the work we do ' +
          'regardless of role, grade or archetype. You can always start with the Core Competencies and then continuing' +
          ' adding more Competencies as if you were creating your own Archetype shape.'
      },
      {
        p:
          'The Capable team will continuously working adding new Archetypes and to make the ones we have even more' +
          ' relevant to your growth & current market needs.'
      }
    ]
  },
  archetypeRole: {
    confirmRemove: 'Remove Archetype',
    removeTipContent: 'This Archetype will be removed from your Growth Canvas.'
  }
};
