/* eslint-disable max-lines */
import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { isEqual, uniq, filter, includes, capitalize } from 'lodash';
import IconButton from '@mui/material/IconButton';
import CompetencyHexagonListV2 from './componentes/CompetencyHexagonListV2';
import InformationFillIcon from '../../components/Common/SvgIcons/InformationFillIcon';
import Loader from '../../nonFeatureComponents/Loader';
import FixedBar from '../../nonFeatureComponents/FixedBar';
import * as CompetenciesStorage from '../../components/Utils/competenciesStorage';
import styles from './index.module.css';
import loaderStyles from '../../nonFeatureComponents/Loader/index.module.css';
import { DASHBOARD, REFLECT_COMPETENCIES } from '../../constants/path';
import LampIcon from '../../assets/Icons/Lamp.png';
import { cloneDeep, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { INITIAL_FILTER_NAME, SearchBarConstants } from './language';
import {
  fetchCoreCapabilities,
  fetchPeopleCompetencies
} from '../../api/domain/competency';
import {
  allCompetenciesGroupByCategory,
  coreCompetenciesFromAllCompetencies
} from '../../domain/competency';
import NavigationHeader from '../../nonFeatureComponents/NavigationHeader';
import StepperLine from '../../nonFeatureComponents/StepperLine';
import ConfirmationModal from '../../feature/ConfirmationModal';
import {
  competenciesSelectPageDes,
  CORE_COMPETENCIES,
  DEFAULT_GROUP_NAME
} from './language';
import Popup from '../../nonFeatureComponents/Popup';
import ArchetypesSelector from '../../feature/ArchetypesSelector';
import SearchBar from '../../feature/SearchBar';
import CompetencyModal from '../../feature/CompetencyModal';
import tipIcon from '../../assets/Icons/tipIcon.png';
import { SearchCategoryEmpty } from '../../nonFeatureComponents/SearchCategoryEmpty';
import { competencySteps } from '../../constants/competency';
import { onKeyDown } from '../../utils/onKeyDown';
import {
  CATEGORY_EMPTY,
  COMPETENCIES_LIST_EMPTY,
  SKILL_LIST_EMPTY
} from '../../constants/modalConstants';
import { newSelectedCompetencyName } from '../../components/Utils/competenciesStorage';
import { styled } from '@mui/material/styles';

const InformationIconButton = styled(IconButton)({
  padding: '0 5px',
  color: 'rgba(0,0,0,1)',
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: 'transparent'
  }
});

const CompetenciesSelectPage = () => {
  const history = useHistory();
  const listWrapper = useRef(null);
  const [isLoading, setLoading] = useState(true);
  //<CompetencyModal module/>: competency: Store the current competency info when clicking the icon of a hexagon
  const [infoOpen, setInfoOpen] = useState(false);
  const [competency, setCompetency] = useState({});
  // Control the display of tip/ConfirmationModal
  const [tipModalOpened, setTipModalOpened] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  // Store the IDs of all selected competencies obtained from the api
  const [selectCompetenciesKey, setSelectCompetenciesKey] = useState([]);

  // <SearchBar/>
  const [searchValue, setSearchValue] = useState('');
  const [isFocus, setFocus] = useState(false);

  // <ArchetypesSelector/>: groupCompetency:store all group options, groupName:store current group option
  const [groupCompetency, setGroupCompetency] = useState([]);
  const [groupName, setGroupName] = useState(DEFAULT_GROUP_NAME);

  // Full competency, full core competencies are only modified during the select operation
  const [coreCompetencies, setCoreCompetencies] = useState([]);
  const [allCompetencies, setAllCompetencies] = useState([]);
  // Full coreCapabilities, no change
  const [coreCapabilities, setCoreCapabilities] = useState([]);

  //core filter: render for hexagons, filters: current filter
  const [filters, setFilters] = useState(INITIAL_FILTER_NAME);
  // filterCompetencies: all competencies in current filter,
  const [filterCompetencies, setFilterCompetencies] = useState([]);
  // filterCoreCompetencies: render core competency in hexagons after filtering and searching
  const [filterCoreCompetencies, setFilterCoreCompetencies] = useState([]);
  // filterAllCompetencies: render all competencies in hexagons after filtering and searching
  const [filterAllCompetencies, setFilterAllCompetencies] = useState([]);

  //Initialize filter
  useEffect(() => {
    if (filters === INITIAL_FILTER_NAME) {
      setFilterCompetencies(coreCompetencies);
      return;
    }
    setFilterCompetencies(
      filter(coreCompetencies, com =>
        includes(com.coreCapabilities, capitalize(filters))
      )
    );
  }, [coreCompetencies, filters]);

  //Add selected field to competencies
  const getSelectCompetencies = competencies => {
    return competencies.map(com => {
      const isReflectCompetency = !isEmpty(com.reflectedInformation);
      if (isReflectCompetency) {
        CompetenciesStorage.set(com.competencyId);
        selectCompetenciesKey.push(com.competencyId);
        setSelectCompetenciesKey(selectCompetenciesKey);
      }
      return {
        ...com,
        selected: isReflectCompetency
      };
    });
  };

  // Get data from api request: coreCapabilities/PeopleCompetencies. State initialization
  useEffect(() => {
    CompetenciesStorage.clear();
    CompetenciesStorage.clearNewSelectedCompetencyName();
    fetchCoreCapabilities().then(res => {
      setCoreCapabilities(res);
    });

    fetchPeopleCompetencies()
      .then(res => {
        const competenciesGroupByCategory = allCompetenciesGroupByCategory(res);
        const coreCompetencies = getSelectCompetencies(
          coreCompetenciesFromAllCompetencies(res)
        );

        const allCompetencies =
          Object.keys(competenciesGroupByCategory).map(key => {
            return getSelectCompetencies(competenciesGroupByCategory[key]);
          }) || [];

        setCoreCompetencies(coreCompetencies);
        setFilterCompetencies(coreCompetencies);
        setFilterCoreCompetencies(coreCompetencies);

        setAllCompetencies(allCompetencies);
        setFilterAllCompetencies(allCompetencies);
        setLoading(false);
      })
      .catch(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectCompetenciesKey]);

  // In the initial state, if using the search bar, give the filter the default value
  useEffect(() => {
    if (isFocus || !isEmpty(searchValue)) {
      setGroupName(DEFAULT_GROUP_NAME);
    }
  }, [isFocus, searchValue]);

  //Initialize <ArchetypesSelector/>
  useEffect(() => {
    if (!isEmpty(allCompetencies)) {
      let groupCompetency = allCompetencies.map(item => item[0].category);
      groupCompetency = [DEFAULT_GROUP_NAME, CORE_COMPETENCIES].concat(
        groupCompetency
      );
      setGroupCompetency(groupCompetency);
    }
  }, [allCompetencies]);

  // Whether at least one competence is selected, Control GoTo button disable
  const hasAllCompetenciesSelected = allCompetencies.some(competency =>
    competency.some(com => com.selected === true)
  );

  const onSearchBarFocus = isFocus => {
    setFocus(isFocus);
  };
  const onSearchBarChange = searchResult => {
    const {
      filterAllDataList = [],
      filterCoreDataList = [],
      curSearchVal
    } = searchResult;

    setSearchValue(curSearchVal);
    if (isEmpty(curSearchVal)) {
      setFilterAllCompetencies(allCompetencies);
      setFilterCoreCompetencies(filterCompetencies);
    } else {
      setFilterAllCompetencies(filterAllDataList);
      setFilterCoreCompetencies(filterCoreDataList);
    }
  };
  const onCompetencySelected = (id, index, competencies) => {
    let chosenCompetency, selected;
    if (isEmpty(competencies)) {
      chosenCompetency = coreCompetencies.find(com => com.competencyId === id);
    } else {
      chosenCompetency = competencies.find(com => com.competencyId === id);
    }
    selected = !Boolean(chosenCompetency.selected);
    chosenCompetency.selected = selected;
    allCompetencies.forEach(competency => {
      competency.forEach(com => {
        if (com.competencyId === id) {
          com.selected = selected;
        }
      });
    });
    coreCompetencies.forEach(com => {
      if (com.competencyId === id) {
        com.selected = selected;
      }
    });
    if (selected) {
      CompetenciesStorage.set(chosenCompetency.competencyId);
      const hasNewCompetencies = localStorage.getItem(
        newSelectedCompetencyName
      );
      if (hasNewCompetencies === null) {
        localStorage.setItem(
          newSelectedCompetencyName,
          JSON.stringify([chosenCompetency.name])
        );
      } else {
        const newSelectedCompetencies = JSON.parse(
          localStorage.getItem(newSelectedCompetencyName)
        );
        localStorage.setItem(
          newSelectedCompetencyName,
          JSON.stringify(
            uniq([...newSelectedCompetencies, chosenCompetency.name])
          )
        );
      }
    } else {
      CompetenciesStorage.removeByID(chosenCompetency.competencyId);
      const existSelectedCompetencies = JSON.parse(
        localStorage.getItem(newSelectedCompetencyName)
      );
      const index = existSelectedCompetencies
        ? existSelectedCompetencies.indexOf(chosenCompetency.name)
        : -1;
      if (index !== -1) {
        existSelectedCompetencies.splice(index, 1);
        localStorage.setItem(
          newSelectedCompetencyName,
          JSON.stringify(existSelectedCompetencies)
        );
      }
    }
    if (isEmpty(index) || isEmpty(competencies)) {
      setCoreCompetencies([...coreCompetencies]);
    } else {
      const result = cloneDeep(allCompetencies);
      result[index] = competencies;
      setAllCompetencies(result);
    }
  };
  const renderCoreFilters = () => (
    <div className={styles.filterList}>
      <div
        tabIndex={0}
        role="button"
        data-cy="see_all"
        className={clsx(styles.filterItem, {
          [styles.filterSelected]: filters === INITIAL_FILTER_NAME
        })}
        onKeyDown={e =>
          onKeyDown(e, () =>
            actionButtonKeydownHandler(e, setFilters, INITIAL_FILTER_NAME)
          )
        }
        onClick={() => setFilters(INITIAL_FILTER_NAME)}
      >
        <div tabIndex={-1} className={styles.noOutline}>
          See all
        </div>
      </div>
      {coreCapabilities.map(cap => (
        <div
          role="button"
          tabIndex={0}
          key={cap.identifier}
          className={clsx(styles.filterItem, {
            [styles.filterSelected]: filters === cap.name
          })}
          data-cy="filter"
          onClick={() => setFilters(cap.name)}
          onKeyDown={e =>
            onKeyDown(e, () =>
              actionButtonKeydownHandler(e, setFilters, cap.name)
            )
          }
        >
          <div tabIndex={-1} className={styles.noOutline}>
            {cap.name}
          </div>
        </div>
      ))}
    </div>
  );
  const renderCategoryListDom = () => {
    if (isEmpty(filterAllCompetencies)) {
      return SearchCategoryEmpty(SearchBarConstants, CATEGORY_EMPTY);
    }
    return (
      <>
        {renderCoreCompetenciesDom()}
        {renderCategoryCompetenciesDom()}
      </>
    );
  };
  const renderCoreCompetenciesDom = () => {
    return (
      (groupName === DEFAULT_GROUP_NAME || groupName === CORE_COMPETENCIES) && (
        <section className={styles.competencyGroup}>
          <div className={styles.competencyGroupTitle} data-testid="groupName">
            <span>Core Competencies</span>
            <InformationIconButton
              aria-label="see core competency information"
              component="span"
              onClick={() => setTipModalOpened(!tipModalOpened)}
            >
              <InformationFillIcon style={{ fontSize: 16 }} />
            </InformationIconButton>
          </div>
          {renderCoreFilters()}
          <div ref={listWrapper} className={styles.competencyList}>
            {filterCoreCompetencies.length > 0 ? (
              <CompetencyHexagonListV2
                competencies={filterCoreCompetencies}
                curWrapperWidth={1198}
                wrapper={listWrapper}
                onSingleClickInfo={handleInfoClick}
                onCompetencySelected={onCompetencySelected}
              />
            ) : (
              SearchCategoryEmpty(SearchBarConstants, COMPETENCIES_LIST_EMPTY)
            )}
          </div>
        </section>
      )
    );
  };
  const renderCategoryCompetenciesDom = () => {
    return filterAllCompetencies.map((competencies, i) => {
      if (competencies.length < 1) {
        const name = allCompetencies[i][0].category;
        return (
          <section
            key={i}
            className={styles.competencyGroup}
            data-testid="groupName"
          >
            <div
              className={styles.competencyGroupTitle}
            >{`${name} Competencies`}</div>
            {SearchCategoryEmpty(SearchBarConstants, COMPETENCIES_LIST_EMPTY)}
          </section>
        );
      }
      const name = competencies[0].category;
      if (groupName === DEFAULT_GROUP_NAME || groupName === name) {
        return (
          <section
            key={i}
            className={styles.competencyGroup}
            data-testid="groupName"
          >
            <div
              className={styles.competencyGroupTitle}
            >{`${name} Competencies`}</div>
            <div ref={listWrapper} className={styles.competencyList}>
              <CompetencyHexagonListV2
                competencies={competencies}
                curWrapperWidth={1198}
                wrapper={listWrapper}
                onSingleClickInfo={handleInfoClick}
                onCompetencySelected={id =>
                  onCompetencySelected(id, i, competencies)
                }
              />
            </div>
          </section>
        );
      }
      return null;
    });
  };
  const actionButtonKeydownHandler = (event, action, param) => {
    if (event.keyCode === 32) {
      event.preventDefault();
    } else if (event.keyCode === 13) {
      event.preventDefault();
      if (param !== undefined) {
        action(param);
      } else {
        action();
      }
    }
  };
  const handleInfoClick = competency => {
    setCompetency(competency);
    setInfoOpen(true);
  };
  const closeTipModal = () => setTipModalOpened(false);

  return isLoading ? (
    <div className={loaderStyles.loaderWrapper}>
      <Loader />
    </div>
  ) : (
    <>
      <FixedBar>
        <NavigationHeader
          onBackTo={() => {
            const localStorageSelectedKey = CompetenciesStorage.get();
            if (
              !isEqual(
                uniq(localStorageSelectedKey).sort(),
                uniq(selectCompetenciesKey).sort()
              )
            ) {
              return setIsConfirmModalOpen(true);
            }
            history.replace(DASHBOARD);
          }}
          disabled={!hasAllCompetenciesSelected}
          onGoTo={() => history.push(REFLECT_COMPETENCIES)}
          backToTitle={competenciesSelectPageDes.navigationHeader.backToTitle}
          goToTitle={competenciesSelectPageDes.navigationHeader.goToTitle}
          showGoToIcon
        >
          <StepperLine activeStep={0} steps={competencySteps} />
        </NavigationHeader>
      </FixedBar>
      <div className={styles.page}>
        <div className={styles.coreCompetenciesExpl}>
          <p className={styles.coreCompetenciesExplTitle}>
            {competenciesSelectPageDes.coreCompetenciesExplanation.title}
          </p>
          <p className={styles.coreCompetenciesDes}>
            {competenciesSelectPageDes.coreCompetenciesExplanation.description}
          </p>
          <section className={styles.search}>
            <ArchetypesSelector
              defaultOption={null}
              disabled={isFocus || !isEmpty(searchValue)}
              onChange={index => {
                if (index === -1) {
                  setGroupName(DEFAULT_GROUP_NAME);
                }
                setGroupName(groupCompetency[index]);
              }}
              options={groupCompetency}
              value={groupCompetency.indexOf(groupName)}
            />
            <SearchBar
              doubleLayerArrayData={allCompetencies}
              singleLayerArrayData={filterCompetencies}
              searchConstants={SearchBarConstants}
              onSearchBarChange={onSearchBarChange}
              onSearchBarFocus={onSearchBarFocus}
            />
          </section>
          {renderCategoryListDom()}
          {isEmpty(searchValue) &&
            SearchCategoryEmpty(SearchBarConstants, SKILL_LIST_EMPTY, tipIcon)}
          <Popup
            open={tipModalOpened}
            close={closeTipModal}
            title={competenciesSelectPageDes.popup.title}
            desc={competenciesSelectPageDes.popup.tipModalInfo}
            image={LampIcon}
            modalClassName="competenciesSelectPageModal"
          />
        </div>
      </div>
      <CompetencyModal
        competency={competency}
        open={infoOpen}
        close={() => setInfoOpen(false)}
      />
      <ConfirmationModal
        open={isConfirmModalOpen}
        setOpen={() => {
          setIsConfirmModalOpen(false);
        }}
        confirmButtonText={
          competenciesSelectPageDes.confirmationModal.confirmButtonText
        }
        cancelButtonText={
          competenciesSelectPageDes.confirmationModal.cancelButtonText
        }
        confirm={() => {
          history.replace(DASHBOARD);
        }}
        cancel={() => {
          setIsConfirmModalOpen(false);
        }}
        title={competenciesSelectPageDes.confirmationModal.title}
        content={competenciesSelectPageDes.confirmationModal.content}
      />
    </>
  );
};

CompetenciesSelectPage.propTypes = {
  location: PropTypes.object.isRequired
};

export default CompetenciesSelectPage;
