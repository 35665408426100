import striptags from 'striptags';

const htmlCleanser = {
  removeTags: stringWithHtml => {
    const stringWithHtmlWithPeriods = stringWithHtml
      .replace(/(\.)?( )*<\/p><p>/g, '</p>. <p>')
      .replace('[default] ', '')
      .replace(/&nbsp;/g, '');
    return striptags(stringWithHtmlWithPeriods);
  },
  removeTagsWithoutLink: stringWithHtml => {
    const stringWithHtmlWithPeriods = stringWithHtml
      .replace(/(\.)?( )*<\/p><p>/g, '</p>. <p>')
      .replace('[default] ', '')
      .replace(/&nbsp;/g, '')
      .replace(/href/g, "target='_blank' rel='noopener noreferrer' href");
    return striptags(stringWithHtmlWithPeriods, ['a'], ' ');
  }
};

export default htmlCleanser;
