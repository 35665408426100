import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.css';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { onKeyDown } from '../../utils/onKeyDown';
import InformationIcon from '../../components/Common/SvgIcons/Information';
import AddIcon from '@mui/icons-material/Add';

const EntryPoint = ({ title, setOpen, subTitle, onClick, bg, page }) => {
  return (
    <div
      className={clsx(styles.entryPoint, styles[`bg${bg}`], styles[`${page}`])}
      data-cy="entryPoint"
    >
      <Link
        to=""
        className={styles.entryPointBtn}
        onClick={onClick}
        onKeyDown={e => onKeyDown(e, onClick)}
        onKeyPress={onClick}
        data-cy="entrence"
      >
        <AddIcon
          role="button"
          titleAccess="Explore new competencies"
          className={styles.entryPointBtnIcon}
        />
      </Link>
      <div className={styles.entryPointTitle}>
        <span className={styles.subTitle}>{subTitle}</span>
        <span className={styles.title}>
          <div>
            <span>{title}</span>
            {!page && (
              <span
                role="button"
                tabIndex="0"
                onKeyDown={e => onKeyDown(e, () => setOpen(true))}
                className={styles.iconSpan}
                aria-label={'What are ' + title + ' ?'}
              >
                <span className={styles.iconSpanInner} tabIndex="-1">
                  <InformationIcon
                    className={styles.informationIcon}
                    onClick={() => setOpen(true)}
                  />
                </span>
              </span>
            )}
          </div>
        </span>
      </div>
    </div>
  );
};

EntryPoint.propTypes = {
  title: PropTypes.any.isRequired,
  subTitle: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  bg: PropTypes.number.isRequired
};

export default EntryPoint;
