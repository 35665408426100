export const FLOW_PAGE_EVENT = {
  category: 'Flow Page',
  action: {
    ARCHETYPE: 'Select Archetype Flow',
    CORECAPABILITY: 'Select Core Capability Flow',
    COMPETENCIES: 'Select Competencies Flow',
    SKILL: 'Select Skill Flow'
  }
};

export const SELECT_ARCHETYPE_EVENT = {
  category: 'Select Relevant Archetype Page',
  action: {
    REMOVE: 'Remove Archetype',
    ADD: 'Add Archetype',
    BACK: 'Back to Select My Archetype Family',
    CONTINUE: 'Continue to Assess Competencies',
    ONBOARDING: 'Onboarding'
  }
};

export const SELECT_ARCHETYPE_FAMILY_EVENT = {
  category: 'Select Archetype Family Page',
  action: {
    ONBOARDING: 'Onboarding'
  }
};

export const ASSESS_COMPETENCY_EVENT = {
  category: 'Assess Competency Page',
  action: {
    BACK: 'Back to Select My Archetype Family',
    CONTINUE: 'Continue to Prioritise Competencies',
    ONBOARDING: 'Onboarding',
    SLIDERINTERACTION: 'Score Slider Interaction'
  }
};

export const PRIORITISE_COMPETENCY_EVENT = {
  category: 'Prioritise Competency Page',
  action: {
    BACK: 'Back to Assess Competencies',
    CONTINUE: 'Continue to Generate Dashboard',
    ONBOARDING: 'Onboarding',
    PRIORITISE: 'Prioritise',
    DEPRIORITISE: 'Deprioritise',
    ARCHETYPE: 'Archetype',
    GAP: 'Gap',
    STRENGTH: 'Strength'
  }
};

export const ARCHETYPE_DASHBOARD_EVENT = {
  category: 'Dashboard Page',
  action: {
    INTERESTS: 'Achievements & Interests',
    BACK: 'Back to Prioritise My Competencies',
    EXPORT: 'Export as PDF',
    CONTRIBUTE: 'Contribute Resources',
    ONBOARDING: 'Onboarding',
    LEARNING: 'Learning Communities',
    EXPLORE: 'Explore Button',
    FEEDBACK: 'Feedback'
  }
};
export const CORE_CAPABILITY_EVENT = {
  category: 'Core Capabilities Flow',
  action: {
    EXPORT: 'Export as PDF',
    DONE_CLICKS: 'I’m done on ‘Reflection’ page clicks'
  }
};
export const LEARNING_COMMUNITY_CLICKS = {
  category: 'Learning Community Clicks'
};

export const CREATE_GOAL_FLOW = {
  category: 'Create goal flow',
  action: {
    START_GOAL: 'Start goal creation',
    CREATE_GOAL: 'Create the goal',
    ADDED_ACTIONS: 'Added actions'
  }
};

export const EDIT_GOAL_FLOW = {
  category: 'Edit goal flow',
  action: {
    ADDED_ACTIONS: 'Added actions'
  }
};

export const PATHWAYS_TO_SUMMIT = {
  category: 'Jump to Summit',
  action: {
    JUMP_TO_SUMMIT_WITH_NO_CYCLES_RECORD:
      'Jump to Summit with no cycles record',
    JUMP_TO_SUMMIT_WITH_OPEN_CYCLES_NO_CHECKINS:
      'Jump to Summit with open cycles no checkins',
    JUMP_TO_SUMMIT_WITH_OPEN_CYCLES_AND_CHECKINS:
      'Jump to Summit with open cycles and checkins',
    JUMP_TO_SUMMIT_WITH_DONE_CYCLES: 'Jump to Summit with done cycles',
    JUMP_TO_MY_CYCLES: 'Jump to my cycles'
  }
};

export const UPDATE_MY_SKILLS_NOW = {
  category: 'Update my skills now',
  action: {
    JUMP_TO_REFLECT_SKILLS: 'Jump to reflect-skills'
  }
};

export const TAKE_ME_TO_ADD_SKILLS = {
  category: 'Take me to add skills',
  action: {
    JUMP_TO_SELECT_SKILLS: 'Jump to select-skills'
  }
};

export const TAKE_THE_TOUR = {
  category: 'Take the tour',
  action: {
    TAKE_QUICK_TOUR_ON_WELCOME_MODAL: 'Take quick tour on welcome modal',
    TAKE_PATHWAYS_TOUR_ON_USER_BOX: 'Take Pathways tour on user box',
    TAKE_QUICK_TOUR_ON_TIPS_MODAL:
      'Take a quick tour on Archetypes, Competencies and Skills modal'
  }
};

export const JUMP_TO_JIGSAW_FROM_PATHWAY = {
  category: 'Jump to Jigsaw from Pathway',
  action: {
    GO_TO_JIGSAW_ON_SHARE_BOX: 'Go to Jigsaw on share box',
    GO_TO_JIGSAW_ON_USER_BOX: 'Go to Jigsaw on user box'
  }
};

export const VISIBILITY_PREFERENCE_EVENT = date => {
  return {
    category: `visibility preference event`,
    action: {
      OPEN_FROM_SYSTEM: 'open from system',
      CLICK_SAVE_PREFERENCE_FROM_SYSTEM: 'click save preference from system',
      OPEN_FROM_EMAIL: `open from email at ${date}`,
      CLICK_SAVE_PREFERENCE_FROM_EMAIL: `click save preference from email at ${date}`
    }
  };
};

export const COURSE_REC_FROM_DASHBOARD = {
  category: `Course rec from dashboard`,
  action: {
    OPEN_DETAIL_FROM_ME_NOW:
      'Open archetype detail pop up from me now dashboard',
    OPEN_DETAIL_FROM_ME_NEXT:
      'Open archetype detail pop up from me next dashboard',
    CLICK_COURSE_TAB_FROM_ME_NOW: `Click top courses tab from me now dashboard`,
    CLICK_COURSE_TAB_FROM_ME_NEXT: `Click top courses tab from me next dashboard`,
    CLICK_INTO_COURSE_TAB_FROM_ME_NOW: `Click into the course from me now dashboard`,
    CLICK_INTO_COURSE_TAB_FROM_ME_NEXT: `Click into the course from me next dashboard`
  }
};

export const COURSE_REC_FROM_SELECT_ARCHETYPE_EVENT = {
  category: `Course rec from select archetype`,
  action: {
    OPEN_ARCHETYPE_DETAIL_POP_UP:
      'Open archetype detail pop up from select archetype page',
    CLICK_TOP_COURSE_TAB: 'Click top course tab from select archetype page',
    CLICK_INTO_THE_COURSE:
      'Click into the course form from select archetype page'
  }
};

export const COURSE_REC_STANDALONE_PAGE_EVENT = {
  category: `Course rec standalone page`,
  action: {
    JUMP_TO_STANDALONE_PAGE_VIA_THE_TOP_COURSE_TAB:
      'Jump to standalone page via the top course tab',
    CLICK_INTO_COURSE_FROM_TOP_COURSE_VIA_STANDALONE_ME_NOW:
      'Click into course from top course via standalone me now',
    CLICK_INTO_COURSE_FROM_TOP_COURSE_VIA_STANDALONE_ME_NEXT:
      'Click into course from top course via standalone me next',
    JUMP_TO_STANDALONE_PAGE_VIA_THE_DASHBOARD:
      'Jump to standalone page via the dashboard',
    CLICK_COURSE_FOR_OTHER_ARCHETYPES_BUTTON:
      'Click course for other archetypes button'
  }
};

export const COURSE_REC_FEEDBACK = {
  category: `Course rec feedback`,
  action: {
    OPEN_THE_THUMB_UP_THUMB_DOWN_FROM_MODAL:
      'Number of clicks from archetype detail pop up to open the thumb up/down',
    CLICK_THE_THUMB_UP_THUMB_DOWN_FROM_MODAL:
      'Number of thumb up/down clicks from archetype detail pop up',
    OPEN_THE_THUMB_UP_THUMB_DOWN_FROM_RECOMMENDED_STANDALONE_PAGE:
      'Number of clicks from standalone page to open the thumb up/down',
    CLICK_THE_THUMB_UP_THUMB_DOWN_FROM_RECOMMENDED_STANDALONE_PAGE:
      'Number of thumb up/down clicks from standalone page'
  }
};
