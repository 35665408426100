import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import globalStyles from '../../globalStyles';
import { styled } from '@mui/material/styles';
export const ErrorButton = styled(Button)({
  width: '157px',
  height: '58px',
  fontSize: '16px',
  fontWeight: 'bold',
  color: globalStyles.white,
  backgroundColor: globalStyles.black,
  lineHeight: '1.5',
  textTransform: 'capitalize',
  '&:hover': {
    backgroundColor: globalStyles.black
  },
  '&:focus': {
    backgroundColor: globalStyles.black,
    outline: 'none'
  }
});

export const CloseButton = props => {
  return <ErrorButton onClick={props.closeErrorBox}>Close</ErrorButton>;
};

CloseButton.propTypes = {
  closeErrorBox: PropTypes.func.isRequired
};

export const GoHomepageButton = () => {
  return (
    <ErrorButton
      component={Link}
      to={{
        pathname: '/'
      }}
    >
      Homepage
    </ErrorButton>
  );
};
