import React, { useState } from 'react';
import styles from './index_style1.module.css';
import RelevantIconBlack from '../../assets/Icons/thumb-up-black.svg';
import RelevantIconWhite from '../../assets/Icons/thumb-up-white.svg';
import NotRelevantIconBlack from '../../assets/Icons/thumb-down-black.svg';
import NotRelevantIconWhite from '../../assets/Icons/thumb-down-white.svg';
import CloseIcon from '../../assets/Icons/close.svg';
import FeedbackIcon from '../../assets/Icons/feedback.svg';
import courseRecPageStyles from './index_style2.module.css';
import { PathwaysTrackEvents } from '../../support/pathwaysTrack';
import {
  COURSE_REC_FEEDBACK,
  COURSE_REC_FROM_DASHBOARD,
  COURSE_REC_FROM_SELECT_ARCHETYPE_EVENT
} from '../../constants/matomo';
import { DASHBOARD_URL_TAB } from '../../constants/dashborad';
import {
  COURSE_RECOMMENDATIONS,
  DASHBOARD,
  SELECT_ARCHETYPES
} from '../../constants/path';
import { upsertCourseRecFeedback } from '../../api/domain/courseRecommendation';
import { COURSE_REC_PAGE } from '../../constants/modalConstants';
import { onKeyDown } from '../../utils/onKeyDown';
import { getUrlParam } from '../../components/Utils/CommonUtil';

const FEEDBACK_TYPE_ENUM = {
  RELEVANT: 'relevant',
  NOT_RELEVANT: 'notRelevant'
};

export const RecommendedCourse = ({
  course,
  archetypeId,
  type,
  courseTrackEvent
}) => {
  const initFeedbackType = isRelevant => {
    switch (isRelevant) {
      case true:
        return FEEDBACK_TYPE_ENUM.RELEVANT;
      case false:
        return FEEDBACK_TYPE_ENUM.NOT_RELEVANT;
      default:
        return null;
    }
  };
  const { url, name, thumbnail, enrolledNum, courseDuration } = course;
  const actualStyles = type === COURSE_REC_PAGE ? courseRecPageStyles : styles;
  const [showFeedback, setShowFeedback] = useState(false);
  const [feedbackType, setFeedbackType] = useState(
    initFeedbackType(course.isRelevant)
  );
  const [onHoverRelevant, setOnHoverRelevant] = useState(false);
  const [onHoverNotRelevant, setOnHoverNotRelevant] = useState(false);
  const isFromModal =
    window.location.pathname === SELECT_ARCHETYPES ||
    window.location.pathname === DASHBOARD;
  const isFromStandalonePage =
    window.location.pathname === COURSE_RECOMMENDATIONS;
  const jumpToCampusCourse = trackEvent => {
    trackEvent && trackEvent();

    const isFromDashboard = window.location.pathname === DASHBOARD;
    const dashboardTab = getUrlParam('target') || DASHBOARD_URL_TAB.ME_NOW;

    isFromDashboard &&
      PathwaysTrackEvents([
        {
          category: COURSE_REC_FROM_DASHBOARD.category,
          action:
            dashboardTab === DASHBOARD_URL_TAB.ME_NOW
              ? COURSE_REC_FROM_DASHBOARD.action
                  .CLICK_INTO_COURSE_TAB_FROM_ME_NOW
              : COURSE_REC_FROM_DASHBOARD.action
                  .CLICK_INTO_COURSE_TAB_FROM_ME_NEXT
        }
      ]);

    const isFromSelectPage = window.location.pathname === SELECT_ARCHETYPES;
    isFromSelectPage &&
      PathwaysTrackEvents([
        {
          category: COURSE_REC_FROM_SELECT_ARCHETYPE_EVENT.category,
          action:
            COURSE_REC_FROM_SELECT_ARCHETYPE_EVENT.action.CLICK_INTO_THE_COURSE
        }
      ]);
  };
  const upsertCourseFeedback = async isRelevant => {
    isRelevant
      ? setFeedbackType(FEEDBACK_TYPE_ENUM.RELEVANT)
      : setFeedbackType(FEEDBACK_TYPE_ENUM.NOT_RELEVANT);
    await upsertCourseRecFeedback({
      courseId: course.id,
      archetypeId: archetypeId,
      isRelevant: isRelevant
    });

    isFromModal &&
      PathwaysTrackEvents([
        {
          category: COURSE_REC_FEEDBACK.category,
          action:
            COURSE_REC_FEEDBACK.action.CLICK_THE_THUMB_UP_THUMB_DOWN_FROM_MODAL
        }
      ]);

    isFromStandalonePage &&
      PathwaysTrackEvents([
        {
          category: COURSE_REC_FEEDBACK.category,
          action:
            COURSE_REC_FEEDBACK.action
              .CLICK_THE_THUMB_UP_THUMB_DOWN_FROM_RECOMMENDED_STANDALONE_PAGE
        }
      ]);
  };

  const showThumbUp = () => {
    const isOnHoverRelevant = onHoverRelevant
      ? RelevantIconBlack
      : RelevantIconWhite;

    return (
      <img
        className={actualStyles.relevantIcon}
        src={
          feedbackType === FEEDBACK_TYPE_ENUM.RELEVANT
            ? RelevantIconBlack
            : isOnHoverRelevant
        }
        alt={'thumb up'}
      />
    );
  };

  const showThumbDown = () => {
    const isOnHoverNotRelevant = onHoverNotRelevant
      ? NotRelevantIconBlack
      : NotRelevantIconWhite;

    return (
      <img
        className={actualStyles.notRelevantIcon}
        src={
          feedbackType === FEEDBACK_TYPE_ENUM.NOT_RELEVANT
            ? NotRelevantIconBlack
            : isOnHoverNotRelevant
        }
        alt={'thumb down'}
      />
    );
  };

  const onClose = () => {
    setShowFeedback(false);
  };

  const givingFeedback = (courseFeedbackType, isRelevant) => {
    return feedbackType !== courseFeedbackType
      ? () => upsertCourseFeedback(isRelevant)
      : () => {
          /* nothing need to do */
        };
  };

  const getClassNameOfGivingFeedbackButton = isRelevant => {
    if (isRelevant) {
      return feedbackType === FEEDBACK_TYPE_ENUM.RELEVANT
        ? actualStyles.selectedFeedbackItem
        : actualStyles.notSelectedFeedbackItem;
    }
    return feedbackType === FEEDBACK_TYPE_ENUM.NOT_RELEVANT
      ? actualStyles.selectedFeedbackItem
      : actualStyles.notSelectedFeedbackItem;
  };
  const openFeedback = () => {
    setShowFeedback(true);
    isFromModal &&
      PathwaysTrackEvents([
        {
          category: COURSE_REC_FEEDBACK.category,
          action:
            COURSE_REC_FEEDBACK.action.OPEN_THE_THUMB_UP_THUMB_DOWN_FROM_MODAL
        }
      ]);
    isFromStandalonePage &&
      PathwaysTrackEvents([
        {
          category: COURSE_REC_FEEDBACK.category,
          action:
            COURSE_REC_FEEDBACK.action
              .OPEN_THE_THUMB_UP_THUMB_DOWN_FROM_RECOMMENDED_STANDALONE_PAGE
        }
      ]);
  };

  return (
    <div className={actualStyles.courseCard}>
      <div className={actualStyles.topCoursesImgContainer}>
        <a
          role="button"
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => jumpToCampusCourse(courseTrackEvent)}
        >
          <img
            className={actualStyles.topCoursesImg}
            src={thumbnail}
            alt={'thumbnail of ' + name}
          />
        </a>
      </div>
      {showFeedback ? (
        <div className={actualStyles.topCoursesFeedbackContainer}>
          <div className={actualStyles.feedback}>
            <div
              role="button"
              className={getClassNameOfGivingFeedbackButton(true)}
              onMouseEnter={() => setOnHoverRelevant(true)}
              onMouseLeave={() => setOnHoverRelevant(false)}
              onClick={givingFeedback(FEEDBACK_TYPE_ENUM.RELEVANT, true)}
              tabIndex={0}
              aria-label="giving a feedback that the recommended course is relevant"
            >
              {showThumbUp()}
              Relevant
            </div>
            <div
              role="button"
              className={getClassNameOfGivingFeedbackButton(false)}
              onMouseEnter={() => setOnHoverNotRelevant(true)}
              onMouseLeave={() => setOnHoverNotRelevant(false)}
              onClick={givingFeedback(FEEDBACK_TYPE_ENUM.NOT_RELEVANT, false)}
              tabIndex={0}
              aria-label="giving a feedback that the recommended course is not relevant"
            >
              {showThumbDown()}
              Not relevant
            </div>
          </div>
          <div
            role="button"
            className={actualStyles.close}
            tabIndex={0}
            onClick={() => onClose()}
            onKeyDown={e => onKeyDown(e, () => onClose())}
            aria-label="won't give feedback and go back"
          >
            <img
              className={actualStyles.closeIcon}
              src={CloseIcon}
              alt={'close'}
            />
          </div>
        </div>
      ) : (
        <div className={actualStyles.topCoursesTextContainer}>
          <div className={actualStyles.courseInfo}>
            <div className={actualStyles.courseName}>
              <a
                role="button"
                className={actualStyles.coursesLink}
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => jumpToCampusCourse()}
                aria-label="jump to campus for the course"
              >
                {name}
              </a>
            </div>
            <p className={actualStyles.enrolledInfo}>
              {(+enrolledNum).toLocaleString()} enrolled ·{' '}
              {Math.floor(courseDuration / 60)}h{courseDuration % 60}min
            </p>
          </div>
          <div
            role="button"
            className={actualStyles.courseFeedback}
            onClick={() => openFeedback()}
            onKeyDown={e => onKeyDown(e, () => openFeedback())}
            tabIndex={0}
            aria-label="a pop up to give feedback"
          >
            <img
              className={actualStyles.feedbackIcon}
              src={FeedbackIcon}
              alt={'a button to give feedback'}
            />
          </div>
        </div>
      )}
    </div>
  );
};
