export const CONFIRM_BUTTON_TEXT = 'Remove';

export const CANCEL_BUTTON_TEXT = 'Cancel';

export const REMOVE_ARCHETYPE_TITLE = 'Are you sure you want to remove?';

export const REMOVE_ARCHETYPE_CONTENT =
  "Don't worry, you can still retrieve all information on this archetype.";

export const REMOVE_ACHIEVEMENT_CONTENT =
  'This entry will be permanently removed.';

export const REMOVE_ACHIEVEMENT_TITLE = 'Are you sure?';

export const COURSE_REC_PAGE = 'courseRecPage';

export const GUIDE_STEPS_WITH_TOP_COURSES = 6;

export const GUIDE_STEPS_WITHOUT_TOP_COURSES = 5;

export const CATEGORY_EMPTY = 'searchCategoryPageEmpty';

export const SKILL_LIST_EMPTY = 'SkillsListSearchEmpty';

export const MISSING_CATEGORY = 'missingCategoryTips';

export const COMPETENCIES_LIST_EMPTY = 'CompetenciesListSearchEmpty';
