import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useUserContext } from '../../../contexts/UserContext';
import { formatToEN } from '../../../utils/datetimeFormat';
import styles from './index.module.css';
import { DASHBOARD_TAB_URL_ENUM } from '../../../constants/dashborad';
import { onKeyDown } from '../../../utils/onKeyDown';
import { getUrlParam } from '../../../components/Utils/CommonUtil';
import { dashboardDes } from '../language';
import GuideModal from '../components/GuideModal';

const ToolsBar = ({ value, options, onChange, handleSetPDFDisplay }) => {
  const {
    state: {
      user: { guideStepNumber = 0, overallUpdatedAt }
    }
  } = useUserContext();
  const toolsBarDes = dashboardDes.toolsBar;

  const [guideModalShow, setGuideModalShow] = useState(true);
  const [beforeTabIndex, setBeforeTabIndex] = useState(0);
  const [_value, _setValue] = useState(0);

  const updateTime = useMemo(() => {
    if (!overallUpdatedAt) {
      return;
    }
    return formatToEN(overallUpdatedAt);
  }, [overallUpdatedAt]);

  const handleTabChange = index => {
    _setValue(index);
    onChange(index);
  };
  const closeGuideModal = () => {
    setGuideModalShow(false);
  };
  const updateGuideModal = curStepNumber => {
    if (+curStepNumber > 3) {
      handleTabChange(0);
      setGuideModalShow(false);
    } else {
      handleTabChange(curStepNumber - 1);
    }
  };

  useEffect(() => {
    if (value !== undefined) {
      _setValue(value);
    }
  }, [value]);

  useEffect(() => {
    const dashboardTab = getUrlParam('target');
    const tabIndex = DASHBOARD_TAB_URL_ENUM[dashboardTab] || 0;

    if (+guideStepNumber > 0 && +guideStepNumber < 4) {
      if (+guideStepNumber === 1) {
        setBeforeTabIndex(tabIndex);
      }
      setGuideModalShow(true);
      handleTabChange(guideStepNumber - 1);
    } else {
      if (+guideStepNumber === -1) {
        handleTabChange(beforeTabIndex);
      }
      setGuideModalShow(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guideStepNumber]);

  return (
    <div className={styles.toolsBar}>
      <div className={styles.tabs} role="tablist">
        {options.map((option, index) => {
          const selected = _value === index;
          return (
            <div
              role="tab"
              aria-label={option.title}
              data-cy={option.title}
              onClick={() => handleTabChange(index)}
              onKeyDown={e => onKeyDown(e, () => handleTabChange(index))}
              tabIndex={0}
              key={option.title}
              className={clsx(styles.tabItem, {
                [styles.active]: selected
              })}
            >
              {selected && (
                <GuideModal
                  guideModalShow={guideModalShow}
                  closeGuideModal={closeGuideModal}
                  updateGuideModal={updateGuideModal}
                />
              )}
              <div className={styles.tabItemTitle} tabIndex={-1}>
                {option.title}
              </div>
              <img
                className={clsx(styles.dude, {
                  [styles.hidden]: !selected
                })}
                style={option.style}
                src={option.dude}
                alt={option.title}
              />
              <div className={styles.bottomLine} />
            </div>
          );
        })}
      </div>
      <div className={styles.right}>
        <div
          className={clsx(styles.lastUpdateTime, !updateTime && styles.hidden)}
        >
          <div>{toolsBarDes.lastUpdate}</div>
          <span className={styles.lastUpdateTimeText}>{updateTime}</span>
        </div>
        {updateTime && <div className={styles.splitLine} />}
        <div
          className={styles.exportPDF}
          onClick={handleSetPDFDisplay}
          tabIndex="0"
          onKeyDown={e => onKeyDown(e, handleSetPDFDisplay)}
          role="button"
          aria-label="download pdf document with my growth canvas"
        >
          {toolsBarDes.downloadPDF}
        </div>
      </div>
    </div>
  );
};

ToolsBar.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  handleSetPDFDisplay: PropTypes.func
};

export default ToolsBar;
