import React from 'react';
import { Modal } from '@mui/material';
import Slide from '@mui/material/Slide';
import pathwayStyles from './PathwayUpdates.module.css';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import SkillReleaseNotes from './SkillReleaseNotes/SkillReleaseNotes';
import { CloseIconButton } from '../../../../nonFeatureComponents/Buttons/Buttons';

export const SKILL_RELEASED = 'skill-released';

const modalStyles = {
  display: 'flex',
  padding: '8px',
  alignItems: 'center',
  justifyContent: 'center'
};
const PathwayUpdates = ({ open, onClose, type, user, skills }) => {
  switch (type) {
    case SKILL_RELEASED:
      return (
        <Modal
          disablePortal
          disableEnforceFocus
          open={open}
          onClose={onClose}
          sx={modalStyles}
        >
          <Slide direction="up" in={open}>
            <main
              data-cy="pathwayUpdatesModal"
              className={clsx(pathwayStyles.modal, pathwayStyles.smaller)}
              tabIndex={-1}
            >
              <header className={pathwayStyles.header}>
                <div
                  className={clsx(
                    pathwayStyles.titleContent,
                    pathwayStyles.headerFix
                  )}
                >
                  <span>
                    {skills?.length > 0
                      ? 'Pathways has now skills!'
                      : 'Pathways has new features!'}
                  </span>
                </div>
                <CloseIconButton handleClick={onClose} />
              </header>
              <section
                className={clsx(pathwayStyles.body, pathwayStyles.noPadding)}
              >
                <SkillReleaseNotes
                  name={user?.firstName}
                  strength={skills}
                  onClose={onClose}
                />
              </section>
            </main>
          </Slide>
        </Modal>
      );
    default:
      return null;
  }
};
PathwayUpdates.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};
export default PathwayUpdates;
