import ArrowTooltip from '../../../../components/Tooltip/ArrowTooltip';
import InformationIcon from '../../../../components/Common/SvgIcons/Information';
import globalStyles from '../../../../globalStyles';
import styles from '../../../../feature/ReflectItem/index.module.css';
import React from 'react';
import * as CompetenciesStorage from '../../../../components/Utils/competenciesStorage';
import * as SkillsStorage from '../../../../components/Utils/skillsStorage';

export const InformationTooltipStyles = {
  '& .MuiTooltip-tooltip': {
    position: 'relative',
    padding: '15px',
    maxWidth: '480px',
    marginTop: '-1em',
    marginBottom: '-1em',
    borderRadius: '7px',
    lineHeight: 1.6,
    backgroundColor: '#545970',
    fontSize: '16px',
    color: globalStyles.white
  }
};

export const reflectListTextInfo = goMeNextVisPreferences => {
  return {
    Competencies: {
      firstColumn: 'Competencies selected',
      secondColumn: 'Current practice level',
      thirdColumn: (
        <ArrowTooltip
          title={
            <div>
              <p style={{ margin: 0, padding: '4px' }}>
                Shape your Plan by considering which Competencies you want to
                develop next; mark them as a ‘Learning Priority’ so you can keep
                track of them.
              </p>
              <p style={{ margin: 0, padding: '4px' }}>
                <span
                  onClick={goMeNextVisPreferences}
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer'
                  }}
                >
                  Check your 'Me Next' visibility preferences
                </span>{' '}
                to make sure your Learning Priorities are visible to staffing
                teams. This will let them know which Competencies you would like
                to get more opportunities to work on.
              </p>
            </div>
          }
          interactive={'true'}
          placement="top"
          classes={InformationTooltipStyles}
        >
          <div className={styles.markAs}>
            This competency is
            <InformationIcon
              color={'#212223'}
              fontSize={'16px'}
              opacity={1}
              className={styles.informationIcon}
            />
          </div>
        </ArrowTooltip>
      ),
      storage: CompetenciesStorage,
      selectedName: JSON.parse(
        localStorage.getItem('competencies_storage_key')
      ),
      newSelectedName: JSON.parse(
        localStorage.getItem('newSelectedCompetencyName')
      ),
      newSelectedNameString: localStorage.getItem('newSelectedCompetencyName'),
      defaultFilterValue: 'All selected competencies',
      coreCategoryMapping: {
        'Core competencies - Consulting': 'Consulting',
        'Core competencies - Cultivation': 'Cultivation',
        'Core competencies - Impact': 'Impact',
        'Core competencies - Leadership': 'Leadership'
      },
      coreCategoryRevertMapping: {
        Consulting: 'Core competencies - Consulting',
        Cultivation: 'Core competencies - Cultivation',
        Impact: 'Core competencies - Impact',
        Leadership: 'Core competencies - Leadership'
      }
    },
    Skills: {
      firstColumn: 'Skills selected',
      secondColumn: 'Current practice level',
      thirdColumn: (
        <ArrowTooltip
          interactive={'true'}
          classes={InformationTooltipStyles}
          placement="top"
          title={
            <div>
              <p style={{ margin: 0, padding: '4px' }}>
                Shape your Plan by considering which Skills you want to develop
                next; mark them as a ‘Learning Priority’ so you can keep track
                of them.
              </p>
              <p style={{ margin: 0, padding: '4px' }}>
                <span
                  onClick={goMeNextVisPreferences}
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer'
                  }}
                >
                  Check your 'Me Next' visibility preferences
                </span>{' '}
                to make sure your Learning Priorities are visible to staffing
                teams. This will let them know which Skills you would like to
                get more opportunities to work on.
              </p>
            </div>
          }
        >
          <div className={styles.markAs}>
            This skill is
            <InformationIcon
              color={'#212223'}
              fontSize={'16px'}
              opacity={1}
              className={styles.informationIcon}
            />
          </div>
        </ArrowTooltip>
      ),
      storage: SkillsStorage,
      selectedName: JSON.parse(localStorage.getItem('skills_storage_key')),
      newSelectedName: JSON.parse(localStorage.getItem('newSelectedSkillName')),
      newSelectedNameString: localStorage.getItem('newSelectedSkillName'),
      defaultFilterValue: 'All selected skills',
      coreCategoryMapping: {}
    }
  };
};
export const behaviourList = [
  {
    Definition:
      'Want to learn for career development. Never done it, knowledge is theoretical only. I am interested in this.',
    descriptions: [
      { key: 'Knowledge', value: 'Theoretical' },
      { key: 'Teaching/Coaching', value: 'Learning mode' },
      { key: 'Pairing', value: 'Cannot lead' },
      { key: 'Influence', value: 'N/A' },
      { key: 'Recognition of skill', value: 'N/A' },
      { key: 'Environment', value: 'Needs a safe environment to deliver.' }
    ]
  },
  {
    Definition:
      'Practiced some. Knowledge is practical but limited; have played around a little but haven’t gone into depth.',
    descriptions: [
      { key: 'Knowledge', value: 'Limited practical' },
      { key: 'Teaching/Coaching', value: 'Learning mode - Learning to teach' },
      { key: 'Pairing', value: 'Can pair and contribute' },
      { key: 'Influence', value: 'Learning how to exert influence' },
      { key: 'Recognition of skill', value: 'N/A' },
      { key: 'Environment', value: 'Needs a safe environment to deliver.' }
    ]
  },
  {
    Definition:
      "I've worked with it, I've gone into some depth, and I'm comfortable beginning to guide others. I can pair with other TWers and influence at my peer level and with folks I'm guiding. I'm starting to understand how to influence the client and stakeholders.",
    descriptions: [
      { key: 'Knowledge', value: 'Practical' },
      { key: 'Teaching/Coaching', value: 'Beginning to teach TWers' },
      { key: 'Pairing', value: 'Can lead pairing with peers' },
      {
        key: 'Influence',
        value:
          'Can influence at peer level, and is starting to understand how to with client.'
      },
      { key: 'Recognition of skill', value: 'Recognized by peers' },
      {
        key: 'Environment',
        value:
          'Can deliver in some complex environments, as long as with more senior team members around.'
      }
    ]
  },
  {
    Definition:
      'Can lead pairing on a project no matter who their pair is. Very comfortable in the environment and can teach others at different skill levels and in different project situations. Can pair comfortably with anyone including the client. Can influence people in a safe environment, and has experience with more than one project.',
    descriptions: [
      { key: 'Knowledge', value: 'Practical' },
      { key: 'Teaching/Coaching', value: 'Teaching TWers/Clients' },
      { key: 'Pairing', value: 'Can lead pairing with clients and TWers' },
      {
        key: 'Influence',
        value: 'Can influence TWers and client in a safe environment'
      },
      { key: 'Recognition of skill', value: 'Recognized by ThoughtWorks' },
      {
        key: 'Environment',
        value:
          'Can deliver in some complex environments, being tech complexity, project size or politics.'
      }
    ]
  },
  {
    Definition:
      'Knowledge & skill in the area is recognized by others, and can share the expertise within the discipline. Can teach others, speak at conferences, influence people with opposing view points and/or in an unknown environment. Done this multiple times in diff situations and contexts. Recognized by your peers in your role, looked to for advice, knowledge and still, and is comfortable in most of the situations.',
    descriptions: [
      { key: 'Knowledge', value: 'Practical' },
      { key: 'Teaching/Coaching', value: 'Can teach anyone' },
      { key: 'Pairing', value: 'Can lead pairing with anyone' },
      { key: 'Influence', value: 'Can influence in any environment' },
      {
        key: 'Recognition of skill',
        value: 'Recognized by ThoughtWorks and outside market'
      },
      { key: 'Environment', value: 'Can deliver regardless of complexity' }
    ]
  }
];
export const getBehaviourDom = () => {
  return behaviourList.map((behaviourItem, behaviourIndex) => {
    let definitionDom = `<p style='color:#212223'>${behaviourItem.Definition}</p>`;
    let descriptionsDom = '';
    behaviourItem.descriptions.forEach(descriptionItem => {
      descriptionsDom += `<p style='color:#212223'><span style='font-weight: bold;padding-right: 5px'>${descriptionItem.key}:</span>${descriptionItem.value}</p>`;
    });
    return `${definitionDom}${descriptionsDom}`;
  });
};
const behaviourDom = getBehaviourDom();

export const skillLevelBehaviours = [
  {
    level: 1,
    description: behaviourDom[0]
  },
  {
    level: 2,
    description: behaviourDom[1]
  },
  {
    level: 3,
    description: behaviourDom[2]
  },
  {
    level: 4,
    description: behaviourDom[3]
  },
  {
    level: 5,
    description: behaviourDom[4]
  }
];
