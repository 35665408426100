import { deleteREST, get, put, patch } from '../util/ajax';
export const fetchPeopleSkills = params => {
  return get('people/skills', { params });
};

export const updatePeopleSkills = body => {
  return put('people/skills', body);
};

export const updateSkill = (id, body) => {
  return patch(`people/skills/${id}`, body);
};

export const removeSkillById = id => {
  return deleteREST(`people/skills/${id}`);
};
