import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Slide from '@mui/material/Slide';
import Modal from '@mui/material/Modal';
import PropTypes from 'prop-types';
import styles from './ArchetypeDetailBaseModal.module.css';
import clsx from 'clsx';
import ArrowLeftIcon from '../../../../SvgIcons/ArrowLeftIcon';
import { onKeyDown } from '../../../../../../utils/onKeyDown';
import { styled } from '@mui/material/styles';
import { CloseIconButton } from '../../../../../../nonFeatureComponents/Buttons/Buttons';

const ModalStyles = styled(Modal)({
  display: 'flex',
  padding: '8px',
  alignItems: 'center',
  justifyContent: 'center',
  overflowY: 'auto'
});

const BackdropStyles = styled(Backdrop)({
  background: 'rgba(0, 0, 0, 0.5)',
  zIndex: -1
});
const ArchetypeDetailBaseModal = ({
  showBackButton,
  onBack,
  open,
  close,
  title,
  children
}) => {
  return (
    <ModalStyles
      role="dialog"
      open={open}
      onClose={close}
      BackdropComponent={BackdropStyles}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Slide direction="up" in={open}>
        <main data-cy="archetypeDetailModal" className={styles.main}>
          <header className={styles.header}>
            <div className={styles.iconWrapper}>
              {showBackButton && (
                <div className={styles.iconWrapper}>
                  <div
                    role="button"
                    aria-label="close"
                    tabIndex={0}
                    onClick={onBack}
                    onKeyDown={e => onKeyDown(e, onBack)}
                    className={styles.backIcon}
                  >
                    <span className={styles.backIconInner} tabIndex={-1}>
                      <ArrowLeftIcon
                        role="button"
                        titleAccess="Back to inherited archetype"
                        data-cy="backButton"
                      />
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className={styles.titleContent} data-cy="tittle">
              {title}
            </div>

            <div className={styles.iconWrapper} data-cy="closeButton">
              <CloseIconButton handleClick={close} />
            </div>
          </header>
          <section className={clsx(styles.body, styles.primaryScrollbar)}>
            {children}
          </section>
        </main>
      </Slide>
    </ModalStyles>
  );
};
ArchetypeDetailBaseModal.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  showBackButton: PropTypes.bool.isRequired,
  onBack: PropTypes.func.isRequired,
  backText: PropTypes.string.isRequired,
  children: PropTypes.element
};
export default ArchetypeDetailBaseModal;
