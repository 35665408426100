import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.css';
import { dashboardDes } from '../../language';

export const ArchetypesLabel = 'Archetypes: ';
export const CoreCompetenciesLabel = 'Core Competencies: ';

export const RelatedContributionForGoal = ({
  archetypes,
  coreCapabilities,
  pageType
}) => {
  const bothListIsEmpty = useMemo(() => {
    return archetypes.length === 0 && coreCapabilities.length === 0;
  }, [archetypes, coreCapabilities]);
  return (
    <div className={styles[pageType]}>
      {bothListIsEmpty ? (
        <p>{dashboardDes.myPlanCardDes.contribution.bothListEmptyTitle}</p>
      ) : (
        <p className={styles.subTitle}>
          {dashboardDes.myPlanCardDes.contribution.notEmptyTitle}
        </p>
      )}
      {archetypes.length > 0 && (
        <p>
          <span className={styles.title}>{ArchetypesLabel}</span>
          <span className={styles.archetypes}>{archetypes.join(', ')}</span>
        </p>
      )}
      {coreCapabilities.length > 0 && (
        <p>
          <span className={styles.title}>{CoreCompetenciesLabel}</span>
          {coreCapabilities.map((coreCapability, index) => {
            return (
              <Fragment key={coreCapability}>
                {index !== 0 && ', '}
                <span className={styles.coreCapability}>{coreCapability}</span>
              </Fragment>
            );
          })}
        </p>
      )}
    </div>
  );
};
RelatedContributionForGoal.propTypes = {
  archetypes: PropTypes.array,
  coreCapabilities: PropTypes.array,
  pageType: PropTypes.string
};
