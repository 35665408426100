import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, useLocation } from 'react-router-dom';

import ErrorPage from '../../components/ErrorPage/ErrorPage';
import ScrollToTop from './ScrollToTop';
import HeaderBar from '../../components/HeaderBar/HeaderBar';
import UserName from '../../components/HeaderBar/UserName';
import Footer from '../../components/Footer/Footer';
import { PathNameAndPageTitle } from '../../constants/pageTitle';

import { useUserContext } from '../../contexts/UserContext';

import styles from './PathwaysRouter.module.css';
import * as CryptoJS from 'crypto-js';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { MatomoInstanceContext } from '../../contexts/MatomoInstanceContext';

import {
  CustomName,
  setCustomVariable
} from '../../components/Utils/matomoUtil';
import {
  CoreCapabilityFlowRoutes,
  HTMLFormPathname,
  routesRender
} from './PathwaysRouterConstants';
import { isNil } from 'lodash';
import { VISIBILITY_PREFERENCE_EVENT } from '../../constants/matomo';
import clsx from 'clsx';

export const isUserFromEmail = pageUrl => {
  return (
    pageUrl.indexOf('fromEmail=true') > -1 && pageUrl.indexOf('date=') > -1
  );
};

const trackUserOpenVisibilityPreference = trackEvent => {
  const pageUrl = window.location.href || '';
  if (
    isUserFromEmail(pageUrl) &&
    pageUrl.indexOf('visibility-preference') > -1
  ) {
    const date = pageUrl.split('date=')[1];
    trackEvent({
      category: VISIBILITY_PREFERENCE_EVENT(date).category,
      action: VISIBILITY_PREFERENCE_EVENT(date).action.OPEN_FROM_EMAIL
    });
  } else if (pageUrl.indexOf('visibility-preference') > -1) {
    trackEvent({
      category: VISIBILITY_PREFERENCE_EVENT().category,
      action: VISIBILITY_PREFERENCE_EVENT().action.OPEN_FROM_SYSTEM
    });
  }
};

const SetMatomo = () => {
  const {
    state: { user }
  } = useUserContext();
  const { trackEvent, pushInstruction, trackPageView } = useMatomo();
  const location = useLocation();
  const trackedPathname = useRef('');

  setCustomVariable(CustomName.WORKINGOFFICE, user?.workingOffice);
  setCustomVariable(CustomName.EMAIL, user?.email);

  const hashEmail = CryptoJS.SHA256(user.email).toString();

  if (isNil(typeof window._paq)) {
    pushInstruction('setUserId', hashEmail);
  }

  const { instance } = useContext(MatomoInstanceContext);
  window.ins = instance;

  useEffect(() => {
    if (hashEmail && user) {
      const { pathname } = location;
      const paq = window._paq || [];
      paq.push(['setUserId', hashEmail]);
      paq.push(['setCustomVariable', 1, 'email', user?.email, 'visit']);
      paq.push(['setCustomDimension', 1, user?.role]);
      paq.push(['setCustomDimension', 2, user?.departmentCode]);
      paq.push(['setCustomDimension', 3, user?.workingCountry]);

      if (trackedPathname.current !== pathname) {
        trackedPathname.current = pathname;

        const currentPageTitle = PathNameAndPageTitle.find(
          item => pathname === item.pathName
        )?.pageTitle;
        trackPageView({ documentTitle: currentPageTitle });

        trackUserOpenVisibilityPreference(trackEvent);
      }
    }
  }, [hashEmail, location, trackEvent, trackPageView, user]);
};

const PathwaysRouter = () => {
  SetMatomo();
  const {
    state: {
      user: { guideStepNumber = 0 }
    }
  } = useUserContext();
  const [isGuideShowState, setGuideShowState] = useState(false);
  const [isTopCourseGuideShow, setIsTopCourseGuideShow] = useState(false);

  useEffect(() => {
    switch (+guideStepNumber) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        setGuideShowState(true);
        setIsTopCourseGuideShow(false);
        break;
      case 6:
      case 10000:
        setIsTopCourseGuideShow(true);
        setGuideShowState(false);
        break;
      default:
        setIsTopCourseGuideShow(false);
        setGuideShowState(false);
    }
  }, [guideStepNumber]);

  const [pageHeight, setPageHeight] = useState(true);

  const isHtmlForm = () => {
    const pathname = window.location.pathname;
    return HTMLFormPathname.some(item => {
      return item === pathname;
    });
  };

  const isHtmlFormState = isHtmlForm();
  const routesDom = routesRender(
    CoreCapabilityFlowRoutes({ isGuideShowState })
  );

  return (
    <div
      className={clsx(
        (isTopCourseGuideShow || isGuideShowState) && styles.showGuide
      )}
    >
      <ScrollToTop />
      {!isHtmlFormState && (
        <HeaderBar
          userName={<UserName isGuideShowState={isGuideShowState} />}
          isGuideShowState={isGuideShowState}
          isTopCourseGuideShow={isTopCourseGuideShow}
        />
      )}
      <div className={pageHeight ? styles.route : styles.errorPageHeight}>
        <Switch>
          {routesDom}
          <Route render={_ => <ErrorPage changeMinHeight={setPageHeight} />} />
        </Switch>
      </div>
      {!isHtmlFormState && <Footer />}
    </div>
  );
};

PathwaysRouter.propTypes = {
  user: PropTypes.shape({
    addedArchetypes: PropTypes.array.isRequired,
    addedFamilies: PropTypes.array.isRequired,
    competencies: PropTypes.array,
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    departmentCode: PropTypes.string.isRequired,
    workingCountry: PropTypes.string.isRequired
  })
};

export default PathwaysRouter;
