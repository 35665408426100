import competencyGroupUrl from '../../data/competencyGroupUrl.json';
import styles from './competencyUtils.module.css';
import globalStyles from '../../globalStyles';

export const getLearningUrl = competencyGroupName => {
  const matchedCompetencyGroup = competencyGroupUrl.find(
    group => group.name === competencyGroupName
  );

  return matchedCompetencyGroup ? matchedCompetencyGroup.learningUrl : '';
};

export const competencyColorsMap = {
  Consulting: styles.consultingBgColor,
  Cultivation: styles.cultivationBgColor,
  Impact: styles.impactBgColor,
  Leadership: styles.leadershipBgColor,
  archetype: styles.archetypeBgColor,
  noBelongsToCar: styles.noBelongsToCarBgColor,
  pdf: styles.pdfColor
};

export const coreCapabilityColorsMap = {
  consulting: globalStyles.lightBlue,
  cultivation: globalStyles.avocadoGreen,
  impact: globalStyles.orange,
  leadership: globalStyles.pink,
  archetype: '#f8eeba',
  pdf: styles.pdfColor
};
