import React, { useRef, useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import styles from './index.module.css';
import searchIcon from '../../assets/Icons/searchIcon.png';
import clearIcon from '../../assets/Icons/searchBarClearIcon.png';
import { isEmpty } from 'lodash';
import { specialCharactersPattern } from '../language';

const SearchBar = props => {
  const {
    doubleLayerArrayData,
    singleLayerArrayData,
    searchConstants: { placeholder = '', ClassName = '' },
    onSearchBarChange = searchValue => {},
    onSearchBarFocus = isFocus => {}
  } = props;

  const inputRef = useRef(null);
  const [searchValue, setSearchValue] = useState('');
  const [isFocus, setFocus] = useState(false);

  const searchFilterCategory = categoryList => {
    let newSearchValue = searchValue.trim();
    let searchCategoryList = [];
    categoryList &&
      categoryList.forEach(categoryListItem => {
        const { name = '' } = categoryListItem;
        if (isEmpty(name)) return null;
        const formatName = name.toLowerCase();
        const formatSearchVal = newSearchValue.trim().toLowerCase();
        if (formatName.indexOf(formatSearchVal) !== -1) {
          if (!isEmpty(formatSearchVal)) {
            let isSpecialCharacters = specialCharactersPattern.test(
              newSearchValue
            );
            let regValue = newSearchValue;
            if (isSpecialCharacters) {
              regValue = newSearchValue.replace(
                specialCharactersPattern,
                function(txt) {
                  return '\\' + txt;
                }
              );
            }
            let reg = new RegExp(regValue, 'gi');
            let nameFilter = name.replace(reg, function(txt) {
              return '<strong>' + txt + '</strong>';
            });
            categoryListItem.nameFilter = nameFilter;
          } else {
            categoryListItem.nameFilter = '';
          }

          searchCategoryList.push(categoryListItem);
        }
      });
    return searchCategoryList;
  };

  const searchFilterList = list => {
    let searchEmptyIndexArray = [];
    let newFilterList = list.map((categoryList, categoryListIndex) => {
      const searchCategoryList = searchFilterCategory(categoryList);
      if (isEmpty(searchCategoryList)) {
        searchEmptyIndexArray.push(categoryListIndex);
      }
      return searchCategoryList;
    });
    return {
      newFilterList,
      searchEmptyIndexArray
    };
  };

  useEffect(() => {
    let searchInfo = {
      curSearchVal: searchValue
    };

    if (!isEmpty(doubleLayerArrayData)) {
      searchInfo.filterAllDataList = searchFilterList(
        doubleLayerArrayData
      ).newFilterList;
      searchInfo.searchEmptyIndexArray = searchFilterList(
        doubleLayerArrayData
      ).searchEmptyIndexArray;
    }
    if (!isEmpty(singleLayerArrayData)) {
      searchInfo.filterCoreDataList = searchFilterCategory(
        singleLayerArrayData
      );
    }

    onSearchBarChange(searchInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doubleLayerArrayData, singleLayerArrayData, searchValue]);

  const searchChange = e => {
    let curSearchVal = e.target.value;
    setSearchValue(curSearchVal);
  };

  const searchFocus = () => {
    setFocus(true);
    onSearchBarFocus(true);
  };

  const searchBlur = () => {
    setFocus(false);
    onSearchBarFocus(false);
  };

  const clearChange = e => {
    setSearchValue('');
  };

  return (
    <div
      className={clsx(styles.inputWrapper, styles[ClassName], {
        [styles.searchFocus]: isFocus || searchValue
      })}
    >
      <img className={styles.searchIcon} src={searchIcon} alt="search" />
      <input
        className={styles.input}
        tabIndex={0}
        placeholder={placeholder}
        value={searchValue}
        ref={inputRef}
        onChange={searchChange}
        onFocus={searchFocus}
        onBlur={searchBlur}
        data-cy="search-input"
      />
      {searchValue && (
        <img
          className={styles.clearIcon}
          src={clearIcon}
          alt="clear"
          onClick={clearChange}
        />
      )}
    </div>
  );
};

SearchBar.propTypes = {
  doubleLayerArrayData: PropTypes.array,
  singleLayerArrayData: PropTypes.array,
  searchConstants: PropTypes.object,
  onSearchBarChange: PropTypes.func,
  onSearchBarFocus: PropTypes.func
};

export default SearchBar;
