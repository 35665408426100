import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import * as DOMPurify from 'dompurify';

import ArrowTooltip from './ArrowTooltip';
import LinkIcon from '../Common/SvgIcons/LinkIcon';

import htmlCleanser from '../../utils/htmlCleanser';
import { competencyColorsMap } from '../Utils/competencyUtils';
import { getCompetencyInformationLink } from '../../utils/competency';

import styles from './CompetencyTooltip.module.css';
import globalStyles from '../../globalStyles';

const sanitizer = DOMPurify.sanitize;

const TooltipContent = ({ identifier, description, coreCapabilities }) => {
  return (
    <div className={styles.competencyDescribe}>
      <div
        className={styles.competencyDes}
        dangerouslySetInnerHTML={{
          __html: htmlCleanser.removeTagsWithoutLink(sanitizer(description))
        }}
      ></div>
      <div className={styles.competencyGroupWrapper}>
        <div className={styles.competencyGroupContainer}>
          {coreCapabilities.sort().map(item => {
            return (
              <span className={styles.competencyGroupName} key={item}>
                <i
                  className={clsx(
                    competencyColorsMap[item],
                    styles.competencyGroupDot
                  )}
                />
                <span>{item}</span>
              </span>
            );
          })}
        </div>
        <a
          className={styles.competencyGroupLink}
          href={getCompetencyInformationLink(identifier)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>Read more</span>
          <LinkIcon className={styles.competencyGroupLinkIcon} />
        </a>
      </div>
    </div>
  );
};

TooltipContent.propTypes = {
  identifier: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  belongsTo: PropTypes.array,
  description: PropTypes.string
};

const useStylesTooltip = {
  '& .MuiTooltip-tooltip': {
    left: '-4px',
    color: `${globalStyles.white}`,
    backgroundColor: globalStyles.blue3,
    padding: '25px',
    minWidth: '400px',
    borderRadius: '2px'
  }
};

export const CompetencyTooltip = ({ children, ...props }) => {
  return (
    <ArrowTooltip
      arrow
      title={<TooltipContent {...props} />}
      interactive={'true'}
      leaveDelay={250}
      placement="bottom"
      classes={useStylesTooltip}
    >
      {children}
    </ArrowTooltip>
  );
};

CompetencyTooltip.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  belongsTo: PropTypes.array,
  description: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default CompetencyTooltip;
export { TooltipContent };
