import { PATHWAYS_TO_SUMMIT } from './matomo';

export const summitCycleCondition = {
  noCyclesRecord: 'noCyclesRecord',
  ongoingCycleWithoutNotes: 'ongoingCycleWithoutNotes',
  ongoingCycleWithNotes: 'ongoingCycleWithNotes',
  doneCycle: 'doneCycle'
};

export const matomoEventListByDifferentCycleCondition = {
  noCyclesRecord: [
    {
      category: PATHWAYS_TO_SUMMIT.category,
      action: PATHWAYS_TO_SUMMIT.action.JUMP_TO_MY_CYCLES
    },
    {
      category: PATHWAYS_TO_SUMMIT.category,
      action: PATHWAYS_TO_SUMMIT.action.JUMP_TO_SUMMIT_WITH_NO_CYCLES_RECORD
    }
  ],
  ongoingCycleWithoutNotes: [
    {
      category: PATHWAYS_TO_SUMMIT.category,
      action: PATHWAYS_TO_SUMMIT.action.JUMP_TO_MY_CYCLES
    },
    {
      category: PATHWAYS_TO_SUMMIT.category,
      action:
        PATHWAYS_TO_SUMMIT.action.JUMP_TO_SUMMIT_WITH_OPEN_CYCLES_NO_CHECKINS
    }
  ],
  ongoingCycleWithNotes: [
    {
      category: PATHWAYS_TO_SUMMIT.category,
      action: PATHWAYS_TO_SUMMIT.action.JUMP_TO_MY_CYCLES
    },
    {
      category: PATHWAYS_TO_SUMMIT.category,
      action:
        PATHWAYS_TO_SUMMIT.action.JUMP_TO_SUMMIT_WITH_OPEN_CYCLES_AND_CHECKINS
    }
  ],
  doneCycle: [
    {
      category: PATHWAYS_TO_SUMMIT.category,
      action: PATHWAYS_TO_SUMMIT.action.JUMP_TO_MY_CYCLES
    },
    {
      category: PATHWAYS_TO_SUMMIT.category,
      action: PATHWAYS_TO_SUMMIT.action.JUMP_TO_SUMMIT_WITH_DONE_CYCLES
    }
  ]
};
