import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import styles from './index.module.css';
import { useUserContext } from '../../../../contexts/UserContext';
import clsx from 'clsx';
import { updateArchetypes } from '../../../../actions/profile';
import { ArchetypeDetailModal } from '../../../../components/Common/Modal/ArchetypeDetails/ArchetypeDetailModal';
import {
  CANCEL_BUTTON_TEXT,
  REMOVE_ACHIEVEMENT_TITLE
} from '../../../../constants/modalConstants';
import { PathwaysTrackEvents } from '../../../../support/pathwaysTrack';
import { COURSE_REC_FROM_SELECT_ARCHETYPE_EVENT } from '../../../../constants/matomo';
import { get, orderBy } from 'lodash';
import { ME_NEXT, ME_NOW } from '../../../../constants/archetype';
import { SELECT_ARCHETYPES } from '../../../../constants/path';
import { USER_GRADE } from '../../../../constants/user';
import { archetypeSelectPageDes } from '../../language';
import ConfirmationModal from '../../../../feature/ConfirmationModal';
import { ArchetypeItem } from '../ArchetypeItem';

const ArchetypeRole = ({ role, single, largeItem }) => {
  const {
    state: { user },
    dispatch
  } = useUserContext();
  const [
    removeConfirmationModalOpen,
    setRemoveConfirmationModalOpen
  ] = useState(false);

  const [selectedId, setSelectedId] = useState(-1);
  const [archetypeId, setArchetypeId] = useState(null);
  const addedArchetypeIds = user.reflectedArchetypes.map(a => a.archetypeId);
  const handleCreate = archetype => {
    const addedArchetypes = user.reflectedArchetypes;
    const { description, ...updatedArchetype } = archetype;
    const updatedArchetypeIds = addedArchetypeIds
      .concat(updatedArchetype.archetypeId)
      .map(id => ({ archetypeId: id }));
    updateArchetypes(dispatch)(
      updatedArchetypeIds,
      Array.from(new Set(addedArchetypes.concat([updatedArchetype])))
    );
  };

  const handleBeforeRemove = id => {
    setSelectedId(id);
    const selectedArchetype = user.reflectedArchetypes.find(
      a => a.archetypeId === id
    );
    const restArchetypeIds = user.reflectedArchetypes
      .map(a => {
        return { archetypeId: a.archetypeId };
      })
      .filter(item => item.archetypeId !== id);
    const stage = get(selectedArchetype, 'reflectedInformation.stage');
    const isChosenIntention = stage === ME_NOW || stage === ME_NEXT;
    if (isChosenIntention) {
      setRemoveConfirmationModalOpen(true);
    } else {
      updateArchetypes(dispatch)(
        restArchetypeIds,
        user.reflectedArchetypes.filter(a => a.archetypeId !== id)
      );
    }
  };
  const handleRemove = useCallback(() => {
    const addedArchetypes = user.reflectedArchetypes;
    const restArchetypeIds = user.reflectedArchetypes
      .map(a => {
        return { archetypeId: a.archetypeId };
      })
      .filter(item => item.archetypeId !== selectedId);
    updateArchetypes(dispatch)(
      restArchetypeIds,
      addedArchetypes.filter(a => a.archetypeId !== selectedId)
    );
    setRemoveConfirmationModalOpen(false);
    setSelectedId(-1);
  }, [dispatch, selectedId, user.reflectedArchetypes]);

  const shouldTrackPopUpForCourseRecV2UserInMatomo =
    user.grade === USER_GRADE.GRADUATE;
  const isFromSelectPage = window.location.pathname === SELECT_ARCHETYPES;

  const openArchetypeDetailPopUp = () => {
    let trackList = [];
    shouldTrackPopUpForCourseRecV2UserInMatomo &&
      isFromSelectPage &&
      trackList.push({
        category: COURSE_REC_FROM_SELECT_ARCHETYPE_EVENT.category,
        action:
          COURSE_REC_FROM_SELECT_ARCHETYPE_EVENT.action
            .OPEN_ARCHETYPE_DETAIL_POP_UP
      });
    PathwaysTrackEvents(trackList);
  };

  const handleShowDetail = id => {
    id && setArchetypeId(id);
    openArchetypeDetailPopUp();
  };
  return (
    <>
      <Grid
        className={clsx({
          [styles.splitLine]: !single
        })}
        key={role.roleName}
        item
        xs={12}
        lg={single ? 12 : 6}
      >
        <div className={styles.role}>
          <header
            className={clsx(styles.roleTitle, {
              [styles.largeItem]: largeItem
            })}
          >
            {role.roleName}
          </header>
          <div className={styles.archetypeItemWrapper}>
            <Grid
              spacing={largeItem ? 2 : 1}
              justifyContent="flex-start"
              container
            >
              {orderBy(role.archetypes, ['archetypeName']).map(archetype => {
                const isSelected = addedArchetypeIds.includes(
                  archetype.archetypeId
                );
                return (
                  <ArchetypeItem
                    key={archetype.archetypeId}
                    isSelected={isSelected}
                    archetype={archetype}
                    single={single}
                    onCreate={handleCreate}
                    onRemove={handleBeforeRemove}
                    onDetail={handleShowDetail}
                    largeItem={largeItem}
                  />
                );
              })}
            </Grid>
          </div>
        </div>
      </Grid>
      <ConfirmationModal
        open={removeConfirmationModalOpen}
        setOpen={() => setRemoveConfirmationModalOpen(false)}
        confirmButtonText={archetypeSelectPageDes.archetypeRole.confirmRemove}
        cancelButtonText={CANCEL_BUTTON_TEXT}
        confirm={() => handleRemove()}
        cancel={() => setRemoveConfirmationModalOpen(false)}
        title={REMOVE_ACHIEVEMENT_TITLE}
        content={archetypeSelectPageDes.archetypeRole.removeTipContent}
      />
      <ArchetypeDetailModal
        archetypeId={archetypeId}
        close={() => setArchetypeId(null)}
      />
    </>
  );
};
ArchetypeRole.propTypes = {
  role: PropTypes.object.isRequired,
  largeItem: PropTypes.bool.isRequired,
  single: PropTypes.bool.isRequired
};
export default ArchetypeRole;
