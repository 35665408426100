import PropTypes from 'prop-types';
import React from 'react';
import styles from './PDFArchetypes.module.css';

const PDFArchetypes = ({ title, archetypes, type }) => {
  return (
    <section className={styles.archetypeWrapper}>
      <p className={styles.pdfArchetypeTitle}>{title}</p>
      {archetypes.length > 0 ? (
        <main className={styles.archetypesList}>
          {archetypes.map(({ archetypeName }, i) => (
            <div key={i} className={styles.archetypeItem}>
              {archetypeName}
            </div>
          ))}
        </main>
      ) : null}
    </section>
  );
};

PDFArchetypes.propTypes = {
  title: PropTypes.string.isRequired,
  archetypes: PropTypes.array.isRequired,
  type: PropTypes.number.isRequired
};

export default PDFArchetypes;
