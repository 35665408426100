import React from 'react';
import styles from './index.module.css';
import topCourseRecSection from '../../../../assets/Icons/topCourseRecSection.svg';
import { Link } from 'react-router-dom';
import { COURSE_RECOMMENDATIONS } from '../../../../constants/path';
import { PathwaysTrackEvents } from '../../../../support/pathwaysTrack';
import { COURSE_REC_STANDALONE_PAGE_EVENT } from '../../../../constants/matomo';
import { dashboardDes } from '../../language';
import * as DOMPurify from 'dompurify';
export const TopCourseRecommendation = props => {
  const sanitizer = DOMPurify.sanitize;

  const jumpToTopCourseRecPage = () => {
    PathwaysTrackEvents([
      {
        category: COURSE_REC_STANDALONE_PAGE_EVENT.category,
        action:
          COURSE_REC_STANDALONE_PAGE_EVENT.action
            .JUMP_TO_STANDALONE_PAGE_VIA_THE_DASHBOARD
      }
    ]);
  };
  return (
    <div className={styles.topCourseRecommendation}>
      <div className={styles.info}>
        <div className={styles.title}>
          {dashboardDes.topCourseRecommendation.title}
        </div>
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: sanitizer(dashboardDes.topCourseRecommendation.description)
          }}
        />
      </div>
      <img
        className={styles.img}
        src={topCourseRecSection}
        alt={'top course Rec Section'}
      />
      <div className={styles.seeRecBtn}>
        <Link
          to={{
            pathname: COURSE_RECOMMENDATIONS,
            state: { tab: props.tabIndex }
          }}
          className={styles.link}
          onClick={jumpToTopCourseRecPage}
        >
          See recommendations
        </Link>
      </div>
    </div>
  );
};
