import styles from './index.module.css';
import { PathwaysLogo } from '../../../../components/PathwaysLogo';
import pathwaysAndCapableIcon from '../../../../assets/Icons/landingPageIcons/pathways-and-capable.png';
import React from 'react';
import { getEnvVars } from '../../../../config/config';
import { landingPageDes } from '../../language';
import * as DOMPurify from 'dompurify';
import { CapabilityModuleLink } from '../../../../constants/links';

export const CapabilityModule = () => {
  const { capableLink } = getEnvVars();
  const sanitizer = DOMPurify.sanitize;
  const {
    descriptionTitle,
    descriptionPart1,
    descriptionPart2,
    descriptionPart3
  } = landingPageDes.capabilityModule;

  return (
    <>
      <section className={styles.capability}>
        <div className={styles.capabilityTitle}>
          <PathwaysLogo modalClassName={'themeColorLogo'} />
          <span>& The Capability Model.</span>
        </div>
        <img
          src={pathwaysAndCapableIcon}
          className={styles.pathwaysCapableIcon}
          alt=""
        />
        <div className={styles.capabilityDescription}>
          <p
            dangerouslySetInnerHTML={{
              __html: sanitizer(descriptionTitle)
            }}
          />
          <p
            dangerouslySetInnerHTML={{
              __html: sanitizer(descriptionPart1)
            }}
          />
          <p
            dangerouslySetInnerHTML={{
              __html: sanitizer(descriptionPart2)
            }}
          />
          <div>
            {descriptionPart3}
            <b>Reference Guide</b>{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={CapabilityModuleLink}
              className={styles.link}
            >
              here
            </a>{' '}
            and <b>Capable</b>{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={capableLink}
              className={styles.link}
            >
              here
            </a>
            .
          </div>
        </div>
      </section>
    </>
  );
};
