import React, { useState } from 'react';
import { get, groupBy, isEmpty, orderBy, reject, upperCase } from 'lodash';
import { orderedCategory } from '../../../../SkillsSelectPage/language';
import ReflectSingleItem from '../../../../../feature/ReflectSingleItem';
import styles from './index.module.css';
import { CapabilityItem } from '../../../../../components/CapabilityItem';
import { proficiencyLevelsDescription } from '../../../../../constants/proficiencyLevels';

export const ListView = ({ skills }) => {
  const [editOpen, setEditOpen] = useState(false);
  const [skill, setSkill] = useState({});
  const handleEditClick = skill => {
    setSkill(skill);
    setEditOpen(true);
  };
  const groupedSkills = () => {
    if (skills.length === 0) {
      return [];
    }
    const res = groupBy(skills, 'category');
    const [technicalName, testingName, languageName] = orderedCategory;

    return reject(
      [
        res[technicalName]
          ? { name: technicalName, children: orderBy(res[technicalName]) }
          : {},
        res[testingName]
          ? { name: testingName, children: orderBy(res[testingName]) }
          : {},
        res[languageName]
          ? { name: languageName, children: orderBy(res[languageName]) }
          : {}
      ],
      isEmpty
    );
  };

  return (
    <div>
      <ReflectSingleItem
        ability={skill}
        open={editOpen}
        close={() => setEditOpen(false)}
        modalTitle="Edit Skill"
        reflectType="Skills"
      />
      {groupedSkills().map(group => {
        return (
          <div className={styles.groupHexagonWrapper} key={group.name}>
            <a className={styles.groupName} rel="noopener noreferrer">
              {upperCase(group.name)}
            </a>
            {group.children.map(item => (
              <div key={item.name} className={styles.item}>
                <CapabilityItem
                  key={item.name}
                  name={item.name}
                  value={get(item, 'reflectedInformation.practiceLevel', 0)}
                  label={
                    proficiencyLevelsDescription[
                      item.reflectedInformation.practiceLevel - 1
                    ]
                  }
                  onClick={() => handleEditClick(item)}
                />
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
};
