import * as React from 'react';
import styles from './index.module.css';
import confusedPeople from '../../assets/Icons/confused-people.svg';
import Preference from './components/Preference/index';
import Helper from './components/Helper/index';
const VisibilityPreference = () => {
  return (
    <div className={styles.page}>
      <Preference />
      <img
        className={styles.pathwaysImg}
        src={confusedPeople}
        alt={'confused-people'}
      />
      <Helper />
    </div>
  );
};
export default VisibilityPreference;
