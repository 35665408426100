import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ErrorBoxWithCloseButton } from './components/ErrorBox/ErrorBox';
import globalStyles from './globalStyles';
import { createInstance, MatomoProvider } from '@datapunt/matomo-tracker-react';
import 'scroll-behavior-polyfill';

import { MatomoInstanceContext } from './contexts/MatomoInstanceContext';
import { BrowserRouter as Router } from 'react-router-dom';
import { Authentication } from './authentication';
import { getEnvVars } from './config/config';
import { ErrorBoundary } from 'react-error-boundary';
import FallbackComponent from './feature/FallbackComponent';

const theme = createTheme({
  components: {
    MuiModal: {
      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }
      }
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          '&:focus': {
            outline: '2px solid #1a73ba'
          }
        }
      }
    },

    MuiPopover: {
      styleOverrides: {
        paper: {
          '&::-webkit-scrollbar': {
            width: 4,
            height: 4
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#023d67',
            borderRadius: 4,
            borderRight: '4px solid #023d67',
            backgroundClip: 'padding-box'
          }
        }
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          maxHeight: 480
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        formControl: {
          'label + &': {
            marginTop: '0'
          }
        }
      }
    }
  },
  overrides: {},
  palette: {
    palette: {
      primary: {
        light: globalStyles.blue1,
        main: globalStyles.primaryColor
      },
      secondary: {
        main: globalStyles.orange2
      }
    }
  },
  typography: {
    fontFamily: ['Open Sans', 'sans-serif'].join(','),
    letterSpacing: 'normal'
  }
});

const { matomoSiteId } = getEnvVars();

let instance = createInstance({
  urlBase: 'https://thoughtworks.innocraft.cloud/',
  siteId: matomoSiteId
});

if (process.env.NODE_ENV === 'development') {
  import('react-axe').then(axe => {
    axe.default(React, ReactDOM, 1000);
  });
}
const root = createRoot(document.getElementById('root'));

root.render(
  <ErrorBoundary FallbackComponent={FallbackComponent}>
    <MatomoProvider value={instance}>
      <MatomoInstanceContext.Provider value={{ instance }}>
        <ThemeProvider theme={theme}>
          <main>
            <h1
              style={{ height: 0, margin: 0, padding: 0, overflow: 'hidden' }}
            >
              pathway
            </h1>
            <ErrorBoxWithCloseButton />
            <Router>
              <Authentication>
                <App />
              </Authentication>
            </Router>
          </main>
        </ThemeProvider>
      </MatomoInstanceContext.Provider>
    </MatomoProvider>
  </ErrorBoundary>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
