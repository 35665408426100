import React, { useEffect } from 'react';

import { ErrorBoxGoHomepage } from '../ErrorBox/ErrorBox';
import PropTypes from 'prop-types';

const ErrorPage = ({ changeMinHeight }) => {
  useEffect(() => {
    changeMinHeight(false);
    return () => {
      changeMinHeight(true);
    };
  });
  return (
    <>
      <ErrorBoxGoHomepage
        title="Page not Found"
        content="Sorry, the page you requested could not be found."
      />
    </>
  );
};

ErrorPage.propTypes = {
  changeMinHeight: PropTypes.func.isRequired
};

export default ErrorPage;
