import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import PropTypes from 'prop-types';
import * as DOMPurify from 'dompurify';
import clsx from 'clsx';
import htmlCleanser from '../../utils/htmlCleanser';
import LinkIcon from '../../components/Common/SvgIcons/LinkIcon';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import { getCompetencyInformationLink } from '../../utils/competency';
import styles from './index.module.css';
import { CloseIconButton } from '../../nonFeatureComponents/Buttons/Buttons';

const sanitizer = DOMPurify.sanitize;

const CompetencyModal = ({ open, close, competency }) => {
  const [isSelected, setIsSelected] = useState();
  const { name, identifier, description } = competency;

  const handleClose = () => {
    close();
    setIsSelected(competency.selected);
  };

  useEffect(() => {
    if (competency && competency.selected) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  }, [competency]);

  return (
    <Modal
      role="dialog"
      aria-modal={true}
      open={open}
      onClose={handleClose}
      Backdrop={{
        timeout: 500
      }}
    >
      <div className={styles.modal}>
        <ModalHeader>
          <div className={styles.titleContent} data-cy="title">
            {name}
          </div>
          <CloseIconButton handleClick={handleClose} />
        </ModalHeader>
        <div className={styles.bodyContent}>
          <div className={styles.hexagonGroup}>
            <div
              className={clsx(styles.hexagonView, {
                [styles.selected]: isSelected
              })}
            >
              <div
                className={clsx(styles.hexagonViewAfter, {
                  [styles.selected]: isSelected
                })}
              >
                <div className={styles.hexagonContentGroup}>
                  <div className={styles.hexagonContent} tabIndex="0">
                    {name}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.textWrapper}>
            <div className={styles.descriptionGroup}>
              {description && (
                <div
                  className={styles.description}
                  tabIndex="0"
                  dangerouslySetInnerHTML={{
                    __html: htmlCleanser.removeTagsWithoutLink(
                      sanitizer(description)
                    )
                  }}
                ></div>
              )}
            </div>
            <div className={styles.competencyGroupWrapper}>
              <a
                className={styles.competencyGroupLink}
                href={getCompetencyInformationLink(identifier)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>Tell me more</span>
                <LinkIcon className={styles.competencyGroupLinkIcon} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

CompetencyModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  competency: PropTypes.object.isRequired
};

export default CompetencyModal;
