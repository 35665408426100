import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { cloneDeep, get, isNil } from 'lodash';
import styles from './index.module.css';
import {
  createCompetency,
  removeCompetencyById,
  updateCompetency
} from '../../../../api/domain/competency';
import { onKeyDown } from '../../../../utils/onKeyDown';
import { CheckBox } from '../../../../nonFeatureComponents/CheckBox';
import { LEARNING_PRIORITY } from '../../../../constants/competency';
import CompetencyModal from '../../../../feature/CompetencyModal';
import AssessSlider from '../../../../nonFeatureComponents/AssessSlider';

const CompetencyItem = ({ competency, hiddenTitle, onChange }) => {
  const [cy, setCy] = useState(competency);
  const [practiceLevel, setPracticeLevel] = useState(0);
  const [infoOpen, setInfoOpen] = useState(false);
  const [isUpdateCompetencyLoading, setUpdateCompetencyLoading] = useState(
    false
  );
  const [isLearningPriority, setIsLearningPriority] = useState(
    get(cy, 'competencyReflectedInformation.isLearningPriority', false)
  );

  useEffect(() => {
    if (!isNil(cy.competencyReflectedInformation)) {
      if (!isNil(cy.competencyReflectedInformation.practiceLevel)) {
        setPracticeLevel(cy.competencyReflectedInformation.practiceLevel);
      } else {
        setPracticeLevel(0);
      }
    }
  }, [cy.competencyReflectedInformation]);

  const onPracticeLevelChange = sliderPracticeLevel => {
    let tmpPracticeLevel = sliderPracticeLevel;
    if (sliderPracticeLevel === practiceLevel) {
      tmpPracticeLevel = 0;
    }
    const newCompetency = cloneDeep(cy);
    newCompetency.competencyReflectedInformation
      ? (newCompetency.competencyReflectedInformation.practiceLevel = tmpPracticeLevel)
      : (newCompetency.competencyReflectedInformation = {
          ...newCompetency.competencyReflectedInformation,
          practiceLevel: tmpPracticeLevel
        });
    if (!isUpdateCompetencyLoading) {
      setUpdateCompetencyLoading(true);
      setPracticeLevel(tmpPracticeLevel);
      handleCompetencyChange(newCompetency).then(() =>
        setUpdateCompetencyLoading(false)
      );
    }
  };

  const onIsLearningPriorityChange = updatedData => {
    if (practiceLevel === undefined) {
      updatedData.practiceLevel = 0;
    } else {
      updatedData.practiceLevel = practiceLevel;
    }
    const originIsLearningPriority = isLearningPriority;
    setIsLearningPriority(!originIsLearningPriority);
    const newCompetency = cloneDeep(cy);
    newCompetency.competencyReflectedInformation
      ? (newCompetency.competencyReflectedInformation.isLearningPriority = !originIsLearningPriority)
      : (newCompetency.competencyReflectedInformation = {
          ...newCompetency.competencyReflectedInformation,
          isLearningPriority: !originIsLearningPriority
        });
    handleCompetencyChange(newCompetency).then();
  };

  const handleCompetencyChange = async newCompetency => {
    try {
      if (isNil(cy.competencyReflectedInformation)) {
        const changedCompetency = {
          competencyId: newCompetency.competencyId,
          practiceLevel:
            newCompetency.competencyReflectedInformation.practiceLevel || 0,
          isLearningPriority:
            newCompetency.competencyReflectedInformation.isLearningPriority ||
            false
        };
        await createCompetency(changedCompetency).then(res => {
          setReflectedInformation(res);
          onChange(newCompetency);
        });
      } else {
        const handleType =
          !newCompetency.competencyReflectedInformation.isLearningPriority &&
          newCompetency.competencyReflectedInformation.practiceLevel === 0
            ? 'removeCompetencyById'
            : 'updateCompetency';
        await updateAndDeleteCompetency(handleType, newCompetency);
      }
    } catch (error) {
      resetCompetency();
    }
  };

  const setReflectedInformation = competency => {
    setCy({
      ...cy,
      competencyReflectedInformation: competency.competencyReflectedInformation
    });
  };

  const resetCompetency = () => {
    setPracticeLevel(
      get(cy, 'competencyReflectedInformation.practiceLevel', 0)
    );
    setIsLearningPriority(
      get(cy, 'competencyReflectedInformation.isLearningPriority', false)
    );
  };

  const updateAndDeleteCompetency = async (handleType, newCompetency) => {
    if (handleType === 'removeCompetencyById') {
      await removeCompetencyById(
        newCompetency.competencyReflectedInformation.id
      ).then(() => {
        const curCompetency = {
          ...cy,
          competencyReflectedInformation: null
        };
        setReflectedInformation(curCompetency);
        onChange(newCompetency);
      });
    }

    if (handleType === 'updateCompetency') {
      await updateCompetency(newCompetency.competencyReflectedInformation.id, {
        practiceLevel:
          newCompetency.competencyReflectedInformation.practiceLevel,
        isLearningPriority:
          newCompetency.competencyReflectedInformation.isLearningPriority
      }).then(() => {
        const curCompetency = {
          ...cy,
          competencyReflectedInformation: {
            ...cy.competencyReflectedInformation,
            practiceLevel:
              newCompetency.competencyReflectedInformation.practiceLevel,
            isLearningPriority:
              newCompetency.competencyReflectedInformation.isLearningPriority
          }
        };
        setReflectedInformation(curCompetency);
        onChange(newCompetency);
      });
    }
  };

  return (
    <div className={styles.competency} data-cy={`competency:${cy.identifier}`}>
      {!hiddenTitle && (
        <div
          className={clsx(styles.name, styles.firstColumn)}
          data-cy="competencyTitle"
        >
          <div
            className={styles.detailBtn}
            role="button"
            onKeyDown={e => onKeyDown(e, () => setInfoOpen(true))}
            tabIndex={0}
          >
            <div
              className={styles.detailBtnInner}
              tabIndex={-1}
              onClick={() => setInfoOpen(true)}
            >
              {cy.name}
            </div>
          </div>
        </div>
      )}
      <div className={clsx(styles.slider, styles.secondColumn)}>
        <AssessSlider
          suggestedLevel={cy.suggestedLevel}
          actualPracticeLevel={practiceLevel}
          levelDescriptions={cy.levelDescriptions?.sort((a, b) =>
            a.level > b.level ? 1 : -1
          )}
          name={cy.name}
          onChange={onPracticeLevelChange}
        />
      </div>
      <div className={clsx(styles.checkBox, styles.thirdColumn)}>
        <CheckBox
          label={LEARNING_PRIORITY}
          onChange={onIsLearningPriorityChange}
          checked={isLearningPriority}
          style={{ marginLeft: '3px' }}
        />
      </div>
      <CompetencyModal
        notShowSelectButton={true}
        competency={cy}
        open={infoOpen}
        close={() => setInfoOpen(false)}
      />
    </div>
  );
};

CompetencyItem.propTypes = {
  competency: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  hiddenTitle: PropTypes.bool
};

export default CompetencyItem;
