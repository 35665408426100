import styles from './index.module.css';
import React from 'react';
import { Button } from '@mui/material';
import globalStyles from '../../../../globalStyles';
import { landingPageDes } from '../../language';
import { PathwaysIntroductionLink } from '../../../../constants/links';
import { styled } from '@mui/material/styles';

export const CampusModule = ({ videoRef }) => {
  const TakeQuickCourseButton = styled(Button)({
    color: globalStyles.white,
    backgroundColor: globalStyles.blue2,
    minWidth: '252px',
    height: '60px',
    fontWeight: 'bold',
    fontSize: '20px',
    textTransform: 'none',
    boxShadow: '0 2px 4px 0 rgba(95, 95, 95, 0.5)',
    opacity: '0.9',
    '&:hover': {
      backgroundColor: globalStyles.blue2,
      opacity: '1'
    },
    '&:focus': {
      backgroundColor: globalStyles.blue2,
      opacity: '1',
      outline: 'none'
    }
  });
  const {
    courseTitle,
    wondering,
    watching,
    takeQuickCourseBtn,
    courseLength
  } = landingPageDes.campusModule;

  return (
    <div className={styles.learnCourse} ref={videoRef}>
      <div className={styles.courseTitle}>{courseTitle}</div>
      <p>{wondering}</p>
      <p> {watching}</p>
      <div className={styles.courseBtns}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={PathwaysIntroductionLink}
          className={styles.selfReflectBtn}
          tabIndex={0}
        >
          <TakeQuickCourseButton>{takeQuickCourseBtn}</TakeQuickCourseButton>
        </a>
        <p className={styles.tip}>{courseLength}</p>
      </div>
    </div>
  );
};
