import React from 'react';
import { SvgIcon } from '@mui/material';

const ArrowDown = props => {
  return (
    <SvgIcon {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M12 16l-6-6h12z" />
    </SvgIcon>
  );
};

export default ArrowDown;
