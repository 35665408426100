import React from 'react';
import { Route } from 'react-router-dom';
import PathwaysDashboardPage from '../Dashboard';
import ArchetypesSelectPage from '../ArchetypesSelectPage';
import { ReflectPage } from '../ReflectPage/index';
import {
  ARCHETYPES_INTENTION,
  COURSE_RECOMMENDATIONS,
  DASHBOARD,
  EDIT_REFLECT_SKILLS,
  REFLECT_ARCHETYPES,
  REFLECT_COMPETENCIES,
  REFLECT_SKILLS,
  SELECT_ARCHETYPES,
  SELECT_COMPETENCIES,
  SELECT_SKILLS,
  VISIBILITY_PREFERENCE
} from '../../constants/path';
import VisibilityPreference from '../VisibilityPreference';
import { CourseRecommendations } from '../CourseRecommendationsPage';
import LandingPage from '../LandingPage';
import ArchetypesIntention from '../ArchetypesIntention';
import ArchetypesReflectPage from '../ArchetypesReflectPage/index';
import CompetenciesSelectPage from '../CompetenciesSelectPage';
import SkillsSelectPage from '../SkillsSelectPage';
export const HTMLFormPathname = [SELECT_COMPETENCIES, REFLECT_COMPETENCIES];

export const CoreCapabilityFlowRoutes = ({ isGuideShowState }) => {
  return [
    {
      key: 'landing-page',
      path: '/',
      render: <LandingPage />
    },
    {
      key: 'dashboard',
      path: DASHBOARD,
      render: <PathwaysDashboardPage isGuideShowState={isGuideShowState} />
    },
    {
      key: 'select-archetypes',
      path: SELECT_ARCHETYPES,
      component: ArchetypesSelectPage
    },
    {
      key: 'reflect-archetypes',
      path: REFLECT_ARCHETYPES,
      component: ArchetypesReflectPage
    },
    {
      key: 'archetypes-intention',
      path: ARCHETYPES_INTENTION,
      component: ArchetypesIntention
    },
    {
      key: 'select-skills',
      path: SELECT_SKILLS,
      component: SkillsSelectPage
    },
    {
      key: 'reflect-skills',
      path: REFLECT_SKILLS,
      component: ReflectPage
    },
    {
      key: 'edit-reflect-skills',
      path: EDIT_REFLECT_SKILLS,
      component: ReflectPage
    },
    {
      type: 'HTML_FORM',
      key: 'select-competencies',
      path: SELECT_COMPETENCIES,
      component: CompetenciesSelectPage
    },
    {
      key: 'reflect-competencies',
      path: REFLECT_COMPETENCIES,
      component: ReflectPage
    },
    {
      key: 'visibility-preference',
      path: VISIBILITY_PREFERENCE,
      component: VisibilityPreference
    },
    {
      key: 'course-recommendations',
      path: COURSE_RECOMMENDATIONS,
      component: CourseRecommendations
    }
  ];
};

export const routesRender = routesInfo => {
  return routesInfo.map(item => {
    const { key, path, component, render } = item;
    if (item.render) {
      return <Route key={key} exact path={path} render={_ => render} />;
    }
    if (item.component) {
      return <Route key={key} exact path={path} component={component} />;
    }
    return null;
  });
};
