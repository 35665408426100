import React, { useState, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { isNil, orderBy } from 'lodash';
import { ARCHETYPES_INTENTION, SELECT_ARCHETYPES } from '../../constants/path';
import { useUserContext } from '../../contexts/UserContext';
import styles from './index.module.css';
import { fetchPeopleArchetypeDetail } from '../../api/domain/archetype';
import { fetchReflectedArchetypes } from '../../actions/profile';
import FixedBar from '../../nonFeatureComponents/FixedBar';
import NavigationHeader from '../../nonFeatureComponents/NavigationHeader';
import StepperLine from '../../nonFeatureComponents/StepperLine';
import ArchetypesSelector from '../../feature/ArchetypesSelector';
import { ARCHETYPE_STEPS } from '../../constants/archetype';
import { archetypesReflectPageDes } from './language';
import CompetenciesList from './components/CompetenciesList';

const ArchetypesReflectPage = () => {
  const { state, dispatch } = useUserContext();
  const history = useHistory();
  const location = useLocation();

  const [archetypes, setArchetypes] = useState([]);
  const [archetype, setArchetype] = useState({ competencies: [] });
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [loadErr, setLoadErr] = useState(false);
  const hasSelectedArchetype = selectedIndex !== -1;
  const fetchArchetype = async archetypeId => {
    setLoading(true);
    setLoadErr(false);

    fetchPeopleArchetypeDetail(archetypeId)
      .then(res => {
        if (!res) {
          throw new Error('no match found');
        }
        setArchetype(res);
      })
      .catch(() => setLoadErr(true))
      .finally(() => setLoading(false));
  };

  const archetypeIndexFromLocationState = useMemo(() => {
    const { value } = location.state || {};
    if (!value) {
      return -1;
    }
    return archetypes.findIndex(item => item.archetypeId === value);
  }, [location, archetypes]);

  useEffect(() => {
    if (!isNil(state.user) && !isNil(state.user.reflectedArchetypes)) {
      const ar = state.user.reflectedArchetypes;
      setArchetypes(orderBy(ar, ['archetypeName']));
    } else {
      fetchReflectedArchetypes(dispatch).then();
    }
  }, [state.user, dispatch]);

  useEffect(() => {
    setSelectedIndex(archetypeIndexFromLocationState);
    archetypes.length === 1 && setSelectedIndex(0);
  }, [archetypeIndexFromLocationState, archetypes]);

  useEffect(() => {
    selectedIndex !== -1 &&
      fetchArchetype(archetypes[selectedIndex].archetypeId);
  }, [archetypes, selectedIndex]);

  return (
    <>
      <FixedBar>
        <NavigationHeader
          onBackTo={() => history.replace(SELECT_ARCHETYPES)}
          onGoTo={() => history.push(ARCHETYPES_INTENTION)}
          backToTitle={archetypesReflectPageDes.navigationHeader.backToTitle}
          goToTitle={archetypesReflectPageDes.navigationHeader.goToTitle}
          showReflectTooltip={false}
          tooltipContent={
            archetypesReflectPageDes.navigationHeader.tooltipContent
          }
          showGoToIcon
        >
          <StepperLine activeStep={1} steps={ARCHETYPE_STEPS} />
        </NavigationHeader>
      </FixedBar>
      <div className={styles.page} data-cy="reflectArchetype">
        {archetypes.length ? (
          <>
            <div className={styles.titleWrapper}>
              <span className={styles.title}>
                {archetypesReflectPageDes.title}
              </span>
              <span>{archetypesReflectPageDes.description}</span>
            </div>
            <ArchetypesSelector
              defaultOption={
                <span className={styles.selectDefaultOptionItem}>
                  {archetypesReflectPageDes.selectDefaultOption}
                </span>
              }
              menuStyleName={styles.selected}
              value={selectedIndex}
              options={archetypes.map((a, index) => (
                <div
                  key={index}
                  className={clsx(styles.selectOptionItem, {
                    [styles.selected]: selectedIndex === index
                  })}
                  data-cy={`archetype:${a.identifier}`}
                >
                  {a.archetypeName}
                </div>
              ))}
              onChange={index => setSelectedIndex(index)}
            />
            {hasSelectedArchetype ? (
              <CompetenciesList
                competencies={orderBy(archetype.competencies, 'name')}
                extendCompetencies={
                  archetype.baseArchetypes
                    ? orderBy(archetype.baseArchetypes.competencies, 'name')
                    : []
                }
                archetypeName={archetype.name}
                extendArchetypeName={archetype.baseArchetypes?.name}
                loading={loading}
                loadErr={loadErr}
              />
            ) : (
              <div className={styles.noSelectMsg}>
                {archetypesReflectPageDes.noSelectMsg}
              </div>
            )}
          </>
        ) : (
          <div className={styles.nodata}>{archetypesReflectPageDes.nodata}</div>
        )}
      </div>
    </>
  );
};

export default ArchetypesReflectPage;
