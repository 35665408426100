import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { List } from '@mui/material';
import styles from './index.module.css';
import { SELECT_COMPETENCIES } from '../../../../constants/path';
import { DashboardSectionHeader } from '../../../../nonFeatureComponents/DashboardSectionHeader';
import { ListView } from './components/ListView';
import MLinearProgress from '../../../../feature/LinearProgress';

export const ListWithStyles = withStyles({
  root: {
    paddingTop: '0px',
    overflowY: 'auto',
    width: '100%',
    height: '378px',
    marginBottom: '7px'
  }
})(List);

export const EmptyListWithStyles = withStyles({
  root: {
    paddingTop: '0px',
    overflowY: 'auto',
    width: '100%',
    height: '350px'
  }
})(List);

const Competencies = ({
  title,
  setOpen,
  competencies,
  isLoading,
  emptyMessage,
  editTargetPath
}) => {
  let content;
  const shouldShowHeader = competencies.length > 0;

  if (!isLoading && !shouldShowHeader) {
    content = emptyMessage;
  } else {
    content = (
      <>
        <MLinearProgress loading={isLoading} />
        <ListView competencies={competencies} />
      </>
    );
  }

  return (
    <div className={styles.competencies}>
      {shouldShowHeader && (
        <DashboardSectionHeader
          title={title}
          setOpen={setOpen}
          selectTargetPath={SELECT_COMPETENCIES}
          editTargetPath={editTargetPath}
          ariaDes="competencies"
        />
      )}
      {shouldShowHeader ? (
        <ListWithStyles className={styles.primaryScrollbar}>
          {content}
        </ListWithStyles>
      ) : (
        <EmptyListWithStyles className={styles.primaryScrollbar}>
          {content}
        </EmptyListWithStyles>
      )}
      <div className={styles.mask} />
    </div>
  );
};

Competencies.propTypes = {
  title: PropTypes.node.isRequired,
  competencies: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  emptyMessage: PropTypes.node.isRequired,
  editTargetPath: PropTypes.shape({
    path: PropTypes.string.isRequired,
    value: PropTypes.number
  })
};

export default Competencies;
