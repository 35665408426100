import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './ArchetypeDetailTabs.module.css';
import clsx from 'clsx';
import { onKeyDown } from '../../../../../../utils/onKeyDown';

const showQualarooSurvey = () => {
  const e = document.querySelector(
    "section[class*=ArchetypeDetailTabs_tabs] > nav[aria-label='Top Courses'] > div"
  );
  if (e) {
    e.onclick = () => {
      window._kiq.push(['set', { event: 'Clicked course recommendation tab' }]);
    };
  }
};
const ArchetypeDetailTabs = ({ onChange, currentIndex, tabs }) => {
  useEffect(() => {
    showQualarooSurvey();
  }, []);
  return (
    <section className={styles.tabs}>
      {tabs.map((tab, index) => {
        return (
          <nav
            role="tab"
            aria-label={tab}
            onClick={() => onChange(index)}
            onKeyDown={e => onKeyDown(e, () => onChange(index))}
            tabIndex={0}
            key={tab}
            className={clsx(styles.tabItem, {
              [styles.active]: index === currentIndex
            })}
          >
            <div tabIndex={-1} className={styles.tabItemInner}>
              {tab}
            </div>
          </nav>
        );
      })}
    </section>
  );
};
ArchetypeDetailTabs.propTypes = {
  currentIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  tabs: PropTypes.array.isRequired
};
export default ArchetypeDetailTabs;
