import React from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';

import Grow from './Grow';

import * as PATH from '../../constants/path';

import styles from './HeaderBar.module.css';
import clsx from 'clsx';
import { PathwaysLogo } from '../PathwaysLogo';

const HeaderBar = props => {
  const history = useHistory();
  const { pathname } = history.location;

  return (
    <header
      className={clsx(
        styles.headerBar,
        props.isGuideShowState && styles.guideShow,
        props.isTopCourseGuideShow && styles.isTopCourseGuideShow
      )}
      data-cy="headerBar"
    >
      <div className={styles.wrapper}>
        <div className={styles.left}>
          <PathwaysLogo
            targetPath={PATH.HOME_PAGE}
            smallScreenHideLogo
            tabIndex="0"
            pathname={pathname}
          />

          <div
            data-cy="meButton"
            className={clsx(styles.growthCanvas, {
              [styles.growthCanvasAfter]: pathname !== '/'
            })}
          >
            <Link to={PATH.DASHBOARD}>My Growth Canvas</Link>
          </div>
        </div>
        <div className={styles.right}>
          <Grow />
          <div className={styles.userName}>
            <span>{props.userName}</span>
          </div>
        </div>
      </div>
    </header>
  );
};

HeaderBar.propTypes = {
  userName: PropTypes.element,
  isGuideShowState: PropTypes.bool
};

export default HeaderBar;
