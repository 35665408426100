import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.css';
import {
  REFLECT_ARCHETYPES,
  SELECT_ARCHETYPES
} from '../../../../constants/path';
import { updateArchetypes } from '../../../../actions/profile';
import { useUserContext } from '../../../../contexts/UserContext';
import Grid from '@mui/material/Grid';
import {
  CANCEL_BUTTON_TEXT,
  REMOVE_ACHIEVEMENT_TITLE
} from '../../../../constants/modalConstants';
import { ArchetypeDetailModal } from '../../../../components/Common/Modal/ArchetypeDetails/ArchetypeDetailModal';
import { USER_GRADE } from '../../../../constants/user';

import { DashboardSectionHeader } from '../../../../nonFeatureComponents/DashboardSectionHeader';
import ConfirmationModal from '../../../../feature/ConfirmationModal';
import { ArchetypeItem } from './ArchetypeItem';
import { dashboardDes } from '../../language';
const ArchetypesCapableOf = ({ title, setOpen, archetypes, emptyMessage }) => {
  const {
    state: {
      user: { reflectedArchetypes, grade }
    },
    dispatch
  } = useUserContext();
  const shouldTrackPopUpForCourseRecV2UserInMatomo =
    grade === USER_GRADE.GRADUATE;
  const [
    removeConfirmationModalOpen,
    setRemoveConfirmationModalOpen
  ] = useState(false);
  const [selectedId, setSelectId] = useState(null);
  const [archetypeId, setArchetypeId] = useState(null);
  const handleBeforeRemove = selectedId => {
    setSelectId(selectedId);
    setRemoveConfirmationModalOpen(true);
  };
  const handleShowDetail = id => {
    id && setArchetypeId(id);
  };
  const handleRemove = useCallback(() => {
    if (!selectedId) {
      return;
    }
    const updatedArchetypes = reflectedArchetypes.filter(
      a => a.archetypeId !== selectedId
    );
    const updatedIds = updatedArchetypes.map(a => {
      return { archetypeId: a.archetypeId };
    });
    setRemoveConfirmationModalOpen(false);
    updateArchetypes(dispatch)(updatedIds, updatedArchetypes);
    setSelectId(null);
  }, [dispatch, reflectedArchetypes, selectedId]);

  return (
    <>
      <div className={styles.archetypes}>
        {archetypes.length > 0 && (
          <DashboardSectionHeader
            title={title}
            setOpen={setOpen}
            selectTargetPath={SELECT_ARCHETYPES}
            editTargetPath={{ path: REFLECT_ARCHETYPES }}
            ariaDes="archetypes"
          />
        )}
        <div className={styles.scrollWrapper}>
          {archetypes.length > 0 ? (
            <Grid spacing={1} container>
              {archetypes.map(({ archetypeId: id, archetypeName: name }) => (
                <Grid item key={id} xs={12} sm={4}>
                  <ArchetypeItem
                    onRemove={handleBeforeRemove}
                    name={name}
                    id={id}
                    onDetail={handleShowDetail}
                    shouldTrackPopUpForCourseRecV2UserInMatomo={
                      shouldTrackPopUpForCourseRecV2UserInMatomo
                    }
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            emptyMessage
          )}
        </div>
      </div>
      <ConfirmationModal
        open={removeConfirmationModalOpen}
        setOpen={() => setRemoveConfirmationModalOpen(false)}
        confirmButtonText={dashboardDes.archetypesCapableOf.confirmButtonText}
        cancelButtonText={CANCEL_BUTTON_TEXT}
        confirm={() => handleRemove()}
        cancel={() => setRemoveConfirmationModalOpen(false)}
        title={REMOVE_ACHIEVEMENT_TITLE}
        content={dashboardDes.archetypesCapableOf.content}
      />
      <ArchetypeDetailModal
        archetypeId={archetypeId}
        close={() => setArchetypeId(null)}
        isShowSelectButton={false}
      />
    </>
  );
};
ArchetypesCapableOf.propTypes = {
  archetypes: PropTypes.array.isRequired,
  title: PropTypes.node.isRequired,
  emptyMessage: PropTypes.node.isRequired
};

export default ArchetypesCapableOf;
