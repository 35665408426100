import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import * as DOMPurify from 'dompurify';

import {
  getWidthBySideWidth,
  getHeightBySideWidth
} from '../../../../utils/hexagon';

import styles from './index.module.css';
import PencilIcon from '../../../../components/Common/SvgIcons/PencilIcon';
import IconButton from '@mui/material/IconButton';
import InformationFillIcon from '../../../../components/Common/SvgIcons/InformationFillIcon';
import addIcon from '../../../../assets/Icons/add.svg';
import deleteIcon from '../../../../assets/Icons/delete-black.svg';
import { onKeyDown } from '../../../../utils/onKeyDown';

/* suppose the Hexagon side width is 2x, then the size of the div is
  {
    width: 2sqrt(3)x;
    height: 4x;
  }
  let x = 45px;
  then:   */
export const ORIGIN_HEX_SIZE_HEIGHT = 160;
export const ORIGIN_HEX_SIZE_WIDTH = 138.56406460551017;
export const COM_ITEM_GAP = 8;

const sanitizer = DOMPurify.sanitize;

const smallerSize = {
  transform: 'scale(0.8)',
  transformOrigin: '50% top'
};

export default function CompetencyHexagon(props) {
  const {
    sideWidth,
    actualScore,
    belongsTo,
    name,
    nameFilter = '',
    description,
    onSelect,
    selected,
    id,
    identifier,
    isStrength,
    isPrioritized,
    onSingleClickEdit,
    onSingleClickInfo,
    competencyLevelBehaviours,
    showInPDF
  } = props;
  const width = sideWidth
    ? getWidthBySideWidth(sideWidth)
    : ORIGIN_HEX_SIZE_WIDTH;
  const height = sideWidth
    ? getHeightBySideWidth(sideWidth)
    : ORIGIN_HEX_SIZE_HEIGHT;

  const selectClick = () => {
    if (onSingleClickEdit) {
      onSingleClickEdit({
        id,
        description,
        belongsTo,
        name,
        actualScore,
        isStrength,
        isPrioritized,
        competencyLevelBehaviours
      });
      return;
    }
    onSelect(selected);
  };

  const infoClick = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    const competencyInfo = {
      id,
      description,
      belongsTo,
      name,
      actualScore,
      isStrength,
      isPrioritized,
      competencyLevelBehaviours
    };
    if (onSingleClickEdit) {
      onSingleClickEdit(competencyInfo);
    }
    if (onSingleClickInfo) {
      onSingleClickInfo({
        ...competencyInfo,
        identifier,
        selected,
        onSelect
      });
    }
  };

  return (
    <div
      role="button"
      data-cy="hexagon"
      onClick={selectClick}
      onKeyDown={e => onKeyDown(e, selectClick)}
      tabIndex={showInPDF ? -1 : 0}
      className="hexagonWrapper"
    >
      <div
        tabIndex={-1}
        className={clsx(
          styles.competency,
          selected ? styles.borderSelected : styles.borderUnselected,
          showInPDF && styles.noScale,
          {
            [styles.competencyHoverable]:
              !!onSingleClickEdit || !!onSingleClickInfo
          }
        )}
        style={{
          width: width,
          height: height,
          marginRight: COM_ITEM_GAP
        }}
      >
        <div
          style={{
            width: width - 4,
            height: height - 4
          }}
          className={clsx(styles.competencyAfter)}
        />
        <div
          style={{
            width: `${width - 4}px`,
            height: `${height - 4}px`
          }}
          className={clsx(styles.competencyAfter, {
            [styles.selected]: selected === true,
            [styles.competencyDashboard]: !!onSingleClickEdit
          })}
        />
        <div
          className={styles.competencyContent}
          style={width < ORIGIN_HEX_SIZE_WIDTH ? { ...smallerSize } : {}}
        >
          {!!onSingleClickInfo ? (
            <div
              className={styles.competencyInfoIconWrapper}
              onClick={e => infoClick(e, id)}
              onKeyDown={e => onKeyDown(e, () => infoClick(e, id))}
            >
              <span
                role="button"
                aria-label="Show details"
                className={styles.competencyInfoIconWrapperInner}
                tabIndex={-1}
              >
                <InformationFillIcon className={styles.competencyInfoIcon} />
              </span>
            </div>
          ) : (
            <div className={styles.noIcon} />
          )}
          <div className={styles.nameOuter} tabIndex={showInPDF ? -1 : 0}>
            <div
              className={clsx(
                styles.name,
                showInPDF ? styles.noUnderline : '',
                {
                  [styles.competencyContentSelected]: selected
                },
                {
                  [styles.competencyDashboard]: !!onSingleClickEdit
                }
              )}
              tabIndex={-1}
              dangerouslySetInnerHTML={{
                __html: sanitizer(nameFilter || name)
              }}
            />
          </div>
          {!selected ? (
            <div
              className={styles.competencyAddIconWrapper}
              tabIndex="0"
              role="button"
            >
              <img
                className={styles.addIcon}
                src={addIcon}
                alt="select competency"
              />
            </div>
          ) : (
            <div
              className={styles.competencyDeleteIconWrapper}
              tabIndex="0"
              role="button"
            >
              <img src={deleteIcon} alt="delete competency" />
            </div>
          )}
        </div>
        {!!onSingleClickEdit && (
          <div className={styles.competencyEditIconWrapper}>
            <IconButton
              aria-label="Edit this reflection"
              onClick={selectClick}
              tabIndex={-1}
            >
              <PencilIcon className={styles.competencyEditIcon} />
            </IconButton>
          </div>
        )}
      </div>
    </div>
  );
}

CompetencyHexagon.propTypes = {
  sideWidth: PropTypes.number,
  belongsTo: PropTypes.array,
  name: PropTypes.string,
  nameFilter: PropTypes.string,
  description: PropTypes.string,
  onSelect: PropTypes.func,
  selected: PropTypes.bool,
  onSingleClickEdit: PropTypes.func,
  onSingleClickInfo: PropTypes.func,
  actualScore: PropTypes.number,
  isStrength: PropTypes.bool,
  isPrioritized: PropTypes.bool,
  competencyLevelBehaviours: PropTypes.array,
  id: PropTypes.number,
  showInPDF: PropTypes.bool,
  identifier: PropTypes.string
};
