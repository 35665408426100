import React from 'react';
import { SvgIcon } from '@mui/material';

const Vector = props => {
  return (
    <SvgIcon {...props}>
      <svg
        width={props.width || 17}
        height={props.height || 17}
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.44084 14.2258C1.66587 13.4529 1.05129 12.5344 0.632408 11.5232C0.213529 10.5119 -0.00138862 9.42789 6.75125e-06 8.33333C6.75125e-06 3.73083 3.73084 0 8.33334 0C12.9358 0 16.6667 3.73083 16.6667 8.33333C16.6667 12.9358 12.9358 16.6667 8.33334 16.6667H6.75125e-06L2.44084 14.2258ZM5.00001 9.16667C5.00001 10.0507 5.3512 10.8986 5.97632 11.5237C6.60144 12.1488 7.44928 12.5 8.33334 12.5C9.21739 12.5 10.0652 12.1488 10.6904 11.5237C11.3155 10.8986 11.6667 10.0507 11.6667 9.16667H5.00001Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
};

export default Vector;
