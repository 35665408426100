import { filter, groupBy, isEmpty, orderBy } from 'lodash';
import {
  BOTH_ME_NOW_AND_ME_NEXT,
  ME_NEXT,
  ME_NOW
} from '../../constants/dashborad';

export const allCompetenciesGroupByCategory = res => {
  const groupedCompetencies = groupBy(res, 'category');
  let sortedCompetencies = {};
  Object.keys(groupedCompetencies)
    .sort()
    .map(item => {
      const competenciesSortedByName = orderBy(groupedCompetencies[item], [
        'name'
      ]);
      return (sortedCompetencies[item] = competenciesSortedByName);
    });
  return sortedCompetencies;
};

export const coreCompetenciesFromAllCompetencies = res => {
  const coreCompetencies = filter(
    res,
    competency => !isEmpty(competency.coreCapabilities)
  );
  return orderBy(coreCompetencies, ['name']);
};

export const reflectedCompetencies = res =>
  filter(res, com => !isEmpty(com?.reflectedInformation));

export const groupCompetenciesByStage = competencies => {
  const strength = filter(
    competencies,
    c =>
      c?.reflectedInformation.stage === ME_NOW ||
      c?.reflectedInformation.stage === BOTH_ME_NOW_AND_ME_NEXT
  );
  const prioritized = filter(
    competencies,
    c =>
      c?.reflectedInformation.stage === ME_NEXT ||
      c?.reflectedInformation.stage === BOTH_ME_NOW_AND_ME_NEXT
  );
  return {
    strength: strength,
    prioritized: prioritized
  };
};
