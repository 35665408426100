import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import globalStyles from '../../globalStyles';
import PropTypes from 'prop-types';

const TooltipWithStyles = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    position: 'relative',
    top: '0px',
    bottom: '0px',
    left: '0px',
    right: '0px',
    padding: '20px',
    color: globalStyles.black3,
    lineHeight: 'normal',
    borderRadius: '10px',
    backgroundColor: globalStyles.white,
    border: `1px solid ${globalStyles.blue3}`
  },
  '.MuiTooltip-arrow': {
    marginLeft: '4px'
  },
  [`&[data-popper-placement*="bottom"] .MuiTooltip-arrow`]: {
    top: '-8px',
    left: 0,
    marginTop: '-0.95em',
    width: 0,
    height: 0,
    borderLeft: '9px solid transparent',
    borderRight: '9px solid transparent',
    borderBottom: `18px solid ${globalStyles.black3}`
  },
  [`&[data-popper-placement*="top"] .MuiTooltip-arrow`]: {
    left: 0,
    bottom: '-11px',
    marginTop: '-0.95em',
    width: 0,
    height: 0,
    borderLeft: '9px solid transparent',
    borderRight: '9px solid transparent',
    borderTop: `18px solid ${globalStyles.black3}`
  },
  [`&[data-popper-placement*="left"] .MuiTooltip-arrow`]: {
    left: 0,
    marginTop: '-0.95em',
    width: 0,
    height: 0,
    borderTop: '9px solid transparent',
    borderBottom: '9px solid transparent',
    borderLeft: `18px solid ${globalStyles.black3}`
  },
  [`&[data-popper-placement*="right"] .MuiTooltip-arrow`]: {
    left: 0,
    marginTop: '-0.95em',
    width: 0,
    height: 0,
    borderTop: '9px solid transparent',
    borderBottom: '9px solid transparent',
    borderRight: `18px solid ${globalStyles.black3}`
  }
}));

const ArrowTooltip = props => {
  const { title, placement, classes, arrow = false } = props;
  return (
    <div>
      <TooltipWithStyles
        arrow={arrow}
        sx={classes}
        placement={placement}
        title={<React.Fragment>{title}</React.Fragment>}
        {...props}
      >
        {props.children}
      </TooltipWithStyles>
    </div>
  );
};

ArrowTooltip.propTypes = {
  title: PropTypes.node
};

export default ArrowTooltip;
