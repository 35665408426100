export const REFLECT_COMPETENCIES = '/reflect-competencies';
export const SELECT_COMPETENCIES = '/select-competencies';
export const SELECT_SKILLS = '/select-skills';
export const REFLECT_SKILLS = '/reflect-skills';
export const EDIT_REFLECT_SKILLS = '/edit-reflect-skills';
export const HOME_PAGE = '/';
export const DASHBOARD = '/dashboard';
export const TARGET = '?target=';
export const DASHBOARD_WITH_TARGET = DASHBOARD + TARGET;
export const STATS = '/stats';
export const SELECT_ARCHETYPES = '/select-archetypes';
export const REFLECT_ARCHETYPES = '/reflect-archetypes';
export const ARCHETYPES_INTENTION = '/archetypes-intention';
export const VISIBILITY_PREFERENCE = '/visibility-preference';
export const COURSE_RECOMMENDATIONS = '/course-recommendations';
