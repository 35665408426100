import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { styled } from '@mui/material/styles';
import globalStyles from '../../globalStyles';
import { Dialog } from '@mui/material';

export const DialogBox = styled(Dialog)({
  '& .MuiDialog-paper': {
    width: '800px',
    margin: '0px',
    borderRadius: '7px',
    maxWidth: '800px'
  }
});

export const DialogTitleWithStyle = styled(DialogTitle)({
  textAlign: 'center',
  backgroundColor: globalStyles.primaryColor,
  flex: 1,
  width: 0,
  letterSpace: 'normal',
  fontSize: '20px',
  fontWeight: 'bold',
  letterSpacing: '0.8px',
  color: globalStyles.black
});

export const DialogContentTextWithStyle = styled(DialogContentText)({
  fontSize: '16px',
  lineHeight: '1.71',
  textAlign: 'center',
  padding: '25px',
  marginBottom: '12px',
  color: globalStyles.black
});

export const DialogActionsWithStyle = styled(DialogActions)({
  paddingBottom: '30px',
  justifyContent: 'center'
});
