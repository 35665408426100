/* eslint-disable max-lines */
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Button, Pagination } from '@mui/material';
import { withStyles } from '@mui/styles';
import Checkbox from '@mui/material/Checkbox';
import Modal from '@mui/material/Modal';
import clsx from 'clsx';
import dom2image from 'dom-to-image';
import PDF from 'jspdf';
import { cloneDeep, get, isEmpty, last } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import dudeLook from '../../src/assets/Icons/pdfDudeLook.png';
import dudeWait from '../../src/assets/Icons/pdfDudeWait.png';
import Close from '../assets/Icons/Close';
import downloadErrorIcon from '../assets/Icons/download-error.png';
import LastUpdateIcon from '../assets/Icons/last-update.png';
import pdfDudeHold from '../assets/Icons/pdfDudeHold.png';
import { CORE_CAPABILITY_EVENT } from '../constants/matomo';
import globalStyles from '../globalStyles';
import {
  ArchetypesLabel,
  CoreCompetenciesLabel,
  RelatedContributionForGoal
} from '../pages/Dashboard/MyPlanCard/RelatedContributionForGoal';
import { formatToEN } from '../utils/datetimeFormat';
import PDFArchetypes from './components/PDFArchetypes';
import PDFCompetencies from './components/PDFCompetencies';
import PDFFooter from './components/PDFFooter';
import PDFHeader from './components/PDFHeader';
import PDFMyInterest from './components/PDFMyInterest';
import styles from './PDFModal.module.css';
import {
  ACTIONS_GAPS_TITLE_HEIGHT,
  EMPTY_ARCHETYPES_CORE_CAPABILITIES_GROWTH_HEIGHT,
  EMPTY_COM_HEIGHT,
  EMPTY_LIST_HEIGHT,
  FULL_RENDER_HEIGHT,
  getArchetypesHeight,
  getCompetenciesHeight,
  getInterestPagination,
  getListHeight,
  getRestHeightAfterRenderCompetencies,
  getRestListNum,
  getRowsHeight,
  GROWTH_TEXT_NUMBER_EACH_ROW,
  GROWTH_TITLE_HEIGHT,
  LIST_TYPE,
  MAX_COMPETENCIES_IN_PAGE,
  MENOW_MENEXT_LOGO_HEIGHT,
  MY_PLAN_HEIGHT,
  PAGINATION_HEIGHT,
  TEXT_NUMBER_EACH_ROW
} from './utils';
import logger from '../config/logsConfig';
import { GOAL_TYPE } from '../constants/goal';
import { onKeyDown } from '../utils/onKeyDown';
import { HexagonCompetencyView } from '../pages/Dashboard/MyPlanCard/GoalCard/HexagonCompetencyView';
import { styled } from '@mui/material/styles';
import { flushSync } from 'react-dom';

export const CustomCheckbox = withStyles({
  root: {
    '& svg': {
      width: '25px',
      height: '25px',
      color: globalStyles.grey23
    }
  },
  checked: {
    '& svg': {
      color: globalStyles.primaryGreen
    }
  },
  disabled: {
    '& svg': {
      opacity: 1
    }
  }
})(Checkbox);

export const DownloadButton = styled(Button)({
  width: '157px',
  height: '58px',
  fontSize: '16px',
  fontWeight: 'bold',
  color: globalStyles.white,
  backgroundColor: globalStyles.black,
  lineHeight: '1.5',
  textTransform: 'capitalize',
  '&:hover': {
    backgroundColor: globalStyles.black
  },
  '&:focus': {
    backgroundColor: globalStyles.black,
    outline: '2px solid #1a73ba'
  },
  zIndex: '5'
});

export const RetryButton = styled(Button)({
  width: '127px',
  height: '58px',
  fontSize: '16px',
  fontWeight: 'bold',
  color: globalStyles.white,
  backgroundColor: globalStyles.black,
  lineHeight: '1.5',
  textTransform: 'capitalize',
  '&:hover': {
    backgroundColor: globalStyles.black
  },
  '&:focus': {
    backgroundColor: globalStyles.black,
    outline: '2px solid #1a73ba'
  },
  zIndex: '5'
});

const PDFModal = ({ open, onClose, meNowData, meNextData, myPlanData }) => {
  const [pageData, setPageData] = useState([]);
  const [pages, setPages] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [progress, setProgress] = useState(-100);
  const [showDownloadMask, setShowDownloadMask] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadError, setDownloadError] = useState(false);
  const { trackEvent } = useMatomo();
  const pageDom = useRef(null);
  const [info] = useState([
    {
      logo: dudeLook,
      text: 'Me Now',
      offset: styles.meNowOffset
    },
    {
      logo: dudeWait,
      text: 'Me Next',
      offset: styles.meNextOffset
    }
  ]);

  const handleDownloadPDF = async () => {
    setProgress(-100);
    setShowDownloadMask(true);
    setIsDownloading(true);
    // a4 size
    let imgX = 595;
    let imgY = 842;
    const fileName = moment(new Date()).format('YYYYMMDD');
    const pdf = new PDF('', 'pt', 'a4', true);

    let renderIndexList = [...Array(pageCount).keys()];

    try {
      for (const i of renderIndexList) {
        flushSync(() => {
          setCurrentIndex(i);
        });
        const base64 = await dom2image.toPng(pageDom.current, {
          width: 1308,
          height: 1800
        });
        await pdf.addImage(base64, 'png', 0, 0, imgX, imgY, '', 'FAST');
        await pdf.addPage();
        await setProgress(((i + 1) / pageCount - 1) * 100);
      }

      pdf.deletePage(pdf.internal.getNumberOfPages());

      setTimeout(() => {
        pdf.save(`MyGrowthCanvas_${fileName}.pdf`);
        handleClose();
      }, 1000);

      logger.info('info download');
      logger.error('error download');

      trackEvent({
        category: CORE_CAPABILITY_EVENT.category,
        action: CORE_CAPABILITY_EVENT.action.EXPORT
      });
    } catch (error) {
      logger.error(`download PDF error: ${error}`);

      if (typeof error === 'object') {
        let messageString = '';
        for (let x in error) {
          if (messageString) messageString += ', ';
          messageString += x + ': ' + error[x];
        }
        let newError = '{' + messageString + '}';
        logger.error(`download PDF newError: ${newError}`);
      }

      setDownloadError(true);
      setIsDownloading(false);
    }
  };

  const getComTitle = type =>
    type === 0 ? 'My Competencies Today' : 'Competencies to develop';

  const getArchTitle = type =>
    type === 0 ? 'My Archetypes Today' : 'Archetypes to develop';

  const getInterest = (type, interests) => (type === 0 ? interests : null);

  useEffect(() => {
    setPageData([
      {
        type: 0,
        competencies: meNowData.competencies,
        archetypes: meNowData.archetypes,
        interests: meNowData.interests
      },
      {
        type: 1,
        competencies: meNextData.competencies,
        archetypes: meNextData.archetypes,
        interests: meNextData.interests
      },
      {
        type: 2,
        goals: myPlanData,
        competencies: [],
        archetypes: [],
        interests: []
      }
    ]);
  }, [
    meNowData.competencies,
    meNowData.archetypes,
    meNowData.interests,
    meNextData.competencies,
    meNextData.archetypes,
    meNextData.interests,
    myPlanData
  ]);
  /* Competencies empty, Archetypes exist, Interest empty   */
  const getPagesWhenCompentenciesEmptyArchetypeExistInterestEmpty = (
    archetypesLength,
    type,
    archetypeList
  ) => {
    let archRenderCounts = getArchetypesHeight(
      archetypesLength,
      0,
      EMPTY_COM_HEIGHT,
      []
    );
    const pages = [
      {
        type,
        competencies: {
          title: getComTitle(type),
          content: []
        },
        archetypes: {
          title: getArchTitle(type),
          content: archetypeList.splice(0, archRenderCounts[0])
        },
        interests: {
          title: 'My Interests are',
          content: []
        }
      }
    ];
    archRenderCounts = getArchetypesHeight(
      archetypeList.length,
      0,
      FULL_RENDER_HEIGHT,
      []
    );

    archRenderCounts.forEach(counts => {
      if (counts !== 0) {
        pages.push({
          type,
          competencies: {
            title: getComTitle(type),
            content: null
          },
          archetypes: {
            title: getArchTitle(type),
            content: archetypeList.splice(0, counts)
          },
          interests: {
            title: 'My Interests are',
            content: []
          }
        });
      }
    });

    let formatInterest = pages.filter(item => item.interests.content);
    formatInterest.shift();
    formatInterest.forEach(item => (item.interests.content = []));
    return pages;
  };

  /* Competencies exist, Archetypes empty, Interest empty   */
  const getPagesWhenCompetenciesExistArchetypeEmptyInterestEmpty = (
    competenciesLength,
    type,
    competencyList
  ) => {
    const comRenderCounts = getCompetenciesHeight(competenciesLength, []);
    const pages = comRenderCounts.map(counts => ({
      type,
      competencies: {
        title: getComTitle(type),
        content: competencyList.splice(0, counts)
      },
      archetypes: { title: getArchTitle(type), content: null },
      interests: {
        title: 'My Interests are',
        content: null
      }
    }));
    const lastPageCounts = last(comRenderCounts);
    if (lastPageCounts > MAX_COMPETENCIES_IN_PAGE) {
      pages.push({
        type,
        competencies: {
          title: getComTitle(type),
          content: null
        },
        archetypes: { title: getArchTitle(type), content: null },
        interests: {
          title: 'My Interests are',
          content: null
        }
      });
    } else {
      last(pages).archetypes.content = null;
    }
    return pages;
  };

  /* Competencies exist, Archetypes exist, Interest empty   */
  const getPagesWhenCompetenciesExistArchetypesExistInterestEmpty = (
    competenciesLength,
    archetypesLength,
    type,
    competencyList,
    archetypeList
  ) => {
    //pagination for competencies
    const comRenderCounts = getCompetenciesHeight(competenciesLength, []);
    let pages = comRenderCounts.map(counts => ({
      type,
      competencies: {
        title: getComTitle(type),
        content: competencyList.splice(0, counts)
      },
      archetypes: {
        title: getArchTitle(type),
        content: null
      },
      interests: {
        title: 'My Interests are',
        content: null
      }
    }));

    //calculate restHeight, then pagination for archetypes
    const restHeight = getRestHeightAfterRenderCompetencies(
      last(comRenderCounts)
    );
    let archRenderCounts = getArchetypesHeight(
      archetypesLength,
      0,
      restHeight,
      []
    );
    if (archRenderCounts[0] !== 0) {
      last(pages).archetypes.content = archetypeList.splice(
        0,
        archRenderCounts[0]
      );
      last(pages).interests.content = [];
    } else {
      pages.push({
        type,
        competencies: {
          title: getComTitle(type),
          content: null
        },
        archetypes: {
          title: getArchTitle(type),
          content: archetypeList.splice(0, archRenderCounts[1])
        },
        interests: {
          title: 'My Interests are',
          content: []
        }
      });
    }

    archRenderCounts = getArchetypesHeight(
      archetypeList.length,
      0,
      FULL_RENDER_HEIGHT,
      []
    );

    archRenderCounts.forEach(counts => {
      if (counts !== 0) {
        pages.push({
          type,
          competencies: {
            title: getComTitle(type),
            content: null
          },
          archetypes: {
            title: getArchTitle(type),
            content: archetypeList.splice(0, counts)
          },
          interests: {
            title: 'My Interests are',
            content: []
          }
        });
      }
    });

    let formatInterest = pages.filter(item => item.interests.content);
    formatInterest.shift();
    formatInterest.forEach(item => (item.interests.content = []));

    return pages;
  };

  /* Competencies empty, Archetypes empty, Interest exist   */
  const getPagesWhenCompetenciesEmptyAchetypesEmptyInterestExist = (
    interests,
    type
  ) => {
    // Pages only have interest, so pagination for interest
    const interestsPagination = getInterestPagination(
      interests,
      FULL_RENDER_HEIGHT - MENOW_MENEXT_LOGO_HEIGHT,
      []
    );
    const pages = [];
    interestsPagination.forEach(splitIndex => {
      const interestInCurrentPage = interests.splice(0, splitIndex);
      if (splitIndex !== 0) {
        pages.push({
          type,
          competencies: {
            title: getComTitle(type),
            content: null
          },
          archetypes: {
            title: getArchTitle(type),
            content: []
          },
          interests: {
            title: 'My Interests are',
            content: getInterest(type, interestInCurrentPage)
          }
        });
      }
    });
    return pages;
  };

  /* Competencies empty, Archetypes exist, Interest exist   */
  const getPagesWhenCompetenciesEmptyAchetypesExistInterestExist = (
    archetypesLength,
    archetypeList,
    type,
    interests
  ) => {
    const pages = [];
    let archetypesPagination = getArchetypesHeight(
      archetypesLength,
      0,
      FULL_RENDER_HEIGHT,
      []
    );
    let interestsPagination = getInterestPagination(
      interests,
      FULL_RENDER_HEIGHT,
      []
    );

    const archetypesPaginationLength = archetypesPagination.length;
    const interestsPaginationLength = interestsPagination.length;
    const interestsData = getInterest(type, interests);
    pages.push({
      type,
      competencies: {
        title: getComTitle(type),
        content: null
      },
      archetypes: {
        title: getArchTitle(type),
        content: archetypeList.splice(0, archetypesPagination[0])
      },
      interests: {
        title: 'My Interests are',
        content: interestsData
          ? interestsData.splice(0, interestsPagination[0])
          : []
      }
    });

    // archetypes fit with restHeight, interest is long and need to be pagination
    if (archetypesPaginationLength === 1 && interestsPaginationLength > 1) {
      interestsPagination = getInterestPagination(
        interestsData,
        FULL_RENDER_HEIGHT,
        []
      );

      interestsPagination.forEach(counts => {
        if (counts !== 0) {
          pages.push({
            type,
            competencies: {
              title: getComTitle(type),
              content: null
            },
            archetypes: {
              title: getArchTitle(type),
              content: []
            },
            interests: {
              title: 'My Interests are',
              content: type === 0 ? interestsData.splice(0, counts) : []
            }
          });
        }
      });
    }

    // archetypes is long and need pagination, interest is fit with rest height
    if (archetypesPaginationLength > 1 && interestsPaginationLength === 1) {
      archetypesPagination = getArchetypesHeight(
        archetypeList.length,
        0,
        FULL_RENDER_HEIGHT,
        []
      );
      archetypesPagination.forEach(counts => {
        if (counts !== 0) {
          pages.push({
            type,
            competencies: {
              title: getComTitle(type),
              content: null
            },
            archetypes: {
              title: getArchTitle(type),
              content: archetypeList.splice(0, counts)
            },
            interests: {
              title: 'My Interests are',
              content: []
            }
          });
        }
      });
    }

    // archetypes and interest are long, and need pagination
    if (archetypesPaginationLength > 1 && interestsPaginationLength > 1) {
      archetypesPagination = getArchetypesHeight(
        archetypeList.length,
        0,
        FULL_RENDER_HEIGHT,
        []
      );
      interestsPagination = getInterestPagination(
        interests,
        FULL_RENDER_HEIGHT,
        []
      );

      const differencePagesBetweenArchetypesAndInterests =
        archetypesPagination.length - interestsPagination.length;
      if (differencePagesBetweenArchetypesAndInterests === 0) {
        archetypesPagination.forEach((archetypeSplitIndex, index) => {
          if (archetypeSplitIndex !== 0 && interestsPagination[index] !== 0) {
            pages.push({
              type,
              competencies: {
                title: getComTitle(type),
                content: null
              },
              archetypes: {
                title: getArchTitle(type),
                content: archetypeList.splice(0, archetypeSplitIndex)
              },
              interests: {
                title: 'My Interests are',
                content:
                  type === 0
                    ? interestsData.splice(0, interestsPagination[index])
                    : []
              }
            });
          }
        });
      }

      /* archetypes pages more than interest pages */
      if (differencePagesBetweenArchetypesAndInterests > 0) {
        /* render both archetype and interest in same pages  */
        interestsPagination.forEach((interestSplitIndex, index) => {
          if (interestSplitIndex !== 0 && archetypesPagination[index] !== 0) {
            pages.push({
              type,
              competencies: {
                title: getComTitle(type),
                content: null
              },
              archetypes: {
                title: getArchTitle(type),
                content: archetypeList.splice(0, archetypesPagination[index])
              },
              interests: {
                title: 'My Interests are',
                content:
                  type === 0 ? interestsData.splice(0, interestSplitIndex) : []
              }
            });
          }
          // remove current archetype page from archetypes paggination
          archetypesPagination.shift();
        });
        /* render the rest of archetypes pages */
        archetypesPagination.forEach(splitIndex => {
          pages.push({
            type,
            competencies: {
              title: getComTitle(type),
              content: null
            },
            archetypes: {
              title: getArchTitle(type),
              content: archetypeList.splice(0, splitIndex)
            },
            interests: {
              title: 'My Interests are',
              content: []
            }
          });
        });
      }
      if (differencePagesBetweenArchetypesAndInterests < 0) {
        /* render both archetype and interest in same pages  */
        archetypesPagination.forEach((archetypeSplitIndex, index) => {
          if (archetypeSplitIndex !== 0 && interestsPagination[index] !== 0) {
            pages.push({
              type,
              competencies: {
                title: getComTitle(type),
                content: null
              },
              archetypes: {
                title: getArchTitle(type),
                content: archetypeList.splice(0, archetypeSplitIndex)
              },
              interests: {
                title: 'My Interests are',
                content:
                  type === 0
                    ? interestsData.splice(0, interestsPagination[index])
                    : []
              }
            });
          }
          // remove current interest page from interests paggination
          interestsPagination.shift();
        });
        /* render the rest of interests pages */
        interestsPagination.forEach(splitIndex => {
          pages.push({
            type,
            competencies: {
              title: getComTitle(type),
              content: null
            },
            archetypes: {
              title: getArchTitle(type),
              content: []
            },
            interests: {
              title: 'My Interests are',
              content: interestsData.splice(0, splitIndex)
            }
          });
        });
      }
    }
    return pages;
  };

  /* Competencies exist, Archetypes empty, Interest exist   */
  const getPagesWhenCompetenciesExistAchetypesEmptyInterestExist = (
    competenciesLength,
    type,
    competencyList,
    interests
  ) => {
    //Pagination for competencies
    const competenciesPagination = getCompetenciesHeight(
      competenciesLength,
      []
    );
    const restHeight = getRestHeightAfterRenderCompetencies(
      last(competenciesPagination)
    );
    // pagination for interest after render competencies
    let interestsPagination = getInterestPagination(interests, restHeight, []);
    let pages = competenciesPagination.map(counts => ({
      type,
      competencies: {
        title: getComTitle(type),
        content: competencyList.splice(0, counts)
      },
      archetypes: {
        title: getArchTitle(type),
        content: null
      },
      interests: {
        title: 'My Interests are',
        content: null
      }
    }));

    if (interestsPagination[0] !== 0) {
      last(pages).interests.content = interests.splice(
        0,
        interestsPagination[0]
      );
      last(pages).archetypes.content = [];
    } else {
      pages.push({
        type,
        competencies: {
          title: getComTitle(type),
          content: null
        },
        archetypes: {
          title: getArchTitle(type),
          content: []
        },
        interests: {
          title: 'My Interests are',
          content: interests.splice(0, interestsPagination[1])
        }
      });
    }

    interestsPagination = getInterestPagination(
      interests,
      FULL_RENDER_HEIGHT,
      []
    );

    interestsPagination.forEach(counts => {
      if (counts !== 0) {
        pages.push({
          type,
          competencies: {
            title: getComTitle(type),
            content: null
          },
          archetypes: {
            title: getArchTitle(type),
            content: []
          },
          interests: {
            title: 'My Interests are',
            content: interests.splice(0, counts)
          }
        });
      }
    });

    return pages;
  };

  /* Competencies exist, Archetypes exist, Interest exist   */
  const getPagesWhenCompetenciesExistArchetypesExistInterestsExist = (
    competenciesLength,
    archetypesLength,
    type,
    competencyList,
    archetypeList,
    interests
  ) => {
    const competenciesPagination = getCompetenciesHeight(
      competenciesLength,
      []
    );
    // pagination for competencies
    let pages = competenciesPagination.map(counts => ({
      type,
      competencies: {
        title: getComTitle(type),
        content: competencyList.splice(0, counts)
      },
      archetypes: {
        title: getArchTitle(type),
        content: null
      },
      interests: {
        title: 'My Interests are',
        content: null
      }
    }));

    // get restHeight after render Compentencies, then pagination for Archetypes and Interest
    const restHeight = getRestHeightAfterRenderCompetencies(
      last(competenciesPagination)
    );
    let archetypesPagination = getArchetypesHeight(
      archetypesLength,
      0,
      restHeight,
      []
    );
    let interestsPagination = getInterestPagination(interests, restHeight, []);

    const archetypesPaginationLength = archetypesPagination.length;
    const interestsPaginationLength = interestsPagination.length;

    const interestsData = getInterest(type, interests);
    // archetypes and interests fit with restHeight, do not pagination
    if (archetypesPaginationLength === 1 && interestsPaginationLength === 1) {
      last(pages).archetypes.content = archetypeList.splice(
        0,
        archetypesPagination[0]
      );
      last(pages).interests.content = interestsData
        ? interestsData.splice(0, interestsPagination[0])
        : [];
    }
    // archetypes fit with restHeight, interest is long and need to be pagination
    if (archetypesPaginationLength === 1 && interestsPaginationLength > 1) {
      last(pages).archetypes.content = archetypeList.splice(
        0,
        archetypesPagination[0]
      );
      last(pages).interests.content = interestsData
        ? interestsData.splice(0, interestsPagination[0])
        : [];
      interestsPagination = getInterestPagination(
        interestsData,
        FULL_RENDER_HEIGHT,
        []
      );

      interestsPagination.forEach(counts => {
        if (counts !== 0) {
          pages.push({
            type,
            competencies: {
              title: getComTitle(type),
              content: null
            },
            archetypes: {
              title: getArchTitle(type),
              content: []
            },
            interests: {
              title: 'My Interests are',
              content: type === 0 ? interestsData.splice(0, counts) : []
            }
          });
        }
      });
    }

    // archetypes is long and need pagination, interest is fit with rest height
    if (archetypesPaginationLength > 1 && interestsPaginationLength === 1) {
      last(pages).archetypes.content = archetypeList.splice(
        0,
        archetypesPagination[0]
      );
      last(pages).interests.content = interestsData
        ? interestsData.splice(0, interestsPagination[0])
        : [];
      archetypesPagination = getArchetypesHeight(
        archetypeList.length,
        0,
        FULL_RENDER_HEIGHT,
        []
      );
      archetypesPagination.forEach(counts => {
        if (counts !== 0) {
          pages.push({
            type,
            competencies: {
              title: getComTitle(type),
              content: null
            },
            archetypes: {
              title: getArchTitle(type),
              content: archetypeList.splice(0, counts)
            },
            interests: {
              title: 'My Interests are',
              content: []
            }
          });
        }
      });
    }

    // archetypes and interest are long, and need pagination
    if (archetypesPaginationLength > 1 && interestsPaginationLength > 1) {
      last(pages).archetypes.content = archetypeList.splice(
        0,
        archetypesPagination[0]
      );
      last(pages).interests.content = interestsData.splice(
        0,
        interestsPagination[0]
      );

      archetypesPagination = getArchetypesHeight(
        archetypeList.length,
        0,
        FULL_RENDER_HEIGHT,
        []
      );
      interestsPagination = getInterestPagination(
        interests,
        FULL_RENDER_HEIGHT,
        []
      );

      const differencePagesBetweenArchetypesAndInterests =
        archetypesPagination.length - interestsPagination.length;
      if (differencePagesBetweenArchetypesAndInterests === 0) {
        archetypesPagination.forEach((archetypeSplitIndex, index) => {
          if (archetypeSplitIndex !== 0 && interestsPagination[index] !== 0) {
            pages.push({
              type,
              competencies: {
                title: getComTitle(type),
                content: null
              },
              archetypes: {
                title: getArchTitle(type),
                content: archetypeList.splice(0, archetypeSplitIndex)
              },
              interests: {
                title: 'My Interests are',
                content:
                  type === 0
                    ? interestsData.splice(0, interestsPagination[index])
                    : []
              }
            });
          }
        });
      }

      /* archetypes pages more than interest pages */
      if (differencePagesBetweenArchetypesAndInterests > 0) {
        /* render both archetype and interest in same pages  */
        interestsPagination.forEach((interestSplitIndex, index) => {
          if (interestSplitIndex !== 0 && archetypesPagination[index] !== 0) {
            pages.push({
              type,
              competencies: {
                title: getComTitle(type),
                content: null
              },
              archetypes: {
                title: getArchTitle(type),
                content: archetypeList.splice(0, archetypesPagination[index])
              },
              interests: {
                title: 'My Interests are',
                content:
                  type === 0 ? interestsData.splice(0, interestSplitIndex) : []
              }
            });
          }
          // remove current archetype page from archetypes paggination
          archetypesPagination.shift();
        });
        /* render the rest of archetypes pages */
        archetypesPagination.forEach(splitIndex => {
          pages.push({
            type,
            competencies: {
              title: getComTitle(type),
              content: null
            },
            archetypes: {
              title: getArchTitle(type),
              content: archetypeList.splice(0, splitIndex)
            },
            interests: {
              title: 'My Interests are',
              content: []
            }
          });
        });
      }
      if (differencePagesBetweenArchetypesAndInterests < 0) {
        /* render both archetype and interest in same pages  */
        archetypesPagination.forEach((archetypeSplitIndex, index) => {
          if (archetypeSplitIndex !== 0 && interestsPagination[index] !== 0) {
            pages.push({
              type,
              competencies: {
                title: getComTitle(type),
                content: null
              },
              archetypes: {
                title: getArchTitle(type),
                content: archetypeList.splice(0, archetypeSplitIndex)
              },
              interests: {
                title: 'My Interests are',
                content:
                  type === 0
                    ? interestsData.splice(0, interestsPagination[index])
                    : []
              }
            });
          }
          // remove current interest page from interests paggination
          interestsPagination.shift();
        });
        /* render the rest of interests pages */
        interestsPagination.forEach(splitIndex => {
          pages.push({
            type,
            competencies: {
              title: getComTitle(type),
              content: null
            },
            archetypes: {
              title: getArchTitle(type),
              content: []
            },
            interests: {
              title: 'My Interests are',
              content: interestsData.splice(0, splitIndex)
            }
          });
        });
      }
    }
    return pages;
  };

  useEffect(() => {
    let pagesConfig = [];
    pageData.forEach(item => {
      const competencyList = cloneDeep(item.competencies);
      const archetypeList = cloneDeep(item.archetypes);
      const interests = cloneDeep(item.interests);
      const competenciesLength = competencyList.length;
      const archetypesLength = archetypeList.length;
      const interestLength = interests ? interests.length : 0;
      const type = item.type;

      if (type !== 2) {
        if (
          competenciesLength === 0 &&
          archetypesLength === 0 &&
          interestLength === 0
        ) {
          pagesConfig.push({
            type,
            competencies: { title: getComTitle(type), content: [] },
            archetypes: { title: getArchTitle(type), content: [] },
            interests: {
              title: 'My Interests are',
              content: []
            }
          });
        } else if (
          competenciesLength > 0 &&
          archetypesLength === 0 &&
          interestLength === 0
        ) {
          const pages = getPagesWhenCompetenciesExistArchetypeEmptyInterestEmpty(
            competenciesLength,
            type,
            competencyList
          );
          pagesConfig.push(...pages);
        } else if (
          competenciesLength === 0 &&
          archetypesLength > 0 &&
          interestLength === 0
        ) {
          const pages = getPagesWhenCompentenciesEmptyArchetypeExistInterestEmpty(
            archetypesLength,
            type,
            archetypeList
          );
          pagesConfig.push(...pages);
        } else if (
          competenciesLength > 0 &&
          archetypesLength > 0 &&
          interestLength === 0
        ) {
          const pages = getPagesWhenCompetenciesExistArchetypesExistInterestEmpty(
            competenciesLength,
            archetypesLength,
            type,
            competencyList,
            archetypeList
          );
          pagesConfig.push(...pages);
        } else if (
          competenciesLength === 0 &&
          archetypesLength === 0 &&
          interestLength > 0
        ) {
          const pages = getPagesWhenCompetenciesEmptyAchetypesEmptyInterestExist(
            interests,
            type
          );
          pagesConfig.push(...pages);
        } else if (
          competenciesLength === 0 &&
          archetypesLength > 0 &&
          interestLength > 0
        ) {
          const pages = getPagesWhenCompetenciesEmptyAchetypesExistInterestExist(
            archetypesLength,
            archetypeList,
            type,
            interests
          );
          pagesConfig.push(...pages);
        } else if (
          competenciesLength > 0 &&
          archetypesLength === 0 &&
          interestLength > 0
        ) {
          const pages = getPagesWhenCompetenciesExistAchetypesEmptyInterestExist(
            competenciesLength,
            type,
            competencyList,
            interests,
            interestLength
          );
          pagesConfig.push(...pages);
        } else if (
          competenciesLength > 0 &&
          archetypesLength > 0 &&
          interestLength > 0
        ) {
          const pages = getPagesWhenCompetenciesExistArchetypesExistInterestsExist(
            competenciesLength,
            archetypesLength,
            type,
            competencyList,
            archetypeList,
            interests
          );
          pagesConfig.push(...pages);
        }
      } else {
        const goals = item.goals;

        const formatGoals = [];
        if (goals && goals.length === 0) {
          pagesConfig.push({
            type: 2,
            goals: [],
            showEmptyMsg: true
          });
          // My Plan
        } else {
          goals.forEach(goal => {
            const { contributions: { categories = [] } = {} } = goal;
            let gaps = cloneDeep(goal.gaps);
            let actions = cloneDeep(goal.actions);
            const gapsLen = gaps.length;
            const actionsLen = actions.length;
            if (gapsLen === 0 && actionsLen === 0) {
              formatGoals.push({
                id: goal.id,
                isPagination: false,
                createdTime: goal.createdAt,
                updatedTime: goal.updatedAt,
                competencyName: goal.name,
                competencyScore: goal.practiceLevel,
                period: goal.period,
                goalDescription: goal.description,
                gapDescription: goal.gaps,
                action: goal.actions,
                contributions: goal.contributions
              });
            } else if (gapsLen > 0 && actionsLen === 0) {
              const [listConfig, restHeight] = getRenderGapsConfig(goal, gaps);
              const lastConfig = last(listConfig);
              const lastGapsHeight = getListHeight(
                lastConfig.gapDescription,
                LIST_TYPE.GAPS,
                false
              );

              const actionRestHeight = restHeight - lastGapsHeight;
              if (actionRestHeight <= 300) {
                listConfig.push({
                  showActionTitle: true,
                  isPagination: true,
                  gapDescription: null,
                  action: []
                });
              } else {
                lastConfig.action = [];
              }

              formatGoals.push(...listConfig);
            } else if (gapsLen === 0 && actionsLen > 0) {
              const descHeight = getRowsHeight(
                goal.description.length,
                TEXT_NUMBER_EACH_ROW
              );
              const restHeight =
                MY_PLAN_HEIGHT - descHeight - EMPTY_LIST_HEIGHT;
              const splitActionsArr = getRestListNum(
                actions.map(action => action.name),
                restHeight,
                false,
                []
              );
              //listConfig is list pages for each goal
              const listConfig = [];

              splitActionsArr.forEach((splitNum, i) => {
                if (i === 0) {
                  listConfig.push({
                    goal: goal.id,
                    isPagination: false,
                    createdTime: goal.createdAt,
                    updatedTime: goal.updatedAt,
                    competencyName: goal.name,
                    competencyScore: goal.practiceLevel,
                    period: goal.period,
                    goalDescription: goal.description,
                    gapDescription: [],
                    action: actions.splice(0, splitNum),
                    contributions: goal.contributions
                  });
                } else {
                  listConfig.push({
                    isPagination: true,
                    gapDescription: null,
                    action: actions.splice(0, splitNum)
                  });
                }
              });

              formatGoals.push(...listConfig);
            } else if (gapsLen > 0 && actionsLen > 0) {
              const [
                listConfig,
                restHeight,
                splitGapsArr
              ] = getRenderGapsConfig(goal, gaps);

              let gapsHeightOfLastPage = 0;
              let lastConfig = last(listConfig);
              let splitActionsArr = [];

              if (splitGapsArr.length === 1) {
                gapsHeightOfLastPage = getListHeight(
                  lastConfig.gapDescription,
                  LIST_TYPE.GAPS,
                  false
                );

                const actionRestHeight = restHeight - gapsHeightOfLastPage;

                if (actionRestHeight > 32 + 22 + 16) {
                  splitActionsArr = getRestListNum(
                    actions.map(action => action.name),
                    restHeight - gapsHeightOfLastPage,
                    false,
                    []
                  );

                  lastConfig.showActionTitle = false;
                  lastConfig.action = actions.splice(
                    0,
                    splitActionsArr.splice(0, 1)
                  );

                  splitActionsArr.forEach(splitNum => {
                    listConfig.push({
                      isPagination: true,
                      gapDescription: null,
                      action: actions.splice(0, splitNum)
                    });
                  });
                } else {
                  splitActionsArr = getRestListNum(
                    actions.map(action => action.name),
                    PAGINATION_HEIGHT,
                    true,
                    []
                  );

                  lastConfig.showActionTitle = true;

                  splitActionsArr.forEach((splitNum, i) => {
                    listConfig.push({
                      showActionTitle: i === 0,
                      isPagination: true,
                      gapDescription: null,
                      action: actions.splice(0, splitNum)
                    });
                  });
                }
              } else {
                gapsHeightOfLastPage = getListHeight(
                  lastConfig.gapDescription.map(gap => gap.description),
                  LIST_TYPE.GAPS,
                  true
                );

                splitActionsArr = getRestListNum(
                  actions.map(action => action.name),
                  PAGINATION_HEIGHT - gapsHeightOfLastPage,
                  true,
                  []
                );

                lastConfig.showActionTitle = true;

                lastConfig.action = actions.splice(
                  0,
                  splitActionsArr.splice(0, 1)
                );

                splitActionsArr.forEach(splitNum => {
                  listConfig.push({
                    isPagination: true,
                    gapDescription: null,
                    action: actions.splice(0, splitNum)
                  });
                });
              }

              formatGoals.push(...listConfig);
            }

            // calculate GrowthHeight to push to new page or keep in current page
            const lastPageOfEachGoal = last(formatGoals);
            const restHeightForGrowth = calculateRestHeightForGrowth(
              lastPageOfEachGoal
            );
            const growthHeight = calculateGrowthHeight(
              lastPageOfEachGoal,
              goal
            );
            if (restHeightForGrowth >= growthHeight) {
              lastPageOfEachGoal.category = categories;
              lastPageOfEachGoal.goalType = goal.goalType;
              lastPageOfEachGoal.id = goal.id;
            } else {
              formatGoals.push({
                id: goal.id,
                isPagination: true,
                gapDescription: null,
                action: null,
                category: categories,
                goalType: goal.goalType,
                showActionTitle: false,
                contributions: goal.contributions
              });
            }
          });

          formatGoals
            ?.filter(goal => !goal.isPagination)
            .forEach((goal, i) => {
              goal.number = i + 1;
            });
          while (formatGoals.length !== 0) {
            pagesConfig.push({
              type: 2,
              goals: formatGoals.splice(0, 3)
            });
          }
        }
      }
    });

    setPages(pagesConfig);
    setPageCount(pagesConfig.length);
  }, [pageData]); // eslint-disable-line react-hooks/exhaustive-deps

  const calculateGrowthHeight = (lastPageOfEachGoal, goal) => {
    const categories = get(goal, 'contributions.categories', []);
    if (goal.goalType === GOAL_TYPE.CUSTOM) {
      return calculateListItemHeight(
        categories,
        LIST_TYPE.GROWTH,
        lastPageOfEachGoal.isPagination
      );
    }

    // When growth does not have any archetypes and core capabilities
    const archetypes = goal.contributions.archetypes;
    const coreCapabilities = goal.contributions.coreCapabilities;

    if (archetypes.length === 0 && coreCapabilities.length === 0) {
      return EMPTY_ARCHETYPES_CORE_CAPABILITIES_GROWTH_HEIGHT;
    }

    //When growth has archetypes or core capabilities
    let archetypesTextLength = 0;
    archetypes &&
      archetypes.length &&
      archetypes.forEach(item => {
        archetypesTextLength += item.length + 2;
      }); // plus 2 because of comma and space
    let coreCapabilitiesTextLength = 0;
    coreCapabilities &&
      coreCapabilities.forEach(
        item => (coreCapabilitiesTextLength += item.length + 2)
      );
    const marginBottom = 14;
    let growthWithArchetypesAndCompetenciesHeight = GROWTH_TITLE_HEIGHT;
    if (archetypes.length !== 0) {
      const archetypesHeight =
        getRowsHeight(
          ArchetypesLabel.length + archetypesTextLength,
          GROWTH_TEXT_NUMBER_EACH_ROW
        ) + marginBottom;
      growthWithArchetypesAndCompetenciesHeight += archetypesHeight;
    }
    if (coreCapabilities.length !== 0) {
      const coreCapabilitiesHeight =
        getRowsHeight(
          CoreCompetenciesLabel.length + coreCapabilitiesTextLength,
          GROWTH_TEXT_NUMBER_EACH_ROW
        ) + marginBottom;
      growthWithArchetypesAndCompetenciesHeight += coreCapabilitiesHeight;
    }

    return growthWithArchetypesAndCompetenciesHeight;
  };

  /* Calculate height of list gaps/ actions/  growth */
  const calculateListItemHeight = (list, listType, isPagination) => {
    if (list.length === 0) {
      return EMPTY_LIST_HEIGHT;
    }

    if (listType === LIST_TYPE.ACTIONS) {
      return getListHeight(
        list.map(item => item.name),
        listType,
        isPagination
      );
    }
    if (listType === LIST_TYPE.GAPS) {
      return getListHeight(list, listType, isPagination);
    }
    if (listType === LIST_TYPE.GROWTH) {
      return getListHeight(
        list.map(item => item),
        listType,
        false
      );
    }
  };

  /* Calculate rest height for growth, in the last page of each goal   */
  const calculateRestHeightForGrowth = lastPageOfEachGoal => {
    const gaps = lastPageOfEachGoal.gapDescription || [];
    const actions = lastPageOfEachGoal.action || [];
    //if lastPage is firstPage of each goal
    if (!lastPageOfEachGoal.isPagination) {
      const descriptionHeight = getRowsHeight(
        lastPageOfEachGoal.goalDescription.length,
        TEXT_NUMBER_EACH_ROW
      );
      const gapsHeight = calculateListItemHeight(gaps, LIST_TYPE.GAPS, false);
      const actionsHeight = calculateListItemHeight(
        actions,
        LIST_TYPE.ACTIONS,
        false
      );
      return MY_PLAN_HEIGHT - (descriptionHeight + gapsHeight + actionsHeight);
    }
    // if lastPage is not first page of each goal
    // when show action title
    if (lastPageOfEachGoal.showActionTitle) {
      const gapsHeightWithoutTitle = calculateListItemHeight(
        gaps,
        LIST_TYPE.GAPS,
        true
      );
      const actionsWithoutTitleHeight = calculateListItemHeight(
        actions,
        LIST_TYPE.ACTIONS,
        true
      );
      return (
        MY_PLAN_HEIGHT -
        (gapsHeightWithoutTitle +
          actionsWithoutTitleHeight +
          ACTIONS_GAPS_TITLE_HEIGHT)
      );
    }
    //calculate when not show action title
    const actionsWithoutTitleHeight = calculateListItemHeight(
      actions,
      LIST_TYPE.ACTIONS,
      true
    );
    return MY_PLAN_HEIGHT - actionsWithoutTitleHeight;
  };

  const getRenderGapsConfig = (goal, gaps) => {
    const descriptionHeight = getRowsHeight(
      goal.description.length,
      TEXT_NUMBER_EACH_ROW
    );
    const restHeight = MY_PLAN_HEIGHT - descriptionHeight;
    const splitGapsArr = getRestListNum(gaps, restHeight, false, []);
    const listConfig = [];
    splitGapsArr.forEach((splitNum, i) => {
      if (i === 0) {
        listConfig.push({
          id: goal.id,
          isPagination: false,
          createdTime: goal.createdAt,
          updatedTime: goal.updatedAt,
          competencyName: goal.name,
          competencyScore: goal.practiceLevel,
          period: goal.period,
          goalDescription: goal.description,
          contributions: goal.contributions,
          gapDescription: gaps.splice(0, splitNum),
          action: null
        });
      } else {
        listConfig.push({
          isPagination: true,
          gapDescription: gaps.splice(0, splitNum),
          action: null
        });
      }
    });
    return [listConfig, restHeight, splitGapsArr];
  };

  const handleClose = () => {
    setCurrentIndex(0);
    setShowDownloadMask(false);
    setIsDownloading(false);
    setDownloadError(false);
    onClose();
  };

  const handleRetry = () => {
    setDownloadError(false);
    handleDownloadPDF().then();
  };

  const renderCompetencies = page => {
    if (page.type === 2) return null;
    return (
      page.competencies.content !== null && (
        <PDFCompetencies
          wrapper={pageDom}
          type={page.type}
          title={page.competencies.title}
          competencies={page.competencies.content}
        />
      )
    );
  };

  const renderArchetypeAndInterest = (
    page,
    currentPageIndex,
    firstMeNowPageIndex,
    firstMeNextPageIndex
  ) => {
    if (page.type === 2) return null;

    const competencies = get(page, 'competencies.content', []);

    const isExistCompetenciesHeader =
      currentPageIndex === firstMeNextPageIndex ||
      competencies ||
      (firstMeNowPageIndex && competencies !== null);

    const header = isExistCompetenciesHeader ? null : (
      <header className={styles.logoWrapper}>
        <div className={clsx(styles.imgWrapper, info[page.type].offset)}>
          <img src={info[page.type].logo} alt="logo" />
        </div>
        <div className={styles.meNowMeNextText}>{info[page.type].text}</div>
      </header>
    );

    return (
      <>
        {header}

        <section className={styles.archetypesWrapper}>
          {page.archetypes.content !== null && (
            <PDFArchetypes
              type={page.type}
              title={page.archetypes.title}
              archetypes={page.archetypes.content}
            />
          )}
          {page.interests.content !== null && (
            <PDFMyInterest
              type={page.type}
              title={page.interests.title}
              interests={page.interests.content}
            />
          )}
        </section>
      </>
    );
  };

  const renderGaps = gaps => {
    if (!isEmpty(gaps) && gaps.length > 0) {
      return gaps.map((gap, i) => (
        <li className={styles.listItem} key={i}>
          {gap}
        </li>
      ));
    } else {
      return (
        <li className={clsx(styles.listItem, styles.emptyMsg)}>
          No gaps listed.
        </li>
      );
    }
  };

  const renderActions = actions => {
    if (!isEmpty(actions) && actions.length > 0) {
      return actions.map((item, i) => (
        <li
          className={clsx(styles.listItem, item.isDone ? styles.done : '')}
          key={item.name + i}
        >
          <div className={styles.checkBoxWrapper}>
            <CustomCheckbox checked={item.isDone} disabled={true} />
          </div>
          {item.name}
        </li>
      ));
    } else {
      return (
        <li className={clsx(styles.listItem, styles.emptyMsg)}>
          No actions listed.
        </li>
      );
    }
  };

  const RenderCategories = category => {
    if (!isEmpty(category) && category.length > 0) {
      return category.map((categoryItem, i) => (
        <li className={styles.listItem} key={i}>
          {categoryItem}
        </li>
      ));
    } else {
      return (
        <li className={clsx(styles.listItem, styles.emptyMsg)}>
          No items listed.
        </li>
      );
    }
  };

  const renderGrowthInMain = goal => {
    const archetypes = get(goal, 'contributions.archetypes', []);
    const coreCapabilities = get(goal, 'contributions.coreCapabilities', []);
    const categories = get(goal, 'contributions.categories', []);
    const title =
      'Working on this learning priority will support my growth in:';
    return (
      <ul className={clsx(styles.ul, styles.growthWrapper)}>
        {<p className={styles.listTitle}>{title}</p>}
        {goal.goalType === GOAL_TYPE.UNDER_ME_NEXT_COMPETENCY ? (
          <RelatedContributionForGoal
            archetypes={archetypes}
            coreCapabilities={coreCapabilities}
            pageType="PDFModal"
          />
        ) : (
          RenderCategories(categories)
        )}
      </ul>
    );
  };

  const renderMyPlan = page => {
    if (page.type !== 2) return null;
    const goals = page.goals;
    if (page.showEmptyMsg && goals.length === 0) {
      return (
        <section className={styles.myPlanWrapper}>
          <h2 className={styles.myPlanTitle}>
            <img src={pdfDudeHold} alt="pdfDudeHold" />
          </h2>
          <main className={styles.goalWrapper}>
            <div className={styles.msgWrapper}>
              <p>You have no goal in this category yet.</p>
              <p style={{ fontSize: 16 }}>
                Please go to your Pathways Growth Canvas and create your first
                goal.
              </p>
            </div>
          </main>
        </section>
      );
    }
    return (
      <section className={styles.myPlanWrapper}>
        <h2 className={styles.myPlanTitle}>
          <img src={pdfDudeHold} alt="pdfDudeHold" />
        </h2>
        <main className={styles.goalWrapper}>
          {goals.map((goal, i) => (
            <section
              key={i}
              className={clsx(
                styles.goal,
                goal.isPagination ? styles.clearTopPadding : ''
              )}
            >
              {!goal.isPagination && (
                <>
                  <div className={styles.header}>
                    <section
                      className={styles.subTitle}
                    >{`GOAL ${goal.number}`}</section>
                    <section className={styles.timeWrapper}>
                      <p className={styles.time}>
                        Created: {formatToEN(goal.createdTime)}
                      </p>
                      <p className={styles.time}>
                        <img src={LastUpdateIcon} alt="" />
                        Last updated:&nbsp;
                        {formatToEN(goal.updatedTime)}
                      </p>
                    </section>
                  </div>
                  <div className={styles.competencyWrapper}>
                    <HexagonCompetencyView
                      name={goal.competencyName || ''}
                      score={goal.competencyScore}
                      isMyOwnGoal={goal.goalType === GOAL_TYPE.CUSTOM}
                    />
                  </div>
                  <p
                    style={{ fontWeight: 'bold' }}
                  >{`In ${goal.period} months, I would like to:`}</p>
                  <p className={styles.goalDesc}>{goal.goalDescription}</p>
                </>
              )}
              {goal.gapDescription !== null && (
                <ul className={clsx(styles.ul, styles.gapWrapper)}>
                  {!goal.isPagination && (
                    <p className={styles.listTitle}>My Gaps</p>
                  )}
                  {renderGaps(goal.gapDescription)}
                </ul>
              )}
              {goal.action !== null && (
                <ul className={clsx(styles.ul, styles.actionWrapper)}>
                  {!goal.isPagination && (
                    <p className={styles.listTitle}>Actions</p>
                  )}
                  {goal.showActionTitle && (
                    <p className={styles.listTitle}>Actions</p>
                  )}
                  {renderActions(goal.action)}
                </ul>
              )}
              {renderGrowthInMain(goal)}
            </section>
          ))}
        </main>
      </section>
    );
  };
  const firstMeNowPageIndex = pages.findIndex(item => item.type === 0);
  const firstMeNextPageIndex = pages.findIndex(item => item.type === 1);

  return (
    <Modal
      disableBackdropClose={isDownloading}
      open={open}
      onClose={handleClose}
    >
      <div aria-labelledby="init" className={styles.modal}>
        <div className={styles.title}>
          <div id="init" className={styles.centralize}>
            PDF Preview
          </div>
          {!isDownloading && (
            <div
              tabIndex={0}
              role="button"
              onClick={handleClose}
              onKeyDown={e => onKeyDown(e, () => handleClose)}
              className={styles.close}
            >
              <span className={styles.closeInner} tabIndex={-1}>
                <Close />
              </span>
            </div>
          )}
        </div>
        <section
          style={{ display: showDownloadMask ? 'block' : 'none' }}
          className={styles.maskWrapper}
        >
          <main className={styles.mask}>
            {downloadError ? (
              <img
                style={{ width: 40, marginTop: -50 }}
                src={downloadErrorIcon}
                alt="download error"
              />
            ) : (
              <div className={styles.progressWrapper}>
                <div
                  style={{ transform: `translate3d(${progress}%, 0, 0)` }}
                  className={styles.progress}
                />
              </div>
            )}
            <div className={styles.tips}>
              {downloadError ? (
                <div style={{ 'text-align': 'center' }}>
                  Failed to download. Please try again.
                  <br />
                  You can check whether your goal writing is correct.
                  <br />
                  eg: add a space after the special char like '%'
                </div>
              ) : (
                'Preparing to download…'
              )}
            </div>
            {downloadError && (
              <div style={{ marginTop: 50 }}>
                <RetryButton tabIndex={0} onClick={handleRetry}>
                  Tra Again
                </RetryButton>
              </div>
            )}
          </main>
        </section>
        <div className={styles.downloadButton}>
          <DownloadButton tabIndex={0} onClick={handleDownloadPDF}>
            Download PDF
          </DownloadButton>
        </div>
        <div className={styles.pagination}>
          <span className={styles.pageTitle}>Page</span>
          <Pagination
            count={pageCount}
            onChange={(e, pageNum) => setCurrentIndex(pageNum - 1)}
          />
        </div>
        <div className={clsx(styles.pdfPageWrapper, styles.primaryScrollbar)}>
          <div className={styles.pdfPage} ref={pageDom}>
            <PDFHeader />
            {pages.length > 0 &&
              pages.map((page, i) => (
                <main
                  style={{ display: currentIndex === i ? 'block' : 'none' }}
                  key={i}
                  className={styles.pdfMain}
                >
                  {renderCompetencies(page)}
                  {renderArchetypeAndInterest(
                    page,
                    i,
                    firstMeNowPageIndex,
                    firstMeNextPageIndex
                  )}
                  {renderMyPlan(page)}
                </main>
              ))}
            <p className={styles.pageNum}>
              {`Pages ${currentIndex + 1} / ${pageCount}`}
            </p>
            <PDFFooter />
          </div>
        </div>
        <div className={styles.linearGradient} />
      </div>
    </Modal>
  );
};

PDFModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  meNowData: PropTypes.object.isRequired,
  meNextData: PropTypes.object.isRequired,
  myPlanData: PropTypes.array.isRequired
};

export default PDFModal;
