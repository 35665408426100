export const safeJSONParseArrayString = str => {
  try {
    const parsed = JSON.parse(str);
    if (Array.isArray(parsed)) {
      return parsed;
    }
    return [];
  } catch (error) {
    return [];
  }
};

export const SKILLS_STORAGE_KEY = 'skills_storage_key';

export const set = ID => {
  const stored = safeJSONParseArrayString(
    window?.localStorage.getItem(SKILLS_STORAGE_KEY)
  );
  stored.push(ID);
  return window?.localStorage.setItem(
    SKILLS_STORAGE_KEY,
    JSON.stringify(stored)
  );
};

export const removeByID = ID => {
  const stored = safeJSONParseArrayString(
    window?.localStorage.getItem(SKILLS_STORAGE_KEY)
  );
  return window?.localStorage.setItem(
    SKILLS_STORAGE_KEY,
    JSON.stringify(stored.filter(id => id !== ID))
  );
};

export const removeNewSelectedSkillName = ID => {
  const stored = safeJSONParseArrayString(
    window?.localStorage.getItem('newSelectedSkillName')
  );
  return window?.localStorage.setItem(
    'newSelectedSkillName',
    JSON.stringify(stored.filter(id => id !== ID))
  );
};

export const get = () =>
  safeJSONParseArrayString(window?.localStorage.getItem(SKILLS_STORAGE_KEY));

export const clear = () => window?.localStorage.removeItem(SKILLS_STORAGE_KEY);
