import { get, deleteREST, put, patch, post } from '../util/ajax';

export const fetchCoreCapabilities = () => {
  return get(`core-capabilities`);
};

export const fetchPeopleCompetencies = params => {
  return get('people/competencies', { params });
};

export const getCompetencyLevelDescription = competencyId => {
  return get(`/competency/${competencyId}/level-descriptions`);
};

export const removeCompetencyById = id => {
  return deleteREST(`people/competencies/${id}`);
};

export const upsertOrRemoveCompetencies = body => {
  return put('people/competencies', body);
};

export const createCompetency = body => {
  return post(`people/competencies`, body);
};

export const updateCompetency = (id, body) => {
  return patch(`people/competencies/${id}`, body);
};
