import React, { useRef, useState, useEffect } from 'react';
import { withStyles } from '@mui/styles';
import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';

export const LinearProgressWithStyles = withStyles(() => {
  return {
    root: {
      position: 'absolute',
      top: '2px',
      left: '0',
      right: '0'
    }
  };
})(LinearProgress);
const MLinearProgress = ({ loading }) => {
  const [isLoading, setIsLoading] = useState(false);
  const timer = useRef(null);
  useEffect(() => {
    timer.current = setTimeout(() => {
      if (loading) {
        setIsLoading(true);
      }
    }, 300);
    return () => {
      timer.current && clearTimeout(timer.current);
    };
  }, [loading, timer, setIsLoading]);
  useEffect(() => {
    if (!loading) {
      setIsLoading(false);
    }
  }, [loading, setIsLoading]);
  if (!isLoading) {
    return null;
  }
  return <LinearProgressWithStyles />;
};
MLinearProgress.propTypes = {
  loading: PropTypes.bool.isRequired
};

export default MLinearProgress;
