import React from 'react';
import PropTypes from 'prop-types';
import { updateUser } from '../../actions/profile';
import { useUserContext } from '../../contexts/UserContext';
import { PathwaysTrackEvents } from '../../support/pathwaysTrack';
import { TAKE_THE_TOUR } from '../../constants/matomo';
import tipsIcon from '../../assets/Icons/archetypesAndCompetenciesAndSkillsModalIcon.png';

import styles from './index.module.css';
import { getEnvVars } from '../../config/config';
import Popup from '../../nonFeatureComponents/Popup';
import { featureComponentsDes } from '../language';
import * as DOMPurify from 'dompurify';
import { CompetencyAndArchetypeTipModalLink } from '../../constants/links';

const { capableLink } = getEnvVars();
const sanitizer = DOMPurify.sanitize;

const CompetencyAndArchetypeTipModal = ({ tipModalOpened, closeTipModal }) => {
  const {
    state: { user },
    dispatch
  } = useUserContext();
  const {
    title,
    tipModalInfo,
    takeQuickTour,
    headerInfo
  } = featureComponentsDes.competencyAndArchetypeTipModal;

  const showGuideMap = () => {
    user.guideStepNumber = 1;
    dispatch(updateUser(user));
    closeTipModal();
    PathwaysTrackEvents([
      {
        category: TAKE_THE_TOUR.category,
        action: TAKE_THE_TOUR.action.TAKE_QUICK_TOUR_ON_TIPS_MODAL
      }
    ]);
  };
  return (
    <Popup
      modalClassName={'competencyAndArchetypeTipModal'}
      open={tipModalOpened}
      close={closeTipModal}
      image={tipsIcon}
      title={title}
      desc={tipModalInfo}
      bottomContent={takeQuickTour}
      bottomClick={showGuideMap}
      headerInfo={
        <div className={styles.headerInfo}>
          <p
            dangerouslySetInnerHTML={{
              __html: sanitizer(headerInfo.title)
            }}
          />
          {headerInfo.description}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={CompetencyAndArchetypeTipModalLink}
            className={styles.link}
          >
            &nbsp;here&nbsp;
          </a>
          and Capable
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={capableLink}
            className={styles.link}
          >
            &nbsp;here&nbsp;
          </a>
        </div>
      }
    />
  );
};

CompetencyAndArchetypeTipModal.propTypes = {
  tipModalOpened: PropTypes.bool.isRequired,
  closeTipModal: PropTypes.func.isRequired
};

export default CompetencyAndArchetypeTipModal;
