import { useState, useEffect } from 'react';

const getWidth = (ref, d) => {
  const style = getComputedStyle(ref.current);
  return (
    ref.current.clientWidth -
    parseFloat(style.paddingLeft || 0) -
    parseFloat(style.paddingRight || 0)
  );
};

export default function useRefWidth(ref, defaultValue) {
  let [width, setWidth] = useState(defaultValue);

  useEffect(() => {
    let timeoutId = null;
    const resizeListener = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => setWidth(getWidth(ref)), 150);
    };
    window.addEventListener('resize', resizeListener);
    if (ref.current && ref.current.clientWidth !== 0) {
      setWidth(getWidth(ref));
    }
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, [ref]);

  return width;
}
