import React, { useEffect, useState } from 'react';
import ArrowDown from '../../components/Common/SvgIcons/ArrowDown';
import LinkIcon from '../../components/Common/SvgIcons/LinkIcon';

import styles from './HeaderBar.module.css';
import { GrowthDiscoverLinks } from '../../constants/links';
import { useUserContext } from '../../contexts/UserContext';
import { PathwaysTrackEvents } from '../../support/pathwaysTrack';
import { COURSE_REC_STANDALONE_PAGE_EVENT } from '../../constants/matomo';
import { USER_GRADE } from '../../constants/user';
import { useHistory } from 'react-router-dom';
import { COURSE_RECOMMENDATIONS } from '../../constants/path';
import { updateProfile, updateUser } from '../../actions/profile';
import { TOP_COURSE_GUIDE_STEP_NUMBER } from '../../constants/number';
import { DevWhiteListForRecommendedCourses } from '../../constants/devWhiteListForRecommendedCourses';
import GuideModal from '../../pages/Dashboard/components/GuideModal';

export default function Grow() {
  const history = useHistory();
  const {
    state: {
      user,
      user: {
        grade,
        guideStepNumber,
        showTopCourseGuide,
        showOnboardingTour,
        email
      }
    },
    dispatch
  } = useUserContext();

  const isShowTopCourse =
    DevWhiteListForRecommendedCourses.includes(email) ||
    grade === USER_GRADE.GRADUATE;

  const [guideModalShow, setGuideModalShow] = useState(false);

  useEffect(() => {
    if (+guideStepNumber === 6 && isShowTopCourse) {
      setGuideModalShow(true);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [guideStepNumber, isShowTopCourse]);

  useEffect(() => {
    if (isShowTopCourse && showTopCourseGuide && !showOnboardingTour) {
      setGuideModalShow(true);
      user.guideStepNumber = TOP_COURSE_GUIDE_STEP_NUMBER;
      window.scrollTo({ top: 0, behavior: 'smooth' });
      dispatch(updateUser(user));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const closeGuideModal = () => {
    setGuideModalShow(false);
    document.getElementById('growthPaths').blur();
    updateProfile(dispatch)({ showTopCourseGuide: false });
  };

  const jumpToCourseRec = () => {
    history.push(COURSE_RECOMMENDATIONS);

    PathwaysTrackEvents([
      {
        category: COURSE_REC_STANDALONE_PAGE_EVENT.category,
        action:
          COURSE_REC_STANDALONE_PAGE_EVENT.action
            .JUMP_TO_STANDALONE_PAGE_VIA_THE_TOP_COURSE_TAB
      }
    ]);
  };

  return (
    <div
      className={styles.growthPath}
      tabIndex="0"
      aria-haspopup="listbox"
      id="growthPaths"
      aria-label="discover growth paths"
    >
      Help Me Grow
      <div className={styles.circleArrow}>
        <ArrowDown className={styles.arrowDown} />
      </div>
      <div
        className={styles.discoverLinkList}
        tabIndex="-1"
        role="listbox"
        aria-labelledby="growthPaths"
      >
        {GrowthDiscoverLinks(isShowTopCourse).map((l, i) => (
          <div
            className={styles.link}
            key={l.name}
            role="option"
            aria-selected={i === 0}
          >
            {l.name === 'Top Courses' ? (
              <a onClick={() => jumpToCourseRec()}>{l.name}</a>
            ) : (
              <div>
                <a target="_blank" rel="noopener noreferrer" href={l.link}>
                  {l.name}
                </a>
                <LinkIcon className={styles.linkIcon} />
              </div>
            )}
          </div>
        ))}
      </div>
      <GuideModal
        guideModalShow={guideModalShow}
        closeGuideModal={closeGuideModal}
        updateGuideModal={closeGuideModal}
      />
    </div>
  );
}
