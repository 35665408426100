import React, { useEffect, useState } from 'react';
import { ajax } from '../../api/util/ajax';
import { HTTP_STATUS } from '../../constants/httpStatus';

const withErrorChecking = Wrapped => {
  function CheckRequests(props) {
    const [error, setError] = useState({});
    useEffect(() => {
      ajax.interceptors.response.use(
        response => {
          return response;
        },
        error => {
          const statusCode = error.response.status;
          if (statusCode !== HTTP_STATUS.UNAUTHORIZED) {
            setError(error);
          }

          return Promise.reject(error);
        }
      );
    });

    return <Wrapped {...props} error={error} setError={setError} />;
  }

  return CheckRequests;
};

export default withErrorChecking;
