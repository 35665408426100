import { getEnvVars } from './config';

const winston = require('winston');
require('winston-daily-rotate-file');

const { level, format } = getEnvVars().log;

const logger = winston.createLogger({
  level: level,
  transports: [
    new winston.transports.Console({
      format: format,
      silent: false
      // silent: process.env.NODE_ENV === 'production'
    })
  ]
});

export default logger;
