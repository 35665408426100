import { filterAndFormatReflectedArchetypeData } from '../domain/archetype';
import {
  fetchPeopleArchetypes,
  updatePeopleArchetype,
  updatePeopleArchetypeStage
} from '../api/domain/archetype';
import { fetchPeopleCompetencies } from '../api/domain/competency';
import { createGoal, fetchGoals, updateGoal } from '../api/domain/goal';
import { fetchPeopleSkills, removeSkillById } from '../api/domain/skill';
import {
  deleteInterests,
  getInterests,
  postInterests
} from '../api/domain/interests';
import { getProfile, patchProfile } from '../api/domain/profile';

export const FETCH_USER = 'FETCH_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const FETCH_USER_ARCHETYPES = 'FETCH_USER_ARCHETYPES';
export const UPDATE_USER_ARCHETYPES = 'UPDATE_USER_ARCHETYPES';
export const UPDATE_ARCHETYPE_STAGE = 'UPDATE_ARCHETYPE_STAGE';
export const FETCH_USER_REFLECTED_COMPETENCIES =
  'FETCH_USER_REFLECTED_COMPETENCIES';
export const FETCH_USER_GOALS = 'FETCH_USER_GOALS';
export const CREATE_USER_GOAL = 'CREATE_USER_GOAL';
export const CREATE_USER_GOAL_FAILED = 'CREATE_USER_GOAL_FAILED';
export const UPDATE_USER_GOAL = 'UPDATE_USER_GOAL';
export const UPDATE_TIP_SHOW_STATE = 'UPDATE_TIP_SHOW_STATE';
export const FETCH_USER_REFLECTED_SKILLS = 'FETCH_USER_REFLECTED_SKILLS';
export const REMOVE_SKILLS = 'REMOVE_SKILLS';
export const FETCH_USER_SHARE_TYPE = 'FETCH_USER_SHARE_TYPE';
export const FETCH_INTERESTS = 'FETCH_INTERESTS';
export const ADD_INTEREST = 'Add_INTEREST';
export const REMOVE_INTEREST = 'REMOVE_INTEREST';

export const fetchUserStart = () => {
  return {
    type: FETCH_USER,
    payload: {
      loading: true,
      error: false
    }
  };
};

export const fetchUserSuccess = user => {
  return {
    type: FETCH_USER,
    payload: { user, loading: false, error: false, isAuthenticated: true }
  };
};

export const fetchUserFailure = () => {
  return {
    type: FETCH_USER,
    payload: { loading: false, error: true, isAuthenticated: false }
  };
};

export const updateUser = user => {
  return {
    type: UPDATE_USER,
    payload: { user }
  };
};

export const fetchUser = async dispatch => {
  dispatch(fetchUserStart());
  try {
    const user = await getProfile();
    dispatch(fetchUserSuccess(user));
  } catch (e) {
    dispatch(fetchUserFailure());
  }
};

export const updateProfile = dispatch => data => {
  return patchProfile(data)
    .then(() => {
      dispatch({
        type: UPDATE_PROFILE,
        payload: data
      });
      return true;
    })
    .catch(() => {
      return false;
    });
};

export const fetchReflectedArchetypes = dispatch => {
  return fetchPeopleArchetypes()
    .then(res => {
      const reflectedArchetype = filterAndFormatReflectedArchetypeData(res);
      dispatch({
        type: FETCH_USER_ARCHETYPES,
        payload: reflectedArchetype
      });
      return res;
    })
    .catch(() => {
      dispatch({
        type: FETCH_USER_ARCHETYPES,
        payload: []
      });
      return [];
    });
};

export const updateArchetypes = dispatch => (
  updatedArchetypeIds,
  addedArchetypes
) => {
  updatePeopleArchetype(updatedArchetypeIds)
    .then(() => {
      dispatch({
        type: UPDATE_USER_ARCHETYPES,
        payload: addedArchetypes
      });
      return true;
    })
    .catch(() => {
      return false;
    });
};

export const updateArchetypeStage = dispatch => (
  archetypeId,
  reflectedInfo
) => {
  updatePeopleArchetypeStage(reflectedInfo.reflectedInformation.id, {
    stage: reflectedInfo.reflectedInformation.stage
  }).then(
    dispatch({
      type: UPDATE_ARCHETYPE_STAGE,
      payload: {
        archetypeId,
        reflectedInformation: reflectedInfo.reflectedInformation
      }
    })
  );
};

export const fetchReflectedCompetencies = dispatch => {
  return fetchPeopleCompetencies({ reflected: true })
    .then(res => {
      dispatch({
        type: FETCH_USER_REFLECTED_COMPETENCIES,
        payload: res
      });
      return res;
    })
    .catch(() => {
      dispatch({
        type: FETCH_USER_REFLECTED_COMPETENCIES,
        payload: []
      });
      return [];
    });
};

export const fetchReflectedSkills = dispatch => {
  return fetchPeopleSkills({ reflected: true })
    .then(res => {
      dispatch({
        type: FETCH_USER_REFLECTED_SKILLS,
        payload: res
      });
      return res;
    })
    .catch(() => {
      dispatch({
        type: FETCH_USER_REFLECTED_SKILLS,
        payload: []
      });
      return [];
    });
};

export const removeSkills = dispatch => id => {
  return removeSkillById(id).then(() =>
    dispatch({
      type: REMOVE_SKILLS,
      payload: id
    })
  );
};

export const fetchUserGoals = dispatch => {
  return fetchGoals()
    .then(res => {
      dispatch({
        type: FETCH_USER_GOALS,
        payload: res
      });
      return res;
    })
    .catch(() => {
      dispatch({
        type: FETCH_USER_GOALS,
        payload: []
      });
    });
};

export const createUserGoal = dispatch => goal => {
  return createGoal(goal).then(res => {
    if (res) {
      dispatch({
        type: CREATE_USER_GOAL,
        payload: res
      });
    }
  });
};

export const updateUserGoal = dispatch => (goalId, goal) => {
  return updateGoal(goalId, goal)
    .then(res => {
      if (res) {
        dispatch({
          type: UPDATE_USER_GOAL,
          payload: res
        });
      }
    })
    .catch(() => {
      return {};
    });
};

export const fetchInterests = dispatch => {
  return getInterests()
    .then(res => {
      dispatch({
        type: FETCH_INTERESTS,
        payload: res
      });
      return res;
    })
    .catch(() => {
      dispatch({
        type: FETCH_INTERESTS,
        payload: []
      });
      return [];
    });
};

export const addInterest = dispatch => content => {
  return postInterests(content)
    .then(res => {
      dispatch({
        type: ADD_INTEREST,
        payload: res
      });
      return res;
    })
    .catch(() => {
      dispatch({
        type: ADD_INTEREST,
        payload: {}
      });
      return {};
    });
};

export const removeInterest = dispatch => id => {
  return deleteInterests(id).then(() => {
    dispatch({
      type: REMOVE_INTEREST,
      payload: id
    });
  });
};
