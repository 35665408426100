import * as React from 'react';
import PropTypes from 'prop-types';
import Modal from '@mui/material/Modal';
import styles from './index.module.css';
import clsx from 'clsx';
import * as DOMPurify from 'dompurify';
import { CloseIconButton, GotButton } from '../Buttons/Buttons';

const Popup = ({
  title,
  desc,
  open,
  close,
  image,
  headerInfo,
  bottomContent,
  bottomClick,
  modalClassName
}) => {
  const sanitizer = DOMPurify.sanitize;
  const isComponent = typeof image === 'function';
  const renderImage = () => {
    if (isComponent) {
      const ImageComponent = image;
      return (
        <ImageComponent
          className={clsx(styles[modalClassName + 'ImageComponent'])}
        />
      );
    } else return <img src={image} alt="tipIcon" />;
  };

  return (
    <Modal
      role="dialog"
      aria-modal={true}
      open={open}
      onClose={close}
      Backdrop={{
        timeout: 500
      }}
    >
      <div
        aria-labelledby="init"
        className={clsx(styles.modal, styles[modalClassName])}
        data-cy="notification"
      >
        <div className={styles.title}>
          <h2 className={styles.title} id="init">
            {title || 'Are you sure?'}
          </h2>
          <CloseIconButton handleClick={close} />
        </div>
        <section
          className={clsx(styles.content, styles[modalClassName + 'Content'])}
        >
          <div className={clsx(styles.desc, styles[modalClassName + 'Desc'])}>
            <div
              className={clsx(
                styles.contentWrapper,
                styles[modalClassName + 'ContentWrapper']
              )}
            >
              <div className={clsx(styles[modalClassName + 'HeaderWrapper'])}>
                <div
                  className={clsx(
                    styles.logoContent,
                    styles[modalClassName + 'LogoContent']
                  )}
                >
                  {renderImage()}
                </div>
                {headerInfo}
              </div>
              <div
                className={clsx(
                  styles.mainContent,
                  styles[modalClassName + 'MainContent']
                )}
              >
                {desc.length > 0 &&
                  desc.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={clsx(
                          styles.paragraph,
                          styles[modalClassName + 'Paragraph']
                        )}
                      >
                        {item.h && (
                          <h4
                            dangerouslySetInnerHTML={{
                              __html: sanitizer(item.h)
                            }}
                          />
                        )}
                        {item.p && (
                          <p
                            dangerouslySetInnerHTML={{
                              __html: sanitizer(item.p)
                            }}
                          />
                        )}
                      </div>
                    );
                  })}
                {bottomContent && (
                  <div className={styles.bottomWrapper}>
                    <span className={styles.bottomLink} onClick={bottomClick}>
                      {bottomContent.link}
                    </span>
                    {bottomContent.des}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className={clsx(
              styles.operateButtons,
              styles[modalClassName + 'OperateButtons']
            )}
          >
            <GotButton onClick={close}>Got it!</GotButton>
          </div>
        </section>
      </div>
    </Modal>
  );
};

Popup.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.array,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  image: PropTypes.any,
  headerInfo: PropTypes.any,
  bottomContent: PropTypes.any,
  modalClassName: PropTypes.string
};

export default Popup;
