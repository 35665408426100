import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.css';
import clsx from 'clsx';

const StepperLine = ({ steps, activeStep, validated }) => {
  return (
    <ul className={styles.steps}>
      {steps.map((step, index) => {
        const disabled =
          (!validated && index > activeStep) || index === activeStep;
        return (
          <li key={index} className={styles.stepItem}>
            <div className={styles.stepItemInner}>
              <div
                className={clsx(styles.dot, {
                  [styles.active]: index <= activeStep
                })}
              />
              <div
                className={clsx(styles.line, {
                  [styles.active]: index < activeStep
                })}
              />
            </div>
            <div
              className={clsx(styles.title, {
                [styles.active]: index === activeStep
              })}
            >
              <span
                className={clsx({
                  [styles.clickable]: !disabled
                })}
                role="button"
              >
                {step}
              </span>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

StepperLine.defaultProps = {
  activeStep: 0,
  validated: true
};

StepperLine.propTypes = {
  steps: PropTypes.array.isRequired,
  activeStep: PropTypes.number.isRequired,
  validated: PropTypes.bool
};

export default StepperLine;
