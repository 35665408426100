export let CommunityChatRooms = [
  {
    name: 'Advisory',
    room: 'AAAAYuiUdEY'
  },
  {
    name: 'Product Management',
    room: 'AAAAnwQsTnc'
  },
  {
    name: 'Infrastructure Engineering',
    room: 'AAAACfSob6Q'
  },
  {
    name: 'Data',
    room: 'AAAAKqldWqc'
  },
  {
    name: 'Project Management',
    room: ''
  },
  {
    name: 'Analysis',
    room: ''
  },
  {
    name: 'Design',
    room: 'AAAAleQKiEI'
  },
  {
    name: 'Quality',
    room: ''
  },
  {
    name: 'Software Development',
    room: 'AAAAVgoBXy0'
  }
];
