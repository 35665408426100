import greenEye from '../assets/Icons/greenEye.svg';
import blueEye from '../assets/Icons/blueEye.svg';
import orangeEye from '../assets/Icons/orangeEye.svg';

export const Visibility = {
  AllDataVisible: 'AllDataVisible',
  SomeDataVisible: 'SomeDataVisible',
  NoneDataVisible: 'NoneDataVisible'
};

export const VisualizationIcon = {
  AllDataVisible: greenEye,
  SomeDataVisible: blueEye,
  NoneDataVisible: orangeEye
};
