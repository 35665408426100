import { getEnvVars } from '../config/config';

const { capableLink } = getEnvVars();

export const getCompetencyInformationLink = identifier => {
  return `${capableLink}/competencies/${identifier}`;
};

export const checkIsPositiveInteger = value => {
  let v = value;

  if (v === '') {
    return false;
  }

  v = +v;

  if (isNaN(v)) {
    return false;
  }

  return v >= 0;
};
