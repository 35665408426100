import React from 'react';
import { SvgIcon } from '@mui/material';

const InformationFillIcon = props => {
  return (
    <SvgIcon {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-11v6h2v-6h-2zm0-4v2h2V7h-2z" />
    </SvgIcon>
  );
};

export default InformationFillIcon;
