import clsx from 'clsx';
import styles from './index.module.css';
import PropTypes from 'prop-types';
import React from 'react';

export const ModuleLine = ({ className }) => (
  <div className={clsx(styles.moduleLine, styles[className])} />
);
ModuleLine.propTypes = {
  className: PropTypes.string
};
