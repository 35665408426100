import React, { useState } from 'react';
import styles from './PDFCometencies.module.css';
import PropTypes from 'prop-types';
import CompetencyHexagonList from '../../components/Hexagon/CompetencyHexagonList/CompetencyHexagonList';
import dudeLook from '../../assets/Icons/pdfDudeLook.png';
import dudeWait from '../../assets/Icons/pdfDudeWait.png';
import clsx from 'clsx';

const PDFCompetencies = ({ wrapper, type, competencies, title }) => {
  const [info] = useState([
    {
      logo: dudeLook,
      text: 'Me Now',
      offset: styles.meNowOffset
    },
    {
      logo: dudeWait,
      text: 'Me Next',
      offset: styles.meNextOffset
    }
  ]);
  return (
    <section className={styles.pdfCompetenciesWrapper}>
      <header className={styles.logoWrapper}>
        <div className={clsx(styles.imgWrapper, info[type].offset)}>
          <img src={info[type].logo} alt="logo" />
        </div>
        <div className={styles.title}>{info[type].text}</div>
      </header>
      <main className={styles.competencyWrapper}>
        <p className={styles.subTitle}>{title}</p>
        {competencies.length > 0 ? (
          <CompetencyHexagonList
            wrapper={wrapper}
            competencies={competencies}
            onCompetencySelected={() => {}}
            showInPDF={true}
          />
        ) : (
          <div>
            <p className={styles.emptyMsg}>
              {type === 0
                ? 'You have no competencies listed as your strengths yet.'
                : 'You have no competencies listed as your learning priorities yet.'}
            </p>
            <p className={styles.emptySubMsg}>
              {type === 0
                ? 'Please start by selecting competencies in Pathways.'
                : 'Please start by selecting competencies in Pathways.'}
            </p>
          </div>
        )}
      </main>
    </section>
  );
};

PDFCompetencies.propTypes = {
  wrapper: PropTypes.object.isRequired,
  competencies: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.number.isRequired
};

export default PDFCompetencies;
