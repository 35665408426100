import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import * as DOMPurify from 'dompurify';

import { proficiencyLevelsDescription } from '../../../constants/proficiencyLevels';
import { competencyColorsMap as itemBorderColorsMap } from '../../Utils/competencyUtils';
import {
  getWidthBySideWidth,
  getHeightBySideWidth
} from '../../../utils/hexagon';

import styles from './CompetencyHexagon.module.css';
import PencilIcon from '../../Common/SvgIcons/PencilIcon';
import IconButton from '@mui/material/IconButton';
import InformationFillIcon from '../../Common/SvgIcons/InformationFillIcon';
import { onKeyDown } from '../../../utils/onKeyDown';

/* suppose the Hexagon side width is 2x, then the size of the div is
  {
    width: 2sqrt(3)x;
    height: 4x;
  }
  let x = 45px;
  then:   */
export const ORIGIN_HEX_SIZE_HEIGHT = 160;
export const ORIGIN_HEX_SIZE_WIDTH = 138.56406460551017;
export const COM_ITEM_GAP = 8;
const BORDER_WIDTH = 4;
const NO_BELONGS_TO_CAR = 'noBelongsToCar';

const sanitizer = DOMPurify.sanitize;

const smallerSize = {
  transform: 'scale(0.8)',
  transformOrigin: '50% top'
};

export default function CompetencyHexagon(props) {
  const {
    sideWidth,
    coreCapabilities,
    name,
    nameFilter = '',
    description,
    onSelect,
    selected,
    competencyId,
    identifier,
    onSingleClickEdit,
    onSingleClickInfo,
    levelDescriptions,
    showInPDF,
    reflectedInformation
  } = props;
  const [belongsToCar, ...belongsToCdr] = coreCapabilities;
  const width = sideWidth
    ? getWidthBySideWidth(sideWidth)
    : ORIGIN_HEX_SIZE_WIDTH;
  const height = sideWidth
    ? getHeightBySideWidth(sideWidth)
    : ORIGIN_HEX_SIZE_HEIGHT;

  const selectClick = () => {
    if (onSingleClickEdit) {
      onSingleClickEdit({
        competencyId,
        identifier,
        description,
        coreCapabilities,
        name,
        reflectedInformation,
        levelDescriptions
      });
      return;
    }
    onSelect(selected);
  };

  const infoClick = e => {
    e.preventDefault();
    e.stopPropagation();
    const competencyInfo = {
      competencyId,
      identifier,
      description,
      coreCapabilities,
      name,
      reflectedInformation,
      levelDescriptions
    };
    if (onSingleClickEdit) {
      onSingleClickEdit(competencyInfo);
    }
    if (onSingleClickInfo) {
      onSingleClickInfo({
        ...competencyInfo,
        selected,
        onSelect
      });
    }
  };

  return (
    <div
      role="button"
      data-cy="hexagon"
      onClick={selectClick}
      onKeyDown={e => onKeyDown(e, selectClick)}
      tabIndex={showInPDF ? -1 : 0}
      className="hexagonWrapper"
    >
      <div
        tabIndex={-1}
        className={clsx(
          styles.competency,
          showInPDF ? styles.noScale : '',
          itemBorderColorsMap[belongsToCar ? belongsToCar : NO_BELONGS_TO_CAR],
          'CompetencyHexagon',
          {
            [styles.competencyHoverable]:
              !!onSingleClickEdit || !!onSingleClickInfo
          }
        )}
        style={{
          width: width,
          height: height,
          marginRight: COM_ITEM_GAP
        }}
      >
        <div
          style={{
            width: width - 4,
            height: height - 4
          }}
          className={clsx(styles.competencyAfter, {
            [styles.selected]: selected && belongsToCdr.length === 0,
            [styles.competencyDashboard]:
              !!onSingleClickEdit && belongsToCdr.length === 0
          })}
        />
        {belongsToCdr.map((b, i) => {
          const reducedHeight = height - 8 * (i + 1);
          const reducedWidth = width - 8 * (i + 1);
          return (
            <div
              key={i}
              style={{
                width: `${reducedWidth}px`,
                height: `${reducedHeight}px`,
                left: `${BORDER_WIDTH * (i + 1)}px`,
                top: `${BORDER_WIDTH * (i + 1)}px`
              }}
              className={clsx(
                styles.competencyRep,
                styles.competency,
                showInPDF ? styles.noScale : '',
                itemBorderColorsMap[b]
              )}
            >
              <div
                style={{
                  width: `${reducedWidth - 4}px`,
                  height: `${reducedHeight - 4}px`
                }}
                className={clsx(styles.competencyAfter, {
                  [styles.selected]:
                    selected === true && i === belongsToCdr.length - 1,
                  [styles.competencyDashboard]:
                    !!onSingleClickEdit && i === belongsToCdr.length - 1
                })}
              />
            </div>
          );
        })}
        <div
          className={styles.competencyContent}
          style={width < ORIGIN_HEX_SIZE_WIDTH ? { ...smallerSize } : {}}
        >
          {!!onSingleClickInfo ? (
            <div
              className={styles.competencyInfoIconWrapper}
              onClick={e => infoClick(e, competencyId)}
              onKeyDown={e => onKeyDown(e, () => infoClick(e, competencyId))}
            >
              <span
                role="button"
                aria-label="Show details"
                className={styles.competencyInfoIconWrapperInner}
                tabIndex={-1}
              >
                <InformationFillIcon className={styles.competencyInfoIcon} />
              </span>
            </div>
          ) : (
            <div className={styles.noIcon} />
          )}
          <div
            onClick={e => infoClick(e, competencyId)}
            onKeyDown={e => onKeyDown(e, () => infoClick(e, competencyId))}
            className="nameOuter"
            tabIndex={showInPDF ? -1 : 0}
          >
            <div
              className={clsx(
                styles.name,
                showInPDF ? styles.noUnderline : '',
                {
                  [styles.competencyContentSelected]: selected
                },
                {
                  [styles.competencyDashboard]: !!onSingleClickEdit
                }
              )}
              tabIndex={-1}
              dangerouslySetInnerHTML={{
                __html: sanitizer(nameFilter || name)
              }}
            />
          </div>
          {reflectedInformation.practiceLevel ? (
            <div className={styles.proficiencyLevel}>
              <span>
                {
                  proficiencyLevelsDescription[
                    reflectedInformation.practiceLevel - 1
                  ]
                }
              </span>
            </div>
          ) : null}
        </div>
        {!!onSingleClickEdit && (
          <div className={styles.competencyEditIconWrapper}>
            <IconButton
              aria-label="Edit this reflection"
              onClick={selectClick}
              tabIndex={-1}
            >
              <PencilIcon className={styles.competencyEditIcon} />
            </IconButton>
          </div>
        )}
      </div>
    </div>
  );
}

CompetencyHexagon.propTypes = {
  coreCapabilities: PropTypes.array,
  sideWidth: PropTypes.number,
  name: PropTypes.string,
  nameFilter: PropTypes.string,
  description: PropTypes.string,
  onSelect: PropTypes.func,
  selected: PropTypes.bool,
  competencyId: PropTypes.string,
  identifier: PropTypes.string,
  onSingleClickEdit: PropTypes.func,
  onSingleClickInfo: PropTypes.func,
  levelDescriptions: PropTypes.array,
  showInPDF: PropTypes.bool,
  reflectedInformation: PropTypes.object
};
