import React from 'react';

import { Checkbox, FormControlLabel } from '@mui/material';
import globalStyles from '../../globalStyles';
import PropTypes from 'prop-types';

const checkBoxStyles = {
  color: globalStyles.primaryColor,
  '&.Mui-checked': {
    color: globalStyles.primaryColor
  }
};

export const CheckBox = ({ label, onChange, checked, style }) => (
  <FormControlLabel
    control={
      <Checkbox
        aria-label={label}
        onChange={onChange}
        checked={checked}
        sx={checkBoxStyles}
      />
    }
    label={label}
    style={style}
  />
);

CheckBox.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  style: PropTypes.object
};
