export const sortBy = (arr, field) => {
  return arr.sort((a, b) => (a[field] > b[field] ? 1 : -1));
};

export const getUrlParam = name => {
  let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
  let r = window.location.search.substring(1).match(reg);
  let h = window.location.hash.substring(3).match(reg);
  if (r !== null) return decodeURIComponent(r[2]);
  if (h !== null) return decodeURIComponent(h[2]);
  return null;
};
