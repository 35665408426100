import { get, patch, put } from '../util/ajax';
export const fetchPeopleArchetypes = () => {
  return get(`people/archetypes`);
};

export const fetchPeopleArchetypeDetail = archetypeId => {
  return get(`people/archetypes/${archetypeId}`);
};

export const fetchCourseRecommendations = archetypeId => {
  return get(`${archetypeId}/recommended-courses`);
};

export const updatePeopleArchetype = archetypeIds => {
  return put(`people/archetypes`, archetypeIds);
};

export const updatePeopleArchetypeStage = (archetypeId, stageInfo) => {
  return patch(`people/archetypes/${archetypeId}`, stageInfo);
};
