import { get, post, deleteREST } from '../util/ajax';

export const getInterests = () => {
  return get(`interests`);
};

export const postInterests = content => {
  return post(`interests`, content);
};

export const deleteInterests = id => {
  return deleteREST(`interests/${id}`);
};
