import React from 'react';
import { List as LoaderList } from 'react-content-loader';

import styles from './index.module.css';

const Loader = () => {
  return <LoaderList className={styles.loader} />;
};

export default Loader;
