import winston from 'winston';
import { ENV } from '../constants/system';
import { PATHWAYS_LINK } from '../constants/links';

export const callbackPath = '/login/callback';

const host = window.location.host;
const defaultEnvVars = {
  oktaAuth: {
    issuer: 'https://dev-56211939.okta.com/oauth2/default',
    clientId: '0oa58dvngdLZ8Eb8g5d7',
    redirectUri: `https://${host}${callbackPath}`,
    scopes: ['openid', 'email', 'profile']
  },
  bffHostUrl: 'https://pathways-bff-dev-9268541-jhf32szdkq-uc.a.run.app',
  capableLink: 'https://capable.thoughtworks.net',
  log: {
    level: 'debug',
    format: winston.format.combine(
      winston.format.colorize(),
      winston.format.simple()
    )
  },
  matomoSiteId: '58'
};

const Envs = {
  local: {
    ...defaultEnvVars,
    oktaAuth: {
      ...defaultEnvVars.oktaAuth,
      redirectUri: `http://${host}${callbackPath}`
    },
    hostList: ['localhost'],
    env: ENV.LOCAL
  },
  dev: {
    ...defaultEnvVars,
    hostList: [PATHWAYS_LINK.DEV],
    bffHostUrl: 'https://pathways-bff-dev-9268541-jhf32szdkq-uc.a.run.app',
    env: ENV.LOCAL
  },
  qa: {
    ...defaultEnvVars,
    hostList: [PATHWAYS_LINK.QA],
    oktaAuth: {
      ...defaultEnvVars.oktaAuth,
      clientId: '0oa6biol726ERon1Q5d7'
    },
    bffHostUrl: 'https://pathways-bff-qa-71af3f5-jhf32szdkq-uc.a.run.app',
    matomoSiteId: '58',
    env: ENV.QA
  },
  staging: {
    ...defaultEnvVars,
    hostList: [PATHWAYS_LINK.STAGING],
    oktaAuth: {
      ...defaultEnvVars.oktaAuth,
      issuer:
        'https://thoughtworks.oktapreview.com/oauth2/ausjs00lctzrNgRMj0h7',
      clientId: '0oa1hl5l8eeJdYleR0h8'
    },
    bffHostUrl: 'https://pathways-bff-staging-4860cff-jhf32szdkq-uc.a.run.app',
    matomoSiteId: '50',
    env: ENV.STAGING
  },
  prod: {
    ...defaultEnvVars,
    hostList: [PATHWAYS_LINK.PRODUCTION],
    oktaAuth: {
      ...defaultEnvVars.oktaAuth,
      issuer: 'https://thoughtworks.okta.com/oauth2/default',
      clientId: '0oa1qy9vbthJG2RRZ0h8'
    },
    bffHostUrl: 'https://pathways-bff-prod-9bd3e78-xc42xotuuq-uc.a.run.app',
    log: {
      level: 'info',
      format: winston.format.simple()
    },
    matomoSiteId: '51',
    env: ENV.PRODUCTION
  }
};

export function getEnvVars() {
  const defaultEnv = Envs.local;
  const { hostname } = window.location;
  for (let envConfig of Object.values(Envs)) {
    if (envConfig.hostList.includes(hostname)) {
      return envConfig;
    }
  }

  return defaultEnv;
}
