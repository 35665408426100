import * as React from 'react';
import { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './index.module.css';
import LogoPathwaysToJigsaw from '../../../../assets/Icons/pathwaysToJigsawV2.svg';
import { useUserContext } from '../../../../contexts/UserContext';
import { JigsawLink } from '../../../../constants/links';
import { getEnvVars } from '../../../../config/config';
import { styled } from '@mui/material/styles';

const AccordionStyles = styled(Accordion)({
  boxShadow: 'none',
  marginBottom: '20px',
  backgroundColor: 'var(--pageBackgroundColor)',
  '&::before': {
    height: 0
  },
  '&.Mui-expanded:last-child': {
    marginBottom: '20px'
  }
});

const AccordionSummaryStyles = styled(AccordionSummary)({
  flexDirection: 'row-reverse',
  paddingLeft: 0,
  gap: '8px',
  minHeight: '24px',
  height: '24px',
  marginLeft: '-15px',
  '&.Mui-expanded': {
    minHeight: '24px',
    marginBottom: '4px'
  },
  '.MuiAccordionSummary-expandIconWrapper': {
    color: 'var(--black)'
  },
  '.MuiAccordionSummary-content': {
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    color: 'var(--black)'
  }
});

const AccordionDetailsStyles = styled(AccordionDetails)({
  padding: 0,
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '20px',
  color: 'var(--black)'
});

const Helper = () => {
  const {
    state: { user }
  } = useUserContext();
  const { env } = getEnvVars();
  const [expanded, setExpanded] = useState(1);
  const handleChange = panel => (_event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const accordionList = [
    {
      key: 1,
      title: 'How does this affect me?',
      content: (
        <p className={styles.helperContent}>
          Making all your ‘Me Next‘ data visible helps you and staffing teams
          align <b>project opportunities</b> according to your growth
          aspirations.
          <br />
          You will also be more likely to find <b>pairing partners</b> in your
          growth journey.
        </p>
      )
    },
    {
      key: 2,
      title: 'Who will see this data?',
      content: (
        <p className={styles.helperContent}>
          The ‘Me Next‘ data you <b>chose to make visible</b> will be added to
          your{' '}
          <a
            className={styles.link}
            rel="noopener noreferrer"
            target="_blank"
            href={JigsawLink(user?.employeeId, env)}
          >
            Jigsaw Profile
          </a>{' '}
          and can be seen by <b>all Thoughtworkers</b>.
        </p>
      )
    },
    {
      key: 3,
      title: 'What about ‘Me Now’ and ‘My Plan’ data?',
      content: (
        <p className={styles.helperContent}>
          <b>‘Me Now‘</b> data is <b>visible by default</b> to all
          Thoughtworkers.
          <br />
          <b>‘My Plan‘</b> data is <b>always private</b> to you.
        </p>
      )
    },
    {
      key: 4,
      title: 'Can I change my mind?',
      content: (
        <p className={styles.helperContent}>
          You can change what your ‘Me Next’ data is and how much of it you want
          to make visible at <b>any time</b>. No questions asked.
          <br />
          All the changes you make in Pathways will be{' '}
          <b>automatically synced</b> with your{' '}
          <a
            className={styles.link}
            rel="noopener noreferrer"
            target="_blank"
            href={JigsawLink(user?.employeeId, env)}
          >
            Jigsaw Profile
          </a>
          .
        </p>
      )
    }
  ];

  const accordion = ({ key, title, content }) => (
    <div key={key}>
      <AccordionStyles expanded={expanded === key} onChange={handleChange(key)}>
        <AccordionSummaryStyles
          expandIcon={<ExpandMoreIcon />}
          aria-controls={key + '-content'}
          id={key + '-header'}
        >
          {title}
        </AccordionSummaryStyles>
        <AccordionDetailsStyles>{content}</AccordionDetailsStyles>
      </AccordionStyles>
    </div>
  );

  return (
    <div className={styles.helper}>
      <div className={styles.logo}>
        <img src={LogoPathwaysToJigsaw} alt="pathways -> jigsaw" />
      </div>
      <div>{accordionList.map(i => accordion(i))}</div>
    </div>
  );
};

export default Helper;
