export const courseRecommendationsPageDes = {
  description:
    'Discover the most enrolled courses in Campus among Thoughtworkers interested in the same Archetypes as you.',
  meNowTab: 'MeNowTab',
  meNextTab: 'MeNextTab',
  topCourseTabs: [
    'Courses for Archetypes you want to grow into',
    'Courses for Archetypes you can play now'
  ],
  otherArchetypes: {
    title: 'Courses for other Archetypes',
    guide: `You will find them by going to the <b>Top Courses</b> tab
        <br />
        from the Archetype <b>Details</b> screen`
  },
  EntryPoint: {
    subTitle: 'To see course recommendations, you must first',
    meNextEmptyMsg: 'Select the Archetypes you want to grow into',
    meNowEmptyMsg: 'Select the Archetypes you can play now'
  }
};

export const ME_NOW_ARCHETYPE_TAB = 1;
export const ME_NEXT_ARCHETYPE_TAB = 0;
