import React from 'react';
import globalStyles from '../../../../../../globalStyles';
import Button from '@mui/material/Button';
import LinkIcon from '../../../../SvgIcons/LinkIcon';
import { styled } from '@mui/material/styles';

const TellMeMoreButtonWithStyles = styled(Button)({
  backgroundColor: globalStyles.grey80,
  color: globalStyles.white,
  borderRadius: '27.5px',
  margin: '10px 0',
  padding: '5px 10px',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: globalStyles.black
  }
});
const TellMeMoreButton = props => {
  return (
    <TellMeMoreButtonWithStyles variant="contained" {...props}>
      Tell me more&nbsp;&nbsp;
      <LinkIcon />
    </TellMeMoreButtonWithStyles>
  );
};

export default TellMeMoreButton;
